<template>
	<div id="pagination" class="col-auto">
		<div class="d-flex align-items-center justify-content-center">
			<button class="btn btn-sm btn-falcon-default me-1" type="button" title="Previous" data-list-pagination="prev"
				:class="(pagination.currentPage > 1) ? '' : 'disabled'"
				@click="clickButtonPage(1)">
				<span class="fas fa-chevron-left"></span>
				<span class="fas fa-chevron-left"></span>
			</button>
			<button class="btn btn-sm btn-falcon-default me-1" type="button" title="Previous" data-list-pagination="prev"
				:class="(pagination.currentPage > 1) ? '' : 'disabled'"
				@click="clickButtonPage(pagination.currentPage - 1)">
				<span class="fas fa-chevron-left"></span>
			</button>
			<ul class="pagination mb-0">
				<li v-for="(item, index) in pagination.getUrlRange" :key="index" :class="(pagination.currentPage == getPageUrl(item)) ? 'active' : ''">
					<button class="btn btn-sm btn-falcon-default ms-1" type="button" title="Next" data-list-pagination="next"
						:disabled="pagination.currentPage == getPageUrl(item)"
						@click="clickButtonPage(getPageUrl(item))">
						{{ getPageUrl(item) }}
					</button>
				</li>
			</ul>
			<button class="btn btn-sm btn-falcon-default ms-1" type="button" title="Next" data-list-pagination="next"
				:class="(pagination.currentPage < pagination.lastPage) ? '' : 'disabled'"
				@click="clickButtonPage(pagination.currentPage + 1)">
				<span class="fas fa-chevron-right"></span>
			</button>
			<button class="btn btn-sm btn-falcon-default ms-1" type="button" title="Next" data-list-pagination="next"
				:class="(pagination.currentPage < pagination.lastPage) ? '' : 'disabled'"
				@click="clickButtonPage(pagination.lastPage)">
				<span class="fas fa-chevron-right"></span>
				<span class="fas fa-chevron-right"></span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: ['pagination'],
	data () {
		return {
		}
	},
	methods: {
		clickButtonPage(page) {
			this.$emit('getAllList', page)
		},
		getPageUrl(url) {
			return url.split('=')[1];
		}
	}
}
</script>
