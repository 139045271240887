<template>
	<div id="deafultModule">
		<shipping-list-component ref="shippingListComponent" @showManifesto="showManifestoLocal"
			:idDelete="shippingIdSelected" @showShipping="showShippingLocal" :newShipping="newShipping"
			@showShippingByShippingRequest="showShippingByShippingRequestLocal">
		</shipping-list-component>

		<button data-bs-toggle="offcanvas" data-bs-target="#shippingsOffCanvasRight" aria-controls="offcanvasRight"
			ref="btnShowShippingsOffCanvasRight" v-show="false">
			Edit Shipping
		</button>
		<div class="offcanvas offcanvas-end" id="shippingsOffCanvasRight" tabindex="-1" ref="shippingsOffCanvasRight"
			aria-labelledby="shippingsOffCanvasRightLabel" style="width: 500px">
			<div class="offcanvas-header" v-show="shippingIdSelected == 0">
				<h5 id="shippingsOffCanvasRightLabel">Envío - <span v-if="shippingIdSelected == 0">Nuevo</span><span
						v-else>{{ shippingIdSelected }}</span></h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					v-show="false" ref="btnCloseShippingsOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<div class="h-100">
					<shipping-component ref="shippingComponent" :id="shippingIdSelected"
						@deleteShipping="deleteShippingResultLocal" @shippingFound="shippingFoundLocal"
						@shippingRequestNotFound="shippingRequestNotFoundLocal"
						@errorAction="errorActionShippingComponentLocal" @saveShipping="saveShippingResultLocal"
						@saveShippingTrackingResult="saveShippingTrackingResultLocal"></shipping-component>
				</div>
			</div>
			<div class="offcanvas-footer">
				<div class="container pb-1">
					<button class="btn btn-danger btn-sm" :disabled="disabledBtnDeleteShipping"
						v-show="showBtnDeleteShipping" @click="deleteShippingLocal">Eliminar Envío</button>
					<button class="btn btn-primary btn-sm float-end" v-show="showBtnSaveShipping"
						@click="saveShippingLocal">Guardar</button>
				</div>
			</div>
		</div>
		<div class="offcanvas offcanvas-top" id="filtersShippingsOffCanvasTop" tabindex="-1"
			aria-labelledby="filtersShippingsOffCanvasTopLabel">
			<div class="container">
				<div class="offcanvas-header">
					<h5 id="filtersShippingsOffCanvasTopLabel"><span class="fas fa-filter"
							data-fa-transform="shrink-3 down-2"></span> Filtros</h5>
					<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas"
						aria-label="Close"></button>
				</div>
				<div class="offcanvas-body">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import shippings from '@/mixins/shippings'

import ShippingComponent from '@/components/ShippingComponent.vue'
import ShippingListComponent from '@/components/ShippingListComponent.vue'

export default {
	name: 'DeafultModule',
	mixins: [mixin, axiosAction, errors, shippings],
	data() {
		return {
			newShipping: null,
			shippingFoundErasable: false,
			shippingIdSelected: 0
		}
	},
	components: {
		'shipping-component': ShippingComponent,
		'shipping-list-component': ShippingListComponent,
	},
	computed: {
		disabledBtnDeleteShipping() {
			return !this.shippingFoundErasable
		},
		showBtnDeleteShipping() {
			if (this.shippingIdSelected == 0) {
				return false
			}
			return true
		},
		showBtnSaveShipping() {
			if (this.shippingIdSelected == 0) {
				return true
			}
			return false
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		deleteShippingLocal() {
			this.$refs.shippingComponent.$refs.btnDeleteShipping.click()
		},
		deleteShippingResultLocal(data) {
			this.$refs.btnCloseShippingsOffCanvasRight.click()
			setTimeout(() => {
				this.shippingIdSelected = data
				this.$refs.shippingListComponent.$refs.btnDeleteListShippings.click()
			}, 50)
		},
		errorActionShippingComponentLocal(error) {
			this.searchingShipping = false
			console.log(error)
		},
		initModuleLocal() {
			this.$refs.shippingsOffCanvasRight.addEventListener('shown.bs.offcanvas', () => {
				if (this.shippingIdSelected == 0) {
					this.$refs.shippingComponent.$refs.dataShippingInput.focus()
				}
			})
		},
		saveShippingLocal() {
			if (this.showBtnSaveShipping) {
				this.$refs.shippingComponent.$refs.btnSaveShipping.click()
			}
		},
		saveShippingResultLocal(data) {
			this.newShipping = data
			setTimeout(() => { this.$refs.shippingListComponent.$refs.btnAddShippingListShippings.click() }, 50)
			this.shippingIdSelected = 0
		},
		saveShippingTrackingResultLocal(data) {
			this.newShipping = data
			setTimeout(() => { this.$refs.shippingListComponent.$refs.btnAddTrackingShippingResultListShippings.click() }, 50)
		},
		shippingFoundLocal(val, data) {
			this.searchingShipping = false

			if (val) {
				this.shippingFoundErasable = (data != null)
				this.shippingFoundErasable = (!this.shippingFoundErasable) ? this.shippingFoundErasable : this.statusIdsShippingErasableOK(data)
				this.$refs.btnShowShippingsOffCanvasRight.click()
			} else {
				this.showMsgErrorHandling('No encontrado', 'No pudimos encontrar el Envío Nº ' + this.shippingIdSelected, 'warn', 5000, 'top right')
				setTimeout(() => { this.shippingIdSelected = 0 }, 5000)
			}
		},
		shippingRequestNotFoundLocal() {
			this.showMsgErrorHandling('No encontrado', 'No pudimos encontrar un Envío con la Solicitud de Envío Nº ' + this.shippingIdSelected, 'warn', 5000, 'top right')
			this.shippingIdSelected = 0
		},
		showManifestoLocal(id) {
		},
		showShippingLocal(id) {
			this.shippingIdSelected = id
			if (!this.searchingShipping) {
				this.newShipping = null
				this.searchingShipping = true
				setTimeout(() => { this.$refs.shippingComponent.$refs.btnShowShipping.click() }, 50)
			} else {
				this.showMsgErrorHandling('Paciencia', 'Aun estamos buscando otro envío', 'info', 300, 'top right')
			}
		},
		showShippingByShippingRequestLocal(id) {
			if (!this.searchingShipping) {
				this.shippingIdSelected = id
				setTimeout(() => { this.$refs.shippingComponent.$refs.btnShowShippingByShippingRequest.click() }, 50)
			} else {
				this.showMsgErrorHandling('Paciencia', 'Aun estamos buscando otro envío', 'info', 300, 'top right')
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
