var menu = {
	data() {
		return {
			listMenus: [],
			loadingMenus: false,
			objectMenu: {
				exists: 0,
				id: 0,
				name: '',
				level: 1,
				to: '',
				class: '',
				id_parent: null,
				description: '',
				removable: 1,
				created_at: null,
				updated_at: null
			},
			paginationMenus: [],
			savingMenu: false,
			searchMenu: '',
			qRowMenu: 25
		}
	},
	methods: {
		addListMenus(newMenu) {
			this.listMenus.push(newMenu)
		},
		clearObjectMenu() {
			this.objectMenu.exists = 0
			this.objectMenu.id = 0
			this.objectMenu.name = ''
			this.objectMenu.level = 1
			this.objectMenu.to = ''
			this.objectMenu.class = ''
			this.objectMenu.id_parent = null
			this.objectMenu.description = ''
			this.objectMenu.removable = 1
			this.objectMenu.created_at = null
			this.objectMenu.updated_at = null
		},
		getAllMenus(page) {
			this.listMenus = []
			this.paginationMenus = []
			this.loadingMenus = true
			let param = '?search=' + this.searchMenu + '&page=' + page + '&qRow=' + this.qRowMenu
			let url = this.getApiBackendNew + 'menus' + param
			this.axiosActionIS(url, 'GET', 'getAllMenusResult')
		},
		getAllMenusResult(response) {
			this.loadingMenus = false
			if (response.data.success == true) {
				this.listMenus = response.data.data
				this.paginationMenus = response.data.page
			}
			this.getAllMenusResultLocal(response)
		},
		getAllMenusResultLocal(response) {
			console.log('getAllMenusResultResultLocal no desarrollado', response)
		},
		saveMenu(m) {
			this.savingMenu = true
			let method = (m.id == 0) ? 'POST' : 'PUT'
			let url = (m.id == 0) ? 'menu/register' : 'menu/' + m.id + '/update'
			url = this.getApiBackendNew + url
			this.axiosActionIS(url, method, 'saveMenuResult', m)
		},
		saveMenuResult(response) {
			this.savingMenu = false
			if (response.data.success == true) {
				this.setObjectMenu(response.data.data)
				if (response.data.message == 'CREATED') {
					this.addListMenus(response.data.data)
				}
				if (response.data.message == 'UPDATED') {
					this.setItemListMenus(response.data.data)
				}
			}
			this.saveMenuResultLocal(response)
		},
		saveMenuResultLocal(response) {
			console.log('saveMenuResultLocal no desarrollado', response)
		},
		setItemListMenus(item) {
			let index = this.listMenus.map(m => parseInt(m.id)).indexOf(parseInt(item.id))
			if (index > -1) {
				let newItem = Object.create(item)
				for (const property in newItem) {
					this.listMenus[index][property] = newItem[property]
				}
			}
		},
		setObjectMenu(item) {
			this.clearObjectMenu()
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectMenu[property] = newItem[property]
			}
		}
	},
};

export default menu;