/* Rquiere de mixin.js */

var trackingShippingRequest = {
	data() {
		return {
			listTrackingsShippingRequest: [],
			methods: {
				trackingsShippingRequestGet: 'trackingsShippingRequest',
			},
			paginationListTrackingsShippingRequest: [],
			loadingTrackingsShippingRequest: false
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		getAllTrackingsShippingRequest(page = 1) {
			this.loadingTrackingsShippingRequest = true
			let parameters = '?search=' + this.searchShippingsRequest + '&page=' + page
			let url = this.getApiBackendNew + this.methods.trackingsShippingRequestGet + parameters
			this.axiosActionIS(url, 'GET', 'getAllTrackingsShippingRequestResult')
		},
		getAllTrackingsShippingRequestResult(response) {
			if (response.data.success == true) {
				this.listTrackingsShippingRequest = response.data.data
				this.paginationListTrackingsShippingRequest = response.data.paginate
			}
			this.getAllTrackingsShippingRequestResultLocal(response)
		},
		getAllTrackingsShippingRequestResultLocal(response) {
			console.log('getAllTrackingsShippingRequestResultLocal no desarrollado', response)
		}

	},
};

export default trackingShippingRequest;