var product = {
    data() {
        return {
            objectProduct: {
                exists: 0,
                id: 0,
                name: '',
                synthetic: null,
                isbn: null,
                length: '',
                width: '',
                height: '',
                weight: '',
                img: '',
                group: 'U',
                status_id: '',
                status: '',
                type_product_id: '',
                type_product: '',
                editorial_id: '',
                editorial: '',
                type_binding_id: '',
                type_binding: '',
                product_locations: [],
                product_price_list: []
            },
            searchingProduct: false
        }
    },
    methods: {
        clearObjectProduct() {
            this.objectProduct.exists = 0
            this.objectProduct.id = 0
            this.objectProduct.name = ''
            this.objectProduct.synthetic = null
            this.objectProduct.isbn = null
            this.objectProduct.length = ''
            this.objectProduct.width = ''
            this.objectProduct.height = ''
            this.objectProduct.weight = ''
            this.objectProduct.img = ''
            this.objectProduct.group = 'U'
            this.objectProduct.status_id = ''
            this.objectProduct.status = ''
            this.objectProduct.type_product_id = ''
            this.objectProduct.type_product = ''
            this.objectProduct.editorial_id = ''
            this.objectProduct.editorial = ''
            this.objectProduct.type_binding_id = ''
            this.objectProduct.type_binding = ''
            this.objectProduct.product_locations = []
            this.objectProduct.product_price_list = []
            this.objectProduct.created_at = ''
            this.objectProduct.updated_at = ''
        },
        setObjectProduct(item) {
            this.clearObjectProduct()
            for (const property in item) {
                this.objectProduct[property] = item[property]
            }
        },
        showProduct(id) {
            this.searchingProduct = true
            let url = this.getApiBackendNew + 'product/' + id
            this.axiosActionIS(url, 'GET', 'showProductResult', null)
        },
        showProductResult(response) {
            this.searchingProduct = false
            if (response.data.success == true) {
                this.setObjectProduct(response.data.data)
            }
            this.showProductResultLocal(response)
        },
        showProductResultLocal(response) {
            console.log('showProductResultLocal no desarrollado', response)
        },
    },
};

export default product;