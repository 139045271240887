<template>
	<div id="userListComponent">
		<div class="card mb-3" id="usersTable">
			<div class="card-header">
                <div class="row flex-between-center">
                    <div class="col-3 col-sm-auto d-flex align-items-center pe-0">
                        <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Usuarios</h5>
                    </div>
                    <div class="col-3 col-sm-13">
						<div class="input-group">
							<input class="form-control form-control-sm shadow-none search" type="search" placeholder="Buscar pedidos" aria-label="search"
								v-model="searchUsers"
								@keyup.enter="getAllUsersLocal(1)"/>
							<div class="input-group-text bg-transparent pointer" @click="getAllUsersLocal(1)">
								<span class="fa fa-search fs--1 text-600"></span>
							</div>
						</div>
                    </div>
					<div class="col-6 col-sm-auto ms-auto text-end ps-0">
                        <div id="orders-actions">
                            <button class="btn btn-falcon-default btn-sm" type="button" @click="showUserWizardComponentModal(0)" v-show="actionSecurity(6)">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
                            <button class="btn btn-falcon-default btn-sm mx-2 d-none" type="button">
								<span class="fas fa-filter" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Filter</span>
							</button>
                            <button class="btn btn-falcon-default btn-sm d-none" type="button">
								<span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Export</span>
							</button>
                        </div>
					</div>
				</div>
            </div>
            <div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
						<thead class="bg-200">
							<tr>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="name">Nombre</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="email">Correo</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="nick">Nick</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="created">Creado</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="role">Tipo de Usuario</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap ps-5" data-sort="address" style="min-width: 200px;">Estado</th>
								<th class="align-middle no-sort"></th>
							</tr>
						</thead>
						<tbody class="list" id="table-users-body">
							<tr class="btn-reveal-trigger" v-for="(item, index) in listUsersFilter" :key="index">
								<td class="name align-middle white-space-nowrap py-2">
									<router-link :to="'/user/' + item.id">
										<div class="d-flex d-flex align-items-center">
											<div class="avatar avatar-xl me-2">
												<div class="avatar-name rounded-circle">
													<span>{{ initialsUser(item) }}</span>
												</div>
											</div>
											<div class="flex-1">
												<h5 class="mb-0 fs--1">{{ item.name }}</h5>
											</div>
										</div>
									</router-link>
								</td>
								<td class="email align-middle py-2">
									<a :href="'mailto:' + item.email">{{ item.email }}</a>
								</td>
								<td class="phone align-middle white-space-nowrap py-2">
									<i>{{ item.nick }}</i>
								</td>
								<td class="joined align-middle py-2">{{ item.created_at }}</td>
								<td class="joined align-middle py-2">{{ roleUserFirs(item) }}</td>
								<td class="address align-middle white-space-nowrap ps-5 py-2">
									<div class="form-check form-switch">
										<input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" v-model="item.status_id" :disabled="disableBtnStatusId" @click="updateUserStatusIdLocal(item)"/>
									</div>
								</td>
								<td class="align-middle white-space-nowrap py-2 text-end">
									<div class="dropdown font-sans-serif position-static">
										<button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" id="customer-dropdown-0" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
											<span class="fas fa-ellipsis-h fs--1"></span>
										</button>
										<div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="customer-dropdown-0">
											<div class="py-2">
												<span class="dropdown-item pointer" @click="showUserWizardComponentModal(item.id)">Ver</span>
												<span class="dropdown-item text-danger pointer" v-show="item.removable == 1 && actionSecurity(7)" @click="deleteUserLocal(item)">Eliminar</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
              </div>
            </div>
            <div class="card-footer d-flex align-items-center justify-content-center">
				<div class="col-auto">
					<select class="form-select form-select-sm" v-model="qRowsUser" @change="changeQRowsUser">
						<option value="10">10 Resultados</option>
						<option value="25">25 Resultados</option>
						<option value="50">50 Resultados</option>
						<option value="100">100 Resultados</option>
						<option value="200">200 Resultados</option>
						<option value="1000">1000 Resultados</option>
					</select>
				</div>

				<pagination :pagination="paginationUsers" @getAllList="getAllUsersLocal"></pagination>
            </div>
        </div>

		<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#userWizarComponentModal" ref="btnShowUserWizardComponentModal" v-show="false">
			Abrir User Edit Component
		</button>

		<!-- Modal -->
		<div class="modal fade" id="userWizarComponentModal" tabindex="-1" aria-labelledby="userWizarComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<user-wizar-component class="modal-content"
						ref="userWizarComponent"
						:id="userIdSelected"
						@messageParent="showMessageLocal"
						@saveUser="saveUserLocal"></user-wizar-component>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import users from '@/mixins/users'

import Pagination from '@/components/Pagination.vue'
import UserWizardComponent from '@/components/UserWizardComponent.vue'

export default {
	name: 'UserListComponent',
	mixins: [mixin, axiosAction, errors, users],
	props: [],
	data () {
		return {
			userIdSelected: 0
		}
	},
	components: {
		'user-wizar-component': UserWizardComponent,
		'pagination': Pagination,
	},
	computed: {
		disableBtnStatusId() {
			return !this.actionSecurity(6)
		},
		listUsersFilter (){
			return this.listUsers.filter(u => {
				u.status_id = (u.status_id == 2) ? 0 : u.status_id
				return u
			})
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'deleteUserResult': this.deleteUserResult(response); break;
				case 'getAllUsersResult': this.getAllUsersResult(response); break;
				case 'updateUserFieldResult': this.updateUserFieldResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		deleteUserLocal(user){
			if(confirm('Está a punto de borrar al usuario ' + user.name + '\n¿Desea continuar?')) {
				this.deleteUser(user.id)
			}
		},
		deleteUserResultLocal(response) {},
		getAllUsersLocal(page = 1) {
			this.getAllUsers(page)
		},
		getAllUsersResultLocal(response) {
		},
		initModuleLocal() {
			this.getAllUsersLocal()
		},
		saveUserLocal(u, type = 'NEW') {
			if(type == 'NEW') {
				this.addListUsers(u)
			} else if(type == 'UPDATE'){
				this.updateItemListUsers(u)
			}
		},
		showMessageLocal(data) {
		},
		showUserWizardComponentModal(userId = 0) {
			this.userIdSelected = userId
			setTimeout(() => { this.$refs.userWizarComponent.$refs.btnShowUser.click() }, 10)
			this.$refs.btnShowUserWizardComponentModal.click()
		},
		updateUserStatusIdLocal(u) {
			setTimeout(() => {
				let statudId = (u.status_id) ? 1 : 2
				this.updateUserStatusId(u.id, statudId)
			}, 100)
		},
		updateUserFieldResultLocal(response) {}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>