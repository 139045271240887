<template>
	<div id="paymentListComponent">
		<button @click="getPaymentsLocal()" ref="btnGetPayment" v-show="false">Get Payments</button>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Pagos </h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="Buscar solicitudes" aria-label="search" ref="searchShippings"
										:disabled="loadingPayments" v-model="searchPayments"
										@focus="$event.target.select()" @keyup.enter="getPaymentsLocal(1)" />
									<div class="input-group-text bg-transparent pointer" @click="getPaymentsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button" data-bs-toggle="offcanvas"
								data-bs-target="#filtersShippingsOffCanvasTop"
								aria-controls="filtersShippingsOffCanvasTop">
								<span class="fas fa-filter" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Filtros</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">Id</th>
								<th class="text-900">Forma de Pago</th>
								<th class="text-900 text-end">Monto</th>
								<th class="text-900 text-center">Banco</th>
								<th class="text-900 text-center">Codigo</th>
								<th class="text-900">Descripción</th>
								<th class="text-900 text-center" style="width: 135px;">
									Fecha</th>
								<th class="text-900 text-center">Estado</th>
								<th class="text-900">Vinculo</th>
							</tr>
						</thead>
						<tbody class="">
							<tr class="border-start-2 border-end-1 hover-actions-trigger"
								v-for="(p, i) in listPaymentsFilter" :key="i">
								<td class="text-center">
									<span @dblclick="console(p)" class="pointer" data-bs-toggle="offcanvas"
										data-bs-target="#paymentComponentOffCanvasRight"
										@click="showPaymentLocal(p.id)">{{ p.id }}</span>
								</td>
								<td>{{ p.type_payment.name }}</td>
								<td class="text-end">{{ methodFormatNumber(p.amount) }}</td>
								<td>
									<div v-show="p.bank_id != null">
										<select v-model="p.bank_id" :disabled="true"
											style="background: transparent; width: 110%; border: none"
											class="text-muted">
											<option value="1">Estado</option>
											<option value="2">Bci</option>
										</select>
									</div>
								</td>
								<td class="text-center">{{ p.code }}</td>
								<td>{{ p.description }}</td>
								<td class="text-center">
									<input type="date" v-model="p.transaction_date" :readonly="true"
										class="text-center text-muted"
										style="background: transparent; width: 80%; border: none">
								</td>
								<td class="text-center">
									<span class="badge" :class="'bg-' + p.status.color">{{ p.status.name }}</span>
									<small class="badge rounded badge-subtle-success false"></small>
								</td>
								<td><span @dblclick="console(p.source)">{{ paymentSource(p.source) }}</span></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationPayments" @getAllList="getPaymentsLocal">
					</pagination>
				</div>
			</div>
		</div>

		<div class="offcanvas offcanvas-end" id="paymentComponentOffCanvasRight" tabindex="-1"
			ref="paymentComponentOffCanvasRight" aria-labelledby="paymentComponentOffCanvasRightLabel"
			style="width: 500px">
			<div class="offcanvas-header">
				<h5 id="paymentComponentOffCanvasRightLabel">Pago ID {{ paymentIdSelected }}</h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					ref="btnCloseNewJourlaItemOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<payment-component :id="paymentIdSelected" ref="paymentComponent"
					@updatePaymentResult="updatePaymentResultLocal"></payment-component>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import payment from '@/mixins/generals/payment.js'

import Pagination from '@/components/Pagination.vue'
import PaymentComponent from '@/components/PaymentComponent.vue'

export default {
	name: 'PaymentListComponent',
	mixins: [mixin, axiosAction, errors, payment],
	props: [],
	data() {
		return {
			paymentIdSelected: 0
		}
	},
	components: {
		'pagination': Pagination,
		'payment-component': PaymentComponent,
	},
	computed: {
		listPaymentsFilter() {
			return this.listPayments
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllPaymentsResult': this.getAllPaymentsResult(response); break;
				case 'showPaymentResult': this.showPaymentResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getPaymentsLocal(page = 1) {
			if (this.loadingPayments == false) {
				this.getPayments(page)
			} else {
				this.showMsgErrorHandling('ESPERE', 'Espere, aun no termina de cargar lo anterior.', 'info', 5000, 'top right')
			}
		},
		getAllPaymentsResultLocal(response) { },
		initModuleLocal() {
			this.setPaymentListInit()
		},
		showPaymentLocal(id) {
			this.paymentIdSelected = id
			setTimeout(() => {
				this.$refs.paymentComponent.$refs.btnShowPayment.click()
			}, 50);
		},
		showPaymentResultLocal(response) {
			console.log(response)
		},
		setPaymentListInit() {
			this.dateFromPayments = this.currentDate(-360)
			this.dateToPayments = this.currentDate() + ' 23:59'
			console.log(this.dateToPayments)
			this.$refs.btnGetPayment.click()
		},
		updatePaymentResultLocal(response) {
			if (response.data.success == true) {
				this.updateListPayments(response.data.data)
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>