var productPriceList = {
	data() {
		return {
			listProductsPriceList: [],
			listProductsPriceListNotBonus: [],
			listProductsPriceListNotFreight: [],
			listProductsPriceListNotTithe: [],
			searchingProductsPriceList: false,
			searchingProductPriceListNotBonus: false,
			searchingProductPriceListNotFreight: false,
			searchingProductPriceListNotTithe: false,
			urlsProductPriceList: {
				get: 'priceLists',
				show: 'priceList/',
				products: '/products'
			},
			paginationProductsPriceList: [],
			searchProductsPriceList: '',
			priceListsIdSelect: 0,
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		calculateTitheProduct(productId) {
			let list = this.listProductsPriceListNotTithe.filter((i) => {
				return (parseInt(i.product_id) == parseInt(productId) && parseInt(i.price_list_id) == parseInt(this.priceListsIdSelect))
			})
			return (list.length == 0)
		},
		getAllProductsPriceList(page = 1) {
			this.searchingProductsPriceList = true
			let parameters = '?search=' + this.searchProductsPriceList + '&page=' + page
			this.axiosActionIS(this.getApiBackend + this.urlsProductPriceList.get + parameters, 'GET', 'getAllProductsPriceListResult')
		},
		getAllProductsPriceListResult(response) {
			this.searchingProductsPriceList = false
			if (response.data.success) {
				this.listProductsPriceList = response.data.data
				this.paginationProductsPriceList = response.data.paginate
			}
			this.getAllProductsPriceListResultLocal(response)
		},
		getAllProductsPriceListResultLocal(response) {
			console.log('getAllProductsPriceListResultLocal no desarrollado', response)
		},
		getProductPriceList(id, search = '') {
			let parameters = '?search=' + search
			this.axiosActionIS(this.getApiBackend + this.urlsProductPriceList.show + id + this.urlsProductPriceList.products + parameters, 'GET', 'getProductPriceListResult')
		},
		getProductPriceListNotBonus(id) {
			this.searchingProductPriceListNotBonus = true
			let url = this.getApiBackend + this.urlsProductPriceList.show + id + this.urlsProductPriceList.products + '/notBonus'
			this.axiosActionIS(url, 'GET', 'getProductPriceListNotBonusResult')
		},
		getProductPriceListNotFreight(id) {
			this.searchingProductPriceListNotFreight = true
			let url = this.getApiBackend + this.urlsProductPriceList.show + id + this.urlsProductPriceList.products + '/notFreight'
			this.axiosActionIS(url, 'GET', 'getProductPriceListNotFreightResult')
		},
		getProductPriceListNotTithe(id) {
			this.searchingProductPriceListNotTithe = true
			let url = this.getApiBackend + this.urlsProductPriceList.show + id + this.urlsProductPriceList.products + '/notTithe'
			this.axiosActionIS(url, 'GET', 'getProductPriceListNotTitheResult')
		},
		getProductPriceListResult(response) {
			this.searchingProductsPriceList = false
			if (response.data.success == true) {
				this.listProductsPriceList = response.data.data
			}
			this.getProductPriceListResultLocal(response)
		},
		getProductPriceListResultLocal(response) {
			console.log('getProductPriceListResultLocal no está desarrollado', response)
		},
		getProductPriceListNotBonusResult(response) {
			this.searchingProductPriceListNotBonus = false
			if (response.data.success) {
				this.listProductsPriceListNotBonus = response.data.data
			}
			this.getProductPriceListNotBonusResultLocal(response)
		},
		getProductPriceListNotBonusResultLocal(response) {
			console.log('getProductPriceListNotBonusResultLocal no está desarrollado', response)
		},
		getProductPriceListNotFreightResult(response) {
			this.searchingProductPriceListNotFreight = false
			if (response.data.success) {
				this.listProductsPriceListNotFreight = response.data.data
			}
			this.getProductPriceListNotFreightResultLocal(response)
		},
		getProductPriceListNotFreightResultLocal(response) {
			console.log('getProductPriceListNotFreightResultLocal no está desarrollado', response)
		},
		getProductPriceListNotTitheResult(response) {
			this.searchingProductPriceListNotTithe = false
			if (response.data.success) {
				this.listProductsPriceListNotTithe = response.data.data
			}
			this.getProductPriceListNotTitheResultLocal(response)
		},
		getProductPriceListNotTitheResultLocal(response) {
			console.log('getProductPriceListNotTitheResultLocal no está desarrollado', response)
		},
		isBonusProduct(p) {
			let index = this.listProductsPriceListNotBonus.map(e => parseInt(e.product_id)).indexOf(parseInt(p.product_id))
			return (index == -1)
		},
		isFreightProduct(p) {
			let index = this.listProductsPriceListNotFreight.map(e => parseInt(e.product_id)).indexOf(parseInt(p.product_id))
			return (index == -1)
		},
		isTitheProduct(p) {
			let index = this.listProductsPriceListNotTithe.map(e => parseInt(e.product_id)).indexOf(parseInt(p.product_id))
			return (index == -1)
		},
	},
};

export default productPriceList;