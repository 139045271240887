<template>
	<div id="consignmentModalComponent">
        <button class="btn btn-primary d-none" type="button" data-bs-toggle="modal" data-bs-target=".consignmentModalComponent" ref="btnShowConsignmentModalComponent" @click="btnShowConsignmentModalComponent">Launch static backdrop modal</button>
        <div class="modal fade consignmentModalComponent" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" aria-labelledby="consignmentModalComponentLabel" aria-hidden="true">
                <div class="modal-dialog mt-6 " role="document" :class="(objectConsignment.id == 0) ? 'modal-md' : 'modal-xl'">
                    <div class="modal-content bconsignment-0">
                        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" @click="btnCloseConsignmentModalComponent" ref="btnCloseConsignmentModalComponent"></button>
                        </div>
                        <div class="modal-body p-0" v-if="!loadingConsignments">
                            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                                <h4 class="mb-1" id="consignmentModalComponentLabel" v-if="objectConsignment.id == 0">Agregar Nueva Remesa</h4>
                                <h4 class="mb-1" id="consignmentModalComponentLabel" v-else>Remesa {{objectConsignment.description}} #{{objectConsignment.id}}</h4>
                                <p class="fs--2 mb-0" v-if="objectConsignment.id == 0">Creando remesa por <a class="link-600 fw-semi-bold" href="#!">{{getUser.name}}</a></p>
                                <p class="fs--2 mb-0" v-else>
									<i v-if="objectConsignment.consignment_trackings_consignment.length == 0">?</i>
									<span v-else>
										Creado por 
										<a class="link-600 fw-semi-bold" href="#!">{{objectConsignment.consignment_trackings_consignment[0].user.name}}</a>
									</span>
								</p>
                            </div>
							<div class="alert alert-info m-2" role="alert" v-show="messagePaymentTableComponenLocal != ''">{{ messagePaymentTableComponenLocal }}</div>
							<div class="p-4">
								<div class="row g-3">
									<div class="col-md-6">
										<label class="form-label" for="inputTeam">
											Campaña
										</label>
										<select class="form-select form-select-sm" id="inputTeam" v-model="objectConsignment.team_id" :disabled="disabledEditConsignment">
											<option value="0" selected disabled>
												Seleccione la campaña...
											</option>
											<option v-for="(item, index) in listTeams" :key="index" :value="item.id">{{item.name}}</option>
										</select>
									</div>
									<div class="col-md-6">
										<label class="form-label" for="inputClient">
											Descripción
										</label>
										<input class="form-control form-control-sm" type="text" v-model="objectConsignment.description" :disabled="disabledEditConsignment"/>
									</div>
								</div>
								<div class="text-end">
									<button class="btn btn-sm btn-primary mt-4" @click="saveConsignmentLocal" :disabled="disabledBtnSaveConsignment">Guardar</button>
								</div>
								<div v-show="objectConsignment.id > 0">
									<hr class="my-4" />
									<payment-table-component ref="paymentTableComponent" 
										:id="objectConsignment.id"
										controller="consignment"
										:disableBtnPlusPayment="(objectConsignment.status_id != 49) || disabledEditConsignment"
										@savePaymentParent="savePaymentLocal"
										@deletePaymentParent="deletePaymentLocal"
										@showUploadDocumentParent="showUploadDocumentLocal">
									</payment-table-component>
								</div>
							</div>
                        </div>
						<div class="modal-body p-0 text-center" v-else>
							<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos cargando la remesa Nº {{ consignmentId }} . . .</p>
						</div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import consignments from '@/mixins/consignments'
import teams from '@/mixins/teams'

import PaymentTableComponent from '@/components/PaymentTableComponent.vue'

export default {
	name: 'ConsignmentModalComponent',
	mixins: [mixin, axiosAction, errors, consignments, teams],
	props: ['consignmentId'],
	data () {
		return {
		}
	},
	components: {
		'payment-table-component': PaymentTableComponent
	},
	computed: {
		disabledBtnSaveConsignment() {
			if(this.objectConsignment.team_id == 0) 
				return true
			if(this.objectConsignment.description.trim().length < 3)
				return true
			if(this.disabledEditConsignment)
				return this.disabledEditConsignment
			return false
		},
		disabledEditConsignment() {
			if(this.loadingTeams == true) {
				return true
			}
			if(parseInt(this.objectConsignment.multiple_payment) == 0) {
				return true
			}
			return false
		},
		messagePaymentTableComponenLocal() {
			return (this.disabledEditConsignment) ? 'No se puede asignar Pagos a esta remesa' : ''
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'getAllTeamsResult': this.getAllTeamsResult(response); break;
				case 'saveConsignmentResult': this.saveConsignmentResult(response); break;
				case 'showConsignmentResult': this.showConsignmentResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setOrderInit()
		},
		deletePaymentLocal(id) {
			this.deleteObjectConsignmentPayment(this.objectConsignment.id, id)
			this.$emit('deletePaymentParent', this.objectConsignment.id, id)
		},
		getAllTeamsLocal() {
			this.getAllTeamByUserClient()
		},
		getAllTeamsResultLocal(response) { },
		btnCloseConsignmentModalComponent() {
			this.clearObjectConsignment()
		},
		btnShowConsignmentModalComponent() {
			if(this.consignmentId > 0) {
				this.showConsignment(this.consignmentId)
			} else {
				this.clearObjectConsignment()
			}
		},
		saveConsignmentLocal() {
			this.saveConsignment(this.objectConsignment)
		},
		saveConsignmentResultLocal(response) {
			if(response.data.success === true) {
				if(response.data.message == 'NEW') {
					setTimeout(() => {this.$refs.paymentTableComponent.$refs.btnSetPaymentTableComponent.click()}, 100)
				}
				this.$emit('saveConsignmentComponent', response)
			} else {
			}
		},
		savePaymentLocal(data) {
			this.addObjectConsignmentPayment(this.objectConsignment.id, data)
			this.$emit('savePaymentParent', this.objectConsignment.id, data)
		},
		setOrderInit() {
			this.getAllTeamsLocal()
		},
		showConsignmentResultLocal(response) {
			if(response.data.success == true) {
				if(response.data.data.id > 0) {
					setTimeout(() => {this.$refs.paymentTableComponent.$refs.btnSetPaymentTableComponent.click()}, 100)
				}
			}
		},
		showUploadDocumentLocal(controller, paymentId, message) {
			this.$emit('showUploadDocumentParent', controller, paymentId, message)
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>