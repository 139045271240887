var budget = {
	data() {
		return {
			objectBudget: {
				exists: 0,
				id: 0,
				name: '',
				type_job_id: 0,
				dimension: '',
				client_id: 0,
				client: {},
				components: [],
				quantities: []
			},
			objectBudgetComponent: {
				id: 0,
				budget_id: 0,
				name: '',
				pages: 0,
				format: '',
				color: '',
				type_paper_id: 0,
				grammage: '',
				dimension_original: '',
				technology_id: 0,
				dimension_end: ''
			},
			objectBudgetQuantity: {
				id: 0,
				budget_id: 0,
				quantity: 0
			}
		}
	},
	methods: {
		addNewItemBlankObjectBudgetComponent() {
			this.clearObjectBudgetComponent()
			let propiertiesItem = Object.create(this.objectBudgetComponent)
			let newObject = new Object()
			for (const property in propiertiesItem) {
				Object.defineProperty(newObject, property, { value: propiertiesItem[property], writable: true, enumerable: true, configurable: true })
			}
			this.objectBudget.components.push(newObject)
		},
		clearObjectBudget() {
			this.objectBudget.exists = 0
			this.objectBudget.id = 0
			this.objectBudget.name = ''
			this.objectBudget.type_job_id = 0
			this.objectBudget.dimensions = ''
			this.objectBudget.client_id = 0
			this.client = {}
			this.components = []
			this.quantities = []
			this.addNewItemBlankObjectBudgetComponent()
			this.clearObjectBudgetQuantity()
		},
		clearObjectBudgetComponent() {
			this.objectBudgetComponent.id = 0
			this.objectBudgetComponent.budget_id = 0
			this.objectBudgetComponent.name = ''
			this.objectBudgetComponent.pages = 0
			this.objectBudgetComponent.format = ''
			this.objectBudgetComponent.color = ''
			this.objectBudgetComponent.type_paper_id = 0
			this.objectBudgetComponent.grammage = ''
			this.objectBudgetComponent.dimension_original = ''
			this.objectBudgetComponent.technology_id = 0
			this.objectBudgetComponent.dimension_end = ''
		},
		clearObjectBudgetQuantity() {
			this.objectBudgetQuantity.id = 0
			this.objectBudgetQuantity.budget_id = 0
			this.objectBudgetQuantity.quantity = 0
		}
	},
};

export default budget;