var comuna = {
    data() {
        return {
            listComunas: [],
            loadingComunas: false,
            paginationComunas: [],
            searchComunas: '',
            urlsComuna: {
                get: 'comunas',
                show: 'comuna/',
            },
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getAllComunas(page = 1) {
            this.loadingComunas = true
            this.listComunas = []
            this.paginationComunas = []
            let parameters = '?search=' + this.searchComunas + '&page=' + page
            let url = this.getApiBackend + this.urlsComuna.get + parameters
            this.axiosActionIS(url, 'GET', 'getAllComunasResult')
        },
        getAllComunasResult(response) {
            this.loadingComunas = false
            this.listComunas = []
            this.paginationComunas = []
            if (response.data.success) {
                this.listComunas = response.data.data
                this.paginationComunas = response.data.paginate
            }
            this.getAllComunasResultLocal(response)
        },
        getAllComunasResultLocal(response) {
            console.log('getAllComunasResultLocal no desarrollado', response)
        },
        showComuna(id) {
            this.loadingComunas = true
            let url = this.getApiBackend + this.urlsComuna.show + id
            this.axiosActionIS(url, 'GET', 'showComunaResult')
        },
        showComunaResult(response) {
            this.loadingComunas = false
            this.showComunaResultLocal(response)
        },
        showComunaResultLocal(response) {
            console.log('showComunaResultLocal no está desarrollado', response)
        },
        selectComunaByStr(str) {
            let comuna = { id: 0, name: '' }
            this.listComunas.forEach(c => {
                if (c.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
                    comuna = c
                }
            });
            return comuna
        }
    },
};

export default comuna;