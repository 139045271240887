var facturacionCL = {
	data() {
		return {
			printingFacturacionCL: false
		}
	},
	methods: {
		getElectronicTicket(tipodte, folio, toPrint = false, header = '', quantity = 1) {
			let url = this.getApiBackend + 'facturacionCL/getElectronicTicket?tipodte=' + tipodte + '&folio=' + folio
			this.axios.get(url, { headers: this.getHeaders })
				.then(response => {
					if (response.data.Error === undefined) {
						if (toPrint) {
							console.group('Imprimiendo documento ' + folio);
							header = (header != '') ? this.repeatCharacter(' ', 32 - parseInt(header.length / 2)) + '!!' + header + '!\n\n' : header
							header += (header != '') ? this.repeatCharacter('#', 64) : header
							let head = (header != '') ? header + '\n' + window.atob(response.data.Head) : window.atob(response.data.Head)
							this.printFacturacionCL(window.btoa(head), response.data.TED, response.data.Foot, localStorage.printerFacturacionCL, quantity)
							console.groupEnd()
						}
					} else {
						alert(response.data.Error)
					}
					this.printingFacturacionCL = false
				})
				.catch(error => {
					console.log(error)
					this.printingFacturacionCL = false
					console.groupEnd()
				})
		},
		printFacturacionCL(head, ted, foot, printer, quantity = 1) {
			if (printer != undefined) {
				try {
					if (!this.getSuccessFullConnectionFacturacionCLPrint) {
						let mensaje = 'No hay conexión con la aplicación de FacturacionCL'
					} else {
						let ringType = 'UERGNDE3';
						let openDrawer = 'Tk8=';
						let printer64 = window.btoa(printer)

						let ticket = head + '|' + ted + '|' + foot + '|' + printer64 + '|' + ringType + '|' + openDrawer;

						for (let i = 0; i < quantity; i++) {
							this.socketFacturacionCLPrinter(ticket);
						}
						console.info('printFacturacionCL - Ticket enviada a la impresora ' + printer);
					}
				} catch (exception) {
					console.info('printFacturacionCL - ERROR: ' + exception);
				}
			} else {
				alert('No hay una impresora seleccionada para las boletas')
				this.$refs.openModalSetingModule.click()
			}
		},
		toPrintFacturacionCL(tipodte, folio, head = '', quantity = 1) {
			this.printingFacturacionCL = true
			this.getElectronicTicket(tipodte, folio, true, head, quantity)
		}
	},
};

export default facturacionCL;