<template>
	<div id="manifestoListComponent">
		<!-- Componente Visible-->
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Manifiestos </h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="Buscar manifiestos" aria-label="search" ref="searchShippings"
										:disabled="loadingManifestos" v-model="searchManifestos"
										@focus="$event.target.select()" @keyup.enter="getManifestosLocal" />
									<div class="input-group-text bg-transparent pointer" @click="getManifestosLocal"
										ref="btnGetManifestos">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								ref="btnShowShippingComponent" :disabled="visibilityObjectManifesto">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1" @click="showNewManifesto">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<table class="table table-sm fs--1">
					<thead>
						<tr class="text-center">
							<th>ID</th>
							<th>Nombre</th>
							<th>Transporte</th>
							<th>Descripción</th>
							<th>Fecha de Creación</th>
							<th>Ultima Actualización</th>
							<th>Envíos Relacionados</th>
							<th>Estado</th>
						</tr>
					</thead>
					<tbody>
						<tr v-show="visibilityObjectManifesto">
							<td class="align-middle">-</td>
							<td class="align-middle">
								<input type="text" v-model="objectManifesto.name" class="fs--1"
									style="width: 100%; background: transparent; border: none"
									placeholder="Nombre del Manifiesto" ref="newObjectManifestoName">
							</td>
							<td class="fs--1 text-center align-middle ">
								<select class="text-center" style="width: 100%; background: transparent; border: none"
									v-model="objectManifesto.transport_id">
									<option value="null" disabled>Seleccione el Transporte</option>
									<option v-for="(t, i) in listTransportsFilter" :key="i" class="text-center"
										:class="'text-' + t.color" :value="t.id">
										{{ t.name }}
									</option>
								</select>
							</td>
							<td class="align-middle">
								<input type="text" v-model="objectManifesto.description" class="fs--1"
									style="width: 100%; background: transparent; border: none"
									placeholder="Alguna descripción">
							</td>
							<td class="text-center align-middle">-</td>
							<td class="text-center align-middle">-</td>
							<td class="text-center align-middle">0</td>
							<td class="text-center align-middle">Para Crear</td>
							<td class="text-end align-middle">
								<span class="pointer" title="Guardar Manifiesto"
									:class="(disabledBtnSaveManifesto) ? '' : 'text-primary'"
									@click="saveManifestoLocal(null)">
									<i class="far fa-save"></i>
								</span>
							</td>
						</tr>
						<tr v-for="(m, i) in listManifestosFilter" :key="i">
							<td class="text-center align-middle">
								<a class="text-primary pointer" @click="showManifestoLocal(m.id)">
									<strong>#{{ m.id }}</strong>
								</a>
							</td>
							<td class="align-middle">
								<span @dblclick="setObjectManifestoLocal(m, 'name_' + i)"
									v-show="m.id != manifestoIdEditable">{{
											m.name }}</span>
								<input type="text" v-model="m.name" class="fs--1"
									style="width: 100%; background: transparent; border: none"
									@focus="$event.target.select()" v-show="m.id == manifestoIdEditable"
									:ref="'name_' + i" @keyup.enter="saveManifestoLocal(m)">
							</td>
							<td class="text-center align-middle" :class="'text-' + m.transport.color">{{
											m.transport.name }}</td>
							<td>
								<span @dblclick="setObjectManifestoLocal(m, 'description_' + i)"
									v-show="m.id != manifestoIdEditable">{{
											m.description }}</span>
								<input type="text" v-model="m.description" class="fs--1"
									style="width: 100%; background: transparent; border: none"
									v-show="m.id == manifestoIdEditable" :ref="'description_' + i"
									@focus="$event.target.select()" @keyup.enter="saveManifestoLocal(m)">
							</td>
							<td class="text-center align-middle">{{ m.created_at }}</td>
							<td class="text-center align-middle">{{ m.updated_at }}</td>
							<td class="text-center align-middle"><span :title="totalItemList(m)">{{ m.shippings.length
									}}</span></td>
							<td class="text-center align-middle">
								<span class="badge badge rounded-pill d-block" :class="'badge-soft-' + m.status.color"
									v-if="m.status_id > 27">{{ m.status.name }}</span>
								<div class="form-check form-switch mb-0" v-else>
									<input class="form-check-input" id="flexSwitchCheckChecked" type="checkbox"
										checked="" v-model="m.status_id" @change="updateManifestoStatusLocal(m)" />
								</div>
							</td>
							<td class="text-end align-middle"></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationManifestos" @getAllList="getManifestosLocal"></pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import manifesto from '@/mixins/manifestos'
import transport from '@/mixins/generals/transport.js'

import Pagination from '@/components/Pagination.vue'

export default {
	name: 'ManifestoListComponent',
	mixins: [mixin, axiosAction, errors, manifesto, transport],
	props: [],
	data() {
		return {
			visibilityObjectManifesto: false,
			manifestoIdEditable: 0
		}
	},
	components: {
		'pagination': Pagination,
	},
	computed: {
		disabledBtnSaveManifesto() {
			return !this.validateObjectManifesto()
		},
		listManifestosFilter() {
			this.listManifestos.forEach(m => {
				if (parseInt(m.status_id) == 26) {
					m.status_id = true
				} else if (parseInt(m.status_id) == 27) {
					m.status_id = false
				}
			})
			return this.listManifestos
		},
		listTransportsFilter() {
			return this.listTransports
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllTransportsResult': this.getAllTransportsResult(response); break;
				case 'getManifestosResult': this.getManifestosResult(response); break;
				case 'saveManifestoResult': this.saveManifestoResult(response); break;
				case 'trackingManifestoRegisterResult': this.trackingManifestoRegisterResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setManifestoListComponentInit()
		},
		getAllTransportsResultLocal(response) { },
		getManifestosLocal(page = 1) {
			this.manifestoIdEditable = 0
			this.visibilityObjectManifesto = false
			this.getManifestos(page)
		},
		getManifestosResultLocal(response) {
		},
		saveManifestoLocal(m = null) {
			if (m == null) {
				this.saveManifesto(this.objectManifesto)
			} else {
				console.log(m)
				this.saveManifesto(m)
			}
		},
		saveManifestoResultLocal(response) {
			if (response.data.success == true) {
				this.clearObjectManifesto()
				this.visibilityObjectManifesto = false
				this.manifestoIdEditable = 0
			}
		},
		setManifestoListComponentInit() {
			this.getAllTransports(1)
		},
		setObjectManifestoLocal(m, input) {
			this.manifestoIdEditable = m.id
			setTimeout(() => {
				this.$refs[input][0].focus()
			}, 50)
		},
		showManifestoLocal(manifestoId) {
			this.$emit('showManifesto', manifestoId)
		},
		showNewManifesto() {
			this.clearObjectManifesto()
			this.visibilityObjectManifesto = true
			setTimeout(() => {
				this.$refs.newObjectManifestoName.focus()
			}, 50)
		},
		totalItemList(m) {
			let weight = 0
			let packages = 0
			m.shippings.forEach(s => {
				weight += parseFloat(s.weight)
				packages += parseInt(s.packages)
			})

			return 'Bultos ' + packages + ' - Peso ' + weight
		},
		trackingManifestoRegisterResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.data.trackings_manifesto.status_id != null) {
					let index = this.listManifestos.map(m => parseInt(m.id)).indexOf(response.data.data.manifesto_id)
					if (index > -1) {
						this.listManifestos[index].status_id = response.data.data.trackings_manifesto.status_id
						this.listManifestos[index].status = response.data.data.trackings_manifesto.status
					}
				}

			}
		},
		updateManifestoStatusLocal(m) {
			let trackingManifestoId = (m.status_id) ? 2 : 4
			this.trackingManifestoRegister(m.id, trackingManifestoId)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>