<template>
	<div id="orderTeamListComponent">
		<button ref="btnAddListOrders" @click="addListOrdersLocal" v-show="false">Add Order to ListOrdes</button>
		<div class="card mb-3" v-show="orderIdSelected == 0">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-3 col-sm-auto d-flex align-items-center pe-0">
						<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Pedidos</h5>
					</div>
					<div class="col-3 col-sm-13">
						<div class="input-group">
							<input class="form-control form-control-sm shadow-none search" type="search"
								placeholder="Buscar pedidos" aria-label="search" v-model="searchOrders"
								@keyup.enter="getAllOrdersByUserLocal(1)" />
							<div class="input-group-text bg-transparent pointer" @click="getAllOrdersByUserLocal(1)">
								<span class="fa fa-search fs--1 text-600"></span>
							</div>
						</div>
					</div>
					<div class="col-md-auto p-3">
						<form class="row align-items-center g-3">
							<div class="col-auto">
								<h6 class="text-700 mb-0">Fechas: </h6>
							</div>
							<div class="col-md-auto position-relative">
								<input class="form-control form-control-sm datetimepicker ps-4" id="ordersDateRange"
									type="text"
									data-options="{&quot;mode&quot;:&quot;range&quot;,&quot;dateFormat&quot;:&quot;M d&quot;,&quot;disableMobile&quot;:true , &quot;defaultDate&quot;: [&quot;Sep 12&quot;, &quot;Sep 19&quot;] }" />
								<span
									class="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2">
								</span>
							</div>
						</form>
					</div>
					<div class="col-6 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm" type="button" @click="showNewOrderModalLocal">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar" v-if="!loadingOrders">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
						<thead class="bg-200 text-900">
							<tr>
								<th class="pe-1 align-middle white-space-nowrap">Pedido</th>
								<th class="pe-1 align-middle white-space-nowrap">Enviar
								</th>
								<th class="pe-1 align-middle white-space-nowrap">Campaña</th>
								<th class="pe-1 align-middle white-space-nowrap text-center">Estado</th>
								<th class="pe-1 align-middle white-space-nowrap text-center">Monto</th>
								<th class=""></th>
							</tr>
						</thead>
						<tbody class="list" id="table-orders-body">
							<tr class="btn-reveal-trigger" v-for="(item, index) in listOrders" :key="index">
								<td class="order py-2 align-middle white-space-nowrap">
									<router-link :to="'/orderTeam/' + item.id" class="text-primary pointer">
										<strong>#{{ item.id }}</strong>
									</router-link>
									<!--<a @click="showOrderLocal(item.id)" class="text-primary pointer">
										<strong>#{{ item.id }}</strong>
									</a>-->
									por <strong>{{ item.user.name }}</strong>
									<div class="text-500">
										<i class="far fa-calendar-alt"></i>
										<span class="ms-1">{{ item.created_at | formatDateMin }}</span>
									</div>
								</td>
								<td class="address py-2 align-middle white-space-nowrap">
									{{ item.client_address_client_sendto.address.street }} -
									{{ item.client_address_client_sendto.address.comuna.name }}
									<p class="mb-0 text-500 fs--2">
										<i class="far fa-user"></i>
										<span class="ms-1">{{
			item.client_address_client_sendto.client_sendto.sendto }}</span>
										|
										<i class="fas fa-phone-alt"></i>
										<span class="ms-1">{{ item.client_address_client_sendto.client_sendto.phone
											}}</span>
									</p>
								</td>
								<td class="team py-2 align-middle white-space-nowrap">
									{{ item.team.name }}
									<div>
										{{ item.team.entity.name }}
									</div>
								</td>
								<td class="status py-2 align-middle white-space-nowrap text-center fs-0">
									<span class="badge badge rounded-pill d-block"
										:class="'badge-soft-' + item.status.color">{{ item.status.name }}</span>
								</td>
								<td class="amount py-2 align-middle white-space-nowrap text-end fs-0 fw-medium">
									${{ methodFormatNumber(item.total) }}
								</td>
								<td class="py-2 align-middle white-space-nowrap text-end">
									<div class="dropdown font-sans-serif position-static">
										<button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
											type="button" id="order-dropdown-0" data-bs-toggle="dropdown"
											data-boundary="viewport" aria-haspopup="true" aria-expanded="false"><span
												class="fas fa-ellipsis-h fs--1"></span></button>
										<div class="dropdown-menu dropdown-menu-end border py-0"
											aria-labelledby="order-dropdown-0">
											<div class="bg-white py-2">
												<span class="dropdown-item pointer" v-show="item.status_id == 30"
													@click="confirmOrderLocal(item)">Confirmar</span>
												<span class=" dropdown-item pointer"
													@click="path('order/' + item.id)">Ver</span>
												<div class="dropdown-divider" v-show="item.status_id == 30"></div>
												<a class="dropdown-item text-danger pointer" href="#!"
													v-show="item.status_id == 30"
													@click="deleteOrderLocal(item)">Eliminar</a>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else class="text-center">
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando
						los pedidos...</p>
				</div>
			</div>
			<div class="card-footer">
				<div class="row flex-between-center">
					<div class="col-auto">
						<select class="form-select form-select-sm" v-model="qRowsOrder" @change="changeQRowsOrder">
							<option value="10">10 Resultados</option>
							<option value="25">25 Resultados</option>
							<option value="50">50 Resultados</option>
							<option value="100">100 Resultados</option>
							<option value="200">200 Resultados</option>
							<option value="1000">1000 Resultados</option>
						</select>
					</div>
					<pagination :pagination="paginationOrders" @getAllList="getAllOrdersByUser"></pagination>
					<div class="col-auto"><a class="fs--1 font-sans-serif" href="#!" v-show="false">Ayuda</a></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import order from '@/mixins/orders'

import Pagination from '@/components/Pagination.vue'

import flatpickr from 'flatpickr'

export default {
	name: 'OrderTeamListComponent',
	mixins: [mixin, axiosAction, errors, order],
	props: ['newOrder'],
	data() {
		return {
			orderIdSelected: 0
		}
	},
	components: {
		'pagination': Pagination,
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteOrderResult': this.deleteOrderResult(response); break;
				case 'getAllOrdersResult': this.getAllOrdersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		addListOrdersLocal() {
			if (this.newOrder != undefined && this.newOrder != null) {
				this.addListOrders(this.newOrder)
				let routeData = this.$router.resolve({ path: '/orderTeam/' + this.newOrder.id });
				window.open(routeData.href, '_blank');
			}
		},
		changeQRowsOrderLocal() {
			this.getAllOrdersByUserLocal()
		},
		getAllOrdersByUserLocal(page = 1) {
			this.orderIdSelected = 0
			this.getAllOrdersByUser(page)
		},
		getAllOrdersResultLocal(response) {
		},
		initModuleLocal() {
			this.setOrderInit()
		},
		setOrderInitLocal() {
			this.setOrderTeamListComponentInit()
		},
		setOrderTeamListComponentInit() {
			flatpickr(
				"#ordersDateRange", {
				mode: "range",
				dateFormat: "Y-m-d",
				defaultDate: [this.dateFromOrders, this.dateToOrders],
				onClose: (selectedDates, dateStr, instance) => {
					dateStr = dateStr.split('to')
					if (dateStr.length == 2) {
						this.dateFromOrders = dateStr[0].trim()
						this.dateToOrders = dateStr[1].trim()
						this.getAllOrdersByUserLocal()
					}
				},
			})
		},
		showNewOrderModalLocal() {
			this.$emit('showNewOrder')
		},
		showOrderLocal(id) {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>