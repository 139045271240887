<template>
	<div id="paymentTableComponent" class="col-auto">
		<button ref="btnSetPaymentTableComponent" @click="btnSetPaymentTableComponent" v-show="false"></button>
		<button ref="btnGetTotalPaymentTableComponent" @click="btnGetTotalPaymentTableComponent" v-show="false"></button>
		<table class="table table-sm fs--1 mb-0 overflow-hidden">
			<thead class="bg-200 text-900">
				<tr>
					<th class="align-middle">ID</th>
					<th class="align-middle">Forma de Pago</th>
					<th class="align-middle text-center">Monto</th>
					<th class="align-middle text-center">Banco</th>
					<th class="align-middle text-center">Código</th>
					<th class="align-middle">Descripción</th>
					<th class="align-middle text-center">Fecha</th>
					<th class="align-middle text-end">
          				<div>
							<button class="btn p-0" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Guardar" @click="setPlusPayment" :disabled="plusPayment || disableBtnPlusPayment">
								<span class="fas fa-plus text-info pointer"></span>
							</button>
						</div>
					</th>
				</tr>
			</thead>
			<tbody class="list">
				<tr v-if="loadingPayments">
					<td colspan="8" class="text-center">cargando los pagos...</td>
				</tr>
				<tr v-for="(item, index) in listPayments" :key="index" class="btn-reveal-trigger" v-else>
					<td class="align-middle">{{ item.id }}</td>
					<td class="align-middle">{{ item.payment.typePayment }}</td>
					<td class="align-middle text-end">{{ methodFormatNumber(item.payment.amount) }}</td>
					<td class="align-middle text-center">{{ item.payment.bank }}</td>
					<td class="align-middle text-center">{{ item.payment.code }}</td>
					<td class="align-middle">{{ item.payment.description }}</td>
					<td class="align-middle text-center">{{ item.payment.transaction_date | formatDateMin }}</td>
					<td class="align-middle text-end">
						<i class="fas fa-exclamation-triangle text-warning margin-right5" v-show="item.payment.status_id == 40" title="Pago Rechazado"></i>
						<i class="fas fa-check text-success margin-right5" v-show="item.payment.status_id == 39"></i>
          				<span>
							<button class="btn p-0" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Borrar" v-show="item.payment.status_id != 39" @click="deletePaymentLocal(item.payment.id)"  :disabled="disableBtnPlusPayment">
								<span class="far fa-trash-alt pointer text-danger"></span>
							</button>
							<button class="btn p-0 ms-2" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Adjuntar documento" v-show="item.exists == 1 && attachImage != false" @click="showUploadDocumentModalComponentLocal(item)">
								<span class="fas fa-file-upload text-info pointer"></span>
							</button>
						</span>
					</td>
				</tr>
				<tr v-show="plusPayment">
					<td class="text-center align-middle">{{ objectPayment.id }}</td>
					<td class="text-left align-middle">
						<select v-model="objectPayment.type_payment_id" style="width: 150px;" class="form-select form-select-sm">
							<option v-for="(item, index) in listTypePayments" :key="index" :value="item.id">{{ item.name }}</option>
						</select>
					</td>
					<td class="text-end align-middle"><input type="text" class="text-end form-control form-control-sm" v-model="objectPayment.amount" style="width: 100%;"/></td>
					<td class="align-middle">
						<select v-model="objectPayment.bank_id" class="form-select form-select-sm">
							<option v-for="(item, index) in listBanks" :key="index" :value="item.id">{{ item.name }}</option>
						</select>
					</td>
					<td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="objectPayment.code" style="width: 100%"/></td>
					<td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="objectPayment.description" style="width: 100%;"/></td>
					<td class="align-middle"><input type="date" class="form-control form-control-sm" v-model="objectPayment.transaction_date" style="width: 100%;"/></td>
					<td class="text-end align-middle">
          				<div>
							<button class="btn p-0" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Guardar" @click="savePaymentLocal()">
								<span class="far fa-save text-info pointer"></span>
							</button>
							<button class="btn p-0 ms-2" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Quitar" @click="plusPayment = !plusPayment">
								<span class="fas fa-minus-circle pointer text-danger"></span>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import payments from '@/mixins/generals/payment.js'
import typePayments from '@/mixins/generals/typePayment.js'

export default {
	name: 'PaymentTableComponent',
	mixins: [mixin, axiosAction, errors, payments, typePayments],
	props: ['id', 'controller', 'disableBtnPlusPayment', 'method', 'attachImage', 'message'],
	data () {
		return {
			plusPayment: false
		}
	},
	computed: {
		totalPayments() {
			let total = this.listPayments.reduce(function(a, b) { 
							return parseFloat(a) + parseFloat(b.payment.amount)
						}, 0);
			return total.toFixed(0)
		},
		totalTaxPayments() {
			let total = this.listPayments.reduce(function(a, b) {
							let c = 0
							if(b.payment.pivot.teamClientPayment.length == 1) {
								if(b.payment.pivot.teamClientPayment[0].percentage != null) {
									c = (b.payment.amount * (b.payment.pivot.teamClientPayment[0].percentage / 100))
								}
							}
							return parseFloat(a) + parseFloat(c)
						}, 0);
			return total.toFixed(0)
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'deletePaymentResult': this.deletePaymentResult(response); break;
				case 'getPaymentByControllerResult': this.getPaymentByControllerResult(response); break;
				case 'getAllTypePaymentsResult': this.getAllTypePaymentsResult(response); break;
				case 'savePaymentResult': this.savePaymentResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.getAllTypePayments()
		},
		btnGetTotalPaymentTableComponent() {
			this.$emit('totalPaymentsParent', this.totalPayments, this.totalTaxPayments)
		},
		btnSetPaymentTableComponent() {
			this.setPaymentTableComponent()
		},
		deletePaymentLocal(id) {
			if(confirm('¿Está seguro de ELIMINAR el pago?')){
				this.deletePayment(this.controller, this.id, id)
			}
		},
		deletePaymentResultLocal(response) {
			if(response.data.success == true) {
				this.$emit('deletePaymentParent', response.data.data)
				this.$refs.btnGetTotalPaymentTableComponent.click()
				this.plusPayment = false
				this.clearObjectPayment()
			}
		},
		getAllTypePaymentsResultLocal(response) {

		},
		getPaymentByControllerResultLocal(response) {
			if(this.listPayments.length == 0 && !this.disableBtnPlusPayment) {
				this.setPlusPayment()
			}
			this.$refs.btnGetTotalPaymentTableComponent.click()
		},
		savePaymentLocal() {
			this.savePayment(this.controller, this.id)
		},
		savePaymentResultLocal(response) {
			if(response.data.success == true) {
				this.$refs.btnGetTotalPaymentTableComponent.click()
				this.$emit('savePaymentParent', response.data.data)
				this.plusPayment = false
				this.clearObjectPayment()
			}
		},
		setPaymentTableComponent() {
			this.listPayments = []
			this.plusPayment = false
			this.clearObjectPayment()
			if(this.id > 0) {
				this.getPaymentByController(this.id, this.controller, this.method)
			}
		},
		setPlusPayment() {
			this.clearObjectPayment()
			this.plusPayment = true
		},
		showUploadDocumentModalComponentLocal(item) {
			this.$emit('showUploadDocumentParent', 'payment', item.id, 'Pago #' + item.id + ' por un monto de $' + this.methodFormatNumber(item.payment.amount))
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>
