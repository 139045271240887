export default {
    state: {
        webSocketServer: null,
        portUrlWebSocketServer: '8888',
        successFullConnectionWebSocketServer: false,
        listPrinterWebSocketServer: [],
        indexSelectedPrinterWebSocketServer: -1,
        quantity: 1
    },
    mutations: {
        analyzeMessageWebSocketServer(state, data) {
            switch (data.cmd) {
                case 'obtenerImpresoras':
                    state.indexSelectedPrinterWebSocketServer = -1
                    state.listPrinterWebSocketServer = data.listaImpresoras
                    console.log('webSocketServer - Se encontraron ' + state.listPrinterWebSocketServer.length + ' impresoras.')
                    state.listPrinterWebSocketServer.forEach((element, index) => {
                        if (element == localStorage.printerWebSocketServer) {
                            state.indexSelectedPrinterWebSocketServer = index
                        }
                    });
                    if (state.indexSelectedPrinterWebSocketServer == -1) {
                        localStorage.removeItem('printerWebSocketServer')
                    }
                    break;
                case 'imprimirEtiqueta':
                    console.log('webSocketServer', data.message)
                    break;
                default:
                    console.info('webSocketServer - analyzeMessageWebSocketServer - Mensaje no codificado')
                    break;
            }
        },
        connectWebSocketServer(state, port = null) {
            if (!state.successFullConnectionWebSocketServer) {
                state.portUrlWebSocketServer = (port != null) ? port : state.portUrlWebSocketServer
                try {
                    let sWSS = (localStorage.socketWebSocketServer !== undefined) ? localStorage.socketWebSocketServer : 'localhost'
                    let webSocket = 'ws://' + sWSS + ':' + state.portUrlWebSocketServer
                    console.log('webSocketServer - Conectando a ' + webSocket + ' ...')
                    state.webSocketServer = new WebSocket(webSocket);

                    state.webSocketServer.onopen = () => {
                        state.successFullConnectionWebSocketServer = true
                        console.log('webSocketServer - Conectado a ' + webSocket + '!!!')
                        this.commit('toListPrinterWebSocketServer')
                    }

                    state.webSocketServer.onclose = () => {
                        console.info('webSocketServer -Conexion WebSocket Server cerrada');
                        state.successFullConnectionWebSocketServer = false
                    }

                    state.webSocketServer.onmessage = (msg) => {
                        let data = JSON.parse(msg.data)
                        this.commit('analyzeMessageWebSocketServer', data)
                    }

                } catch (exception) {
                    console.info('webSocketServer - ERROR: ' + exception);
                }
            }
        },
        toListPrinterWebSocketServer(state) {
            try {
                if (!state.successFullConnectionWebSocketServer) {
                    console.error('No hay conexión WebSocketServer')
                } else {
                    let cmd = 'obtenerImpresoras'
                    state.webSocketServer.send(cmd + '|');
                }
            } catch (exception) {
                console.info('webSocketServer - toListPrinterWebSocketServer - ERROR: ' + exception);
            }
        },
        socketWebSocketServerPrinter(state, zpl) {
            let printer = localStorage.printerWebSocketServer
            if (printer != undefined) {
                try {
                    if (!state.successFullConnectionWebSocketServer) {
                        console.error('No hay conexión WebSocketServer')
                    } else {
                        for (let i = 0; i < state.quantity; i++) {
                            let cmd = 'imprimirEtiqueta';
                            state.webSocketServer.send(cmd + '|' + printer + '|' + zpl);
                            console.info('printWebSocketServer - Etiqueta enviada a la impresora ' + printer);
                        }
                    }
                } catch (exception) {
                    console.info('printWebSocketServer - ERROR: ' + exception);
                }
            } else {
                alert('No hay una impresora seleccionada para las etiquetas')
            }
        }
    },
    getters: {
        getListPrinterWebSocketServer: state => { return state.listPrinterWebSocketServer },
        getSuccessFullConnectionWebSocketServer: state => { return state.successFullConnectionWebSocketServer }
    },
    actions: {
        connectWebSocketServer(context, port = null) {
            context.commit('connectWebSocketServer', port)
        },
        socketWebSocketServerPrinter(context, zpl) {
            context.commit('socketWebSocketServerPrinter', zpl)
        }
    },
    modules: {}
};
