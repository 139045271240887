var team = {
	data() {
		return {
			dateFromTeams: '2020-07-15',
			dateToTeams: '2020-07-18',
			listTeams: [],
			loadingTeams: false,
			urlsTeam: {
				create: 'team/register',
				delete: '/delete',
				get: 'teams',
				show: 'team/',
				showFull: 'teamFull/'
			},
			objectTeam: {
				exists: 0,
				id: 0,
				name: '',
				description: '',
				begin_date: '',
				end_date: null,
				client_id: 0,
				client: {},
				clients: [],
				entity_id: 0,
				entity: {},
				status_id: 0,
				status: {},
				price_list_id: 0,
				price_list: {},
				orders: [],
				expenses: [],
				consignments: [],
				team_invoice_third: [],
				created_at: '',
				updated_at: '',
				total_orders: 0,
				bonusable: 0,
				tithe: 0,
				total_payments: 0,
				total_expenses: 0,
				charge_payments: 0,
				penalty_devolution: 0,
				tax_diference_invoce_third: 0,
				balance: 0,
				total_viatic: 0,
				total_commission: 0,
			},
			qRowsTeam: 25,
			paginationTeams: [],
			searchTeams: '',
			penalty: 0.1,
			devolution: 0.15,
			freight: 1000,
		}
	},
	computed: {
		teamPriceListId() {
			let priceListId = null
			if (this.objectTeam.price_list_id == null) {
				this.objectTeam.orders.forEach(o => {
					o.order_products.forEach(op => {
						priceListId = op.price_list_id
					})
				});
			} else {
				priceListId = this.objectTeam.price_list_id
			}
			return priceListId
		}
	},
	filters: {
	},
	methods: {
		addListTeams(newTeam) {
			this.listTeams.unshift(newTeam)
			if (this.listTeams.length > this.qRowsTeam) {
				this.listTeams.splice(this.qRowsTeam, 1)
			}
		},
		deleteItemListTeam(id) {
			let index = this.listTeams.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let teamDeleted = this.listTeams.splice(index, 1)
		},
		deleteTeam(id) {
			this.$emit('showLockScreen', 'Borrando campaña . . .')
			this.axiosActionIS(this.getApiBackend + this.urlsTeam.show + id + this.urlsTeam.delete, 'DELETE', 'deleteTeamResult')
		},
		deleteTeamResult(response) {
			if (response.data.success === true) {
				this.deleteItemListTeam(response.data.data)
			}
			this.deleteTeamResultLocal(response)
		},
		deleteTeamResultLocal(response) {
			console.log('deleteTeamResultLocal no desarrollado', response)
		},
		changeQRowsTeam() {
			localStorage.setItem('qRowsTeam', this.qRowsTeam)
			this.changeQRowsTeamLocal()
		},
		changeQRowsTeamLocal() {
			console.log('changeQRowsTeamLocal no desarrollado')
		},
		clearObjectTeam() {
			this.objectTeam.exists = 0
			this.objectTeam.id = 0
			this.objectTeam.name = ''
			this.objectTeam.description = ''
			this.objectTeam.begin_date = this.currentDate()
			this.objectTeam.end_date = null
			this.objectTeam.client_id = 0
			this.objectTeam.client = {}
			this.objectTeam.clients = []
			this.objectTeam.entity_id = 0
			this.objectTeam.entity = {}
			this.objectTeam.status_id = 0
			this.objectTeam.status = {}
			this.objectTeam.orders = []
			this.objectTeam.consignments = []
			this.objectTeam.created_at = null
			this.objectTeam.updated_at = null
			this.objectTeam.total_orders = 0
			this.objectTeam.bonusable = 0
			this.objectTeam.tithe = 0
			this.objectTeam.total_payments = 0
			this.objectTeam.total_expenses = 0
			this.objectTeam.charge_payments = 0
			this.objectTeam.penalty_devolution = 0
			this.objectTeam.tax_diference_invoce_third = 0
			this.objectTeam.balance = 0
			this.objectTeam.total_viatic = 0
			this.objectTeam.total_commission = 0
			this.expenses = []
		},
		closeTeam() {
			let url = this.getApiBackend + 'team/' + this.objectTeam.id + '/close'
			this.axiosActionIS(url, 'POST', 'closeTeamResult', this.objectTeam)
		},
		closeTeamResult(response) {
			if (response.data.success == true) {
				this.setObjectTeam(response.data.data)
			}
			this.closeTeamResultLocal(response);
		},
		closeTeamResultLocal(response) {
			console.log('closeTeamResultLocal no desarrollado', response)
		},
		getAllTeams(page = 1, statusId = -1) {
			this.listTeams = []
			this.paginationTeams = []
			this.loadingTeams = true
			let parameters = '?search=' + this.searchTeams + '&page=' + page + '&qRow=' + this.qRowsTeam + '&fromDate=' + this.dateFromTeams + '&toDate=' + this.dateToTeams + '&statusId=' + statusId
			this.$emit('showLockScreen', 'Obteniendo lista de Campañas . . .')
			let url = this.getApiBackend + this.urlsTeam.get + parameters
			this.axiosActionIS(url, 'GET', 'getAllTeamsResult')
		},
		getAllTeamByUserClient(page = 1, statusId = -1) {
			this.listTeams = []
			this.paginationTeams = []
			this.loadingTeams = true
			let parameters = '?search=' + this.searchTeams + '&page=' + page + '&qRow=' + this.qRowsTeam + '&fromDate=' + this.dateFromTeams + '&toDate=' + this.dateToTeams + '&statusId=' + statusId
			this.$emit('showLockScreen', 'Obteniendo lista de Campañas . . .')
			let url = this.getApiBackend + this.urlsTeam.get + '/byUserClient' + parameters
			this.axiosActionIS(url, 'GET', 'getAllTeamsResult')
		},
		getAllTeamsResult(response) {
			this.loadingTeams = false
			if (response.data.success == true) {
				this.listTeams = response.data.data
				this.paginationTeams = response.data.paginate
			}
			this.getAllTeamsResultLocal(response)
		},
		getAllTeamsResultLocal(response) {
			console.log('getAllTeamsResultLocal no desrrollado', response)
		},
		getTeamClients(id) {
			this.objectTeam.clients = []
			let url = this.getApiBackend + this.urlsTeam.show + id + '/clients'
			this.axiosActionIS(url, 'GET', 'getTeamClientsResult')
		},
		getTeamClientsResult(response) {
			if (response.data.success == true) {
				this.objectTeam.clients = response.data.data
			}
			this.getTeamClientsResultLocal(response)
		},
		getTeamClientsResultLocal(response) {
			console.log('getTeamClientsResultLocal no desarrollado', response)
		},
		getTeamOrders(id) {
			this.objectTeam.orders = []
			let url = this.getApiBackend + this.urlsTeam.show + id + '/orders'
			this.axiosActionIS(url, 'GET', 'getTeamOrdersResult')
		},
		getTeamOrdersResult(response) {
			if (response.data.success == true) {
				this.objectTeam.orders = response.data.data
			}
			this.getTeamOrdersResultLocal(response)
		},
		getTeamOrdersResultLocal(response) {
			console.log('getTeamOrdersResultLocal no desarrollado', response)
		},
		saveTeam() {
			let method = (this.objectTeam.exists == 0) ? 'POST' : 'PUT'
			this.$emit('showLockScreen', 'Guardando datos . . .')
			if (method == 'POST') {
				this.axiosActionIS(this.getApiBackend + this.urlsTeam.create, method, 'saveTeamResult', this.objectTeam)
			} else {
				//this.axiosActionIS(this.getApiBackend + this.urlsTeam.show + this.objectTeam.id, method, 'saveTeamResult', this.objectTeam)
			}
		},
		saveTeamResult(response) {
			if (response.data.success === true) {
				if (response.data.message == 'NEW') {
					this.$snotify.success('Campaña creada con el código ' + response.data.data.id, 'Nueva Campaña')
					this.addListTeams(response.data.data)
				} else if (response.data.message == 'UPDATED') {
					this.$snotify.info('Se ha actualizado la Campaña ' + response.data.data.name + ' con exito!!!', 'Actualizado')
					this.updateListTeam(response.data.data)
				}
				this.setObjectTeam(response.data.data)
			}
			this.saveTeamResultLocal(response)
		},
		saveTeamResultLocal(response) {
			console.log('saveTeamResultLocal no desarrollado', response)
		},
		setListeningTeam() {
			this.sockets.subscribe('DELETE_TEAM', (data) => { this.deleteItemListTeam(data.body) })
			this.sockets.subscribe('NEW_TEAM', (data) => { this.addListTeams(data.body) })
			this.sockets.subscribe('UPDATE_TEAM', (data) => { this.updateListTeam(data.body) })
		},
		setObjectTeam(item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectTeam[property] = newItem[property]
			}
		},
		setTeamInit() {
			this.qRowsTeam = (localStorage.getItem('qRowsTeam') === null) ? this.qRowsTeam : localStorage.getItem('qRowsTeam')
			this.dateFromTeams = this.currentDate(-180)
			this.dateToTeams = this.currentDate()
			this.setListeningTeam()
			this.setTeamInitLocal()
		},
		showTeam(id, action = 'view') {
			this.loadingTeams = true
			let param = '?action=' + action
			this.axiosActionIS(this.getApiBackend + this.urlsTeam.show + id + param, 'GET', 'showTeamResult', null)
		},
		showTeamFull(id, action = 'view') {
			this.loadingTeams = true
			let param = '?action=' + action
			let url = this.getApiBackend + this.urlsTeam.showFull + id + param
			this.axiosActionIS(url, 'GET', 'showTeamResult', null)
		},
		showTeamResult(response) {
			this.loadingTeams = false
			if (response.data.success == true) {
				this.setObjectTeam(response.data.data)
			}
			this.showTeamResultLocal(response)
		},
		showTeamResultLocal(response) {
			console.log('showTeamResultLocal no desarrollado', response)
		},
		updateListTeam(item) {
			let index = this.listTeams.map((el) => parseInt(el.id)).indexOf(parseInt(item.id))
			if (index > -1) {
				let newItem = Object.create(item)
				for (const property in newItem) {
					this.listTeams[index][property] = newItem[property]
				}
			}
		}
	},
};

export default team;