<template>
	<div class="team-statistics">
		<div class="card mb-3" id="statisticsFilter">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-auto p-3">
						<div class="row align-items-center g-3">
							<div class="col-auto">
								<h6 class="text-700 mb-0">Administración: </h6>
							</div>
							<div class="col-md-auto position-relative">
								<select class="form-select form-select-sm" aria-label="Default select example" v-model="entityIdSeleted">
									<option v-for="(item, index) in listEntitiesFilter" :key="index" :value="item.id">{{ item.name }}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-auto p-3">
						<div class="row align-items-center g-3">
							<div class="col-auto">
								<h6 class="text-700 mb-0">Tipo de Campañas: </h6>
							</div>
							<div class="col-md-auto position-relative">
								<select class="form-select form-select-sm" aria-label="Default select example" v-model="typeTeamIdSeleted" @change="objectResult = null">
									<option v-for="(item, index) in listTypeTeamsFilter" :key="index" :value="item.id">{{ item.name }}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-auto p-3">
                        <div id="teamStatisticsActions" class="d-grid">
                            <button class="btn btn-falcon-default btn-sm" type="button" @click="getStatisticsTeamByFiltersLocal()" :disabled="disabledBtnSearchStatisticTeam">
								<div v-if="!loadingTeamsStatistic">
									<span class="fas fa-search" data-fa-transform="shrink-3 down-2"></span>
									<span class="ms-1">Buscar</span>
								</div>
								<div v-else>
									<span class="fas fa-spinner" data-fa-transform="shrink-3 down-2"></span>
									<span class="ms-1">Buscando . . .</span>
								</div>
							</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="typeTeamIdSeleted == 3 || typeTeamIdSeleted == 4 || typeTeamIdSeleted == 5">
			<team-student-statistics-component ref="teamsStudentStatisticsComponent" :typeTeamIds="[typeTeamIdSeleted]" :entityId="[entityIdSeleted]" :statusIds="[42]"></team-student-statistics-component>
		</div>
		<div v-show="typeTeamIdSeleted == 1 || typeTeamIdSeleted == 2" class="row g-3 mb-3">
			<team-permanent-statistics-component ref="teamsPermanentStatisticsComponent" :typeTeamIds="[1]" :entityId="[entityIdSeleted]"></team-permanent-statistics-component>
			<div class="col-lg-7" v-if="false">
				<div class="card h-100">
					<div class="card-body p-0">
						<div class="scrollbar">
							<table class="table table-dashboard mb-0 table-borderless fs--1 border-200 overflow-hidden rounded-3">
								<thead class="bg-light">
									<tr class="text-900">
										<th>Colportores</th>
										<th class="text-center">Compras</th>
										<th class="text-center">eta</th>
									</tr>
								</thead>
								<tbody>
									<tr class="border-bottom border-200" v-for="(t, i) in listTeamsFilter" :key="i">
										<td>
											<div class="d-flex align-items-center position-relative">
												<div class="avatar avatar-2xl" :class="(shoppingClient(t.client_id) > 0) ? 'status-online' : 'status-offline'">
													<img v-if="(t.client_img != null && t.client_img != '')" class="rounded-circle" :src="t.client_img" alt="" />
													<div v-else class="avatar-name rounded-circle" :style="'background-color: #' + t.entity_color_hex"><span>{{ t.client_name	.charAt(0).toUpperCase() + '' + t.client_surname.charAt(0).toUpperCase() }}</span></div>
												</div>
												<div class="flex-1 ms-3">
													<h6 class="mb-0 fw-semi-bold"><router-link class="stretched-link text-900" :to="'/client/' + t.client_id">{{ t.client_name }} {{ t.client_surname }}</router-link></h6>
													<p class="text-500 fs--2 mb-0">Colportor {{ t.type_colportor_name }}</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-end"><h6 class="mb-0">${{ methodFormatNumber(shoppingClient(t.client_id)) }}</h6></td>
										<td class="align-middle text-center">
											<div v-if="percentageMeta(t) > -1">
												<small class="badge fw-semi-bold rounded-pill mb-1" :class="'badge-soft-' + colorPercentage(percentageMeta(t))">{{ percentageMeta(t) }}%</small>
												<div class="progress rounded-3 worked" style="height: 6px;">
													<div class="progress-bar rounded-pill" :class="'bg-' + colorPercentage(percentageMeta(t))" role="progressbar" :style="'width: ' + percentageMeta(t) + '%' " :aria-valuenow="percentageMeta(t)" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
											</div>
											<div v-else title="No se informa Meta">
												--
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div  v-if="objectResult != null && false">
			<div class="card mb-3" id="statisticsChart" v-if="(typeTeamIdSeleted == 1 || typeTeamIdSeleted == 2)">
				<div class="card-body d-flex flex-center flex-column">
					<object-inspector :data="objectResult" :expandLevel="2"></object-inspector>
					<object-inspector :data="listAssistants" :expandLevel="2"></object-inspector>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import ObjectInspector from "vue-object-inspector";

import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import typeTeam from '@/mixins/teams/typeTeam.js'
import entities from '@/mixins/generals/entity.js'
import statisticsTeams from '@/mixins/teams/statisticsTeams.js'

import TeamStudentStatisticsComponent from '@/components/TeamStudentStatisticsComponent.vue'
import TeamPermanentStatisticsComponent from '@/components/TeamPermanentStatisticsComponent.vue'

import { PieChart, DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import flatpickr from 'flatpickr'

export default({
	name: 'Statistics',
	mixins: [mixin, axiosAction, errors, typeTeam, entities, statisticsTeams],
	components: {
		'team-student-statistics-component': TeamStudentStatisticsComponent,
		'team-permanent-statistics-component': TeamPermanentStatisticsComponent,
		'pie-chart': PieChart,
		'doughnut-chart': DoughnutChart,
		'object-inspector': ObjectInspector
	},
	computed: {
		listProdutsFilter() {
			let list = []
			if(this.objectResult != null) {
				let index = -1
				let index_ = -1
				this.objectResult.products.forEach(p => {
					index = list.map(t => parseInt(t.id)).indexOf(parseInt(p.product_id))
					if(index == -1) {
						list.push({
							id: p.product_id,
							name: p.product_name,
							synthetic: p.product_synthetic,
							quantity: parseInt(p.product_quantity),
							clients: [{id: parseInt(p.client_id), quantity: parseInt(p.product_quantity)}],
							orders: [{id: parseInt(p.order_id), quantity: parseInt(p.product_quantity)}],
							prices: [{price: parseFloat(p.product_price), quantity: parseInt(p.product_quantity)}],
							teams: [{id: parseInt(p.team_id), quantity: parseInt(p.product_quantity)}],
						})
					} else {
						list[index].quantity+= parseInt(p.product_quantity)
						index_ = list[index].orders.map(c => parseInt(c.id)).indexOf(parseInt(p.client_id))
						if(index_ == -1) {
							list[index].clients.push({id: parseInt(p.client_id), quantity: parseInt(p.product_quantity)})
						} else {
							list[index].clients[index_].quantity+= parseInt(p.product_quantity)
						}

						index_ = list[index].orders.map(o => parseInt(o.id)).indexOf(parseInt(p.order_id))
						if(index_ == -1) {
							list[index].orders.push({id: parseInt(p.order_id), quantity: parseInt(p.product_quantity)})
						} else {
							list[index].orders[index_].quantity+= parseInt(p.product_quantity)
						}

						index_ = list[index].prices.map(p => parseFloat(p.price)).indexOf(parseFloat(p.product_price))
						if(index_ == -1) {
							list[index].prices.push({price: parseFloat(p.product_price), quantity: parseInt(p.product_quantity)})
						} else {
							list[index].prices[index_].quantity+= parseInt(p.product_quantity)
						}

						index_ = list[index].teams.map(t => parseInt(t.id)).indexOf(parseInt(p.team_id))
						if(index_ == -1) {
							list[index].teams.push({id: parseInt(p.team_id), quantity: parseInt(p.product_quantity)})
						} else {
							list[index].teams[index_].quantity+= parseInt(p.product_quantity)
						}

					}
				})
			}
			return list
		},
		dataEntitiesCountTeams() {
			let labels = []
			let dataValue = []
			let backgroundColor = []
			this.listEntitiesFilter.forEach(e => {
				if(parseInt(e.total) > 0) {
					labels.push(e.synthetic)
					dataValue.push(parseInt(e.total))
					backgroundColor.push('#' + e.color_hex)
				}
			})
			let testData =  {
        		labels: labels,
        		datasets: [{
					data: dataValue,
					backgroundColor: backgroundColor
				}],

      		}
			return testData
		},
		disabledBtnSearchStatisticTeam() {
			if(this.entityIdSeleted == 0)
				return true
			if(this.typeTeamIdSeleted == -1)
				return true
			if(this.loadingTeamsStatistic)
				return true
			return false
		},
		listAssistants() {
			let list = []
			if(this.objectResult != null) {
				let index = -1
				this.objectResult.orders.forEach(o => {
					index = list.map(a => parseInt(a.id)).indexOf(parseInt(o.client_t_id))
					if(index == -1) {
						list.push({
							id: parseInt(o.client_t_id),
							name: o.client_t_name,
							surname: o.client_t_surname,
							email: o.client_t_email,
							phone: o.client_t_phone,
							img: o.client_t_img,
						})
					}
				})
			}
			return list

		},
		listTeamsFilter() {
			let list = []
			if(this.objectResult != null) {
				list = this.objectResult.teams
			}
			return list			
		},
		listEntitiesFilter() {
			return this.listEntities.filter(e => {
				let index = this.entityIdsNotDisplay.map(e => parseInt(e)).indexOf(parseInt(e.id))
				if(this.objectResult != null) {
					let total = 0
					if(index == -1) {
						this.objectResult.orders.forEach(o => {
							if(parseInt(o.entity_id) == parseInt(e.id)) {
								total+= parseInt(o.total)
							}
						})
					}
					e.total = total
				}
				this.optionsEntitiesCountTeams.plugins.title.text = 'Compras en ' + this.entityFromListEntitiesById(this.entityIdSeleted).name
				return (index == -1)
			})
		},
		listTypeTeamsFilter() {
			return this.listTypeTeams.filter(e => {
				return (this.typeTeamIdsNotDisplay.map(e => parseInt(e)).indexOf(parseInt(e.id)) == -1)
			})
		},
		monthsAnalyzed() {
			let fromDate = this.filters.fromDate.split('-')
			let toDate = this.filters.toDate.split('-')
			let monthMax = ((parseInt(toDate[0]) - parseInt(fromDate[0])) * 12) + parseInt(toDate[1])
			let result = monthMax - parseInt(fromDate[1]) + 1
			return result
		},
		objectListFilter() {
			return this.listAssistants
		},
		totalTeamsFound() {
			let total = this.listEntities.reduce(function(a, e) {
							let b = (e.teams != undefined) ? e.teams.length : 0
							return a + b
						}, 0);
			return total.toFixed(0)
		}
	},
	data() {
		return {
			entityIdsNotDisplay: [1,2],
			typeTeamIdsNotDisplay: [1],
			testData: null,
			entityIdSeleted: 0,
			typeTeamIdSeleted: -1,
			optionsEntitiesCountTeams: {
				plugins: {
					title: {
						display: true,
						text: 'Resultados de la Busqueda'
					},
					subtitle: {
						display: true,
						text: ''
					},
					legend: {
						display: true,
						position: 'left',
						onClick: (e, data) => {this.clicked(e, data)}
					},
					onClick: (e, data) => {this.clicked(e, data)}
				}
			},
			chartData: {
        		labels: [ 'Target', 'Reached' ],
        		datasets: [{
					data: [1200000,823000],
					circumference: 180,
					rotation: 270,
					cutout: '75%',
					backgroundColor: ['#2c7be5', '#d8e2ef']
				}],

      		},
			chartOptions: {
				responsive: true,
			}
		}
	},
	methods: {
		clicked(e, data) {
			let index = this.listEntities.map(e => e.synthetic).indexOf(data.text)
			if(index > -1) {
				if(this.entityIdSeleted != this.listEntities[index].id) {
					this.entityIdSeleted = this.listEntities[index].id
					this.getStatisticsTeamByFiltersLocal()
				}
			}
		},
		colorPercentage(percentaje) {
			if(percentaje > 98) {
				return 'success'
			}	
			if(percentaje > 79) {
				return 'primary'
			}	
			if(percentaje > 50) {
				return 'info'
			}	
			return 'danger'
		},
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'getAllEntitiesResult': this.getAllEntitiesResult(response); break;
				case 'getAllTypeTeamsResult': this.getAllTypeTeamsResult(response); break;
				case 'getStatisticsTeamByFiltersResult': this.getStatisticsTeamByFiltersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setTeamStatisticsInit()
		},
		setTeamStatisticsInit() {
			this.filters.fromDate = '2024-01-01'//this.currentDate(-365)
			this.filters.toDate = this.currentDate()
			flatpickr(
				"#teamsDateRange", {
				mode: "range",
				minDate: '2024-01-01',
				dateFormat: "Y-m-d",
				defaultDate: [this.filters.fromDate, this.filters.toDate],
				onClose: (selectedDates, dateStr, instance) => {
					dateStr = dateStr.split('to')
					if(dateStr.length == 2) {
						this.filters.fromDate = dateStr[0].trim()
						this.filters.toDate = dateStr[1].trim()
					}
				},
			})
			this.getAllEntitiesLocal()
			this.getAllTypeTeamsLocal()
		},
		getAllEntitiesLocal() {
			this.getAllEntities()
		},
		getAllEntitiesResultLocal(response) {},
		getAllTypeTeamsLocal() {
			this.getAllTypeTeams()
		},
		getAllTypeTeamsResultLocal(response) {},
		getStatisticsTeamByFiltersLocal() {
			let typeTeamIds = []
			if(this.typeTeamIdSeleted == 0) {
				this.listTypeTeamsFilter.forEach(e => {
					typeTeamIds.push(e.id)
				})
			} else {
				typeTeamIds.push(this.typeTeamIdSeleted)
			}
			this.testData = null
			this.filters.entityIds = [this.entityIdSeleted]
			this.filters.typeTeamIds = typeTeamIds
			if(this.typeTeamIdSeleted == 1 || this.typeTeamIdSeleted == 2) {
				this.$refs.teamsPermanentStatisticsComponent.$refs.btnGetDataPermanents.click()
			} else {
				this.$refs.teamsStudentStatisticsComponent.$refs.btnGetDataStudents.click()
			}
		},
		getStatisticsTeamByFiltersResultLocal(response) { },
		percentageMeta(t) {
			if(t.type_colportor_percentage_min > 0) {
				let fmr = 1166000
				let percentage_meta = fmr * (t.type_colportor_percentage_min / 100)
				let shopping = this.shoppingClient(t.client_id)
				let average = shopping / this.monthsAnalyzed
				let percentage_currtent = (average * 100) / (percentage_meta * 1.19)
				return percentage_currtent.toFixed(2)
			}
			return -1
		},
		shoppingClient(clientId) {
			let total = 0
			if(this.objectResult != null) {
				total = this.objectResult.orders.reduce(function(a, o) {
								let b = 0
								if(parseInt(o.client_o_id) == parseInt(clientId)) {
									b = parseInt(o.total)
								}
							return a + b
						}, 0);
			}
			return total.toFixed(0)				
		},
	},
	mounted () {
		this.initModule(this.$options.name)
	},
});
 </script>