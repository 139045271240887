export default {
    state: {
        socketFacturacionCL: null,
        portFacturacionCLPrint: '8005',
        successFullConnectionFacturacionCLPrint: false,
        listPrinterFacturacionCLPrint: [],
        lookingForPrinters: false,
        indexSelectedPrinterFacturacionCLPrint: -1
    },
    mutations: {
        analyzeMessageFacturacionCLPrint(state, data) {
            state.lookingForPrinters = false
            switch (data.cmd[0]) {
                case 'getPrinters':
                    state.indexSelectedPrinterFacturacionCLPrint = -1
                    state.listPrinterFacturacionCLPrint = data.data
                    console.log('faturacionCL - Se encontraron ' + state.listPrinterFacturacionCLPrint.length + ' impresoras.')
                    state.listPrinterFacturacionCLPrint.forEach((element, index) => {
                        if (element == localStorage.printerFacturacionCL) {
                            state.indexSelectedPrinterFacturacionCLPrint = index
                        }
                    });
                    if (state.indexSelectedPrinterFacturacionCLPrint == -1) {
                        localStorage.removeItem('printerFacturacionCL')
                    }
                    break;
                case 'ticketPrint':
                    console.log('faturacionCL - ', data.data[0])
                    break;
                default:
                    console.info('faturacionCL - analyzeMessageFacturacionCLPrint - Mensaje no codificado')
            }
        },
        connectFacturacionCLPrint(state, port = null) {
            if (!state.getSuccessFullConnectionFacturacionCLPrint) {
                state.portFacturacionCLPrint = (port != null) ? port : state.portFacturacionCLPrint
                try {
                    let sFCL = (localStorage.socketFacturacionCL !== undefined) ? localStorage.socketFacturacionCL : 'localhost'
                    let webSocket = 'ws://' + sFCL + ':' + state.portFacturacionCLPrint
                    console.log('faturacionCL - Conectando a ' + webSocket + ' ...')
                    state.socketFacturacionCL = new WebSocket(webSocket);

                    state.socketFacturacionCL.onopen = () => {
                        state.successFullConnectionFacturacionCLPrint = true
                        console.log('faturacionCL - Conectado a ' + webSocket + '!!!')
                        this.commit('toListPrinterFacturacionCLPrint')
                    }

                    state.socketFacturacionCL.onclose = () => {
                        state.successFullConnectionFacturacionCLPrint = false
                        //state.socketFacturacionCLCloseLocal()
                    }

                    state.socketFacturacionCL.onmessage = (msg) => {
                        let data = JSON.parse(msg.data)
                        this.commit('analyzeMessageFacturacionCLPrint', data)
                    }

                } catch (exception) {
                    console.info('faturacionCL - ERROR: ' + exception);
                }
            }
            return 1
        },
        socketFacturacionCLPrinter(state, ticket) {
            state.socketFacturacionCL.send(ticket)
        },
        toListPrinterFacturacionCLPrint(state) {
            if (state.successFullConnectionFacturacionCLPrint) {
                console.log('faturacionCL - Buscando Impresoras ...')
                state.listPrinterFacturacionCLPrint = []
                state.lookingForPrinters = true
                try {
                    if (state.socketFacturacionCL.readyState === 3) {
                    } else {
                        state.socketFacturacionCL.send('getPrinters');
                    }
                } catch (exception) {
                    console.info('faturacionCL - toListPrinterFacturacionCLPrint - ERROR: ' + exception);
                }
            } else {
                console.log('faturacionCL - No está conectado a socketFacturacionCLPrint')
            }
        },
    },
    getters: {
        getIndexSelectedPrinterFacturacionCLPrint: state => { state.indexSelectedPrinterFacturacionCLPrint },
        getListPrinterFacturacionCLPrint: state => { return state.listPrinterFacturacionCLPrint },
        getSuccessFullConnectionFacturacionCLPrint: state => { return state.successFullConnectionFacturacionCLPrint }
    },
    actions: {
        socketFacturacionCLPrinter(context, ticket) {
            context.commit('socketFacturacionCLPrinter', ticket)
        },
        connectFacturacionCLPrint(context, port = null) {
            context.commit('connectFacturacionCLPrint', port)
        }
    },
    modules: {},
};