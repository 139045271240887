<template>
	<div id="budgetListComponent">
		<div>
			<budget-wizard-component></budget-wizard-component>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import BudgetWizardComponent from '@/components/BudgetWizardComponent.vue'

export default {
	name: 'BudgetListComponent',
	mixins: [mixin, axiosAction, errors],
	props: [],
	data() {
		return {
		}
	},
	components: {
		'budget-wizard-component': BudgetWizardComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.seBudgetListComponentInit()
		},
		seBudgetListComponentInit() {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>