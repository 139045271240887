<template>
	<div id="actionListComponent">
		<button ref="btnSetActionsSelectedFromRole" @click="setActionsSeletedFromRole" v-show="false">Set Actions
			Selected</button>
		<div v-show="view == 'table'">
			<table class="table table-sm fs--1">
				<thead>
					<tr>
						<th>ID</th>
						<th>Nombre</th>
						<th>Descripción</th>
						<th>Ruta</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(a, i) in listActionsFilter" :key="i">
						<td>{{ a.id }}</td>
						<td>{{ a.name }}</td>
						<td>{{ a.description }}</td>
						<td>{{ a.controller_path }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-show="view == 'list'" class="fs--1 py-2">
			<div v-for="(a, i) in listActionsFilter" :key="i" class="ps-2 pe-2">
				<input class="form-check-input me-2" type="checkbox" value="" :id="'flexCheckDefault' + a.id"
					v-show="selectable == true" v-model="a.selected" @change="changeActionSelectedLocal(a)">
				<a class="text-800 pointer" @click="setObjectActionLocal(a)">
					<strong>{{ a.name }}</strong>
				</a>
				<div class="float-end me-5" v-show="viewDescription == true"><i>{{ a.description }}</i></div>
			</div>
		</div>


		<!-- Modal Action-->
		<button ref="btnShowActionModal" data-bs-toggle="modal" data-bs-target="#actionModal" v-show="false">Show Action
			Modal</button>
		<div class="modal fade" id="actionModal" tabindex="-1" aria-labelledby="actionModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title text-900" id="actionModalLabel">
							Action
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row g-2">
							<div class="col-6">
								<div class="mb-3">
									<label class="form-label">
										Nombre
									</label>
									<input class="form-control form-control-sm" type="text" placeholder="Nombre"
										v-model="objectAction.name" />
								</div>
							</div>
							<div class="col-6">
								<div class="mb-3">
									<label class="form-label">
										Descripción
									</label>
									<input class="form-control form-control-sm" type="text" placeholder="Descripción"
										v-model="objectAction.description" />
								</div>
							</div>
							<div class="col-6">
								<div class="mb-3">
									<label class="form-label">
										Ruta
									</label>
									<input class="form-control form-control-sm" type="text" placeholder="Descripción"
										v-model="objectAction.controller_path" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import action from '@/mixins/generals/action.js'

export default {
	name: 'ActionListComponent',
	mixins: [mixin, axiosAction, errors, action],
	props: ['selectable', 'editable', 'viewDescription', 'view', 'actionsSelected', 'selectAll'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		listActionsFilter() {
			this.listActions.forEach(a => {
				a.selected = this.selectAll
			})
			return this.listActions
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getActionsResult': this.getActionsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		changeActionSelectedLocal(a) {
			this.$emit('changeActionSelected', a)
		},
		getActionsLocal(page = 1) {
			this.getActions(page)
		},
		getActionsResultLocal(response) { },
		initModuleLocal() {
			this.setActionListComponentInit()
		},
		saveActionLocal() {
			this.saveAction(this.objectAction)
		},
		saveActionResultLocal(response) { },
		setActionListComponentInit() {
			this.getActionsLocal()
		},
		setActionsSeletedFromRole() {
			if (this.actionsSelected != undefined) {
				this.listActions.forEach(a => {
					a.selected = false
				})
				let index = -1
				this.actionsSelected.forEach(as => {
					index = this.listActions.map(a => parseInt(a.id)).indexOf(parseInt(as.id))
					if (index > -1) {
						this.listActions[index].selected = true
					}
				})
			}
		},
		setObjectActionLocal(a) {
			this.savingAction = false
			if (this.editable == true) {
				this.setObjectAction(a)
				this.$refs.btnShowActionModal.click()
			}
			if (this.selectable == true) {
				a.selected = !a.selected
				this.changeActionSelectedLocal(a)
			}
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>