var manifesto = {
	data() {
		return {
			listManifestos: [],
			paginationManifestos: [],
			objectManifesto: {
				exists: 0,
				id: 0,
				transport_id: null,
				transport: null,
				name: '',
				description: '',
				status_id: null,
				status: null,
				barcode: '',
				created_at: '',
				created_at: '',
				shippings: [],
				trackings_manifesto: []
			},
			loadingManifestos: false,
			savingManifesto: false,
			searchManifestos: ''
		}
	},
	methods: {
		addListManifestos(manifesto) {
			let index = this.listManifestos.map(m => parseInt(m.id)).indexOf(parseInt(manifesto.id))
			if (index == -1) {
				this.listManifestos.unshift(manifesto)
			}
		},
		clearObjectManifesto() {
			this.objectManifesto.exists = 0
			this.objectManifesto.id = 0
			this.objectManifesto.transport_id = null
			this.objectManifesto.transport = null
			this.objectManifesto.name = ''
			this.objectManifesto.description = ''
			this.objectManifesto.status_id = null
			this.objectManifesto.status = null
			this.objectManifesto.barcode = ''
			this.objectManifesto.created_at = ''
			this.objectManifesto.created_at = ''
			this.objectManifesto.shippings = []
			this.objectManifesto.trackings_manifesto = []
		},
		getManifestos(page = 1) {
			this.loadingShippings = true
			this.listManifestos = []
			this.paginationManifestos = []
			let param = '?page=' + page
			param += '&search=' + this.searchManifestos
			param += '&qRow=' + 15
			let url = this.getApiBackendNew + 'manifestos' + param
			this.axiosActionIS(url, 'GET', 'getManifestosResult')
		},
		getManifestosResult(response) {
			this.loadingShippings = false
			if (response.data.success == true) {
				this.listManifestos = response.data.data
				this.paginationManifestos = response.data.paginate
			}
			this.getManifestosResultLocal(response)
		},
		getManifestosResultLocal(response) {
			console.log('getManifestosResultLocal no desarrollado', response)
		},
		saveManifesto(m) {
			this.savingManifesto = true
			let method = (m.exists == 0) ? 'POST' : 'PUT'
			let url = (m.exists == 0) ? 'manifesto/register' : 'manifesto/' + m.id + '/update'
			url = this.getApiBackendNew + url
			this.axiosActionIS(url, method, 'saveManifestoResult', m)
		},
		saveManifestoResult(response) {
			this.savingManifesto = false
			if (response.data.success == true) {
				this.addListManifestos(response.data.data)
			}
			this.saveManifestoResultLocal(response)
		},
		saveManifestoResultLocal(response) {
			console.log('saveManifestoResultLocal no desarrollado', response)
		},
		setObjectManifesto(m) {
			this.clearObjectManifesto()
			let newM = Object.create(m)
			for (const property in newM) {
				this.objectManifesto[property] = newM[property]
			}
		},
		showManifesto(id, resource = 'default') {
			this.clearObjectManifesto()
			this.searchingManifesto = true
			let url = this.getApiBackendNew + 'manifesto/' + id + '?resource=' + resource
			this.axiosActionIS(url, 'GET', 'showManifestoResult')
		},
		showManifestoResult(response) {
			this.searchingManifesto = false
			if (response.data.success == true) {
				this.setObjectManifesto(response.data.data)
			}
			this.showManifestoResultLocal(response)
		},
		showManifestoResultLocal(response) {
			console.log('showManifestoResultLocal no desarrollado', response)
		},
		trackingManifestoRegister(id, trackingManifestoId) {
			this.savingManifesto = false
			let data = {
				trackingManifestoId: trackingManifestoId
			}
			let url = this.getApiBackendNew + 'manifesto/' + id + '/tracking/register'
			this.axiosActionIS(url, 'POST', 'trackingManifestoRegisterResult', data)
		},
		trackingManifestoRegisterResult(response) {
			this.savingManifesto = true
			this.trackingManifestoRegisterResultLocal(response)
		},
		trackingManifestoRegisterResultLocal(response) {
			console.log('trackingManifestoRegisterResultLocal no desarrollado', response)
		},
		validateObjectManifesto() {
			if (this.objectManifesto.name.trim().length < 3) {
				return false
			}
			if (this.objectManifesto.transport_id == 0 || this.objectManifesto.transport_id == null) {
				return false
			}
			return true
		}
	},
};

export default manifesto;