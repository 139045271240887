<template>
	<div id="shippingComponent">
		<button ref="btnDeleteShipping" @click="deleteShippingLocal" v-show="false">Delete Shipping</button>
		<button ref="btnSaveShipping" @click="saveShippingLocal" v-show="false">Save Shipping</button>
		<button ref="btnShowShipping" @click="showShippingLocal" v-show="false">Show Shipping</button>
		<button ref="btnShowShippingByShippingRequest" @click="showShippingByShippingRequestLocal" v-show="false">Show
			Shipping by
			Shipping Request</button>
		<div v-show="generandoEtiquetaCorresChile"
			style="position: absolute; z-index:1; height: 100vh; top:0; right:0; left: 0px; align-items: center; display: flex;"
			class="bg-secondary">
			<div style="width: 100%;" class="text-center text-bg-secondary">
				<div class="spinner-border spinner-border-sm" role="status"><span
						class="visually-hidden">Loading...</span></div>
				Generando Etiqueta de Correos de Chile . . .
			</div>
		</div>

		<div class="border-bottom border-muted mb-2" v-show="objectShipping.id == 0">
			<div class="mb-1">
				<label class="form-label" for="barCodeInput">
					<span class="me-1" v-show="iconDataShippingInput == 'barcode'"><i class="fas fa-barcode"></i></span>
					<span class="me-1" v-show="iconDataShippingInput == 'box'"><i class="fas fa-box"></i></span>
					<span class="me-1" v-show="iconDataShippingInput == 'weight'"><i class="fas fa-weight"></i></span>
					{{ textDataShippingInput }}:</label>
				<input class="form-control form-control-sm" id="barCodeInput" ref="dataShippingInput" type="text"
					placeholder="" v-model="dataShipping" @focus="$event.target.select()"
					@keyup.enter="analyzeDataShipping" :disabled="disabledAll" />
			</div>
		</div>
		<div v-show="showData">
			<div class="border-bottom border-muted mb-2">
				<div class="row fs-2" v-if="objectShipping.id > 0">
					<div class="col-md-6">
						Envío Nº {{ objectShipping.id }}
					</div>
					<div class="col-md-6 text-end">
						{{ objectShipping.status.name }}
					</div>
					<hr>
				</div>
				<div class="py-2 px-2 border-top border-danger">
					<div class=""><i class="fas fa-user-alt me-2"></i><span>{{ objectShipping.sendto }}</span></div>
					<div class=""><i class="fas fa-house-user me-2"></i><span>{{ objectShipping.address }}, {{
			objectShipping.comuna }}</span></div>
					<div class=""><i class="fas fa-phone-square-alt me-2"></i><span>{{ objectShipping.phone }}</span>
					</div>
					<div class=""><i class="far fa-envelope text-secondary me-2"></i><span>{{ objectShipping.email
							}}</span>
					</div>
					<div class=""><i class="far fa-address-card text-secondary me-2"></i><span>{{
			objectShipping.document_number }}</span></div>
					<hr />
					<div class="text-center py-2 fs-3 text-white" :class="'bg-' + objectShipping.transport.color"
						v-if="objectShipping.transport != null">
						{{ objectShipping.transport.name }}
						<div>
							<span v-show="objectShipping.freight_order != null && objectShipping.freight_order != ''">{{
			objectShipping.freight_order }}</span>
						</div>
					</div>
					<div class="container mt-1 mb-1">
						<div class="row">
							<div class="col-6  bg-800 text-center text-white">
								<span class="fs--1">Cantidad</span>
								<input type="text" class="text-center text-white fs-3 mb-2"
									style="background: none; border:none; width: 100%;"
									v-model="objectShipping.packages" @focus="$event.target.select()"
									:readonly="disabledPW"
									@keyup.enter="updateShippingByFieldLocal('packages', objectShipping.packages)"
									@click="setFocusObjectShippingPW()" @dblclick="clearObjectShippingPW('packages')">
							</div>
							<div class="col-6 bg-1000 text-center text-white">
								<span class="fs--1">Peso</span>
								<input type="text" class="text-center text-white fs-3 mb-2"
									style="background: none; border:none; width: 100%;" v-model="objectShipping.weight"
									@focus="$event.target.select()" :readonly="disabledPW"
									@keyup.enter="updateShippingByFieldLocal('weight', objectShipping.weight)"
									@click="setFocusObjectShippingPW()" @dblclick="clearObjectShippingPW('weight')">
							</div>
						</div>
					</div>
					<div class="form-group row">
						<span for="manifesto" class="col-sm-5 col-form-label fs-0">Manifiesto: </span>
						<div class="col-sm-7">
							<select id="manifesto" class="form-control form-control-sm"
								ref="objectShippingManifestoSelect" v-model="objectShipping.manifesto_id"
								:disabled="disabledObjectShippingManifesto"
								@change="updateShippingByFieldLocal('manifesto_id', objectShipping.manifesto_id)">
								<option value="0" disabled v-show="objectShipping.manifesto_id == 0">Seleccione el
									Manifiesto</option>
								<option v-for="(m, i) in listManifestoFilter" :key="i" :value="m.id"
									:disabled="objectShipping.manifesto_id == m.id">{{ m.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="text-end">
						<div class="form-check form-switch form-check-inline">
							<input class="form-check-input" id="flexSwitchCheckChecked" type="checkbox" checked=""
								:disabled="disabledGenerateLabel" v-model="generateLabel" />
							<label class="form-check-label" for="flexSwitchCheckChecked">Generar Etiqueta
								Transporte</label>
						</div>
					</div>
				</div>
			</div>
			<div class="border-bottom border-muted mb-2">
				<hr>
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item fs--1"><a class="nav-link active" id="shippings-request-tab"
							data-bs-toggle="tab" href="#tab-shippings-request" role="tab"
							aria-controls="tab-shippings-request" aria-selected="true">Solicitud de Envios
							({{ objectShipping.shipping_request.length }})</a></li>
					<li class="nav-item fs--1"><a class="nav-link" id="boxs-tab" data-bs-toggle="tab" href="#tab-boxs"
							role="tab" aria-controls="tab-boxs" aria-selected="false">Cajas
							({{ objectShipping.shipping_packages.length }})</a></li>
					<li class="nav-item fs--1"><a class="nav-link" id="contact-tab" data-bs-toggle="tab"
							href="#tab-contact" role="tab" aria-controls="tab-contact"
							aria-selected="false">Seguimiento</a></li>
				</ul>
				<div class="tab-content p-3" id="myTabContent" style="overflow: auto; ">
					<div class=" tab-pane fade show active" id="tab-shippings-request" role="tabpanel"
						aria-labelledby="shippings-request-tab" style="padding: 0px">
						<table class="table table-sm table-12 fs--2">
							<thead>
								<tr>
									<th>SE ID</th>
									<th>Enviar a</th>
									<th>Dirección</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(sr, i) in listObjectShipoingRequestFilter" :key="i" class="pointer">
									<td>{{ sr.shipping_request_id }}</td>
									<td>{{ sr.sendto }}</td>
									<td>{{ sr.address }}, {{ sr.comuna.name }}</td>
									<td class="text-end"></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tab-pane fade" id="tab-boxs" role="tabpanel" aria-labelledby="boxs-tab">
						<div class="">
							<table class="table table-sm table-12 fs--2">
								<tbody>
									<tr v-for="(sp, i) in objectShipping.shipping_packages" :key="i">
										<td>{{ sp.package_id }}</td>
										<td>{{ sp.barcode }}</td>
										<td class="text-end">
											<span @click="toPrintLabelShippingOne(sp)">
												<i class="fas fa-print pointer"></i>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="tab-pane fade" id="tab-contact" role="tabpanel" aria-labelledby="contact-tab">
						<table class="table table-sm table-striped table-hover fs--2">
							<thead>
								<tr>
									<th class="text-center">ID</th>
									<th>Fecha</th>
									<th>Seguimiento</th>
									<th>Usuario</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(ts, i) in objectShipping.trackings_shipping" :key="i"
									@dblclick="console(ts)">
									<td class="text-center">{{ ts.id }}</td>
									<td class="text-center">{{ ts.created_at }}</td>
									<td>{{ ts.trackings_shipping.description }}</td>
									<td>{{ ts.user.name }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="mb-2 text-center" v-show="visibleChangeStatus">
				<div>
					<div class="mb-2" v-show="visibleChangeStatusInput">
						<input class="form-control form-control-sm" v-model="observationShippingTrackingShipping" />
					</div>
					<button class="btn btn-sm mb-1" :class="(visibleChangeStatusInput) ? 'btn-success' : 'btn-primary'"
						@click="saveShippingTrackingLocal">
						<span v-if="visibleChangeStatusInput">Entregado</span>
						<span v-else>Despachado</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import shipping from '@/mixins/shippings'
import analizeSTR from '@/mixins/generals/analizeSTR.js'
import shippingRequest from '@/mixins/shippingsRequest'
import label from "@/mixins/labels/"
import correosChile from '@/mixins/generals/correosChile.js'

export default {
	name: 'ShippingComponent',
	mixins: [mixin, axiosAction, errors, shipping, analizeSTR, shippingRequest, label, correosChile],
	props: ['id'],
	data() {
		return {
			showData: false,
			generateLabel: false,
			dataShipping: '',
			observationShippingTrackingShipping: ''
		}
	},
	components: {
	},
	computed: {
		disabledAll() {
			return this.generandoEtiquetaCorresChile
		},
		disabledGenerateLabel() {
			if (this.objectShipping.transport != null) {
				return !(this.objectShipping.transport.generate_label == 1)
			}
			return true
		},
		disabledObjectShippingManifesto() {
			if (this.objectShipping.status_id != 20 && this.objectShipping.status_id != null) {
				return true
			}
			return false
		},
		disabledPW() {
			if (this.objectShipping.status_id != 20) {
				return true
			}
			return false
		},
		listManifestoFilter() {
			let list = []
			if (this.objectShipping.manifesto != null) {
				list.push({ id: this.objectShipping.manifesto.id, name: this.objectShipping.manifesto.name })
			}
			if (this.objectShipping.manifestos != null) {
				let i = -1
				this.objectShipping.manifestos.forEach(m => {
					if (list.map(ma => ma.id).indexOf(m.id) == -1) {
						list.push({ id: m.id, name: m.name })
					}
				})
			}
			return list
		},
		listObjectShipoingRequestFilter() {
			this.objectShipping.shipping_request.forEach((sr, i) => {
				if (sr.recipient_data != undefined) {
					this.objectShipping.shipping_request[i] = sr.recipient_data
					this.objectShipping.shipping_request[i].genesys_cabmov = sr.genesys_cabmov
					this.objectShipping.shipping_request[i].legal_document = sr.legal_document
				}
			})
			return this.objectShipping.shipping_request
		},
		iconDataShippingInput() {
			if (this.objectShipping.sendto != '') {
				if (this.objectShipping.packages == '') {
					return 'box'
				}
				if (this.objectShipping.weight == '') {
					return 'weight'
				}
			}
			return 'barcode'
		},
		textDataShippingInput() {
			if (this.objectShipping.sendto != '') {
				if (this.objectShipping.packages == '') {
					return 'Cantidad de Bultos'
				}
				if (this.objectShipping.weight == '') {
					return 'Peso'
				}
			}
			return 'Código de Barra'
		},
		visibleChangeStatus() {
			return (this.objectShipping.status_id == 20)
		},
		visibleChangeStatusInput() {
			if (this.objectShipping.transport_id == 1)
				return true
			if (this.objectShipping.transport_id == 4)
				return true
			if (this.objectShipping.transport_id == 6)
				return true
			return false
		}
	},
	methods: {
		analyzeDataShipping() {
			let r = this.analizeSTR(this.dataShipping)
			if (r['result'] == 'INTEGER' || r['result'] == 'FLOAT') {
				if (this.objectShipping.exists == 0) {
					this.showMsgErrorHandling('Sin datos de envío', 'Primero debemos saber a quien vamos a envíar<br>Ingrese una solicitud de envío', 'warn', 5000, 'top right')
				} else {
					this.analyzeDataShippingSetPW(r['result'])
					console.log('Actualizar Cantidades')
				}
				this.dataShipping = ''
			} else if (r['result'] == 'SHIPPINGREQUEST') {
				this.showShippingRequestLocal(r['data'])
			} else {
				this.showMsgErrorHandling('Sin datos de envío', 'No sabemos que hacer con eso', 'warn', 5000, 'top right')
				this.dataShipping = ''
			}
		},
		analyzeDataShippingSetPW(type) {
			if (this.objectShipping.packages == '') {
				if (type == 'INTEGER' && this.dataShipping > 0) {
					this.objectShipping.packages = this.dataShipping
				} else {
					this.showMsgErrorHandling('Cantidad Incorrecta', 'La Cantidad debe ser un número Entero mayor a 0', 'warn', 5000, 'top right')
				}
				return
			} else if (this.objectShipping.weight == '') {
				if (this.dataShipping > 0) {
					this.objectShipping.weight = this.dataShipping
				} else {
					this.showMsgErrorHandling('Cantidad Incorrecta', 'El peso debe ser mayor a 0', 'warn', 5000, 'top right')
				}
			} else {
				this.showMsgErrorHandling('Completo', 'Ya ingresó la cantidad de bultos y el peso', 'warn', 5000, 'top right')
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteShippingResult': this.deleteShippingResult(response); break;
				case 'etiquetaCorreosChileZPLResult': this.etiquetaCorreosChileZPLResult(response); break;
				case 'saveShippingResult': this.saveShippingResult(response); break;
				case 'saveShippingTrackingResult': this.saveShippingTrackingResult(response); break;
				case 'showLabelResult': this.showLabelResult(response); break;
				case 'showShippingResult': this.showShippingResult(response); break;
				case 'showShippingByShippingRequestResult': this.showShippingByShippingRequestResult(response); break;
				case 'showShippingRequestResult': this.showShippingRequestResult(response); break;
				case 'updateShippingByFieldResult': this.updateShippingByFieldResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectShippingPW(field) {
			if (this.objectShipping.status_id == null) {
				this.objectShipping[field] = ''
				this.$refs.dataShippingInput.focus()
			}
		},
		deleteShippingLocal() {
			if (this.objectShipping.id > 0) {
				this.$confirm(
					{
						message: 'Está seguro que quiere eliminar el envío Nº ' + this.objectShipping,
						button: {
							no: 'No, de ninguna manera',
							yes: 'Si, dale'
						},
						callback: confirm => {
							if (confirm) {
								this.deleteShipping(this.objectShipping.id)
							}
						}
					}
				)
			} else {
				this.showMsgErrorHandling('IMPOSIBLE', 'No puede Eliminar algo que no existe', 'warn', 5000, 'top right')
			}
		},
		deleteShippingResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('deleteShipping', response.data.data)
			}
		},
		etiquetaCorreosChileZPLResultLocal(response) {
			if (response.data.success == true) {
				let data = response.data.data
				if (data.status == 200) {
					data.data.archivos.forEach((a, i) => {
						this.socketWebSocketServerPrinter(a.etiqueta, localStorage.printerWebSocketServer)
					});
					this.resetShippingComponent()
				} else if (data.status == 400) {
					let error = '<ul>'
					data.message.errores.forEach(e => {
						for (let clave in e) {
							if (clave != 'estado' && clave != 'codAdmision') {
								error += '<li>' + e[clave].campo + ': ' + e[clave].mensaje + '</li>'
							}
						}
					})
					error += '</ul>'
					this.showMsgErrorHandling('Errores Correos Chile', error, 'warn', 5000, 'top right')
				}
			} else {
				let error = '<ul>'
				for (let clave in response.data.data) {
					error += '<li>' + response.data.data[clave] + '</li>'
				}
				error += '</ul>'
				this.showMsgErrorHandling(response.data.message, error, 'warn', 5000, 'top right')
			}
		},
		generateLabelTransport() {
			this.etiquetaCorreosChileZPL(
				this.legalDocuments(),
				'EV' + this.objectShipping.id,
				(this.objectShipping.office == 1) ? '07' : '24',
				this.remitentes[0].nombre,
				this.remitentes[0].rut,
				this.remitentes[0].direccion[0].codPais,
				this.remitentes[0].direccion[0].comuna,
				this.remitentes[0].direccion[0].calle + ' ' + this.remitentes[0].direccion[0].numero,
				this.remitentes[0].direccion[0].codPostal,
				'Ventas General',
				'968736112',
				'contacto@aceschile.cl',
				this.objectShipping.sendto,
				'056',
				this.objectShipping.comuna,
				this.objectShipping.address,
				'',
				this.objectShipping.document_number,
				this.objectShipping.sendto,
				this.objectShipping.phone,
				this.objectShipping.email,
				this.objectShipping.packages,
				this.objectShipping.weight,
				this.objectShipping.id,
				(this.objectShipping.paid_shipping == 1) ? 'P' : 'D',
				this.objectShipping.total_price
			)
		},
		initModuleLocal() {
			this.setShippingComponentInit()
		},
		legalDocuments() {
			let lds = ''
			this.objectShipping.shipping_request.forEach(sr => {
				sr.legal_document.forEach(ld => {
					if (ld.legal_document_number > 0) {
						lds += (lds == '') ? ld.legal_document_number : ', ' + ld.legal_document_number
					}
				})
				sr.genesys_cabmov.forEach(gc => {
					if (parseInt(gc.tipocompcod) == 33 || parseInt(gc.tipocompcod) == 39 || parseInt(gc.tipocompcod) == 1 || parseInt(gc.tipocompcod) == 120 || parseInt(gc.tipocompcod) == 52) {
						lds += (lds == '') ? gc.nrocomp : ', ' + gc.nrocomp
					}
				})
			})
			return lds
		},
		resetShippingComponent() {
			this.showData = false
			this.clearObjectShipping()
			setTimeout(() => {
				this.$refs.dataShippingInput.focus()
			}, 500)
		},
		saveShippingLocal() {
			if (this.validateFormShipping() && !this.disabledAll) {
				this.saveShipping(this.objectShipping)
			} else {
				this.showMsgErrorHandling('Falta Información', 'Faltan datos por llenar' + this.listMessageError(), 'warn', 5000, 'top right')
			}
		},
		saveShippingResultLocal(response) {
			if (response.data.success == true) {
				localStorage.setItem('MA' + this.objectShipping.transport.syntethic.toUpperCase(), this.objectShipping.manifesto.id)
				this.$emit('saveShipping', response.data.data)
				this.toPrintLabelShippingAll(response.data.data.shipping_packages, false)
				if (this.objectShipping.transport.generate_label == 1 && this.generateLabel == 1) {
					this.generateLabelTransport()
				} else {
					this.resetShippingComponent()
				}
			}
		},
		saveShippingTrackingLocal() {
			let trackingShippingId = (this.visibleChangeStatusInput) ? 4 : 3
			this.saveShippingTracking(this.objectShipping.id, trackingShippingId, this.observationShippingTrackingShipping)
		},
		saveShippingTrackingResultLocal(response) {
			this.$emit('saveShippingTrackingResult', response)
		},
		setDataSendLocal(sr) {
			if (this.objectShipping.id > 0) {
				if (this.objectShipping.shipping_request_id != sr.shipping_request_id) {
					this.objectShipping.shipping_request_id = sr.shipping_request_id
					this.objectShipping.sendto = sr.sendto
					this.objectShipping.address = sr.address
					this.objectShipping.comuna = sr.comuna
					this.objectShipping.email = sr.email
					this.objectShipping.phone = sr.phone
					this.objectShipping.barcode = sr.barcode
				} else {
					console.log('es el mismo')
				}
			}
		},
		setFocusObjectShippingPW() {
			if (this.objectShipping.id == 0) {
				this.$refs.dataShippingInput.focus()
			}
		},
		setShippingComponentInit() {
			this.$refs.dataShippingInput.focus()
			this.showLabel(1)
		},
		shippingFound(data = null) {
			this.$emit('shippingFound', true, data)
			this.$refs.dataShippingInput.focus()
		},
		showLabelResultLocal(response) { },
		showShippingLocal() {
			this.observationShippingTrackingShipping = ''
			this.showData = false
			if (this.id > 0) {
				this.showShipping(this.id, 'toEdit')
			} else {
				this.clearObjectShipping()
				this.shippingFound()
			}
		},
		showShippingResultLocal(response) {
			if (response.data.success == true) {
				this.showShippingByShippingRequestFound(response)
				this.objectShipping.shipping_request.forEach(sr => {
					sr = sr.recipient_data
				})
				this.shippingFound(this.objectShipping)
			} else {
				this.$emit('shippingFound', false, null)
			}
		},
		showShippingByShippingRequestLocal() {
			this.observationShippingTrackingShipping = ''
			this.clearObjectShipping()
			this.showShippingByShippingRequest(this.id)
		},
		showShippingByShippingRequestResultLocal(response) {
			if (response.data.success == true) {
				this.showShippingByShippingRequestFound(response)
				this.shippingFound(response.data.data)
			} else {
				this.$emit('shippingRequestNotFound', false, null)
			}
		},
		showShippingByShippingRequestFound(response) {
			this.showData = true
			this.objectShipping.manifesto_id = (response.data.data.manifesto != null) ? response.data.data.manifesto.id : 0
			this.generateLabel = (this.objectShipping.transport.generate_label == 1)
		},
		showShippingRequestLocal(shippingRequestId) {
			this.showShippingRequest(shippingRequestId, 'byShipping')
		},
		showShippingRequestResultLocal(response) {
			if (response.data.success == true) {
				if (this.statusOKLocal(response.data.data)) {
					if (this.objectShipping.exists == 0) {
						this.setObjectShipping(response.data.data)
						this.objectShipping.total_price = 0
						this.showShippingByShippingRequestFound(response)
						this.setObjectShippingShippingRequest(response.data.data)
						this.setObjectShippingManifesto()
					} else {
						this.setObjectShippingShippingRequest(response.data.data)
					}
				} else {
					this.showMsgErrorHandling('No se puede Ingresar', 'La Solicitud de envío tiene el estado "<b class="text-warning">' + response.data.data.status.name.toUpperCase() + '</b>", que no se puede ingresar', 'warn', 5000, 'top right')
				}
				this.dataShipping = ''
			} else {
				this.showMsgErrorHandling('NO encontrado', response.data.data, 'warn', 5000, 'top right')
				this.dataShipping = ''
			}
		},
		setObjectShippingShippingRequest(data) {
			let i = this.objectShipping.shipping_request.map(sr => parseInt(sr.shipping_request_id)).indexOf(parseInt(data.shipping_request_id))
			if (i == -1) {
				if (data.transport_id == this.objectShipping.transport_id) {
					let shippingRequest = {
						shipping_request_id: data.shipping_request_id,
						address: data.address,
						comuna: data.comuna,
						document_number: data.document_number,
						email: data.email,
						phone: data.phone,
						sendto: data.sendto,
						barcode: data.barcode,
						legal_document: data.legal_documents,
						genesys_cabmov: data.genesys_cabmov,
						total_price: data.total_price
					}
					this.objectShipping.shipping_request.push(shippingRequest)
					this.objectShipping.total_price = parseInt(this.objectShipping.total_price) + parseInt(data.total_price)
				} else {
					this.showMsgErrorHandling('Diferente Trasporte', 'La SE Nº ' + data.shipping_request_id + ' se va por <b class="text-' + data.transport.color + '">' + data.transport.name + '</b>', 'warn', 5000, 'top right')
				}
			} else {
				this.showMsgErrorHandling('Tome Atención', 'La SE Nº ' + data.shipping_request_id + ' ya está en este envío', 'warn', 5000, 'top right')
			}
		},
		setObjectShippingManifesto() {
			let mId = localStorage.getItem('MA' + this.objectShipping.transport.syntethic.toUpperCase())
			let i = this.listManifestoFilter.map(m => parseInt(m.id)).indexOf(parseInt(mId))
			this.objectShipping.manifesto_id = (i > -1) ? mId : 0
		},
		statusOKLocal(sr) {
			let i = this.statusIdsShippingRequestAllowed.map(st => parseInt(st)).indexOf(parseInt(sr.status.id))
			return (i > -1)
		},
		updateShippingByFieldLocal(field, value) {
			if (this.objectShipping.id > 0) {
				this.updateShippingByField(this.objectShipping.id, field, value)
			}
		},
		updateShippingByFieldResultLocal(response) {
			if (response.data.success == true) {
				let msg = ''
				if (response.data.message == 'manifesto_id') {
					msg = 'Se ha asignado con exito el manifiesto'
				} else if (response.data.message == 'packages') {
					msg = 'Se cambió con exito la cantidad'
				} else if (response.data.message == 'weight') {
					msg = 'Se cambió con exito el peso'
				}
				this.showMsgErrorHandling('Actualizado', msg, 'success', 5000, 'top right')
			}
		},
		validateFormShipping() {
			let result = true
			let goto = false
			this.messageError = []
			if (this.objectShipping.sendto == '') {
				this.messageError.push('Falta ingresar a quien envía')
				result = false
			}
			if (this.objectShipping.address == '') {
				this.messageError.push('Falta ingresar la dirección')
				result = false
			}
			if (this.objectShipping.comuna == '') {
				this.messageError.push('Falta ingresar la comuna')
				result = false
			}
			if (this.objectShipping.phone == '') {
				this.messageError.push('Falta ingresar el telefono')
				result = false
			}
			if (this.objectShipping.email == '') {
				this.messageError.push('Falta ingresar el email')
				result = false
			}
			if (this.objectShipping.document_number == '') {
				this.messageError.push('Falta ingresar el RUT')
				result = false
			}
			if (this.objectShipping.packages == '') {
				this.messageError.push('Falta ingresar cantidad de bultos')
				if (!goto) {
					this.$refs.dataShippingInput.focus()
					goto = true
				}
				result = false
			}
			if (this.objectShipping.weight == '') {
				this.messageError.push('Falta ingresar el peso')
				if (!goto) {
					this.$refs.dataShippingInput.focus()
					goto = true
				}
				result = false
			}
			if (this.objectShipping.manifesto_id == 0) {
				this.messageError.push('Falta elegir el manifiesto')
				if (!goto) {
					this.$refs.objectShippingManifestoSelect.focus()
					goto = true
				}
				result = false
			}
			if (this.objectShipping.shipping_request.length == 0) {
				this.messageError.push('No hay asociada una SE')
				result = false
			}
			return result
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>