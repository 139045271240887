/* Requiere de genesys.js */

var movimiento = {
	data() {
		return {
			movimiento: [],
			searchingMovimiento: false,
			objectCabmov: {
				brutocomp: 0,
				cargadoa: '',
				cobro: 0,
				codbareti: '',
				comentario: '',
				comuna: '',
				direccion: '',
				enviara: '',
				rut: '',
				email: '',
				fecmov: '',
				succod: '',
				nrocomp: '',
				tipocompcod: 0,
				nrocompLegal: '',
				fecmovLegal: '1900-01-01',
				succodLegal: 0,
				tipocompcodLegal: 0,
				codbaretiLegal: 'S/BARCODE',
				observtext: '',
				comentario: '',
				telefono: '',
				transportId: 0,
				transportName: ''
			}
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		analyzeResultGetMovimiento(response, onlyCabmov = false) {
			if (response.data.data.cabmov.fecmov != '' && response.data.data.cabmov.succod != '') {
				this.objectCabmov.nrocomp = response.data.data.cabmov.nrocomp
				this.objectCabmov.tipocompcod = response.data.data.cabmov.tipocompcod
				this.objectCabmov.fecmov = response.data.data.cabmov.fecmov
				this.objectCabmov.succod = response.data.data.cabmov.succod
				this.objectCabmov.codbareti = response.data.data.cabmov.codbareti
				this.objectCabmov.brutocomp = response.data.data.cabmov.brutocomp
				this.analyzeResultGetMovimientoLocal(response)
				if (response.data.data.cabmov.tipocompcod != 2) {
					if (!onlyCabmov) {
						this.analyzeResultConsultarBoleta(response.data.data.movrel, response.data.data.cabmov.tipofac)
						this.analyzeResultConsultarClienteEnvioLocal(response.data.data.climov)
						this.analyzeResultConsultarCobroLocal(response.data.data.cabmov.brutocomp)
						/*this.consultarCobro(response.data.data.cabmov.brutocomp)
						this.consultarObservacion(response.data.data.observmov)*/
					}
				}
			} else {
				alert('No se encontró el comprobante')
			}
		},
		analyzeResultGetMovimientoLocal(response) {
			console.log('analyzeResultGetMovimientoLocal no desarrollado', response)
		},
		analyzeResultConsultarBoleta(movrel, tipofac) {
			movrel.forEach(m => {
				if (m['tipocompcodrel'] == 1) {
					this.objectCabmov.nrocompLegal = m['nrocomprel']
					this.objectCabmov.fecmovLegal = m['fecmovrel']
					this.objectCabmov.succodLegal = m['succodrel']
					this.objectCabmov.tipocompcodLegal = (tipofac == 'A') ? 33 : 39
					this.objectCabmov.codbaretiLegal = m['getcodbaretirel']
				}
			});
			this.analyzeResultConsultarBoletaLocal(this.objectCabmov)
		},
		analyzeResultConsultarBoletaLocal(cabmov) {
			console.log('analyzeResultConsultarBoletaLocal no desarrollado', cabmov)
		},
		analyzeResultConsultarClienteEnvioLocal(climov) {
			console.log('analyzeResultConsultarClienteEnvioLocal no desarrollado', climov)
		},
		analyzeResultConsultarCobroLocal(brutocomp) {
			console.log('analyzeResultConsultarCobroLocal no desarrollado', brutocomp)
		},
		clearObjectCabmov() {
			this.objectCabmov.brutocomp = 0
			this.objectCabmov.cargadoa = ''
			this.objectCabmov.cobro = 0
			this.objectCabmov.codbareti = ''
			this.objectCabmov.comentario = ''
			this.objectCabmov.comuna = ''
			this.objectCabmov.direccion = ''
			this.objectCabmov.enviara = ''
			this.objectCabmov.rut = ''
			this.objectCabmov.email = ''
			this.objectCabmov.fecmov = ''
			this.objectCabmov.succod = ''
			this.objectCabmov.tipocompcod = 0
			this.objectCabmov.nrocomp = ''
			this.objectCabmov.nrocompLegal = ''
			this.objectCabmov.fecmovLegal = '1900-01-01'
			this.objectCabmov.succodLegal = 0
			this.objectCabmov.tipocompcodLegal = 0
			this.objectCabmov.codbaretiLegal = 'S/BARCODE'
			this.objectCabmov.observtext = ''
			this.objectCabmov.comentario = ''
			this.objectCabmov.telefono = ''
			this.objectCabmov.transportId = 0
			this.objectCabmov.transportName = ''
		},
		getMovimiento(tipocompcod, nrocomp = 0) {
			this.searchingMovimiento = true
			nrocomp = (nrocomp == 0) ? '' : '-' + nrocomp
			let url = this.getApiBackend + 'genesys/get'

			let param = { 'get': 'movimiento/' + 'get/' + tipocompcod + nrocomp }

			this.axiosActionIS(url, 'POST', 'getMovimientoResult', param)
		},
		getMovimientoResult(response) {
			this.searchingMovimiento = false
			if (response.data.success === true) {

			}
			this.getMovimientoResultLocal(response)
		},
		getMovimientoResultLocal(response) {
			console.log('getMovimientoResultLocal no desarrollado ', response)
		},
		getMovimientoSinAtender() {
			let url = 'movimiento/' + '/getUnattended';
			this.$emit('showLockScreen', 'Buscando comprobantes sin atender en Genesys . . .')
			console.log('Buscando comprobantes sin atender en Genesys . . .')
			this.searching = true
			this.axios.get(url)
				.then(response => { this.getMovimientoSinAtenderResult(response); this.$emit('hideLockScreen'); this.searching = false })
				.catch(error => { console.log(error); alert(error); this.$emit('hideLockScreen'); this.searching = false })
		},
		getMovimientoSinAtenderResult(response) {
			console.log(response)
		},
		saveAttendGenesys(cabmov, detmov, grupomov, climov) {
			let data = {
				cabmov: cabmov,
				detmov: detmov,
				grupomov: grupomov,
				climov: climov,
				frontend: 'iSystem 4 (' + this.auth.id + ')',
				userdoc: 15,
				tipdoccod: 1
			}
			let url = this.getApiBackend + 'genesys/get'
			let param = { 'method': 'POST', 'get': 'movimiento/' + 'saveAttendFromISystem4', 'data': data }
			//console.log(url, param)
			this.axiosActionIS(url, 'POST', 'resultSaveAttendGenesys', param)
		},
		resultSaveAttendGenesys(response) {
			this.resultSaveAttendGenesysLocal(response)
		},

	},
};

export default movimiento;