/* Rquiere de mixin.js */

var universityCareer = {
	data() {
		return {
			listUniversityCareers: [],
			urlsUniversityCareer: {
				get: 'universityCareers',
				show: 'universityCareer/',
			},
			paginationUniversityCareers: [],
			searchUniversityCareers: '',
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		getAllUniversityCareers(page = 1) {
			let parameters = '?search=' + this.searchUniversityCareers + '&page=' + page
			let url = this.getApiBackend + this.urlsUniversityCareer.get + parameters
			this.axiosActionIS(url, 'GET', 'getAllUniversityCareersResult')
		},
		getAllUniversityCareersResult(response) {
			if (response.data.success) {
				this.listUniversityCareers = response.data.data
				this.paginationUniversityCareers = response.data.paginate
			}
			this.getAllUniversityCareersResultLocal(response)
		},
		getAllUniversityCareersResultLocal(response) {
			console.log('getAllUniversityCareersResultLocal no desarrollado', response)
		},
		getUniversityCareer(id) {
			this.axiosActionIS(this.getApiBackend + this.urlsUniversityCareer.show + id, 'GET', 'getUniversityCareerResult')
		},
		getUniversityCareerResult(response) {
			this.getUniversityCareerResultLocal(response)
		},
		getUniversityCareerResultLocal(response) {
			console.log('getUniversityCareerResultLocal no está desarrollado', response)
		},
	},
};

export default universityCareer;