var typeTeam = {
    data() {
        return {
            listTypeTeams: [],
            loadingTypeTeams: false,
            paginationTypeTeams: [],
            searchTypeTeams: '',
            urlsTypeTeam: {
                get: 'typeTeams',
                show: 'typeTeam/',
            },
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getAllTypeTeams(page = 1) {
            this.loadingTypeTeams = true
            this.listTypeTeams = []
            this.paginationTypeTeams = []
            let parameters = '?search=' + this.searchTypeTeams + '&page=' + page
            let url = this.getApiBackend + this.urlsTypeTeam.get + parameters
            this.axiosActionIS(url, 'GET', 'getAllTypeTeamsResult')
        },
        getAllTypeTeamsResult(response) {
            this.loadingTypeTeams = false
            this.listTypeTeams = []
            this.paginationTypeTeams = []
            if (response.data.success) {
                this.listTypeTeams = response.data.data
                this.paginationTypeTeams = response.data.paginate
            }
            this.getAllTypeTeamsResultLocal(response)
        },
        getAllTypeTeamsResultLocal(response) {
            console.log('getAllTypeTeamsResultLocal no desarrollado', response)
        },
    },
};

export default typeTeam;