<template>
	<div id="objectListPriceListComponent">
		<button ref="btnClearListPriceListsObject" @click="clearListPriceListsObjectLocal" v-show="false">Borrar Lista</button>
		<button ref="btnGetPriceLists" @click="getAllPriceListsObjectLocal" v-show="false">Buscar listas de precio</button>
		<div v-if="loadingPriceListsObject">
			Buscando listas de precios asignadas
		</div>
		<div v-else class="table-responsive scrollbar" >
			<table class="table table-hover table-striped overflow-hidden table-sm fs--1 mb-0">
				<thead>
					<tr>
						<th>ID</th>
						<th>Nombre</th>
						<th class="text-end">
							<button class="btn p-0" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Agregar" :disabled="disabledBtnPlusObjectPriceList" @click="setNewItemObjectPriceList(true)">
								<span class="fas fa-plus text-info pointer"></span>
							</button>							
						</th>
					</tr>
				</thead>
				<tbody>
					<tr  v-if="listObjectPriceListsFilter.length == 0">
						<td colspan="3">No hay listas de precios asignadas</td>
					</tr>
					<tr v-for="(opl, i) in listObjectPriceListsFilter" :key="i">
						<td>{{ opl.id }}</td>
						<td>{{ opl.name }}</td>
						<td class="text-end">
							<button class="btn p-0" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Quitar" @click="deleteObjectPriceListLocal(opl.price_list_id)">
								<span class="fas fa-minus-circle pointer text-danger"></span>
							</button>
						</td>
					</tr>
					<tr v-show="newItemObjectPriceList">
						<td>0</td>
						<td>
							<select class="" v-model="priceListIdSelected">
								<option v-for="(pl, i) in listPriceListsFilter" :key="i" :value="pl.id">{{ pl.name }}</option>
							</select>
						</td>
						<td class="text-end">
							<button class="btn p-0" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Guardar" :disabled="disabledBtnSaveObjectPriceList" @click="saveClienPriceListLocal()">
								<span class="far fa-save pointer text-primary"></span>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import clients from '@/mixins/clients'
import users from '@/mixins/users'
import priceList from '@/mixins/generals/priceList.js'

export default {
	name: 'ObjectListPriceListComponent',
	mixins: [mixin, axiosAction, errors, clients, users,  priceList],
	props: ['parent', 'object'],
	data () {
		return {
			newItemObjectPriceList: false,
		}
	},
	components: {
	},
	computed: {
		disabledBtnPlusObjectPriceList() {
			return this.newItemObjectPriceList
		},
		disabledBtnSaveObjectPriceList() {
			return (this.priceListIdSelected == 0)
		},
		listPriceListsFilter() {
			return this.listPriceLists.filter(pl => {
				return (this['object' + this.capitalizarPrimeraLetra(this.object)][this.object + '_price_list'].map(obj => parseInt(obj.price_list_id)).indexOf(parseInt(pl.id)) == -1)
			})
		},
		listObjectPriceListsFilter() {
			return this['object' + this.capitalizarPrimeraLetra(this.object)][this.object + '_price_list']
		},
		loadingPriceListsObject() {
			return this['loadingPriceLists' + this.capitalizarPrimeraLetra(this.object)]
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'delete' + this.capitalizarPrimeraLetra(this.object) + 'PriceListResult': this['delete' + this.capitalizarPrimeraLetra(this.object) + 'PriceListResult'](response); break;
				case 'getAll' + this.capitalizarPrimeraLetra(this.object) + 'PriceListsResult': this['getAll' + this.capitalizarPrimeraLetra(this.object) + 'PriceListsResult'](response); break;
				case 'save' + this.capitalizarPrimeraLetra(this.object) + 'PriceListResult': this['save' + this.capitalizarPrimeraLetra(this.object) + 'PriceListResult'](response); break;
				case 'getAllPriceListsResult': this.getAllPriceListsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearListPriceListsObjectLocal() {
			return this['object' + this.capitalizarPrimeraLetra(this.object)][this.object + '_price_list'] = []
		},
		deleteObjectPriceListLocal(priceListId) {
			if(this.parent.id > 0) {
				this.newItemObjectPriceList = false
				this['delete' + this.capitalizarPrimeraLetra(this.object) + 'PriceList'](this.parent.id, priceListId)
			}
		},
		deleteClientPriceListResultLocal(response) {},
		deleteUserPriceListResultLocal(response) {},
		getAllPriceListsObjectLocal() {
			if(this.parent.id > 0) {
				this['getAll' + this.capitalizarPrimeraLetra(this.object) + 'PriceLists'](this.parent.id)
			}
		},
		getAllClientPriceListsResultLocal(response) {
			this.setNewItemObjectPriceList(this.objectClient.client_price_list.length == 0)
		},
		getAllUserPriceListsResultLocal(response) {
			this.setNewItemObjectPriceList(this.objectUser.user_price_list.length == 0)
		},
		getAllPriceListsResultLocal(response) {},
		initModuleLocal() {
			this.setObjectListPriceListComponentInit()
		},
		saveClienPriceListLocal() {
			if(this.priceListIdSelected > 0 && this.parent.id > 0) {
				this['save' + this.capitalizarPrimeraLetra(this.object) + 'PriceList'](this.parent.id, this.priceListIdSelected)
			}
		},
		saveClientPriceListResultLocal(response) {
			this.saveObjectPriceListResultLocal(response)
		},
		saveUserPriceListResultLocal(response) {
			this.saveObjectPriceListResultLocal(response)
		},
		saveObjectPriceListResultLocal(response) {
			if(response.data.success == true) {
				this.newItemObjectPriceList = false
				this.priceListIdSelected = 0
			}
		},
		setObjectListPriceListComponentInit() {
			this.getAllPriceLists()
		},
		setNewItemObjectPriceList(v) {
			this.priceListIdSelected = 0
			this.newItemObjectPriceList = v
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>