<template>
	<div id="menuListComponent">
		<button ref="btnSetMenusSelectedFromRole" @click="setMenusSeletedFromRole" v-show="false">Set Menus
			Selected</button>
		<div class="accordion fs--1" id="accordionPanelsStayOpenExample" style="background-color: transparent;">
			<div class="accordion-item" v-for="(m, i) in listMenusFilter" :key="i"
				style="border: none; background-color: transparent;">
				<div class="accordion-header">
					<div class="float-end m-2 pointer">
						<span data-bs-toggle="collapse" :data-bs-target="'#panelsStayOpen-' + m.id" aria-expanded="true"
							:aria-controls="'panelsStayOpen-' + m.id" style="display: inline-block;"
							@click="clickAccordion(m)" v-show="insertable == true && m.children.length > 0">
							<span v-show="m.open == 0">
								<i class="fas fa-plus"></i>
							</span>
							<span v-show="m.open == 1">
								<i class="fas fa-window-minimize"></i>
							</span>
						</span>
					</div>
					<div class="">
						<div class="p-2">
							<input class="form-check-input me-2" type="checkbox" value=""
								:id="'flexCheckDefault' + m.id" v-show="selectable == true" v-model="m.selected"
								@change="changeMenuSelectedLocal(m)">
							<div style="display: inline-block; width: 20px;">
								<i :class="m.class" class="me-1"></i>
							</div>
							<a class="text-800 pointer" @click="setObjectMenuLocal(m)">
								<strong>{{ m.name }}</strong>
							</a>
							<div class="float-end">
							</div>
							<div class="float-end me-2" v-show="viewDescription == true"><i>{{ m.description }}</i>
							</div>
						</div>
					</div>
				</div>
				<div :id="'panelsStayOpen-' + m.id" class="collapse pb-2" :class="(m.open == true) ? 'show' : ''">
					<div v-for="(m1, x) in m.children" :key="x" class="ps-4 pe-2">
						<input class="form-check-input me-2" type="checkbox" value="" :id="'flexCheckDefault' + m1.id"
							v-show="selectable == true" v-model="m1.selected" @change="changeMenuSelectedLocal(m1)">
						<div style="display: inline-block; width: 20px;">
							<i :class="m1.class" class="me-1"></i>
						</div>
						<a class="text-800 pointer" @click="setObjectMenuLocal(m1)">
							<strong>{{ m1.name }}</strong>
						</a>
						<div class="float-end me-5" v-show="viewDescription == true"><i>{{ m1.description }}</i></div>
					</div>
					<div class="ps-4 pe-2 text-400" v-show="insertable == true">
						<span @click="showMenuModal(m)">
							<div style="display: inline-block; width: 20px;">
								<i class="fas fa-plus me-1"></i>
							</div>
							<strong class="pointer"><i>Nuevo item en {{ m.name }}</i></strong>
						</span>
					</div>
				</div>
			</div>
			<div class="accordion-item" v-show="insertable == true"
				style="border: none; background-color: transparent;">
				<div class="accordion-header">
					<div class="p-2">
						<span @click="showMenuModal()">
							<div style="display: inline-block; width: 20px;">
								<i class="fas fa-plus me-1"></i>
							</div>
							<strong class="pointer"><i>Nuevo item</i></strong>
						</span>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Menu-->
		<button ref="btnShowMenuModal" data-bs-toggle="modal" data-bs-target="#menuModal" v-show="false">Show Menu
			Modal</button>
		<div class="modal fade" id="menuModal" tabindex="-1" aria-labelledby="menuModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title text-900" id="menuModalLabel"><i
								:class="(objectMenu.class == '' || objectMenu.class == null) ? '' : objectMenu.class"></i>
							Menu
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-4 text-end">
								<label>Nombre</label>
							</div>
							<div class="col-8">
								<input class="form-control form-control-sm" v-model="objectMenu.name"
									:disabled="disabledFormMenu" @focus="$event.target.select()">
							</div>
						</div>
						<div class="row">
							<div class="col-4 text-end">
								<label>Ruta</label>
							</div>
							<div class="col-8">
								<input class="form-control form-control-sm" v-model="objectMenu.to"
									:disabled="disabledFormMenu" @focus="$event.target.select()">
							</div>
						</div>
						<div class="row">
							<div class="col-4 text-end">
								<label>Icono</label>
							</div>
							<div class="col-8">
								<input class="form-control form-control-sm" v-model="objectMenu.class"
									:disabled="disabledFormMenu" @focus="$event.target.select()">
							</div>
						</div>
						<div class="row">
							<div class="col-4 text-end">
								<label>Padre</label>
							</div>
							<div class="col-8">
								<select class="form-select form-select-sm" v-model="objectMenu.id_parent"
									:disabled="disabledFormMenu">
									<option :value="0">Raiz</option>
									<option v-for="(m, i) in listMenus" :key="i" :value="m.id">{{ m.name }}</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="col-4 text-end">
								<label>Descripción</label>
							</div>
							<div class="col-8">
								<input class="form-control form-control-sm" v-model="objectMenu.description"
									:disabled="disabledFormMenu" @focus="$event.target.select()">
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal"
							ref="btnCloseMenuModal">Cerrar</button>
						<button type="button" class="btn btn-sm btn-primary" :disabled="disabledBtnSaveMenu"
							@click="saveMenuLocal">Guardar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import menus from '@/mixins/generals/menu.js'
export default {
	name: 'MenuListComponent',
	mixins: [mixin, axiosAction, errors, menus],
	props: ['selectable', 'openAll', 'insertable', 'editable', 'selectAll', 'viewDescription', 'menusSelected'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		disabledBtnSaveMenu() {
			if (this.objectMenu.removable == 0)
				return true

			if (this.savingMenu == true)
				return true

			return false
		},
		disabledFormMenu() {
			if (this.savingMenu == true)
				return true

			return (this.objectMenu.removable == 0)
		},
		listMenusFilter() {
			let menus = []
			let index = -1
			this.listMenus.forEach(m => {
				if (this.openAll == true) {
					m.open = true
				}
				m.selected = this.selectAll
				if (m.id_parent == null || m.id_parent == 0) {
					m.id_parent = 0
					m.children = []
					menus.push(m)
				} else {
					index = menus.map(m1 => parseInt(m1.id)).indexOf(parseInt(m.id_parent))
					if (index > -1) {
						menus[index].children.push(m)
					}
				}
			});
			return menus
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllMenusResult': this.getAllMenusResult(response); break;
				case 'saveMenuResult': this.saveMenuResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		changeMenuSelectedLocal(m) {
			this.$emit('changeMenuSelected', m)
		},
		clickAccordion(m) {
			m.open = (m.open == 0) ? 1 : 0
		},
		initModuleLocal() {
			this.setMenuListComponentInit()
		},
		getAllMenusLocal(page = 1) {
			this.getAllMenus(page)
		},
		getAllMenusResultLocal(response) {
		},
		saveMenuLocal() {
			this.saveMenu(this.objectMenu)
		},
		saveMenuResultLocal(response) {
			if (response.data.success == true) {
				this.$refs.btnCloseMenuModal.click()
			}
		},
		setMenuListComponentInit() {
			this.getAllMenusLocal()
		},
		setMenusSeletedFromRole() {
			if (this.menusSelected != undefined) {
				this.listMenus.forEach(m => {
					m.selected = false
				})
				let index = -1
				this.menusSelected.forEach(ms => {
					index = this.listMenus.map(m => parseInt(m.id)).indexOf(parseInt(ms.id))
					if (index > -1) {
						this.listMenus[index].selected = true
					}
				})
			}
		},
		setObjectMenuLocal(m) {
			this.savingMenu = false
			if (this.editable == true) {
				this.setObjectMenu(m)
				this.$refs.btnShowMenuModal.click()
			}
			if (this.selectable == true) {
				m.selected = !m.selected
				this.changeMenuSelectedLocal(m)
			}
		},
		showMenuModal(m = null) {
			this.clearObjectMenu()
			this.savingMenu = false
			if (m != null) {
				this.objectMenu.id_parent = m.id
				this.objectMenu.level = parseInt(m.level) + 1
			}
			this.$refs.btnShowMenuModal.click()
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>
