/* Rquiere de mixin.js */

import { computed } from "vue";

var typeLegalDocument = {
    data() {
        return {
            listTypeLegalDocuments: [],
            methodsTypeLegalDocument: {
                create: 'typeLegalDocument/register',
                get: 'typeLegalDocuments',
                show: 'typeLegalDocument/',
            },
            paginationTypeLegalDocuments: [],
            searchTypeLegalDocuments: '',
            searchingTypeLegalDocument: false,
            objectTypeLegalDocument: {
                exists: 0,
                id: 0,
                name: '',
                syntetic: '',
                weGenerate: 0,
                needReference: 0,
            },
            referenceTypeLegalDocument: [
                { legalDocumentID: 61, references: [33, 39] },
                { legalDocumentID: 56, references: [61] },
            ]
        }
    },
    methods: {
        getAllTypeLegalDocuments(page = 1) {
            this.listTypeLegalDocuments = []
            this.searchingTypeLegalDocument = true
            let url = this.getApiBackendNew + this.methodsTypeLegalDocument.get
            this.axiosActionIS(url, 'GET', 'getAllTypeLegalDocumentsResult')
        },
        getAllTypeLegalDocumentsResult(response) {
            this.searchingTypeLegalDocument = false
            if (response.data.success) {
                this.listTypeLegalDocuments = response.data.data
                this.paginationTypeLegalDocuments = response.data.paginate
            } else {
                this.$snotify.error('Problema al tratar de rescatar los documentos legales', 'Error')
            }
            this.getAllTypeLegalDocumentsResultLocal(response)
        },
        getAllTypeLegalDocumentsResultLocal(response) {
            console.log('getAllTypeLegalDocumentsResult no desarrollado', response)
        },
        clearObjectTypeLegalDocument() {
            this.objectTypeLegalDocument.exists = 0
            this.objectTypeLegalDocument.id = 0
            this.objectTypeLegalDocument.name = ''
            this.objectTypeLegalDocument.syntetic = ''
            this.objectTypeLegalDocument.weGenerate = 0
            this.objectTypeLegalDocument.needReference = 0
        },
    },
};

export default typeLegalDocument;