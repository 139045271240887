<template>
	<div id="aasinetModule">
		<div class="d-flex border-bottom border-muted pb-1 mb-2">
			<span class="fa-stack me-2 ms-n1">
				<i class="fas fa-circle fa-stack-2x text-300"></i>
				<i class="fa-inverse fa-stack-1x text-aasinet fab fa-gripfire"></i>
			</span>
			<div class="col">
				<h5 class="mb-0 text-aasinet position-relative">
					<span class="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
					<span class="position-relative bg-200 dark__bg-1100 pe-3">Aasinet</span>
				</h5>
				<p class="mb-0"><small><i>Consultar datos a ASSINET</i></small></p>
			</div>
		</div>

		<div>
			<div>
				<aasinet-chart-of-account-list-component></aasinet-chart-of-account-list-component>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import AasinetChartOfAccountListComponent from '@/components/AasinetChartOfAccountListComponent.vue'

export default {
	name: 'AasinetModule',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
		}
	},
	components: {
		'aasinet-chart-of-account-list-component': AasinetChartOfAccountListComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
