<template>
	<div id="userWizardComponent" class="user-wizard-component">
		<notifications position="bottom right"/>
		<button ref="btnClearObjectUser" v-show="false" @click="clearObjectUserLocal">Limpiar objectUser</button>
		<button ref="btnShowUser" v-show="false" @click="showUserLocal">Buscar User {{id}}</button>
		<div class="card theme-wizard mb-5 mb-lg-0 mb-xl-5 mb-xxl-0 h-100" >
			<div class="card-header bg-light pt-3 pb-2">
				<ul class="nav nav-pills mb-3" role="tablist" id="pill-tab1">
					<li class="nav-item" role="presentation">
						<button class="nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab1" type="button" role="tab" aria-controls="form-wizard-progress-tab1" aria-selected="true" ref="account" @click="selectedWizardTabLocal(0)">
							<span class="fas fa-lock me-2" data-fa-transform="shrink-2"></span>
							<span class="d-none d-md-inline-block fs--1">Usuario</span>
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab2" type="button" role="tab" aria-controls="form-wizard-progress-tab2" aria-selected="false" ref="profile" @click="selectedWizardTabLocal(1)" :disabled="userId == 0">
							<span class="fas fa-user me-2" data-fa-transform="shrink-2"></span>
							<span class="d-none d-md-inline-block fs--1">Cliente</span>
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab3" type="button" role="tab" aria-controls="form-wizard-progress-tab3" aria-selected="false" ref="priceList" @click="selectedWizardTabLocal(2)" :disabled="objectUser.id == 0">
							<span class="fas fa-lock me-2" data-fa-transform="shrink-2"></span>
							<span class="d-none d-md-inline-block fs--1">Lista de Precios</span>
						</button>
					</li>
				</ul>
			</div>
			<div class="progress" style="height: 2px;">
				<div class="progress-bar" :class="(savingUserLocal) ? 'progress-bar-striped progress-bar-animated' : ''" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100" :style="'width:' + listWizardUser[indexSelectedListWizard].percentage + '%'"></div>
			</div>
			<div class="card-body py-4">
				<div class="tab-content">
                    <div class="tab-pane px-sm-3 px-md-5" role="tabpanel" aria-labelledby="form-wizard-progress-tab1" id="form-wizard-progress-tab1">
						<user-edit-component ref="userEditComponent"
							:id="userId"
							:user="objectUser"
							@saveDisabled="saveUserDisabledLocal"
							@saveUserResult="saveUserResultLocal"
							@showUserResult="showUserResultLocal"></user-edit-component>
                    </div>
                    <div class="tab-pane px-sm-3 px-md-5" role="tabpanel" aria-labelledby="form-wizard-progress-tab2" id="form-wizard-progress-tab2">
						<div v-if="searchingClient">Buscando coincidencia con email en la base de datos de clientes...</div>
						<div v-if="listClients.length > 0"  class="alert alert-warning fs--1" role="alert">
							<p>Se encontraron {{ listClients.length }} clientes con el correo {{ listClients[0].email }}</p>
							<hr>
							<div v-for="(item, index) in listClients" :key="index" class="row">
								<div class="col-md-4">{{ item.name + ' ' + item.surname }}</div>
								<div class="col-md-2">{{ item.number_document }}</div>
								<div class="col-md-2">{{ item.phone }}</div>
								<div class="col-md-2">{{ item.type_client.name }}</div>
								<div class="col-md-2 text-end"><span class="btn-link pointer" @click="linkUserClientLocal(item)">vincular</span></div>
							</div>
						</div>
						<div v-if="!userNew && objectUser.client_id == null" class="alert alert-info fs--1" role="alert">
							No tiene ningun cliente vinculado a este usuario
						</div>
						<client-edit-component ref="clientEditComponent"
							:id="clientId"
							:edit="(userNew && objectUser.client_id == null) || (!userNew && objectUser.client_id == null)"
							@saveDisabled="saveClientDisabledLocal"
							@saveClientResult="saveClientResultLocal"
							@showClientResultTrue="showClientResultTrueLocal">
						</client-edit-component>
					</div>
                    <div class="tab-pane " role="tabpanel" aria-labelledby="form-wizard-progress-tab3" id="form-wizard-progress-tab3">
						<user-price-list-component ref="userListPriceListComponent"
							:parent="objectUser"
							object="user">
						</user-price-list-component>
					</div>
				</div>
			</div>
			<div class="card-footer bg-light" v-if="indexSelectedListWizard < 3">
				<div class="px-sm-3 px-md-5">
                    <ul class="pager wizard list-inline mb-0">
						<li class="previous">
							<h1 class="badge bg-info" v-if="userNew">Nuevo</h1>
							<span class="fs--1" v-else>creado <i>{{ this.objectUser.created_at }}</i></span>
	                    </li>
						<li class="next">
							<button class="btn btn-primary px-5 px-sm-6" @click="saveLocal" :disabled="btnSaveDisabled" v-show="showBtnSave">
								<span v-if="!savingUserLocal">
									Guardar <span class="far fa-save ms-2" data-fa-transform="shrink-3"></span>
								</span>
								<span v-else>
									Guardando <span class="fas fa-spinner ms-2" data-fa-transform="shrink-3"></span>
								</span>
							</button>
						</li>
                    </ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import users from '@/mixins/users'
import clients from '@/mixins/clients'

import ClientEditComponent from '@/components/ClientEditComponent.vue'
import UserEditComponent from '@/components/UserEditComponent.vue'
import ObjectListPriceListComponent from '@/components/ObjectListPriceListComponent.vue'

export default {
	name: 'UserWizardComponent',
	mixins: [mixin, axiosAction, errors, users, clients],
	props: ['id', 'typeUser', 'withOutAccount'],
	data () {
		return {
			listWizardUser: [
				{ percentage: '33', ref: 'account', enabled: true, textBtnNext: 'Siguente'},
				{ percentage: '66', ref: 'profile', enabled: true, textBtnNext: 'Siguente'},
				{ percentage: '100', ref: 'priceList', enabled: true, textBtnNext: 'Finalizar'},
			],
			defaultIndexSelectedListWizard: 0,
			indexSelectedListWizard: 0,
			savingUserLocal: false,
			saveClientDisabled: true,
			saveUserDisabled: true,
			userId: 0,
			clientId: 0,
			userNew: true
		}
	},
	components: {
		'client-edit-component': ClientEditComponent,
		'user-edit-component': UserEditComponent,
		'user-price-list-component': ObjectListPriceListComponent,
	},
	computed: {
		btnSaveDisabled() {
			if(this.indexSelectedListWizard == 0)
				return this.saveUserDisabled
			if(this.indexSelectedListWizard == 1)
				return this.saveClientDisabled
		},
		showBtnSave() {
			if(this.userNew && this.objectUser.client_id == null) {
				return true
			}
			if(this.objectUser.client_id == null && this.indexSelectedListWizard == 1) {
				return true
			}
			if(this.indexSelectedListWizard == 0) {
				return true
			}
			return false
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'showClientByEmailResult': this.showClientByEmailResult(response); break;
				case 'updateUserFieldResult': this.updateUserFieldResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectUserLocal() {
			this.clearObjectClient()
			this.clearObjectUser()
			this.$refs.clientEditComponent.$refs.btnClearObjectClient.click()
			this.$refs.userEditComponent.$refs.btnClearObjectUser.click()
		},
		clickWizardTab() {
			if(this.listWizardUser[this.indexSelectedListWizard].enabled) {
				this.$refs[this.listWizardUser[this.indexSelectedListWizard].ref].click()
			}
			return this.listWizardUser[this.indexSelectedListWizard].enabled
		},
		initModuleLocal() {
			this.setUserEditInit()
		},
		linkUserClientLocal(c) {
			this.clientId = c.id
			setTimeout(() => { this.$refs.clientEditComponent.$refs.btnShowClient.click() }, 10)
		},
		nextWizardTabLocal() {
			let length = this.listWizardUser.length
			if(this.indexSelectedListWizard + 1 < length) {
				this.indexSelectedListWizard++
				this.clickWizardTab()
			}
		},
		prevWizardTabLocal() {
			if(this.indexSelectedListWizard > 0) {
				this.indexSelectedListWizard--
				this.clickWizardTab()
			}
		},
		saveClientDisabledLocal(value) {
			this.saveClientDisabled = value
		},
		saveUserDisabledLocal(value) {
			this.saveUserDisabled = value
		},
		saveClientLocal() {
			this.clientId = this.id
			this.$refs.clientEditComponent.$refs.btnSaveClient.click()	
		},
		saveClientResultLocal(c) {
			this.setObjectClient(c)
			this.updateUserClientIdLocal(this.objectUser.id, this.objectClient.id)
		},
		saveLocal() {
			if(this.indexSelectedListWizard == 0) {
				this.$refs.userEditComponent.$refs.btnSaveUser.click()	
			} else if(this.indexSelectedListWizard == 1) {
				this.$refs.clientEditComponent.$refs.btnSaveClient.click()
			}
		},
		saveUserResultLocal(u) {
			this.userId = u.id
			this.setObjectUser(u)
			if(this.objectUser.client_id == null && this.userNew) {
				this.showClientByEmail(this.objectUser.email)
			}
			if(this.userNew) {
				this.$emit('saveUser', u)
				setTimeout(() => { this.nextWizardTabLocal() }, 10)
				this.userNew = false
			} else {
				this.$emit('saveUser', u, 'UPDATE')
			}
		},
		selectedWizardTabLocal(index) {
			this.indexSelectedListWizard = index
			this.clickWizardTab()
		},
		setUserEditInit() {
			this.clearObjectClient()
			this.clearObjectUser()
			this.listClients = []
			this.clientId = 0
			this.$refs.clientEditComponent.$refs.btnClearObjectClient.click()
			this.indexSelectedListWizard = this.defaultIndexSelectedListWizard
			this.clickWizardTab()
		},
		showClientByEmailResultLocal(response) {
			if(response.data.success == true) {
			}
		},
		showClientResultTrueLocal(c) {
			this.clientId = c.id
			if(this.userNew || this.objectUser.client_id == null) {
				this.updateUserClientIdLocal(this.userId, this.clientId)
			}
		},
		showUserLocal() {
			this.setUserEditInit()
			this.userNew = (this.id == 0)
			this.userId = this.id
			setTimeout(() => { this.$refs.userEditComponent.$refs.btnShowUser.click() }, 10)
		},
		showUserResultLocal(u) {
			this.setObjectUser(u)
			if(u.client_id > 0) {
				this.clientId = u.client_id
				setTimeout(() => { this.$refs.clientEditComponent.$refs.btnShowClient.click() }, 10)
				setTimeout(() => { this.$refs.userListPriceListComponent.$refs.btnGetPriceLists.click() }, 10)

			} else {
				this.showClientByEmail(this.objectUser.email)
			}
		},
		showMsgErrorHandlingLocal(response) {
			this.savingUserLocal = false
			console.log('Error del Servidor (Back-end)', response)
		},
		updateUserClientIdLocal(userId, clientId) {
			this.updateUserClientId(userId, clientId)
		},
		updateUserFieldResultLocal(response) {
			if(response.data.success == true) {
				this.setObjectUser(response.data.data)
				this.listClients = []
				this.nextWizardTabLocal()
				this.finish = true
			}
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>