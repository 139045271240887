export default {
    state: {
        connected: false
    },
    mutations: {
        SOCKET_connect(state) {
            state.connected = true
        },
        SOCKET_disconnect(state) {
            state.connected = false
        },
        SOCKET_EVENT_NAME(data) {
            console.log(data)
        }
    },
    getters: {
        getConnected: state => { return state.connected },
    },
    actions: { },
    modules: { },
  };