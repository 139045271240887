<template>
	<div id="clientWizardComponent" class="client-wizard-component">
		<button ref="btnClearObjectClient" v-show="false" @click="clearObjectClientLocal">Limpiar objectClient</button>
		<button ref="btnShowClient" v-show="false" @click="showClientLocal">Buscar Cliente {{id}}</button>
		<div class="card theme-wizard mb-5 mb-lg-0 mb-xl-5 mb-xxl-0 h-100" >
			<div class="card-header bg-light pt-3 pb-2">
				<ul class="nav nav-pills mb-3" role="tablist" id="pill-tab2">
					<li class="nav-item" role="presentation">
						<button class="nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab2" type="button" role="tab" aria-controls="form-wizard-progress-tab2" aria-selected="true" ref="profile" @click="selectedWizardTabLocal(0)">
							<span class="fas fa-user me-2" data-fa-transform="shrink-2"></span>
							<span class="d-none d-md-inline-block fs--1">Cliente</span>
						</button>
					</li>
					<li class="nav-item" role="presentation" v-if="actionSecurity(6)">
						<button class="nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab1" type="button" role="tab" aria-controls="form-wizard-progress-tab1" aria-selected="false" ref="account" @click="selectedWizardTabLocal(1)" :disabled="objectClient.id == 0">
							<span class="fas fa-lock me-2" data-fa-transform="shrink-2"></span>
							<span class="d-none d-md-inline-block fs--1">Cuenta</span>
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab3" type="button" role="tab" aria-controls="form-wizard-progress-tab3" aria-selected="false" ref="priceList" @click="selectedWizardTabLocal(2)" :disabled="objectClient.id == 0">
							<span class="fas fa-lock me-2" data-fa-transform="shrink-2"></span>
							<span class="d-none d-md-inline-block fs--1">Lista de Precios</span>
						</button>
					</li>
				</ul>
			</div>
			<div class="progress" style="height: 2px;">
				<div class="progress-bar" :class="(savingClientLocal) ? 'progress-bar-striped progress-bar-animated' : ''" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100" :style="'width:' + listWizardClient[indexSelectedListWizard].percentage + '%'"></div>
			</div>
			<div class="card-body py-4">
				<div class="tab-content">
                    <div class="tab-pane px-sm-3 px-md-5" role="tabpanel" aria-labelledby="form-wizard-progress-tab2" id="form-wizard-progress-tab2">
						<client-edit-component ref="clientEditComponent"
							:id="objectClient.id"
							@saveDisabled="saveClientDisabledLocal"
							@saveClientResult="saveClientResultLocal"
							@showClientResultTrue="showClientResultTrueLocal"></client-edit-component>

					</div>
                    <div class="tab-pane px-sm-3 px-md-5" role="tabpanel" aria-labelledby="form-wizard-progress-tab1" id="form-wizard-progress-tab1">
						<div v-if="listUsers.length > 0"  class="alert alert-warning fs--1" role="alert">
							<p>Se encontró un usuario con este correo: {{ listUsers[0].name }} "<i>{{ listUsers[0].email }}</i>".</p>
							<p>Si quiere vincularlo haga click <span class="btn-link pointer" @click="linkUserClientLocal(listUsers[0])">aquí</span></p>
						</div>
						<user-edit-component ref="userEditComponent"
							:id="objectUser.id"
							:edit="objectUser.id == 0 && listUsers.length == 0"
							:user="objectUser"
							@saveDisabled="saveUserDisabledLocal"
							@saveUserResult="saveUserResultLocal"
							@showUserResult="showUserResultLocal"></user-edit-component>
                    </div>
                    <div class="tab-pane " role="tabpanel" aria-labelledby="form-wizard-progress-tab3" id="form-wizard-progress-tab3">
						<client-price-list-component ref="clientListPriceListComponent"
							:parent="objectClient"
							object="client">
						</client-price-list-component>
					</div>
				</div>
			</div>
			<div class="card-footer bg-light">
				<div class="px-sm-3 px-md-5">
                    <ul class="pager wizard list-inline mb-0">
						<li class="previous">
							<h1 class="badge bg-info" v-if="clientNew">Nuevo</h1>
							<span class="fs--1" v-else>creado <i>{{ this.objectClient.created_at }}</i></span>
	                    </li>
						<li class="next">
							<button class="btn btn-primary px-5 px-sm-6" @click="saveLocal" :disabled="btnSaveDisabled" v-show="showBtnSave">
								<span v-if="!savingClientLocal">
									Guardar <span class="far fa-save ms-2" data-fa-transform="shrink-3"></span>
								</span>
								<span v-else>
									Guardando <span class="fas fa-spinner ms-2" data-fa-transform="shrink-3"></span>
								</span>
							</button>
						</li>
                    </ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import clients from '@/mixins/clients'
import users from '@/mixins/users'

import ClientEditComponent from '@/components/ClientEditComponent.vue'
import UserEditComponent from '@/components/UserEditComponent.vue'
import ObjectListPriceListComponent from '@/components/ObjectListPriceListComponent.vue'

export default {
	name: 'ClientWizardComponent',
	mixins: [mixin, axiosAction, errors, clients, users],
	props: ['id', 'typeClient', 'withOutAccount'],
	data () {
		return {
			createAccount: false,
			listWizardClient: [
				{ percentage: '33', ref: 'profile', enabled: true, textBtnNext: 'Siguente'},
				{ percentage: '66', ref: 'account', enabled: true, textBtnNext: 'Siguente'},
				{ percentage: '100', ref: 'priceList', enabled: true, textBtnNext: 'Finalizar'},
			],
			defaultIndexSelectedListWizard: 0,
			saveClientDisabled: true,
			saveUserDisabled: true,
			indexSelectedListWizard: 0,
			savingClientLocal: false,
			clientNew: true
		}
	},
	components: {
		'client-edit-component': ClientEditComponent,
		'user-edit-component': UserEditComponent,
		'client-price-list-component': ObjectListPriceListComponent,
	},
	computed: {
		btnSaveDisabled() {
			if(this.indexSelectedListWizard == 0)
				return this.saveClientDisabled
			if(this.indexSelectedListWizard == 1)
				return this.saveUserDisabled
		},
		showBtnSave() {
			if(this.clientNew && this.objectUser.client_id == null) {
				return true
			}
			if(this.objectUser.client_id == null && this.indexSelectedListWizard == 1) {
				return true
			}
			if(this.indexSelectedListWizard == 0) {
				return true
			}
			return false
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'getUserByClientIdResult': this.getUserByClientIdResult(response); break;
				case 'updateUserFieldResult': this.updateUserFieldResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectClientLocal() {
			this.clearObjectClient()
			this.clearObjectUser()
			this.$refs.clientEditComponent.$refs.btnClearObjectClient.click()
			this.$refs.userEditComponent.$refs.btnClearObjectUser.click()
		},
		clickWizardTab() {
			if(this.listWizardClient[this.indexSelectedListWizard].enabled) {
				this.$refs[this.listWizardClient[this.indexSelectedListWizard].ref].click()
			}
			return this.listWizardClient[this.indexSelectedListWizard].enabled
		},
		getUserByClientIdResultLocal(response) {
			if(response.data.success == true) {
				if(this.objectUser.id > 0) {
					setTimeout(() => {this.$refs.userEditComponent.$refs.btnShowUser.click()}, 10)
				} else {
					this.objectUser.email = this.objectClient.email
					setTimeout(() => {this.$refs.userEditComponent.$refs.btnSetObjectUser.click()}, 10)
				}
			}
		},
		initModuleLocal() {
			this.setClientEditInit()
		},
		linkUserClientLocal(u) {
			this.objectUser.id = u.id
			setTimeout(() => { this.$refs.userEditComponent.$refs.btnShowUser.click() }, 10)
		},
		nextWizardTabLocal() {
			if(this.indexSelectedListWizard + 1 < this.listWizardClient.length) {
				this.indexSelectedListWizard++
				this.clickWizardTab()
			}
		},
		prevWizardTabLocal() {
			if(this.indexSelectedListWizard > 0) {
				this.indexSelectedListWizard--
				this.clickWizardTab()
			}
		},
		saveClientDisabledLocal(value) {
			this.saveClientDisabled = value
		},
		saveUserDisabledLocal(value) {
			this.saveUserDisabled = value
		},
		saveLocal() {
			if(this.indexSelectedListWizard == 0) {
				this.$refs.clientEditComponent.$refs.btnSaveClient.click()
			} else if(this.indexSelectedListWizard == 1) {
				this.$refs.userEditComponent.$refs.btnSaveUser.click()	
			}
		},
		selectedWizardTabLocal(index) {
			this.indexSelectedListWizard = index
			this.clickWizardTab()
		},
		setClientEditInit() {
			this.clearObjectClient()
			this.clearObjectUser()
			this.$refs.clientEditComponent.$refs.btnClearObjectClient.click()
			this.$refs.userEditComponent.$refs.btnClearObjectUser.click()
			this.indexSelectedListWizard = this.defaultIndexSelectedListWizard
			this.clickWizardTab()
		},
		showMsgErrorHandlingLocal(response) {
			this.savingClientLocal = false
			console.log('Error del Servidor (Back-end)', response)
		},
		showClientLocal() {
			this.setClientEditInit()
			this.clientNew = (this.id == 0)
			this.objectClient.id = this.id
			setTimeout(() => { this.$refs.clientEditComponent.$refs.btnShowClient.click() }, 10)
		},
		saveClientResultLocal(c) {
			this.setObjectClient(c)
			if(this.clientNew) {
				this.$emit('saveClient', c)
				setTimeout(() => {
					this.objectUser.email = this.objectClient.email
					this.nextWizardTabLocal()
					setTimeout(() => {this.$refs.userEditComponent.$refs.btnSetObjectUser.click()}, 10)
				}, 10)
				this.clientNew = false
			} else {
				this.$emit('saveClient', c, 'UPDATE')
			}
		},
		saveUserResultLocal(u) {
			this.setObjectUser(u)
			this.updateUserClientIdLocal(this.objectUser.id, this.objectClient.id)
		},
		showClientResultTrueLocal(c) {
			this.setObjectClient(c)
			if(this.actionSecurity(6)) {
				this.getUserByClientId(this.objectClient.id)
			}
			setTimeout(() => { this.$refs.clientListPriceListComponent.$refs.btnGetPriceLists.click() }, 10)
		},
		showUserResultLocal(u) {
			if(u.client_id == null) {
				this.setObjectUser(u)
				this.updateUserClientIdLocal(this.objectUser.id, this.objectClient.id)
				console.log(this.objectUser.id, this.objectClient.id)
			}
		},
		updateUserClientIdLocal(userId, clientId) {
			this.updateUserClientId(userId, clientId)
		},
		updateUserFieldResultLocal(response) {
			if(response.data.success == true) {
				this.listUsers = []
			}
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>