<template>
	<div id="legalDocuments">
		<legal-documents-list-component v-show="optionView == 'list'" ref="legalDocumentsListComponent"
			@legalDocumentComponent="viewLegalDocumentComponentLocal"
			:newLegalDocument="newLegalDocument"></legal-documents-list-component>
		<div v-show="optionView == 'edit'">
			<div class="card">
				<div class="card-body">
					<legal-document-component ref="legalDocument" :id="legalDocumentIdSelected"
						@saveLegalDocumentResult="saveLegalDocumentResultLocal"
						@sendToRegulatoryEntityResult="sendToRegulatoryEntityResultLocal"></legal-document-component>
				</div>
			</div>
			<div class="text-end">
				<small>
					<a class="btn btn-sm btn-link" @click="optionView = 'list'">Listar Documentos Electronicos</a>
				</small>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import LegalDocumentComponent from '@/components/LegalDocumentComponent.vue'
import LegalDocumentsListComponent from '@/components/LegalDocumentsListComponent.vue'

export default {
	name: 'LegalDocuments',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
			optionView: 'list',
			legalDocumentIdSelected: 0,
			newLegalDocument: null
		}
	},
	components: {
		'legal-document-component': LegalDocumentComponent,
		'legal-documents-list-component': LegalDocumentsListComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		},
		saveLegalDocumentResultLocal(data) {
			this.newLegalDocument = null
			this.newLegalDocument = data
			setTimeout(() => { this.$refs.legalDocumentsListComponent.$refs.btnAddLegalDocument.click() }, 50)
			this.$refs.legalDocumentsListComponent.$refs.btnAddLegalDocument.click()
		},
		sendToRegulatoryEntityResultLocal(response) {
			this.newLegalDocument = null
			if (response.data.data) {
				this.newLegalDocument = response.data.data
				setTimeout(() => { this.$refs.legalDocumentsListComponent.$refs.btnUpdateLegalDocument.click() }, 50)
				this.optionView = 'list'
			}
		},
		viewLegalDocumentComponentLocal(id) {
			this.legalDocumentIdSelected = id
			this.optionView = 'edit'
			setTimeout(() => { this.$refs.legalDocument.$refs.btnClearLegalDocumentComponent.click() }, 50)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
