var label = {
    data() {
        return {
            listLabels: [],
            methodsLabel: {
                show: 'label/',
                get: 'labels',
                create: 'label/register',
                delete: '/delete',
                update: '/update'
            },
            paginationLabels: [],
            searchLabels: '',
            objectLabel: {},
            listVariablesF: [],
            selectedLabelId: 0,
            searchingLabels: false,
            qRowsLabel: 25
        }
    },
    computed: {
        listVariables() {
            let result = []
            let str = (this.objectLabel.zpl == undefined) ? '' : this.objectLabel.zpl
            let search_1 = '{{'
            let search_2 = '}}'
            let begin = 0
            let variable
            while (begin != -1 && str.length > 0) {
                begin = str.indexOf(search_1)
                if (begin > -1) {
                    str = str.substr(begin + search_1.length, str.length - begin - search_1.length)
                    begin = str.indexOf(search_2)
                    if (begin > -1) {
                        variable = str.substr(0, begin)
                        let index = result.map((el) => el.name).indexOf(variable.trim())
                        if (index == -1) {
                            result.push({ 'name': variable.trim(), 'val': '', 'original': search_1 + variable + search_2 })
                        }
                        str = str.substr(begin + search_2.length, str.length - begin - search_2.length)
                    }
                }
            }
            this.listVariablesF = result
            return result
        }
    },
    methods: {
        clearListVariablesF() {
            this.listVariablesF.forEach(element => { element.val = '' })
        },
        replaceZPLVariableFromArray(arrayZPL) {
            this.clearListVariablesF()
            Object.values(arrayZPL).forEach(val => {
                let index = this.listVariablesF.map((el) => el.name).indexOf(val.name)
                if (index > -1) this.listVariablesF[index].val = val.value
            });
        },
        setObjectLabel(item) {
            let newItem = Object.create(item)
            for (const property in newItem) {
                this.objectLabel[property] = newItem[property]
            }
        },
        showLabel(id) {
            let url = this.getApiBackend + this.methodsLabel.show + id
            this.axiosActionIS(url, 'GET', 'showLabelResult', null)
        },
        showLabelResult(response) {
            if (response.data.success == true) {
                this.setObjectLabel(response.data.data)
                this.listVariables
            }
            this.showLabelResultLocal(response)
        },
        showLabelResultLocal(response) {
            console.log('showLabelResultLocal no desarrollado', response)
        },
        ZPLEnd() {
            let variable = this.objectLabel.zpl
            this.listVariablesF.forEach(element => {
                variable = variable.replace(new RegExp(element.original, "gi"), element.val)
            });
            return variable
        }
    }
};

export default label;