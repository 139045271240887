/* Rquiere de mixin.js */

var legalDocument = {
    data() {
        return {
            dateFromLegalDocuments: '2020-07-15',
            dateToLegalDocuments: this.currentDate(),
            listLegalDocuments: [],
            urlsLegalDocument: {
                create: 'legalDocument/register',
                get: 'legalDocuments',
                show: 'legalDocument/',
                generate: '/facturacionCL'
            },
            paginationLegalDocuments: [],
            searchLegalDocuments: '',
            objectLegalDocument: {
                exists: 0,
                id: 0,
                type_legal_document_id: 0,
                type_legal_document: null,
                legal_document_number: 0,
                date_legal_document: this.currentDate(),
                issuing_entity_id: 0,
                issuing_entity_name: 'Corp Iglesia de los Adventitas del Septimo Dia',
                issuing_entity_document_number: '82745300-5',
                issuing_entity_economic_activity: 'Actividad Religiosa',
                issuing_entity_address_id: 0,
                issuing_entity_address_street: 'Santa Elena 1038',
                issuing_entity_address_comuna: 'Santiago',
                issuing_entity_address_city: 'Santiago',
                receiving_entity_id: 0,
                receiving_entity_name: '',
                receiving_entity_document_number: '',
                receiving_entity_economic_activity: '',
                receiving_entity_address_id: 0,
                receiving_entity_address_street: '',
                receiving_entity_address_comuna: '',
                receiving_entity_address_city: '',
                contact: '',
                phone: '',
                details: [],
                observation: '',
                exempt: 0,
                discount: 0,
                surcharge: 0,
                net: 0,
                tax: 0,
                total: 0,
                url: '',
                url_assignable: '',
                legal_document_references: [{
                    type_legal_document_id: 0,
                    legal_document_number: 0,
                    date_legal_document: '',
                    code_reference: 0,
                    reason: ''
                }],
            },
            objectLegalDocumentDetail: {
                id: 0,
                legal_document_id: 0,
                type_code: 'INT1',
                code: 0,
                name: '',
                price: '',
                quantity: '',
                total: '',
                discount_rate: 0,
                discount_amount: 0,
                surcharge_rate: 0,
                surcharge_amount: 0,
                order_: null,
                created_at: null,
                updated_at: null
            },
            loadingLegalDocuments: false,
            searchingLegalDocuments: false,
            percentageTax: 0.19,
            readonlyReceivingEntityName: false,
            qRowsLegalDocument: 25,
            savingLegalDocument: false,
            fixed: 2
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        addListLegalDocuments(newLegalDocument) {
            this.listLegalDocuments.unshift(newLegalDocument)
            if (this.listLegalDocuments.length > this.qRowsLegalDocument) {
                this.listLegalDocuments.splice(this.qRowsLegalDocument, 1)
            }
        },
        changeQRowsLegalDocument() {
            localStorage.setItem('qRowsLegalDocument', this.qRowsLegalDocument)
            this.getAllLegalDocuments()
        },
        getAllLegalDocuments(page = 1) {
            this.loadingLegalDocuments = true
            let parameters = '?search=' + this.searchLegalDocuments + '&page=' + page + '&qRow=' + this.qRowsLegalDocument + '&fromDate=' + this.dateFromLegalDocuments + '&toDate=' + this.dateToLegalDocuments
            let url = this.getApiBackendNew + 'legalDocuments' + parameters
            this.axiosActionIS(this.getApiBackendNew + this.urlsLegalDocument.get + parameters, 'GET', 'getAllLegalDocumentsResult')
        },
        getAllLegalDocumentsResult(response) {
            this.loadingLegalDocuments = false
            if (response.data.success) {
                this.listLegalDocuments = response.data.data
                this.paginationLegalDocuments = response.data.paginate
            }
            this.getAllLegalDocumentsResultLocal(response)
        },
        getAllLegalDocumentsResultLocal(response) {
            console.log('getAllLegalDocumentsResult no desarrollado', response)
        },
        getByReceivingEntityDocumentNumber(entityDocumentNumber) {
            let url = this.getApiBackendNew + this.urlsLegalDocument.show + 'entityDocumentNumber/' + entityDocumentNumber
            this.axiosActionIS(url, 'GET', 'getByReceivingEntityDocumentNumberResult')
        },
        getByReceivingEntityDocumentNumberResult(response) {
            this.getByReceivingEntityDocumentNumberResultLocal(response)
        },
        getByReceivingEntityDocumentNumberResultLocal(response) {
            console.log('getByReceivingEntityDocumentNumberResult no desarrollado', response)
        },
        clearObjectLegalDocument() {
            this.objectLegalDocument.exists = 0
            this.objectLegalDocument.id = 0
            this.objectLegalDocument.type_legal_document_id = 0
            this.objectLegalDocument.type_legal_document = null
            this.objectLegalDocument.legal_document_number = 0
            this.objectLegalDocument.date_legal_document = this.currentDate()
            this.objectLegalDocument.issuing_entity_id = 0
            this.objectLegalDocument.issuing_entity_name = 'Corp Iglesia de los Adventitas del Septimo Dia'
            this.objectLegalDocument.issuing_entity_document_number = '82745300-5'
            this.objectLegalDocument.issuing_entity_economic_activity = 'Actividad Religiosa'
            this.objectLegalDocument.issuing_entity_address_id = 0
            this.objectLegalDocument.issuing_entity_address_street = 'Santa Elena 1038'
            this.objectLegalDocument.issuing_entity_address_comuna = 'Santiago'
            this.objectLegalDocument.issuing_entity_address_city = 'Santiago'
            this.objectLegalDocument.receiving_entity_id = 0
            this.objectLegalDocument.receiving_entity_name = ''
            this.objectLegalDocument.receiving_entity_document_number = ''
            this.objectLegalDocument.receiving_entity_economic_activity = ''
            this.objectLegalDocument.receiving_entity_address_id = 0
            this.objectLegalDocument.receiving_entity_address_street = ''
            this.objectLegalDocument.receiving_entity_address_comuna = ''
            this.objectLegalDocument.contact = ''
            this.objectLegalDocument.phone = ''
            this.objectLegalDocument.details = []
            this.objectLegalDocument.observation = ''
            this.objectLegalDocument.exempt = 0
            this.objectLegalDocument.discount = 0
            this.objectLegalDocument.surcharge = 0
            this.objectLegalDocument.net = 0
            this.objectLegalDocument.tax = 0
            this.objectLegalDocument.total = 0
            this.objectLegalDocument.url = ''
            this.objectLegalDocument.url_assignable = ''
            this.objectLegalDocument.legal_document_references[0].type_legal_document_id = 0
            this.objectLegalDocument.legal_document_references[0].legal_document_number = 0
            this.objectLegalDocument.legal_document_references[0].date_legal_document = ''
            this.objectLegalDocument.legal_document_references[0].code_reference = 0
            this.objectLegalDocument.legal_document_references[0].reason = ''
            this.readonlyReceivingEntityName = false
        },
        migrateLegalDocumentToGenesys(id) {
            let url = this.getApiBackendNew + this.urlsLegalDocument.show + id + '/migrateGenesys'
            this.axiosActionIS(url, 'POST', 'migrateLegalDocumentToGenesysResult')
        },
        migrateLegalDocumentToGenesysResult(response) {
            if (response.data.success == true) {
                this.$snotify.info('Se migró con exito a Genesys el documento Nº ' + response.data.data.nrocomp, 'Migración Exitosa!!!')
            }
            this.migrateLegalDocumentToGenesysResultLocal(response)
        },
        migrateLegalDocumentToGenesysResultLocal(response) {
            console.log('migrateLegalDocumentToGenesysResultLocal no desarrollado', response)
        },
        saveLegalDocument(ld = null) {
            this.savingLegalDocument = true
            let method = (ld.exists == 0) ? 'POST' : 'PUT'
            let url = this.getApiBackendNew
            url += (method == 'POST') ? 'legalDocument/register' : '/legalDocument/' + ld.id + '/update'
            this.axiosActionIS(url, method, 'saveLegalDocumentResult', ld)
        },
        saveLegalDocumentResult(response) {
            this.savingLegalDocument = false
            if (response.data.success) {
                this.addListLegalDocuments(response.data.data)
                this.setObjectLegalDocument(response.data.data)
            } else {
            }
            this.saveLegalDocumentResultLocal(response)
        },
        saveLegalDocumentResultLocal(response) {
            console.log('saveLegalDocumentResultLocal no desarrollado', response)
        },
        sendToRegulatoryEntity(id) {
            let url = this.getApiBackendNew + this.urlsLegalDocument.show + id + this.urlsLegalDocument.generate
            console.log(url)
            this.axiosActionIS(url, 'GET', 'sendToRegulatoryEntityResult')
        },
        sendToRegulatoryEntityResult(response) {
            if (response.data.success) {
                this.updateListLegalDocuments(response.data.data)
                this.setObjectLegalDocument(response.data.data)
            } else {
            }
            this.sendToRegulatoryEntityResultLocal(response)

        },
        sendToRegulatoryEntityResultLocal(response) {
            console.log('sendToRegulatoryEntityResultLocal no desarrollada', response)
        },
        setObjectLegalDocument(item) {
            for (const property in item) {
                this.objectLegalDocument[property] = item[property]
            }
        },
        setListeningLegalDocument() {
            this.sockets.subscribe('NEW_LEGAL_DOCUMENT', (data) => { this.addListLegalDocuments(data.body) })
            this.sockets.subscribe('UPDATE_DATA_FACTURACIONCL', (data) => { this.updateListLegalDocuments(data.body) })
        },
        showLocalDocument(id) {
            this.searchingLegalDocuments = true
            let url = this.getApiBackendNew + 'legalDocument/' + id
            this.axiosActionIS(url, 'GET', 'showLocalDocumentResult', null)
        },
        showLocalDocumentResult(response) {
            this.searchingLegalDocuments = false
            if (response.data.success) {
                this.setObjectLegalDocument(response.data.data)
            }
            this.showLocalDocumentResultLocal(response)
        },
        showLocalDocumentResultLocal(response) {
            console.log('showLocalDocumentResultLocal no desarrollado', response)
        },
        updateListLegalDocuments(legalDocument) {
            let index = this.listLegalDocuments.map((el) => parseInt(el.id)).indexOf(parseInt(legalDocument.id))
            if (index > -1) {
                this.listLegalDocuments[index].legal_document_number = legalDocument.legal_document_number
                this.listLegalDocuments[index].url = legalDocument.url
                this.listLegalDocuments[index].url_assignable = legalDocument.url_assignable
            }
        }
    },
};

export default legalDocument;