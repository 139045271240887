/* Rquiere de mixin.js */

var uploadDocument = {
	data() {
		return {
			listUploadDocuments: [],
			urlsUploadDocument: {
				get: 'uploadDocuments',
				show: 'uploadDocument',
				delete: 'delete'
			},
			paginationUploadDocuments: [],
			searchUploadDocuments: '',
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		addListUploadDocuments(newUploadDocument) {
			this.listUploadDocuments.push(newUploadDocument)
		},
		deleteItemUploadDocument(id) {
			let index = this.listUploadDocuments.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let uploadDocuments = this.listUploadDocuments.splice(index, 1)
		},
		deleteUploadDocument(controller, controllerId, id) {
			let url = this.getApiBackend + controller + '/' + controllerId + '/' + this.urlsUploadDocument.show + '/' + id + '/' + this.urlsUploadDocument.delete
			this.axiosActionIS(url, 'DELETE', 'deleteUploadDocumentResult')
		},
		deleteUploadDocumentResult(response) {
			if (response.data.success === true) {
				this.deleteItemUploadDocument(response.data.data)
			} else {
				console.log(response)
			}
			this.deleteUploadDocumentResultLocal(response)
		},
		deleteUploadDocumentResultLocal(response) {
			console.log('deleteUploadDocumentResultLocal no desarrollado', response)
		},
		getAllUploadDocuments(page = 1) {
			let parameters = '?search=' + this.searchUploadDocuments + '&page=' + page
			this.axiosActionIS(this.getApiBackend + this.urlsUploadDocument.get + parameters, 'GET', 'getAllUploadDocumentsResult')
		},
		getAllUploadDocumentsResult(response) {
			this.listUploadDocuments = response.data.data
			this.getAllUploadDocumentsResultLocal(response)
		},
		getAllUploadDocumentsResultLocal(response) {
			console.log('getAllUploadDocumentsResultLocal no desarrollado', response)
		},
		getUploadDocumentByController(id, controller = '') {
			let url = this.getApiBackend + controller + '/' + id + '/' + this.urlsUploadDocument.get
			this.axiosActionIS(url, 'GET', 'getUploadDocumentByControllerResult')
		},
		getUploadDocumentByControllerResult(response) {
			if (response.data.success === true) {
				this.listUploadDocuments = response.data.data
			} else {
				console.warn('El formato de la respuesta no es valida')
			}
			this.getUploadDocumentByControllerResultLocal(response)
		},
		getUploadDocumentByControllerResultLocal(response) {
			console.log('getUploadDocumentByControllerResultLocal no desarrollado', response)
		},
		saveUploadDocument(controller, id, data) {
			let url = this.getApiBackend + controller + '/' + id + '/' + this.urlsUploadDocument.show + '/register'
			this.axiosActionIS(url, 'POST', 'saveUploadDocumentResult', data)
		},
		saveUploadDocumentResult(response) {
			if (response.data.success) {
				this.addListUploadDocuments(response.data.data)
			} else {
				console.log('No se puedo subir el archivo')
				console.log(response)
			}
			this.saveUploadDocumentResultLocal(response)
		},
		saveUploadDocumentResultLocal(response) {
			console.log('saveUploadDocumentResultLocal no desarrollado')
		}
	},
};

export default uploadDocument;