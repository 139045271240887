/* Rquiere de mixin.js */

var client = {
	data() {
		return {
			objectClient: {
				exists: 0,
				id: 0,
				name: '',
				surname: '',
				email: '',
				phone: '',
				img: '',
				birthdate: '',
				number_document: '',
				type_client_id: null,
				type_client: {},
				client_price_list: [],
				address: []
			},
			listClients: [],
			loadingClients: false,
			listPriceListsClient: [],
			loadingPriceListsClient: false,
			messageErrorSave: {
				success: false,
				message: '',
				data: []
			},
			urlsClient: {
				create: 'client/register',
				delete: '/delete',
				get: 'clients',
				show: 'client/',
				update: 'client/update',
				priceList: '/priceList',
			},
			paginationClients: [],
			searchClients: '',
			qRowsClient: 25,
			searchingClient: false,
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		addListClients(newClient) {
			this.listClients.push(newClient)
		},
		addListClientPriceList(i) {
			this.objectClient.client_price_list.push(i)
		},
		changeQRowsClient() {
			localStorage.setItem('qRowsClient', this.qRowsClient)
			this.getAllClients()
		},
		clearObjectClient() {
			this.objectClient.exists = 0
			this.objectClient.id = 0
			this.objectClient.name = ''
			this.objectClient.surname = ''
			this.objectClient.number_document = ''
			this.objectClient.birthdate = ''
			this.objectClient.email = ''
			this.objectClient.phone = ''
			this.objectClient.img = ''
			this.objectClient.type_client_id = null
			this.objectClient.type_client = {}
			this.objectClient.client_sendto_id = ''
			this.objectClient.client_sendto = {}
			this.objectClient.client_price_list = []
			this.objectClient.address = []
		},
		clearMessageErrorSave() {
			this.messageErrorSave.success = false
			this.messageErrorSave.message = ''
			this.messageErrorSave.data = []
		},
		deleteClient(id) {
			this.$emit('showLockScreen', 'Borrando cliente . . .')
			this.axiosActionIS(this.getApiBackendNew + this.urlsClient.show + id + this.urlsClient.delete, 'DELETE', 'deleteClientResult')
		},
		deleteItemListClients(id) {
			let index = this.listClients.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			if (index > -1)
				return this.listClients.splice(index, 1)
			return false
		},
		deleteItemClientPriceList(id) {
			let index = this.objectClient.client_price_list.map((el) => parseInt(el.price_list_id)).indexOf(parseInt(id))
			if (index > -1)
				return this.objectClient.client_price_list.splice(index, 1)
			return false
		},
		getAllClients(page = 1) {
			this.loadingClients = true
			this.$emit('showLockScreen', 'Obteniendo lista de clientes . . .')
			let parameters = '?search=' + this.searchClients + '&page=' + page + '&qRow=' + this.qRowsClient
			this.axiosActionIS(this.getApiBackendNew + this.urlsClient.get + parameters, 'GET', 'getAllClientsResult')
		},
		getAllClientPriceLists(id) {
			this.loadingPriceListsClient = true
			this.objectClient.client_price_list = []
			this.axiosActionIS(this.getApiBackendNew + this.urlsClient.show + id + this.urlsClient.priceList, 'GET', 'getAllClientPriceListsResult')
		},
		getAllClientPriceListsResult(response) {
			this.loadingPriceListsClient = false
			if (response.data.success == true) {
				this.objectClient.client_price_list = response.data.data
			}
			this.getAllClientPriceListsResultLocal(response)
		},
		getAllClientPriceListsResultLocal(response) {
			console.log('getAllClientPriceListsResultLocal no desarrollado', response)
		},
		saveClient(item) {
			let method = (item.exists == 0) ? 'POST' : 'PUT'
			this.$emit('showLockScreen', 'Guardando datos . . .')
			if (method == 'POST') {
				this.axiosActionIS(this.getApiBackendNew + this.urlsClient.create, method, 'saveClientResult', item)
			} else if (method == 'PUT') {
				this.axiosActionIS(this.getApiBackendNew + this.urlsClient.show + item.id, method, 'saveClientResult', item)
			}
		},
		saveClientPriceList(id, priceListId) {
			let url = this.getApiBackendNew + this.urlsClient.show + id + '/priceList/register'
			this.axiosActionIS(url, 'POST', 'saveClientPriceListResult', { priceListId: priceListId })
		},
		saveClientPriceListResult(response) {
			if (response.data.success == true) {
				this.addListClientPriceList(response.data.data)
				this.$notify({ title: 'AGREGADA', text: 'Lista de Precio agregada', type: 'info' })
			}
			this.saveClientPriceListResultLocal(response)
		},
		saveClientPriceListResultLocal(response) {
			console.log('saveClientPriceListResultLocal no desarrollado', response)
		},
		deleteClientPriceList(id, priceListId) {
			let url = this.getApiBackendNew + this.urlsClient.show + id + '/priceList/' + priceListId + '/delete'
			this.axiosActionIS(url, 'DELETE', 'deleteClientPriceListResult')
		},
		deleteClientPriceListResult(response) {
			if (response.data.success == true) {
				this.deleteItemClientPriceList(response.data.data)
				this.$notify({ title: 'QUITADA!!!', text: 'Se ha quidatado la lista de precios', type: 'info' })
			}
			this.deleteClientPriceListResultLocal(response)
		},
		deleteClientPriceListResultLocal(response) {
			console.log('deleteClientPriceListResultLocal no desarrollado', response)
		},
		deleteClientResult(response) {
			if (response.data.success) {
				this.deleteItemListClients(response.data.data)
				this.clearObjectClient()
				this.$notify({ title: 'Cliente BORRADO!!!', text: 'Se ha borrado el cliente', type: 'info' })
			}
			this.deleteClientResultLocal(response)
		},
		deleteClientResultLocal(response) {
			console.log('deleteClientResultLocal no desarrollado', response)
		},
		getAllClientsResult(response) {
			this.loadingClients = false
			if (response.data.success === true) {
				this.listClients = response.data.data
				this.paginationClients = response.data.paginate
			}
			this.getAllClientsResultLocal(response)
		},
		getAllClientsResultLocal(response) {
			console.log('getAllClientsResultLocal no desarrollado', response)
		},
		saveClientResult(response) {
			if (response.data.success == true) {
				if (response.data.message == 'NUEVO') {
					this.setObjectClient(response.data.data)
					this.addListClients(response.data.data)
					this.$notify({ title: 'Nuevo Cliente!!!', text: 'Se ha creado con exito el nuevo cliente', type: 'success' })
				} else if (response.data.message == 'ACTUALIZADO') {
					this.setObjectClient(response.data.data)
					this.updateItemListClients(response.data.data)
					this.$notify({ title: 'Cliente Actualizado!!!', text: 'Se ha actualizado con exito el cliente', type: 'info' })
				}
			}
			this.saveClientResultLocal(response)
		},
		saveClientResultLocal(response) {
			console.log('saveClientResultLocal no desarrollado', response)
		},
		setClientAddressSend(id, addressId) {
			let url = this.getApiBackendNew + this.urlsClient.show + id + '/address/' + addressId + '/send'
			this.axiosActionIS(url, 'PUT', 'setClientAddressSendResult', null)
		},
		setClientAddressSendResult(response) {
			if (response.data.success == true) {
				this.$snotify.info('Seleccion realizada')
			}
			this.setClientAddressSendResultLocal(response)
		},
		setClientAddressSendResultLocal(response) {
			console.log('setClientAddressSendResultLocal no desarrollado', response)
		},
		setErrorSaveClientLocal(response) {
			console.groupCollapsed('Error!!! Resultado de consulta axios, funcion por omision (setErrorSaveClientLocal)')
			console.log(response)
			console.groupEnd()
		},
		setObjectClient(item) {
			for (const property in item) {
				this.objectClient[property] = item[property]
			}
		},
		showClient(id, action = 'view') {
			this.searchingClient = true
			let param = '?action=' + action
			this.axiosActionIS(this.getApiBackendNew + this.urlsClient.show + id + param, 'GET', 'showClientResult', null)
		},
		showClientByEmail(email) {
			this.listClients = []
			this.searchingClient = true
			let url = this.getApiBackendNew + 'client/byEmail?all=true&email=' + email
			this.axiosActionIS(url, 'GET', 'showClientByEmailResult', null)
		},
		showClientByEmailResult(response) {
			this.searchingClient = false
			if (response.data.success == true) {
				this.listClients = response.data.data
			}
		},
		showClientByEmailResultLocal(response) {
			console.log('showClientByEmailResultLocal no desarrolado', response)
		},
		showClientResult(response) {
			this.searchingClient = false
			if (response.data.success == true) {
				this.setObjectClient(response.data.data)
			}
			this.showClientResultLocal(response)
		},
		showClientResultLocal(response) {
			console.log('showClientResultLocal no desarrollado', response)
		},
		updateItemListClients(item) {
			let index = this.listClients.map((el) => parseInt(el.id)).indexOf(parseInt(item.id))
			if (index > -1) {
				for (const property in item) {
					this.listClients[index][property] = item[property]
				}
			}
			if (this.objectClient.id == item.id) {
				this.setObjectClient(item)
			}
		},
	},
};

export default client;