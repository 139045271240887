<template>
	<div id="user">
		<div class="card mb-3">
            <div class="card-header position-relative min-vh-25 mb-7">
            	<div class="bg-holder rounded-3 rounded-bottom-0" style="background-image:url(/assets/img/generic/4.jpg);">
            </div>
			<!--/.bg-holder-->
            <div class="avatar avatar-5xl avatar-profile">
				<div class="avatar-name rounded-circle" v-if="objectUser.img == null || objectUser.img == '' || objectUser.img == 'user.jpg'">
					<span>{{ initialsUser(objectUser) }}</span>
				</div>

				<img class="rounded-circle img-thumbnail shadow-sm" :src="'/img/perfil/' + objectUser.img" width="200" alt="" v-else/>
			</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-8">
                  <h4 class="mb-1"> {{ objectUser.name }}<span data-bs-toggle="tooltip" data-bs-placement="right" title="Verified"><small class="fa fa-check-circle text-primary" data-fa-transform="shrink-4 down-2"></small></span>
                  </h4>
                  <h5 class="fs-0 fw-normal">Senior Software Engineer at Technext Limited</h5>
                  <p class="text-500">{{ roleUserFirs(objectUser) }}</p>
                  <button class="btn btn-falcon-primary btn-sm px-3" type="button">Following</button>
                  <button class="btn btn-falcon-default btn-sm px-3 ms-2" type="button">Message</button>
                  <div class="border-bottom border-dashed my-4 d-lg-none"></div>
                </div>
                <div class="col ps-2 ps-lg-3"><a class="d-flex align-items-center mb-2" href="#"><span class="fas fa-user-circle fs-3 me-2 text-700" data-fa-transform="grow-2"></span>
                    <div class="flex-1">
                      <h6 class="mb-0">See followers (330)</h6>
                    </div>
                  </a><a class="d-flex align-items-center mb-2" href="#"><img class="align-self-center me-2" src="/assets/img/logos/g.png" alt="Generic placeholder image" width="30" />
                    <div class="flex-1">
                      <h6 class="mb-0">Google</h6>
                    </div>
                  </a><a class="d-flex align-items-center mb-2" href="#"><img class="align-self-center me-2" src="/assets/img/logos/apple.png" alt="Generic placeholder image" width="30" />
                    <div class="flex-1">
                      <h6 class="mb-0">Apple</h6>
                    </div>
                  </a><a class="d-flex align-items-center mb-2" href="#"><img class="align-self-center me-2" src="/assets/img/logos/hp.png" alt="Generic placeholder image" width="30" />
                    <div class="flex-1">
                      <h6 class="mb-0">Hewlett Packard</h6>
                    </div>
                  </a></div>
              </div>
            </div>
          </div>


		  <div class="card h-100">
                    <div class="card-header">
                      <h5 class="mb-0">Profile</h5>
                    </div>
                    <div class="card-body bg-body-tertiary pb-0">
                      <form data-wizard-form="2">
                        <div class="mb-3">
                          <div class="row" data-dropzone="data-dropzone" data-options='{"maxFiles":1,"data":[{"name":"avatar.png","size":"54kb","url":"/assets/img/team"}]}'>
                            <div class="fallback">
                              <input type="file" name="file" />
                            </div>
                            <div class="col-md-auto">
                              <div class="dz-preview dz-preview-single">
                                <div class="dz-preview-cover d-flex align-items-center justify-content-center mb-3 mb-md-0">
                                  <div class="avatar avatar-4xl"><img class="rounded-circle" src="/assets/img/team/avatar.png" alt="..." data-dz-thumbnail="data-dz-thumbnail" /></div>
                                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md">
                              <div class="dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message">
                                <div class="text-center"><img class="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />Upload your profile picture
                                  <p class="mb-0 fs--1 text-400">Upload a 300x300 jpg image with <br />a maximum size of 400KB</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="form-label" for="form-example-gender">Gender</label>
                          <select class="form-select" name="gender" id="form-example-gender">
                            <option value="">Select your gender ...</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div class="mb-3">
                          <label class="form-label" for="form-example-wizard-phone">Phone</label>
                          <input class="form-control" type="text" name="phone" placeholder="Phone" id="form-example-wizard-phone" />
                        </div>
                        <div class="mb-3">
                          <label class="form-label" for="form-example-wizard-datepicker">Date of Birth</label>
                          <input class="form-control datetimepicker" type="text" placeholder="dd/mm/yy" data-options='{"dateFormat":"dd/mm/yy","disableMobile":true}' id="form-example-wizard-datepicker" />
                        </div>
                        <div class="mb-3">
                          <label class="form-label" for="form-example-wizard-address">Address</label>
                          <textarea class="form-control" rows="4" id="form-example-wizard-address"></textarea>
                        </div>
                      </form>
                    </div>
                  </div>







	</div>
</template>

<script>
	import mixin from '@/mixins'
	import axiosAction from '@/mixins/axiosAction.js'
	import errors from '@/mixins/errors.js'
	import users from '@/mixins/users'

	export default {
		name: 'User',
		mixins: [mixin, axiosAction, errors, users],
		props: ['id'],
		data() {
			return {
			}
		},
		components: {
		},
		computed: {
		},
		methods: {
			axiosResultLocal(responseLocal, response) {
				switch(responseLocal) {
					case 'showUserResult': this.showUserResult(response); break;
					default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
				}
			},
			initModuleLocal() {
				this.showUserLocal()
			},
			showUserLocal() {
				this.showUser(this.id)
			},
			showUserResultLocal(response) { console.log(response) }
		},
		mounted () {
			this.initModule(this.$options.name)
		},
	}	
</script>

<style lang="scss">
</style>
