/* Requiere de genesys.js */

var asiento = {
    data() {
        return {
            listAsientos: [],
            listLinkFunctionsAasinetGenesys: [
                { code: '131111', name: 'Administracion General', dsadpto: '101' },
                { code: '131331', name: 'Distribucion - Ventas', dsadpto: '201' },
                { code: '131332', name: 'Produccion - Taller', dsadpto: '301' },
            ],
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getAsiento(fromDate, toDate) {
            let url = this.getApiBackend + this.getGenesys
            let param = { 'get': this.urlApiAsiento + '?fromDate=' + fromDate + '&toDate=' + toDate }
            this.axiosActionIS(url, 'POST', 'getAsientoResult', param)
        },
        getAsientoResult(response) {
            this.listAsientos = response.data.asiento
            this.getAsientoResultLocal(response)
        },
        getAsientoResultLocal(response) {
            console.log('getAsientoResultLocal no desarrollado', response)
        },
        getFunctionCodeByDsadpto(val) {
            let index = this.listLinkFunctionsAasinetGenesys.map((el) => parseInt(el.dsadpto)).indexOf(parseInt(val))
            if (index > -1) {
                val = this.listLinkFunctionsAasinetGenesys[index].code
            }
            return val
        },
        getItemAsiento(numasie) {
            let url = this.getApiBackend + this.getGenesys
            let param = { 'get': this.urlApiAsiento + 'getAllItem?numasie=' + numasie }
            this.axiosActionIS(url, 'POST', 'getItemAsientoResult', param)
        },
        getItemAsientoResult(response) {
            this.getItemAsientoResultLocal(response)
        },
        getItemAsientoResultLocal(response) {
            console.log(response)
        }
    },
};

export default asiento;