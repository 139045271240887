<template>
	<div id="orderColportajeModalComponent">
		<button class="btn btn-primary d-none" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
			ref="btnShowOrderColportajeModalComponent" @click="btnShowOrderColportajeModalComponent">Launch static
			backdrop modal</button>
		<div class="modal fade" id="staticBackdrop" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
			aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl mt-6" role="document">
				<div class="modal-content border-0">
					<div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
						<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
							data-bs-dismiss="modal" aria-label="Close" @click="btnCloseOrderModalComponent"
							ref="btnCloseOrderModalComponent"></button>
					</div>
					<div class="modal-body p-0">
						<div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
							<h4 class="mb-1" id="staticBackdropLabel">Agregar Nuevo Pedido</h4>
							<p class="fs--2 mb-0">Cargado por <a class="link-600 fw-semi-bold" href="#!">{{ getUser.name
									}}</a></p>
						</div>
						<div class="p-4">
							<div class="row">
								<div class="col-lg-8">
									<div class="d-flex">
										<span class="fa-stack ms-n1 me-3">
											<i class="fas fa-circle fa-stack-2x text-200"></i>
											<i class="fa-inverse fa-stack-1x text-primary fas fa-tag"
												data-fa-transform="shrink-2"></i>
										</span>
										<div class="flex-1">
											<h5 class="mb-2 fs-0">Detalle del pedido</h5>
											<div class="d-flex">
												<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
													<thead>
														<tr>
															<th scope="col" class="text-center">#</th>
															<th scope="col">Articulo</th>
															<th scope="col" class="text-center" style="width: 75">
																Cantidad</th>
															<th scope="col" class="text-center">Precio</th>
															<th scope="col" class="text-center">Total</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in objectOrder.order_products"
															:key="index">
															<td class="text-center align-middle">{{ index + 1 }}</td>
															<td class="align-middle">
																{{ item.product }}
																<p class="mb-0 text-500">Código {{ item.product_id }}
																</p>
															</td>
															<td class="align-middle">
																<div class="input-group input-group-sm flex-nowrap"
																	data-quantity="data-quantity">
																	<button
																		class="btn btn-sm btn-outline-secondary border-300 px-2"
																		data-type="minus" :disabled="item.quantity < 2"
																		@click="item.quantity--">-</button>
																	<input
																		class="form-control form-control-sm text-center px-2 input-spin-none"
																		type="number" min="1" value="1"
																		aria-label="Amount (to the nearest dollar)"
																		style="width: 50px" v-model="item.quantity" />
																	<button
																		class="btn btn-sm btn-outline-secondary border-300 px-2"
																		data-type="plus"
																		@click="item.quantity++">+</button>
																</div>
															</td>
															<td class="text-end align-middle">{{
				methodFormatNumber(item.price) }}</td>
															<td class="text-end align-middle">{{
				methodFormatNumber(item.quantity * item.price) }}</td>
															<td class="text-end align-middle">
																<button class="btn p-0 ms-2" type="button"
																	data-bs-toggle="tooltip" data-bs-placement="top"
																	title="Delete"
																	@click="deleteItemOrderProductLocal(item)">
																	<small><span
																			class="text-danger fas fa-trash-alt"></span></small>
																</button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="row g-0 justify-content-end">
												<div class="col-auto">
													<table class="table table-sm table-borderless fs--1 text-end">
														<tr>
															<th class="text-900">Subtotal:</th>
															<td class="fw-semi-bold" style="width: 100px">${{
				methodFormatNumber(totalOrderProducts) }}</td>
															<td></td>
														</tr>
														<tr>
															<th class="text-900">Diezmo:</th>
															<td class="fw-semi-bold">${{
				methodFormatNumber(titheOrderProducts) }}</td>
															<td></td>
														</tr>
														<tr class="border-top">
															<th class="text-900">Total:</th>
															<td class="fw-semi-bold">${{
				methodFormatNumber(parseInt(objectOrder.total) +
					parseInt((objectOrder.tithe == null) ? 0 :
						objectOrder.tithe)) }}</td>
															<td></td>
														</tr>
													</table>
												</div>
											</div>
											<hr class="my-4" />
										</div>
									</div>
									<div class="d-flex">
										<span class="fa-stack ms-n1 me-3">
											<i class="fas fa-circle fa-stack-2x text-200"></i>
											<i class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
												data-fa-transform="shrink-2"></i>
										</span>
										<div class="flex-1">
											<h5 class="mb-2 fs-0">Datos de Envío</h5>
											<div class="row g-3">
												<div class="col-md-6">
													<label class="form-label" for="inputTeam">
														Campaña
														<small :title="'Código seleccionado: ' + objectOrder.team_id"
															class="text-success fas fa-check"
															v-if="(objectOrder.team_id > 0)"></small>
													</label>
													<select class="form-select form-select-sm" id="inputTeam"
														v-model="objectOrder.team_id" :disabled="loadingTeams">
														<option value="0" selected disabled>
															Seleccione la campaña...
														</option>
														<option v-for="(item, index) in listTeamsFilter" :key="index"
															:value="item.id">{{ item.name }}</option>
													</select>
												</div>
												<div class="col-md-6">
													<label class="form-label" for="inputClient">
														Cargado a
													</label>
													<autocomplete-input-component ref="autocompleteInputComponentClient"
														_class="form-control form-control-sm"
														:_path="getApiBackend + 'clients/getByUser'" :_disabled="false"
														@actionSelectItem="setClientLocal">
													</autocomplete-input-component>
													<small v-if="(objectOrder.client_id == 0)"
														class="text-warning"><i>Debe seleccionar un cliente desde la
															lista</i></small>

												</div>
												<div class="col-md-6">
													<label class="form-label" for="inputSendTo">Quien recibe </label>
													<autocomplete-input-component ref="autocompleteInputComponentSendTo"
														_class="form-control form-control-sm"
														:_path="getApiBackend + 'client/' + objectOrder.client_id + '/sendto'"
														:_disabled="objectOrder.client_id == 0"
														@actionSelectItem="setSendtoLocal">
													</autocomplete-input-component>
												</div>
												<div class="col-md-6">
													<label class="form-label" for="inputPhone">Telefono</label>
													<input class="form-control form-control-sm" id="inputPhone"
														type="text" v-model="objectOrder.sendto[0].phone" />
												</div>
												<div class="col-md-4">
													<label class="form-label" for="inputNumberDocument">RUT</label>
													<input class="form-control form-control-sm" id="inputNumberDocument"
														type="text" v-model="objectOrder.sendto[0].number_document" />
												</div>
												<div class="col-md-8">
													<label class="form-label" for="inputEmail">Email</label>
													<input class="form-control form-control-sm" id="inputEmail"
														type="text" v-model="objectOrder.sendto[0].email" />
												</div>
												<div class="col-8">
													<label class="form-label" for="inputAddress">Direccion</label>
													<autocomplete-input-component
														ref="autocompleteInputComponentAddress"
														_class="form-control form-control-sm"
														:_path="getApiBackend + 'client/' + objectOrder.client_id + '/address'"
														:_disabled="objectOrder.client_id == 0"
														@actionSelectItem="setAddresLocal">
													</autocomplete-input-component>
												</div>
												<div class="col-md-4">
													<label class="form-label" for="inputComuna">Comuna</label>
													<select class="form-select form-select-sm" id="inputComuna"
														:disabled="loadingComunas"
														v-model="objectOrder.sendto[0].comuna_id">
														<option selected disabled>
															Seleccione la comuna
														</option>
														<option v-for="(item, index) in listComunas" :key="index"
															:value="item.id">{{ item.name }}</option>
													</select>
												</div>
												<div class="col-md-12">
													<label class="form-label" for="inputComuna">Observación</label>
													<input type="text" class="form-control form-control-sm"
														v-model="objectOrder.order_observations[0].observation" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="row mb-3">
										<div class="col-5 col-form-label">
											<h6 class="mt-5 mt-lg-0">Lista de Precios</h6>
										</div>
										<div class="col-sm-7">
											<select class="form-select form-select-sm" id="inputComuna"
												:disabled="(listClientPriceListsFilter.length == 0) || loadingPriceListsClient"
												v-model="priceListsIdSelect" @change="getProductPriceListLocal">
												<option value="0" disabled selected>Seleccione la Lista de Precios
												</option>
												<option v-for="(item, index) in listClientPriceListsFilter" :key="index"
													:value="item.id">{{ item.name }}</option>
											</select>
										</div>
									</div>
									<div class="card ">
										<div class="card-header d-flex flex-between-center bg-light py-2">
											<div class="input-group input-group-sm">
												<label v-show="1 == 0" for="searchProductList"
													ref="searchProductList"></label>
												<input class="form-control form-control-sm shadow-none search"
													type="search" id="searchProductList"
													placeholder="Ingrese Producto a" aria-label="search"
													v-model="searchProductsPriceList"
													v-on:keyup.enter="analyzeEnterSearchProductList()"
													:disabled="listClientPriceListsFilter.length == 0" />
												<span class="input-group-text input-group-text-sm">x {{ quantity
													}}</span>
												<div class="input-group-text bg-transparent pointer"
													@click="analyzeEnterSearchProductList()"
													:disabled="listClientPriceListsFilter.length == 0">
													<span class="fa fa-search fs--1 text-600"></span>
												</div>
											</div>
										</div>
										<div class="card-body scrollbar recent-activity-body-height ps-2">
											<div class="d-flex align-items-center position-relative mb-3 border-bottom pointer"
												v-for="(item, index) in listProductsPriceList" :key="index"
												@click="addProductToOrderProducLocal(item)">
												<div class="flex-1 ms-3">
													<h6 class="mb-0 fw-semi-bold">
														{{ item.product }}
														<h5 class="fas fa-cart-plus float-end pointer margin-top5 text-primary"
															title="Agregar al pedido"></h5>
													</h6>
													<p class="text-500 fs--2 mb-0" v-for="(it, ind) in item.attacheds"
														:key="ind"><i>{{ it.product }}</i></p>
													<div class="row">
														<div class="text-600 fs--2 col-md-5">Código: {{ item.product_id
															}}
														</div>
														<div class="text-600 fs--3 col-md-7 text-end">${{
				methodFormatNumber(totalItemProduct(item)) }}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="pt-3 text-end">
										<button class="btn btn-sm btn-primary" type="button"
											:disabled="disableButtonSaveOrder" @click="saveOrderComponentLocal">Guardar
											Pedido</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import orders from '@/mixins/orders'
import teams from '@/mixins/teams'
import comunas from '@/mixins/generals/comuna.js'
import clients from '@/mixins/clients'
import productPriceList from '@/mixins/products/productPriceList.js'

import AutoCompleteInputComponent from '@/components/AutoCompleteInputComponent.vue'
export default {
	name: 'OrderColportajeModalComponent',
	mixins: [mixin, axiosAction, errors, orders, teams, comunas, clients, productPriceList],
	data() {
		return {
			priceListsIdSelect: 0,
			quantity: 1,
			countQuantity0: 0
		}
	},
	components: {
		'autocomplete-input-component': AutoCompleteInputComponent
	},
	computed: {
		disableButtonSaveOrder() {
			if (this.countQuantity0 > 0) {
				return true
			}
			if (this.objectOrder.team_id < 1)
				return true
			if (this.objectOrder.client_id < 1)
				return true
			if (this.objectOrder.sendto[0].sendto.trim().length < 3)
				return true
			if (this.objectOrder.sendto[0].phone.trim().length < 9)
				return true
			if (this.objectOrder.sendto[0].street.trim().length < 5)
				return true
			if (this.objectOrder.sendto[0].comuna_id < 1)
				return true
			if (this.objectOrder.order_products.length == 0)
				return true
			return false
		},
		listClientPriceListsFilter() {
			return this.objectClient.client_price_list
		},
		listTeamsFilter() {
			let list = []

			list = this.listTeams.filter(t => {
				return t.status_id == 42
			})
			return list
		},
		totalOrderProducts() {
			this.countQuantity0 = 0
			let total = this.objectOrder.order_products.reduce((a, b) => {
				if (b.quantity == 0) {
					this.countQuantity0++
				}
				let c = b.quantity * b.price
				return a + c
			}, 0);
			this.objectOrder.total = total
			return total.toFixed(0)
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllComunasResult': this.getAllComunasResult(response); break;
				case 'getAllClientPriceListsResult': this.getAllClientPriceListsResult(response); break;
				case 'getAllTeamsResult': this.getAllTeamsResult(response); break;
				case 'getProductPriceListResult': this.getProductPriceListResult(response); break;
				case 'getProductPriceListNotBonusResult': this.getProductPriceListNotBonusResult(response); break;
				case 'getProductPriceListNotFreightResult': this.getProductPriceListNotFreightResult(response); break;
				case 'getProductPriceListNotTitheResult': this.getProductPriceListNotTitheResult(response); break;
				case 'saveOrderResult': this.saveOrderResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.getAllComunasLocal()
			this.getAllTeamsLocal()
		},
		addProductToOrderProducLocal(item) {
			this.addOrderProductLocal(item)
			Object.values(item.attacheds).forEach(it => {
				it.price_list_id = item.price_list_id
				this.addOrderProductLocal(it)
			})
			this.quantity = 1
			setTimeout(() => { this.$refs.searchProductList.click() }, 100)
		},
		addOrderProductLocal(item) {
			let index = this.objectOrder.order_products.map((el) => parseInt(el.product_id)).indexOf(parseInt(item.product_id))
			if (index > -1) {
				this.objectOrder.order_products[index].quantity = parseInt(this.objectOrder.order_products[index].quantity) + parseInt(this.quantity)
			} else {
				this.objectOrder.order_products.push({
					id: this.objectOrder.order_products.length + 1,
					product_id: item.product_id,
					product: item.product,
					quantity: this.quantity,
					price: item.price,
					tax: this.tax,
					price_list_id: item.price_list_id,
					with_freight: (this.isFreightProduct(item)) ? this.freight : 0,
					bonusable: this.isBonusProduct(item),
					tithable: this.isTitheProduct(item),
				})
			}
			return
		},
		analyzeEnterSearchProductList() {
			if (!isNaN(this.searchProductsPriceList) && this.searchProductsPriceList.trim() != '' && this.searchProductsPriceList.length < 8 && this.searchProductsPriceList > 0) {
				this.quantity = this.searchProductsPriceList
				this.searchProductsPriceList = ''
			} else if (!isNaN(this.searchProductsPriceList) && this.searchProductsPriceList.trim() != '' && this.searchProductsPriceList.length == 13) {
				alert('buscar como codigo')
			} else if (this.searchProductsPriceList.substring(0, 1).toUpperCase() == 'H' && !isNaN(this.searchProductsPriceList.substring(1, this.searchProductsPriceList.trim().length))) {
				alert('Buscar por codigo ' + this.searchProductsPriceList.substring(1, this.searchProductsPriceList.trim().length))
			} else {
				this.getProductPriceList(this.priceListsIdSelect, this.searchProductsPriceList)
			}
		},
		btnCloseOrderModalComponent() {
			this.clearComponent()
		},
		btnShowOrderColportajeModalComponent() {
			this.clearComponent()
		},
		deleteItemOrderProductLocal(item) {
			let index = this.objectOrder.order_products.map((el) => parseInt(el.product_id)).indexOf(parseInt(item.product_id))
			let orderProducts = this.objectOrder.order_products.splice(index, 1)
		},
		clearComponent() {
			this.clearObjectOrder()
			this.clearObjectClient()
			this.quantity = 1
			this.listProductsPriceList = []
			this.$refs.autocompleteInputComponentClient.$refs.clear.click()
			this.$refs.autocompleteInputComponentSendTo.$refs.clear.click()
			this.$refs.autocompleteInputComponentAddress.$refs.clear.click()
		},
		getAllComunasLocal() {
			this.getAllComunas()
		},
		getAllComunasResultLocal(response) { },
		getAllClientPriceListsResultLocal(response) {
			if (response.data.data.length > 0) {
				this.priceListsIdSelect = response.data.data[0].id
				this.getProductPriceListLocal()
			}
		},
		getProductPriceListLocal() {
			this.getProductPriceList(this.priceListsIdSelect, this.searchProductsPriceList)
		},
		getProductPriceListResultLocal(response) {
			if (response.data.success == true) {
				this.getProductPriceListNotBonus(this.priceListsIdSelect)
				this.getProductPriceListNotFreight(this.priceListsIdSelect)
				this.getProductPriceListNotTithe(this.priceListsIdSelect)
			}
		},
		getProductPriceListNotBonusResultLocal(response) { },
		getProductPriceListNotFreightResultLocal(response) { },
		getProductPriceListNotTitheResultLocal(response) { },
		getAllTeamsLocal() {
			this.getAllTeamByUserClient()
		},
		getAllTeamsResultLocal(response) { },
		saveOrderComponentLocal() {
			this.saveOrder(this.objectOrder, 'byListOrderTeam')
		},
		saveOrderResultLocal(response) {
			if (response.data.success === true) {
				this.$emit('saveOrder', response.data.data)
				this.$refs.btnCloseOrderModalComponent.click()
			} else {

			}
		},
		setAddresLocal(item) {
			this.objectOrder.sendto[0].address_id = item.id
			this.objectOrder.sendto[0].street = item.string
			if (item.id > 0) {
				this.objectOrder.sendto[0].comuna_id = item.item.comuna_id
				this.objectOrder.sendto[0].comuna = item.item.comuna
			} else {
				this.objectOrder.sendto[0].comuna_id = 0
				this.objectOrder.sendto[0].comuna = 0
			}
		},
		setClientLocal(item, msg) {
			this.clearObjectClient()
			if (item.id > 0) {
				this.objectOrder.client_id = item.id
				this.objectOrder.client = item.string
				this.objectOrder.sendto[0].client_id = item.id
				this.getAllClientPriceLists(item.id)
			} else {
				this.objectOrder.client_id = 0
				this.objectOrder.client = ''
				this.objectOrder.sendto[0].client_id = 0
			}
		},
		setSendtoLocal(item) {
			this.objectOrder.sendto[0].exists = 0
			this.objectOrder.sendto[0].client_sendto_id = 0
			this.objectOrder.sendto[0].sendto = item.string
			this.objectOrder.sendto[0].phone = ''
			this.objectOrder.sendto[0].number_document = ''
			this.objectOrder.sendto[0].email = ''
			if (item.id > 0) {
				this.objectOrder.sendto[0].exists = 1
				this.objectOrder.sendto[0].client_sendto_id = item.id
				this.objectOrder.sendto[0].sendto = item.item.sendto
				this.objectOrder.sendto[0].phone = item.item.phone
				this.objectOrder.sendto[0].number_document = item.item.number_document
				this.objectOrder.sendto[0].email = item.item.email
			}
		},
		totalItemProduct(item) {
			let price = parseInt(item.price)
			Object.values(item.attacheds).forEach(it => {
				price = parseInt(it.price) + parseInt(price)
			})
			return price
		}
	},
	mounted() {
		this.initModule()
	},
}
</script>