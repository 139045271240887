import Vue from 'vue'
import Main from './Main.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import Notifications from 'vue-notification'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

/*Vue.use(new VueSocketIO({
	debug: true,
	connection: SocketIO(store.getters.getUrlSocketIO),
	vuex: {
		store,
		actionPrefix: 'SOCKET_',
		mutationPrefix: 'SOCKET_'
	},
}))*/

Vue.prototype.axios = axios

Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(VueConfirmDialog)

router.beforeResolve((to, from, next) => {
	switch (to.path) {
		case '/login':
		case '/unauthorized':
		case '/logout':
			store.commit('setInitialized', false)
			store.commit('setToken', null)
			next()
			break;
		case '/loading':
			store.commit('setHeadersAuthorization', localStorage.getItem('token'))
			next()
			break;
		default:
			if (localStorage.getItem('token') != null) {
				if (!store.getters.getInitialized) {
					localStorage.setItem('redirect', to.path)
					next({ path: '/loading' })
				} else {
					store.commit('setHistory', { fullPath: to.fullPath, name: to.name, from: from.fullPath })
					next()
				}
			} else {
				localStorage.setItem('redirect', to.path)
				next({ path: '/unauthorized' })
			}
			break;
	}
})


new Vue({
	router,
	axios,
	store,
	render: h => h(Main)
}).$mount('#app')
