<template>
	<div id="ClientEditComponent" class="client-edit-component">
		<button ref="btnClearObjectClient" v-show="false" @click="clearObjectClientLocal">Limpiar objectClient</button>
		<button ref="btnShowClient" v-show="false" @click="showClientLocal">Buscar Cliente {{id}}</button>
		<button ref="btnSaveClient" v-show="false" @click="saveClientLocal" :disabled="btnSaveClientDisabled">Guardar Cliente</button>
		<div class="row g-2">
			<div class="col-md-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-name-client">Nombre</label>
					<input class="form-control" placeholder="Juan Manuel" type="text" id="form-wizard-progress-name-client" v-model="objectClient.name" :readonly="disabledFormClient"/>
				</div>
			</div>
			<div class="col-md-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-surname-client">Apellidos</label>
					<input class="form-control" placeholder="Perez Ahumada" type="text" id="form-wizard-progress-surname-client" v-model="objectClient.surname" :readonly="disabledFormClient"/>
				</div>
			</div>
		</div>
		<div class="row g-2">
			<div class="col-md-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-numberDocument">RUT</label>
					<input class="form-control" placeholder="12345678-9" type="text" id="form-wizard-progress-numberDocument" v-model="objectClient.number_document" :readonly="disabledFormClient"/>
				</div>
			</div>
			<div class="col-md-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-phone">Telefono</label>
					<input class="form-control" placeholder="987654321" type="text" id="form-wizard-progress-phone" v-model="objectClient.phone" :readonly="disabledFormClient"/>
				</div>
			</div>
		</div>
		<div class="mb-3">
			<label class="form-label" for="form-wizard-progress-wizard-email-client">Correo Electronico</label>
			<input class="form-control" type="text" placeholder="tucuenta@mail.com" id="form-wizard-progress-wizard-email-client" v-model="objectClient.email" :readonly="disabledFormClient"/>
		</div>
		<div class="row g-2">
			<div class="col-md-6">
				<label class="form-label" for="form-wizard-progress-typeClient">Tipo {{typeClient}}</label>
				<select class="form-select" name="gender" id="form-wizard-progress-typeClient" v-model="objectClient.type_client_id" :disabled="disabledFormClient">
					<option value="0" disabled>Selecione un tipo de {{typeClient}} ...</option>
					<option v-for="(item, index) in listTypesClientFilter" :key="index" :value="item.id">{{ item.name }}</option>
					<option value="2">Colportor Estudiante</option>
				</select>
			</div>
			<div class="col-md-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-wizard-datepicker">Fecha de Nacimiento</label>
					<input class="form-control datetimepicker" type="date" placeholder="dd/mm/yyyy" id="form-wizard-progress-wizard-datepicker"  v-model="objectClient.birthdate" :readonly="disabledFormClient"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import clients from '@/mixins/clients'
import typeClient from '@/mixins/clients/typeClient.js'

export default {
	name: 'ClientEditComponent',
	mixins: [mixin, axiosAction, errors, clients, typeClient],
	props: ['id', 'edit', 'typeClient', 'withOutAccount'],
	data () {
		return {
		}
	},
	components: {
	},
	computed: {
		disabledFormClient() {
			if(this.edit == undefined)
				return false
			return !this.edit
		},
		btnSaveClientDisabled() {
			let disabled = false
			if(this.objectClient.name.length < 3)
				disabled = true
			if(this.objectClient.surname.length < 3)
				disabled = true
			if(this.objectClient.type_client_id == null) {
				disabled = true
			}
			this.$emit('saveDisabled', disabled)
			return disabled
		},
		listTypesClientFilter() {
			return this.listTypesClient
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'getAllTypesClientResult': this.getAllTypesClientResult(response); break;
				case 'showClientResult': this.showClientResult(response); break;
				case 'saveClientResult': this.saveClientResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectClientLocal() {
			this.clearObjectClient()
		},
		getAllTypeClientLocal() {
			this.getAllTypesClient()
		},
		getAllTypesClientResultLocal(response) {},
		initModuleLocal() {
			this.setClientEditInit()
		},
		onFileChangeLocal(e) {
			this.filesUpload = []
			this.onFileChange(e)
		},
		dragFileChangeLocal(e) {
			this.filesUpload = []
			this.dragFileChange(e)
		},
		saveClientLocal() {
			this.saveClient(this.objectClient)
		},
		saveClientResultLocal(response) {
			if(response.data.success == true) {
				this.$emit('saveClientResult', this.objectClient)
			}
		},
		setClientEditInit() {
			this.getAllTypeClientLocal()
		},
		showClientLocal() {
			this.clearObjectClient()
			if(this.id > 0) {
				this.showClient(this.id)
			}
		},
		showClientResultLocal(response) {
			if(response.data.success == true) {
				this.$emit('showClientResultTrue', this.objectClient)
			} else {
				this.$emit('showClientResultFalse', response)
			}
		},
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>