<template>
	<div id="clientHistory">
		<client-history-team-component :id="idLocal" :showDetail="true" ref="clientHistoryTeamComponent" :teamId="93"></client-history-team-component>
	</div>
</template>

<script>
	import mixin from '@/mixins'
	import axiosAction from '@/mixins/axiosAction.js'
	import errors from '@/mixins/errors.js'

	import ClientHistoryTeamComponent from '@/components/ClientHistoryTeamComponent.vue'

	export default {
		name: 'ClientHistory',
		mixins: [mixin, axiosAction, errors],
		props: ['id'],
		data() {
			return {
				idLocal: this.id
			}
		},
		components: {
			'client-history-team-component' : ClientHistoryTeamComponent,
		},
		computed: {
		},
		methods: {
			axiosResultLocal(responseLocal, response) {
				switch(responseLocal) {
					case 'defaultFunction': this.defaultFunction(response); break;
					default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
				}
			},
			initModuleLocal() {
				if(this.id > 0) {
					this.$refs.clientHistoryTeamComponent.$refs.btnShowClient.click()
				}
			}
		},
		mounted () {
			this.initModule(this.$options.name)
		},
	}	
</script>

<style lang="scss">
</style>
