var role = {
    data() {
        return {
            listRoles: [],
            loadingRoles: false,
            paginationRoles: [],
            savingRole: false,
            deletingRole: false,
            searchRoles: '',
            objectRole: {
                exists: 0,
                id: 0,
                name: '',
                label: '',
                created_at: null,
                updated_at: null,
                menus: [],
                actions: []
            },
            urlsRole: {
                getAll: 'roles',
                getCurrentUser: 'rolesCurrentUser',
                show: 'role/',
            },
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        deleteRoleAction(id, actionId) {
            this.deletingRole = true
            let url = this.getApiBackendNew + 'role/' + id + '/action/' + actionId
            this.axiosActionIS(url, 'DELETE', 'deleteRoleActionResult')
        },
        deleteRoleActionResult(response) {
            this.deletingRole = false
            if (response.data.success == true) {
                if (this.objectRole.id > 0) {
                    let i = this.objectRole.actions.map(a => parseInt(a.id)).indexOf(parseInt(response.data.data))
                    if (i > -1) {
                        this.objectRole.actions.splice(i, 1)
                    }
                }
            }
            this.deleteRoleActionResultLocal(response)
        },
        deleteRoleActionResultLocal(response) {
            console.log('deleteRoleActionResultLocal no desarrollado', response)
        },
        deleteRoleMenu(id, menuId) {
            this.deletingRole = true
            let url = this.getApiBackendNew + 'role/' + id + '/menu/' + menuId
            this.axiosActionIS(url, 'DELETE', 'deleteRoleMenuResult')
        },
        deleteRoleMenuResult(response) {
            this.deletingRole = false
            if (response.data.success == true) {
                if (this.objectRole.id > 0) {
                    let i = this.objectRole.menus.map(m => parseInt(m.id)).indexOf(response.data.data)
                    if (i > -1) {
                        this.objectRole.menus.splice(i, 1)
                    }
                }
            }
            this.deleteRoleMenuResultLocal(response)
        },
        deleteRoleMenuResultLocal(response) {
            console.log('deleteRoleMenuResultLocal no desarrollado', response)
        },
        clearObjectRole() {
            this.objectRole.exists = 0
            this.objectRole.id = 0
            this.objectRole.name = ''
            this.objectRole.label = ''
            this.objectRole.created_at = null
            this.objectRole.updated_at = null
        },
        getRoles(page = 1, type = 'getAll') {
            this.loadingRoles = true
            this.listRoles = []
            this.paginationRoles = []
            let parameters = '?search=' + this.searchRoles + '&page=' + page
            let url = this.getApiBackendNew + this.urlsRole[type] + parameters
            this.axiosActionIS(url, 'GET', 'getRolesResult')
        },
        getRolesResult(response) {
            this.loadingRoles = false
            this.listRoles = []
            this.paginationRoles = []
            if (response.data.success) {
                this.listRoles = response.data.data
                this.paginationRoles = response.data.paginate
            }
            this.getRolesResultLocal(response)
        },
        getRolesResultLocal(response) {
            console.log('getRolesResultLocal no desarrollado', response)
        },
        setItemListRoles(item) {
            let index = this.listRoles.map(r => parseInt(r.id)).indexOf(parseInt(item.id))
            if (index > -1) {
                let newItem = Object.create(item)
                for (const property in newItem) {
                    this.listRoles[index][property] = newItem[property]
                }
            }
        },
        setObjectRole(item) {
            this.clearObjectRole()
            let newItem = Object.create(item)
            for (const property in newItem) {
                this.objectRole[property] = newItem[property]
            }
        },
        saveRole(r) {
            this.savingRole = true
            let method = (r.id == 0) ? 'POST' : 'PUT'
            let url = (r.id == 0) ? 'role/register' : 'role/' + r.id + '/update'
            url = this.getApiBackendNew + url
            this.axiosActionIS(url, method, 'saveRoleResult', r)
        },
        saveRoleResult(response) {
            this.savingRole = false
            if (response.data.success == true) {
                this.setObjectRole(response.data.data)
                if (response.data.message == 'UPDATED') {
                    this.setItemListRoles(response.data.data)
                }
            }
            this.saveRoleResultLocal(response)
        },
        saveRoleResultLocal(response) {
            console.log('saveRoleResultLocal no desarrollado', response)
        },
        saveRoleAction(id, actionId) {
            this.savingRole = true
            let data = {
                actionId: actionId
            }
            let url = this.getApiBackendNew + 'role/' + id + '/action/register'
            this.axiosActionIS(url, 'POST', 'saveRoleActionResult', data)

        },
        saveRoleActionResult(response) {
            if (response.data.success == true) {
                if (this.objectRole.id > 0) {
                    this.objectRole.actions.push(response.data.data)
                }
            }
            this.saveRoleActionResultLocal(response)
        },
        saveRoleActionResultLocal(response) {
            console.log('saveRoleActionResultLocal no desarrollado', response)
        },
        saveRoleMenu(id, menuId) {
            this.savingRole = true
            let data = {
                menuId: menuId
            }
            let url = this.getApiBackendNew + 'role/' + id + '/menu/register'
            this.axiosActionIS(url, 'POST', 'saveRoleMenuResult', data)

        },
        saveRoleMenuResult(response) {
            if (response.data.success == true) {
                if (this.objectRole.id > 0) {
                    this.objectRole.menus.push(response.data.data)
                }
            }
            this.saveRoleMenuResultLocal(response)
        },
        saveRoleMenuResultLocal(response) {
            console.log('saveRoleMenuResultLocal no desarrollado', response)
        },
        showRole(id) {
            this.loadingRoles = true
            let url = this.getApiBackendNew + this.urlsRole.show + id
            this.axiosActionIS(url, 'GET', 'showRoleResult')
        },
        showRoleResult(response) {
            this.loadingRoles = false
            if (response.data.success == true) {
                this.setObjectRole(response.data.data)
            }
            this.showRoleResultLocal(response)
        },
        showRoleResultLocal(response) {
            console.log('showRoleResultLocal no está desarrollado', response)
        },
    },
};

export default role;