<template>
	<div id="teamStudentStatisticsComponent">
        <button ref="btnGetDataStudents" @click="getDataStudentsLocal" v-show="false">Launch static backdrop modal</button>
		<div class="row mb-3">
			<div class="col">
				<div class="card bg-100 shadow-none border">
					<div class="row gx-0 flex-between-center">
						<div class="col-sm-auto d-flex align-items-center"><img class="ms-n2" src="/assets/img/illustrations/crm-bar-chart.png" alt="" width="90" />
							<div>
								<h6 class="text-primary fs--1 mb-0">Estadisticas de </h6>
								<h4 class="text-primary fw-bold mb-0">Camapañas de <span class="text-info fw-medium">Estudiantes</span></h4>
							</div>
							<img class="ms-n4 d-md-none d-lg-block" src="/assets/img/illustrations/crm-line-chart.png" alt="" width="150" />
						</div>
						<div class="col-md-auto p-3">
							<form class="row align-items-center g-3">
								<div class="col-auto">
									<h6 class="text-700 mb-0">Fechas: </h6>
								</div>
								<div class="col-md-auto position-relative">
									<input class="form-control form-control-sm datetimepicker ps-4" id="ordersDateRange" type="text" data-options="{&quot;mode&quot;:&quot;range&quot;,&quot;dateFormat&quot;:&quot;M d&quot;,&quot;disableMobile&quot;:true , &quot;defaultDate&quot;: [&quot;Sep 12&quot;, &quot;Sep 19&quot;] }" />
									<span class="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2"></span>
								</div>
							</form>
						</div>
						<div class="col-md-auto p-3">
							<form class="row align-items-center g-3">
								<div class="col-auto">
								<h6 class="text-700 mb-0">Estado: </h6>
								</div>
								<div class="col-md-auto position-relative">
									<select class="form-select form-select-sm ps-4" v-model="statusIdsLocal" @change="getDataStudentsLocal">
										<option value="-1">Todas</option>
										<option value="42">Abiertas</option>
										<option value="43">Cerradas</option>
									</select>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="objectResult != null">
			<div class="row g-3 mb-3" v-if="true">
				<div class="col-xxl-3 col-md-4 col-sm-6">
					<div class="card overflow-hidden mb-2" style="min-width: 12rem">
						<div class="bg-holder bg-card" style="background-image:url(/assets/img/icons/spot-illustrations/corner-1.png);"></div>
						<div class="card-body position-relative">
							<h6>Total Bonificable</h6>
							<div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-success">{{ methodFormatNumber(totalBonusable) }}</div>
						</div>
					</div>

					<div class="card overflow-hidden mb-2" style="min-width: 12rem">
						<div class="bg-holder bg-card" style="background-image:url(/assets/img/icons/spot-illustrations/corner-2.png);"></div>
							<div class="card-body position-relative">
								<h6>Libros</h6>
								<div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info">{{ methodFormatNumber(totalQuantityBooks[0].total) }}</div>
								<a class="fw-semi-bold fs--1 text-nowrap" href="#listProducts">
									Ir al ranking
									<span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
								</a>
						</div>
					</div>

					<div class="card overflow-hidden mb-2" style="min-width: 12rem">
						<div class="bg-holder bg-card" style="background-image:url(/assets/img/icons/spot-illustrations/corner-3.png);"></div>
							<div class="card-body position-relative">
								<h6>Revistas</h6>
								<div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info">{{ methodFormatNumber(totalQuantityBooks[2].total) }}</div>
						</div>
					</div>

				</div>
				<div class="col-xxl-3 col-md-4 col-sm-6" v-for="(t, i) in teamsFilter" :key="i">
					<div class="card h-100" :class="(t.status_id == 42) ? 'border border-dark' : ''">
						<div class="card-header">
							<div class="row justify-content-between gx-0">
								<div class="col-auto">
									<h1 class="fs-0 text-900">{{ t.name }}</h1>
									<div class="row">
										<div class="col-6">
											<small>Total Compra</small>
											<h4 class="text-primary mb-0">${{ methodFormatNumber(totalDebt(t)) }}</h4>
										</div>
										<div class="col-6 text-end">
											<small>Bonificable</small>
											<h4 class="text-success mb-0" title="Bonificable">${{ methodFormatNumber(t.bonusable) }}</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body pt-0 pb-3 h-100">
							<div class="mx-nx1">
								<table class="table table-borderless font-sans-serif fw-medium fs--1">
									<tr>
									<td class="pb-2 pt-0">Pedido + Flete</td>
									<td class="pb-2 pt-0 text-end" style="width: 50%">${{ methodFormatNumber(t.total_orders) }}</td>
									</tr>
									<tr>
									<td class="pb-2 pt-0">Diezmo</td>
									<td class="pb-2 pt-0 text-end" style="width: 50%">${{ methodFormatNumber(t.tithe) }}</td>
									</tr>
									<tr>
									<td class="pb-2 pt-0">Gastos</td>
									<td class="pb-2 pt-0 text-end" style="width: 50%">${{ methodFormatNumber(t.total_expenses) }}</td>
									</tr>
									<tr>
									<td class="pb-2 pt-0">% por Compra Aquí</td>
									<td class="pb-2 pt-0 text-end" style="width: 50%">${{ methodFormatNumber(t.charge_payments) }}</td>
									</tr>
									<tr>
									<td class="pb-2 pt-0">Multa</td>
									<td class="pb-2 pt-0 text-end" style="width: 50%">${{ methodFormatNumber(t.penalty_devolution) }}</td>
									</tr>
									<tr>
									<td class="pb-2 pt-0">Factura a Terceros</td>
									<td class="pb-2 pt-0 text-end" style="width: 50%">${{ methodFormatNumber(t.tax_diference_invoce_third) }}</td>
									</tr>
								</table>
								<div class="echart-gross-revenue-chart px-3 h-100 mt-n7 ms-auto">
									<div>
										<doughnut-chart  id="my-chart-id"
														:options="chartOptions"
														:chartData="chartDataLocal(t)">
													</doughnut-chart>
										<p class="mb-0 mt-n8 text-center fs-2 fw-medium">{{ t.percentage }}%</p>
										<div class="text-center fw-medium font-sans-serif">${{ methodFormatNumber(t.total_payments) }}</div>
									</div>
								</div>
								<div class="text-end">
									{{ t.client.name + ' ' + t.client.surname}}
								</div>
							</div>
						</div>
						<div class="card-footer border-top py-2 d-flex flex-between-center">
							<div class="d-flex" id="gross-revenue-footer">
								{{ t.entity.synthetic }}
							</div>
							<router-link :to="'/team/' + t.id" class="btn btn-link btn-sm px-0" >Ver Campaña<span class="fas fa-chevron-right ms-1 fs--2"></span></router-link>
						</div>
					</div>
				</div>

				<div class="card h-lg-100" id="listProducts">
	                <div class="card-header d-flex flex-between-center bg-body-tertiary py-2">
						<h6 class="mb-0">Rancking Ventas</h6>
                	</div>
		                <div class="card-body pb-0">
							<div class="d-flex mb-3 hover-actions-trigger align-items-center border-bottom" v-for="(p, i) in listProductsFilter(-1)" :key="i">
								<div class="file-thumbnail">
									<img class="border h-100 w-100 object-fit-cover rounded-2" :src="p.img" alt="" />
                    			</div>
                    			<div class="ms-3 flex-shrink-1 flex-grow-1">
									<h6 class="mb-1">
										<a class="stretched-link text-900 fw-semi-bold" href="#!">{{ p.name }}</a>
									</h6>
                      				<div class="fs--1">
										<span class="fw-semi-bold">Cantidad</span>
									<span class="fw-medium text-600 ms-2">{{ methodFormatNumber(p.quantity) }}</span>
								</div>
                      			<div class="hover-actions end-0 top-50 translate-middle-y">
									<a class="btn btn-tertiary border-300 btn-sm me-1 text-600" data-bs-toggle="tooltip" data-bs-placement="top" title="Download" href="assets/img/icons/cloud-download.svg" download="download">
										<img src="assets/img/icons/cloud-download.svg" alt="" width="15" />
									</a>
                        			<button class="btn btn-tertiary border-300 btn-sm me-1 text-600 shadow-none" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><img src="assets/img/icons/edit-alt.svg" alt="" width="15" /></button>
                      			</div>
                    		</div>
                  		</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="card h-lg-100 overflow-hidden">
					<div class="card-body p-0">
						<table class="table table-dashboard mb-0 table-borderless fs--1 border-200 overflow-hidden rounded-3 table-member-info">
							<thead class="bg-body-tertiary">
								<tr>
									<th class="text-900">Campaña</th>
									<th class="text-900 text-center">Estado</th>
									<th class="text-900 text-center">Deuda</th>
									<th class="text-900 text-center">Abonos</th>
									<th class="text-900 pe-x1 text-center" style="width: 8rem">Pagos (%)</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(t, i) in objectResult" :key="i" class="border-bottom border-200">
									<td>
										<div class="d-flex align-items-center position-relative">
											<div class="avatar avatar-2xl">
												<img class="rounded-circle" src="/assets/img/teams2.jpg" alt="" />
											</div>
											<div class="flex-1 ms-3">
												<h6 class="mb-0 fw-semi-bold">
													<router-link class="stretched-link text-900" :to="'/team/' + t.id">{{ t.name }}</router-link>
												</h6>
												<p class="text-500 fs--2 mb-0">{{ t.client.name + ' ' + t.client.surname }} - {{ t.entity.synthetic }}</p>
											</div>
										</div>
									</td>
									<td class="align-middle text-center">
										<span class="badge badge rounded-pill d-block" :class="'badge-soft-' + t.status.color">{{ t.status.name }}</span>
									</td>
									<td class="align-middle px-4 text-end text-nowrap">
										<h6 class="mb-0">${{ methodFormatNumber(totalDebt(t)) }}</h6>
									</td>
									<td class="align-middle px-4 text-end text-nowrap">
										<h6 class="mb-0">${{ methodFormatNumber(t.total_payments) }}</h6>
									</td>
									<td class="align-middle pe-x1">
										<div class="d-flex align-items-center">
											<div class="progress me-3 rounded-3 bg-200" style="height: 5px; width:80px" role="progressbar" :aria-valuenow="t.percentage" aria-valuemin="0" aria-valuemax="100">
												<div class="progress-bar bg-primary rounded-pill" :style="'width: ' + t.percentage +'%'"></div>
											</div>
											<div class="fw-semi-bold ms-2">{{ t.percentage }}%</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import statisticsTeams from '@/mixins/teams/statisticsTeams.js'

import { DoughnutChart } from 'vue-chart-3';

export default {
	name: 'TeamStudentStatisticsComponent',
	mixins: [mixin, axiosAction, errors, statisticsTeams],
	props: ['typeTeamIds', 'entityId', 'statusIds'],
	data () {
		return {
			productNotStadistic: [5000287, 5002792],
			chartOptions: {
				responsive: true,
				aspectRadio: 1.8,
				plugins: {
					legend: {
						display: false
					}
				}
			},
			statusIdsLocal: -1
		}
	},
	components: {
		'doughnut-chart': DoughnutChart
	},
	computed: {
		statusIdsLocalArray() {
			if(this.statusIdsLocal == -1)
				return [42, 43]
			return [this.statusIdsLocal]
		},
		teamsFilter() {
			this.objectResult.sort((a, b) => {
				if (parseInt(a.bonusable) < parseInt(b.bonusable)) {
					return 1;
				}
				if (parseInt(a.bonusable) > parseInt(b.bonusable)) {
					return -1;
				}
				return 0;
			});
			return this.objectResult
		},
		totalBonusable() {
			let total = this.objectResult.reduce((a, b) => { 
							return a + parseInt(b.bonusable)
						}, 0);
			return total.toFixed(0)
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'getStatisticsTeamByFiltersResult': this.getStatisticsTeamByFiltersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		chartDataLocal(t) {
			let chartData={ 
					labels: ['Pagado','Falta por pagar'],
					datasets: [{
						data: [t.total_payments, this.totalDebt(t) - t.total_payments],
						circumference: 180,
						rotation: 270,
						cutout: '75%',
						backgroundColor: ['#' +t.entity.color_hex, '#d8e2ef']
					}],
				}
			return chartData
		},
		initModuleLocal() {
			this.setNombreInit()
		},
		getDataStudentsLocal() {
			this.filters.entityIds = this.entityId
			this.filters.typeTeamIds = this.typeTeamIds
			this.filters.statusIds = this.statusIdsLocalArray
			this.getStatisticsTeamByFilters('getDataStudents')
		},
		getStatisticsTeamByFiltersResultLocal(response) { },
		listProductsFilter() {
			let list = []
			let i = -1
			let ind = -1
			this.teamsFilter.forEach(t => {
				t.order_products_resume.forEach(p => {
					ind = this.productNotStadistic.map(e => parseInt(e)).indexOf(parseInt(p.id))
					if(ind == -1) {
						i = list.map(l => parseInt(l.id)).indexOf(parseInt(p.id))
						if(i == -1) {
							let pr = {
								id: p.id,
								name: p.name,
								synthetic: p.synthetic,
								img: p.img,
								quantity: parseInt(p.quantity),
								price: parseInt(p.price)
							}
							list.push(pr)
						} else {
							list[i].quantity+= parseInt(p.quantity)
						}
					}
				})
			})
			list = list.sort(function (a, b) { return (a.quantity < b.quantity) ? 1 : (a.quantity > b.quantity) ? -1: 0 });

			return list
		},
		setNombreInit() {
			let data = new Date()
			this.filters.fromDate = data.getFullYear() + '-01-01'
			this.filters.toDate = this.currentDate()
			flatpickr(
				"#ordersDateRange", {
				mode: "range",
				dateFormat: "Y-m-d",
				minDate: "2024-01",
				defaultDate: [this.filters.fromDate, this.filters.toDate],
				onClose: (selectedDates, dateStr, instance) => {
					dateStr = dateStr.split('to')
					if(dateStr.length == 2) {
						console.log(dateStr[0].trim())
						this.filters.fromDate = dateStr[0].trim()
						this.filters.toDate = dateStr[1].trim()
						this.getDataStudentsLocal()
					}
				},
			})
		},
		totalDebt(t) {
			let total = 0
			total+= parseInt(t.total_orders)
			total+= parseInt(t.tithe)
			total+= parseInt(t.total_expenses)
			total+= parseInt(t.charge_payments)
			total+= parseInt(t.penalty_devolution)
			total+= parseInt(t.tax_diference_invoce_third)
			t.percentage = (t.total_payments * 100) / total
			t.percentage = t.percentage.toFixed(0)
			return total
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>