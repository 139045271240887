<template>
	<div id="shippingListComponent">
		<!-- Botones ocultos que realizan una acción de el componente -->
		<button ref="btnDeleteListShippings" @click="deleteShippingResultExternal()" v-show="false">Delete Shipping List
			Shippings</button>
		<button ref="btnAddShippingListShippings" @click="addShippingListShippings()" v-show="false">Add Shipping List
			Shippings</button>
		<button ref="btnAddTrackingShippingResultListShippings" @click="addTrakingShippingListShippings()"
			v-show="false">Add Traking Shipping List
			Shippings</button>
		<!-- Componente Visible-->
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Envios </h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="Buscar solicitudes" aria-label="search" ref="searchShippings"
										:disabled="loadingShippings" v-model="searchShippings"
										@focus="$event.target.select()" @keyup.enter="analyzeSearchShippings" />
									<div class="input-group-text bg-transparent pointer"
										@click="analyzeSearchShippings">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showShippingLocal(0)" ref="btnShowShippingComponent">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
							<button class="btn btn-falcon-default btn-sm me-2" type="button" data-bs-toggle="offcanvas"
								data-bs-target="#filtersShippingsOffCanvasTop"
								aria-controls="filtersShippingsOffCanvasTop">
								<span class="fas fa-filter" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Filtros</span>
							</button>

							<div class="btn-group" role="group">
								<button class="btn btn-falcon-default dropdown-toggle btn-sm" id="btnGroupVerticalDrop1"
									type="button" data-bs-toggle="dropdown" aria-haspopup="true"
									aria-expanded="false"><span class="fas fa-truck ms-1"
										data-fa-transform="shrink-3 down-2 "></span></button>
								<div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
									<a class="dropdown-item" href="#" data-bs-toggle="modal"
										data-bs-target="#assignFreightOrderComponentModal"
										@click="showAssignFreightOrderComponentModalLocal">Asignar OF</a>
									<a class="dropdown-item" href="#" data-bs-toggle="modal"
										data-bs-target="#manifestoListComponentModal"
										@click="showManifestoListComponentModalLocal">Manifiestos</a>
									<a class="dropdown-item" href="#" data-bs-toggle="modal"
										data-bs-target="#correosChileComponentModal"
										@click="showCorreosChileComponentModalLocal">Manifiestos Correos Chile</a>
									<a class="dropdown-item" href="#">Configuración</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 sort align-middle">Enviar a</th>
								<th class="text-900 sort align-middle white-space-nowrap" style="min-width: 12.5rem;">
									Dirección</th>
								<th class="text-900 sort align-middle white-space-nowrap text-center">Peso</th>
								<th class="text-900 sort align-middle white-space-nowrap text-center">Bultos</th>
								<th class="text-900 sort align-middle white-space-nowrap text-center">Manifiesto</th>
								<th class="text-900 sort align-middle white-space-nowrap text-center">Estado</th>
								<th class="text-900 sort align-middle white-space-nowrap text-end"></th>
							</tr>
						</thead>
						<tbody class="">
							<tr class="border-start-2 border-end-1 hover-actions-trigger"
								v-for="(s, i) in listShippingsFilter" :key="i">
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<a class="text-primary pointer" @click="showShippingLocal(s.id)">
										<strong>#{{ s.id }}</strong>
									</a>
									{{ actionToTake(s) }} a
									<strong>{{ s.sendto }}</strong>
									<br />
									<small class="text-muted">
										<i class="far fa-envelope text-secondary"></i>
										<a href="mailto:ricky@example.com" class="text-muted ms-1">{{ s.email }}</a>
										|<i class="far fa-address-card ms-1 text-secondary"></i>
										<span class="ms-1">{{ s.document_number }}</span>
										|<i class="fas fa-phone-alt ms-1 text-secondary"></i>
										<span class="ms-1">{{ s.phone }}</span>
									</small>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<div>
										{{ s.address }}, {{ s.comuna }}
										<div v-if="s.transport != null">
											<span :class="'text-' + s.transport.color">
												<i class="fas fa-truck-moving"></i> {{ s.transport.name }}
												<span v-show="s.paid_shipping == 0"> -
													<span class="badge bg-dark dark__bg-dark">Por Pagar</span>
												</span>
											</span>
											<span v-if="s.freight_order != null && s.freight_order != ''"
												@click="trackingTransportLocal(s)" :title="'OF ' + s.freight_order">
												<i class="ms-1 fas fa-info-circle text-info pointer"></i>
											</span>
										</div>
										<div v-else>
											{{ s.transport }}
										</div>
									</div>
								</td>
								<td class="align-middle fw-medium border-bottom border-muted text-center">{{ s.packages
									}}
								</td>
								<td class="align-middle fw-medium border-bottom border-muted text-center">{{ s.weight
									}}</td>
								<td class="align-middle text-center fs-0 white-space-nowrap border-bottom border-muted">
									<span class="badge badge rounded-pill d-block pointer"
										:class="'badge-soft-' + s.transport.color" v-if="s.transport != null"
										@click="showManifestoLocal(s.manifesto.id)">
										{{ s.manifesto.name }}
									</span>
								</td>
								<td class="align-middle text-center fs-0 white-space-nowrap border-bottom border-muted">
									<span class="badge badge rounded-pill d-block"
										:class="'badge-soft-' + s.status.color">{{ s.status.name }}</span>
								</td>
								<td class="w-auto text-end border-bottom border-muted">
									<div class="btn-group btn-group hover-actions end-0 me-0"
										v-show="statusIdsShippingErasableOK(s)">
										<span class="btn ps-2 text-danger" type="button" @click="deleteShippingLocal(s)"
											data-bs-toggle="tooltip" data-bs-placement="top" title="Borrar"><span
												class="fas fa-trash-alt "></span></span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListShippings" @getAllList="getAllShippingsLocal">
					</pagination>
				</div>
			</div>
		</div>

		<!-- Modal de Trasporte Correos de Chile-->
		<div class="modal fade" id="correosChileComponentModal" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="correosChileComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg mt-6" role="document">
				<div class="modal-content border-0">
					<div class="modal-header">
						<div class="rounded-top-3 bg-body-tertiary py-1 ps-2 pe-6">
							<h4 class="" id="correosChileComponentModalLabel">
								Manifiestos Correos Chile
							</h4>
						</div>
						<div class="position-absolute top-0 end-0 mt-3 me-3 z-1">
							<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
								data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>
					<div class="modal-body p-0">
						<correos-chile-component ref="correosChileComponent"></correos-chile-component>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Lista Manifesto-->
		<div class="modal fade" id="manifestoListComponentModal" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="manifestoListComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl mt-6" role="document">
				<div class="modal-content border-0">
					<div class="modal-header">
						<div class="rounded-top-3 bg-body-tertiary py-1 ps-2 pe-6">
							<h4 class="" id="			tabindex=" -1" aria-labelledby="manifestoListComponentModalLabel"
								aria-hidden="true">
								">
								Manifiestos Correos Chile
							</h4>
						</div>
						<div class="position-absolute top-0 end-0 mt-3 me-3 z-1">
							<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
								data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>
					<div class="modal-body p-0">
						<manifesto-list-component ref="manifestoListComponent" @showManifesto="showManifestoLocal">
						</manifesto-list-component>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Lista Manifesto-->
		<button data-bs-toggle="modal" data-bs-target="#manifestoComponentModal" v-show="false"
			ref="btnShowManifestoComponent">Show Manifesto {{ manifestoId }}</button>
		<div class="modal fade" id="manifestoComponentModal" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="manifestoComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl mt-6" role="document">
				<div class="modal-content border-0">
					<div class="modal-header" v-show="false">
						<div class="rounded-top-3 bg-body-tertiary py-1 ps-2 pe-6">
							<h4 class="" id="manifestoComponentModalLabel">
								Manifiesto {{ manifestoId }}
							</h4>
							<div class="position-absolute top-0 end-0 mt-3 me-3 z-1">
								<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
									data-bs-dismiss="modal" aria-label="Close"
									ref="btnCloseManifestoComponentModal"></button>
							</div>
						</div>
					</div>
					<div class="modal-body p-0">
						<manifesto-component ref="manifestoComponent" :id="manifestoId" :visibleBtnCloseComponent="true"
							@closeComponent="closeComponentModal"></manifesto-component>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal de Trazabilidad Correos de Chile -->
		<button class="btn btn-primary" type="button" data-bs-toggle="modal"
			data-bs-target="#correosChileTrazabilidadComponentModal" ref="btnShowcorreosChileTrazabilidadComponentModal"
			v-show="false">Launch static backdrop modal</button>
		<div class="modal fade" id="correosChileTrazabilidadComponentModal" data-bs-keyboard="false"
			data-bs-backdrop="static" tabindex="-1" aria-labelledby="correosChileTrazabilidadComponentModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-lg mt-6" role="document">
				<div class="modal-content border-0">
					<div class="modal-header border-danger">
						<div class="rounded-top-3 bg-body-tertiary py-1 ps-2 pe-6">
							<h4 class="" id="correosChileTrazabilidadComponentModalLabel">
								Trazabilidad Correos Chile - OF {{ numeroEnvio }}
							</h4>
						</div>
						<div class="position-absolute top-0 end-0 mt-3 me-3 z-1">
							<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
								data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>
					<div class="modal-body py-4">
						<correos-chile-trazabilidad-component ref="correosChileTrazabilidadComponent"
							:numeroEnvio="numeroEnvio"></correos-chile-trazabilidad-component>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal Lista Manifesto-->
		<div class="modal fade" id="assignFreightOrderComponentModal" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="assignFreightOrderComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl mt-6" role="document">
				<div class="modal-content border-0">
					<div class="modal-header">
						<div class="rounded-top-3 bg-body-tertiary py-1 ps-2 pe-6">
							<h4 class="" id="assignFreightOrderComponentModalLabel">
								Asignación de ordenes de Flete
							</h4>
						</div>
						<div class="position-absolute top-0 end-0 mt-3 me-3 z-1">
							<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
								data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>
					<div class="modal-body p-0">
						<assign-freight-order-component></assign-freight-order-component>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import shipping from '@/mixins/shippings'
import analizeSTR from '@/mixins/generals/analizeSTR.js'
import shippingRequest from '@/mixins/shippingsRequest'
import manifesto from '@/mixins/manifestos'
import correosChile from '@/mixins/generals/correosChile.js'

import Pagination from '@/components/Pagination.vue'
import CorreosChileComponent from '@/components/CorreosChileComponent.vue'
import CorreosChileTrazabilidadComponent from '@/components/CorreosChileTrazabilidadComponent.vue'

import ManifestoListComponent from '@/components/ManifestoListComponent.vue'
import ManifestoComponent from '@/components/ManifestoComponent.vue'

import AssignFreightOrderComponent from '@/components/AssignFreightOrderComponent.vue'

export default {
	name: 'ShippingListComponent',
	mixins: [mixin, axiosAction, errors, shipping, analizeSTR, shippingRequest, manifesto, correosChile],
	props: ['idDelete', 'newShipping'],
	data() {
		return {
			listStatusSent: [21, 22, 24],
			numeroEnvio: 0,
			manifestoId: 0
		}
	},
	components: {
		'assign-freight-order-component': AssignFreightOrderComponent,
		'pagination': Pagination,
		'correos-chile-component': CorreosChileComponent,
		'correos-chile-trazabilidad-component': CorreosChileTrazabilidadComponent,
		'manifesto-list-component': ManifestoListComponent,
		'manifesto-component': ManifestoComponent
	},
	computed: {
		listShippingsFilter() {
			return this.listShippings
		},
	},
	methods: {
		addShippingListShippings() {
			if (this.newShipping != null && this.newShipping != undefined) {
				let ns = {
					address: this.newShipping.address,
					comuna: this.newShipping.comuna,
					created_at: this.newShipping.created_at,
					document_number: this.newShipping.document_number,
					email: this.newShipping.email,
					freight_order: this.newShipping.freight_order,
					id: this.newShipping.id,
					manifesto: this.newShipping.manifesto,
					packages: this.newShipping.packages,
					paid_shipping: this.newShipping.paid_shipping,
					phone: this.newShipping.phone,
					sendto: this.newShipping.sendto,
					status: this.newShipping.status,
					status_id: this.newShipping.status_id,
					total_price: this.newShipping.total_price,
					transport: this.newShipping.transport,
					transport_id: this.newShipping.transport_id,
					updated_at: this.newShipping.updated_at,
					weight: this.newShipping.weight,
				}
				this.addListShippings(ns)
			}
		},
		addTrakingShippingListShippings() {
			if (this.newShipping != null && this.newShipping != undefined) {
				this.saveShippingTrackingResult(this.newShipping)
			}
		},
		actionToTake(s) {
			let i = this.listStatusSent.map(st => st).indexOf(parseInt(s.status_id))
			if (i > -1) {
				return 'enviado'
			}
			return 'enviar'
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteShippingResult': this.deleteShippingResult(response); break;
				case 'getAllShippingsResult': this.getAllShippingsResult(response); break;
				case 'showShippingResult': this.showShippingResult(response); break;
				case 'showShippingRequestResult': this.showShippingRequestResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		analyzeSearchShippings() {
			if (!this.loadingShippings) {
				let r = this.analizeSTR(this.searchShippings)
				if (r['result'] == 'INTEGER' || r['result'] == 'SHIPPING') {
					this.showShippingLocal(r['data'])
					console.log('buscar directamente el envío')
				} else if (r['result'] == 'SHIPPINGREQUEST') {
					this.showShippingByShippingRequestLocal(r['data'])
					console.log('buscar directamente el solicitud de envío')
				} else if (r['result'] == 'MANIFESTO') {
					this.showManifestoLocal(r['data'])
					this.searchShippings = ''
					console.log('Mostrar manifesto')
				} else {
					this.getAllShippingsLocal(1)
				}
			} else {
				console.log('Aun estamos buscando los envíos')
			}
		},
		closeComponentModal() {
			this.$refs.btnCloseManifestoComponentModal.click()
			setTimeout(() => {
				this.$refs.searchShippings.focus()
			}, 100)
		},
		deleteShippingLocal(s) {
			if (s.id > 0) {
				this.$confirm(
					{
						message: 'Está seguro que quiere eliminar el envío Nº ' + s.id,
						button: {
							no: 'No, de ninguna manera',
							yes: 'Si, dale'
						},
						callback: confirm => {
							if (confirm) {
								this.deleteShipping(s.id)
							}
						}
					}
				)
			} else {
				this.showMsgErrorHandling('IMPOSIBLE', 'No puede Eliminar algo que no existe', 'warn', 5000, 'top right')
			}
		},
		deleteShippingResultExternal() {
			this.deleteListShippings(this.idDelete)
		},
		deleteShippingResultLocal(response) {
		},
		getAllShippingsLocal(page) {
			this.getAllShippings(page, 'toListShippings')
		},
		getAllShippingsResultLocal(response) {
			if (response.data.data.length == 0) {
				this.showMsgErrorHandling('Sin resultados', 'No se encontraron resultados', 'warn', 5000, 'top right')
			}
			console.log('Terminanos de Buscar los envíos, ' + response.data.data.length + ' registros')
		},
		initModuleLocal() {
			this.setShippingListComponentInit()
		},
		showAssignFreightOrderComponentModalLocal() {

		},
		setShippingListComponentInit() {
			this.getAllShippingsLocal(1)
		},
		showCorreosChileComponentModalLocal() {
			this.$refs.correosChileComponent.$refs.manifestoCorreosChileTab.click()
		},
		showManifestoLocal(manifestoId) {
			this.$refs.btnShowManifestoComponent.click()
			this.manifestoId = manifestoId
			setTimeout(() => {
				this.$refs.manifestoComponent.$refs.btnShowManifesto.click()
			}, 50)
		},
		showManifestoListComponentModalLocal() {
			this.$refs.manifestoListComponent.$refs.btnGetManifestos.click()
		},
		showMsgErrorHandlingLocal(error) {
			this.loadingShippings = false
			console.log('Se produjo un error en la operacion', error)
		},
		showShippingByShippingRequestLocal(shippingRequestId) {
			this.$emit('showShippingByShippingRequest', shippingRequestId)
		},
		showShippingLocal(id) {
			this.$emit('showShipping', id)
		},
		trackingTransportLocal(s) {
			if (parseInt(s.transport_id) == 3) {
				this.numeroEnvio = s.freight_order
				setTimeout(() => {
					this.$refs.btnShowcorreosChileTrazabilidadComponentModal.click()
					this.$refs.correosChileTrazabilidadComponent.$refs.btnTrazabilidadCorreosChile.click()
				}, 50)

			}
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>