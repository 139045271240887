<template>
	<div id="messageLoadingComponent">

		<div class="flex-center"
			style="position: fixed; top: 0; z-index: 10100; left: 0px; width: 100%; min-height: 100vh;">
			<div style="position: absolute; width: 100%; opacity: 0.9; min-height: 100vh;" :class="backgroundTheme">
			</div>
			<div class="row flex-center min-vh-100 py-6 text-center">
				<div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
					<div class="d-flex justify-content-center py-2">
						<div class="d-flex" style="z-index: 10101">
							<span class="fa-spin">
								<i class="fas fa-circle-notch"></i>
							</span>
							<span class="ms-1">{{ text }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

export default {
	name: 'MessageLoadingComponent',
	mixins: [mixin, axiosAction, errors],
	props: ['class_', 'text'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		backgroundTheme() {
			let bg = (localStorage.getItem('theme') == 'dark') ? 'bg-100' : 'bg-200'
			return bg
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setMessageLoadingComponentInit()
		},
		setMessageLoadingComponentInit() {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>