/* Rquiere de mixin.js */

var typeExpense = {
	data() {
		return {
			listTypeExpenses: [],
			urlsTypeExpense: {
				get: 'typeExpenses',
				show: 'typeExpense/',
			},
			paginationTypeExpenses: [],
			searchTypeExpenses: '',
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		getAllTypeExpenses(page = 1) {
			let url = this.getApiBackend + this.urlsTypeExpense.get
			this.axiosActionIS(url, 'GET', 'getAllTypeExpensesResult')
		},
		getAllTypeExpensesResult(response) {
			if (response.data.success) {
				this.listTypeExpenses = response.data.data
				this.paginationTypeExpenses = response.data.paginate
			}
			this.getAllTypeExpensesResultLocal(response)
		},
		getAllTypeExpensesResultLocal(response) {
			console.log('getAllTypeExpensesResultLocal no desarrollado', response)
		},
		getTypeExpense(id) {
			this.axiosActionIS(this.getApiBackend + this.urlsTypeExpense.show + id, 'GET', 'getTypeExpenseResult')
		},
		getTypeExpenseResult(response) {
			this.getTypeExpenseResultLocal(response)
		},
		getTypeExpenseResultLocal(response) {
			console.log('getTypeExpenseResultLocal no está desarrollado', response)
		},
	},
};

export default typeExpense;