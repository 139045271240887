/* Rquiere de mixin.js */

var entity = {
    data() {
        return {
            listEntities: [],
            loadingEntities: false,
            urlsEntity: {
                get: 'entities',
                show: 'entity/',
            },
            paginationEntities: [],
            searchEntities: '',
            objectEntity: {
                exists: 0,
                id: 0,
                name: '',
                synthetic: '',
                parent_entity_id: null,
                status_id: 1,
                acms_id: null,
                color: null,
                color_hex: null,
                teams: []
            }
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        clearObjectEntity() {
            this.objectEntity.exists = 0
            this.objectEntity.id = 0
            this.objectEntity.name = ''
            this.objectEntity.synthetic = ''
            this.objectEntity.parent_entity_id = null
            this.objectEntity.status_id = 1
            this.objectEntity.acms_id = null
            this.objectEntity.color = null
            this.objectEntity.color_hex = null
            this.objectEntity.teams = []
        },
        getAllEntities(page = 1) {
            this.loadingEntities = true
            let parameters = '?search=' + this.searchEntities + '&page=' + page
            let url = this.getApiBackend + this.urlsEntity.get + parameters
            this.axiosActionIS(url, 'GET', 'getAllEntitiesResult')
        },
        getAllEntitiesResult(response) {
            this.loadingEntities = false
            if (response.data.success === true) {
                this.listEntities = response.data.data
                this.paginationEntities = response.data.paginate
            }
            this.getAllEntitiesResultLocal(response)
        },
        getAllEntitiesResultLocal(response) {
            console.log('getAllEntitiesResultLocal no desarrollado', response)
        },
        getEntity(id) {
            this.axiosActionIS(this.apiBackend + this.methodsEntity.show + id, 'GET', 'getEntityResult')
        },
        getEntityResult(response) {
            this.getEntityResultLocal(response)
        },
        getEntityResultLocal(response) {
            console.log('getEntityResultLocal no está desarrollado', response)
        },
        setObjectEntity(item) {
            let newItem = Object.create(item)
            for (const property in newItem) {
                this.objectEntity[property] = newItem[property]
            }
        },
        entityFromListEntitiesById(id) {
            this.clearObjectEntity()
            let index = this.listEntities.map(e => parseInt(e.id)).indexOf(parseInt(id))
            if (index > -1) {
                this.setObjectEntity(this.listEntities[index])
            }
            return this.objectEntity
        },
    },
};

export default entity;