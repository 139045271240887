var genesys = {
    data() {
        return {
            getGenesys: 'genesys/get',
            urlApiAsiento: 'asiento/',
            tipocompcodSearch: 0,
            nrocompSearch: '',
            listTipocomp: [
                { tipocompcod: 20, tipocompdesc: 'Orden de Pedido', sintetico: 'OP', view: true },
                { tipocompcod: 2, tipocompdesc: 'Nota de Crédito', sintetico: 'NC', view: true },
                { tipocompcod: 120, tipocompdesc: 'Reposición', sintetico: 'R', view: true },
                { tipocompcod: 40, tipocompdesc: 'Envio de Suscripción', sintetico: 'ES', view: true },
                { tipocompcod: 1, tipocompdesc: 'Boleta/Factura', sintetico: 'B/F', view: false }
            ],
        }
    },
    methods: {
    },
};

export default genesys;