<template>
	<div id="manifestos">
		<div>
			<manifesto-list-component ref="manifestoListComponent"
				@showManifesto="showManifestoLocal"></manifesto-list-component>
		</div>
		<div>
			<manifesto-component ref="manifestoComponent" :id="manifestoId"></manifesto-component>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import ManifestoListComponent from '@/components/ManifestoListComponent.vue'
import ManifestoComponent from '@/components/ManifestoComponent.vue'

export default {
	name: 'ManifestosModule',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
			manifestoId: 0
		}
	},
	components: {
		'manifesto-list-component': ManifestoListComponent,
		'manifesto-component': ManifestoComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.$refs.manifestoListComponent.$refs.btnGetManifestos.click()
		},
		showManifestoLocal(manifestoId) {
			this.manifestoId = manifestoId
			setTimeout(() => {
				this.$refs.manifestoComponent.$refs.btnShowManifesto.click()
			}, 50)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
