/* Rquiere de mixin.js */

var typePayment = {
	data() {
		return {
			listTypePayments: [],
			urlsTypePayment: {
				get: 'typePayments',
				show: 'typePayment/',
			},
			paginationTypePayments: [],
			searchTypePayments: '',
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		getAllTypePayments(page = 1) {
			let parameters = '?search=' + this.searchTypePayments + '&page=' + page
			this.axiosActionIS(this.getApiBackendNew + this.urlsTypePayment.get + parameters, 'GET', 'getAllTypePaymentsResult')
		},
		getAllTypePaymentsResult(response) {
			if (response.data.success) {
				this.listTypePayments = response.data.data
				this.paginationTypePayments = response.data.paginate
			}
			this.getAllTypePaymentsResultLocal(response)
		},
		getAllTypePaymentsResultLocal(response) {
			console.log('getAllTypePaymentsResultLocal no desarrollado', response)
		},
		getTypePayment(id) {
			this.axiosActionIS(this.getApiBackendNew + this.urlsTypePayment.show + id, 'GET', 'getTypePaymentResult')
		},
		getTypePaymentResult(response) {
			this.getTypePaymentResultLocal(response)
		},
		getTypePaymentResultLocal(response) {
			console.log('getTypePaymentResultLocal no está desarrollado', response)
		},
	},
};

export default typePayment;