<template>
	<div id="legalDocumentComponent" class="fs--1">
		<button ref="btnClearLegalDocumentComponent" @click="clearLegalDocumentComponentLocal" v-show="false">
			Clear Legal Document Component
		</button>
		<button ref="btnToCompleteData" @click="toCompleteLegalDocumentLocal" v-show="false">To Complete
			Data</button>
		<div>
			<div v-show="objectLegalDocument.id > 0">
				<div class="row">
					<div class="col-6">
						<div class="fs-2">Folio: {{ objectLegalDocument.legal_document_number }}</div>
						<div>{{ objectLegalDocument.created_at }}</div>
					</div>
					<div class="col-6 text-end">
						<div class="fs-2" v-if="objectLegalDocument.type_legal_document != null">
							<a class="text-danger" :href="objectLegalDocument.url"
								:download="objectLegalDocument.type_legal_document.syntetic + objectLegalDocument.legal_document_number"
								v-show="objectLegalDocument.url != ''" title="Descargar Documento">
								<i class="far fa-file-pdf"></i>
							</a>
							<a class="text-warnign" :href="objectLegalDocument.url_assignable"
								:download="objectLegalDocument.type_legal_document.syntetic + 'C' + objectLegalDocument.legal_document_number"
								v-show="objectLegalDocument.url_assignable != ''" title="Descargar Documento Cedible">
								<i class="far fa-file-pdf ms-2"></i>
							</a>
							<span v-if="objectLegalDocument.legal_document_number == 0"
								:title="'Generar ' + objectLegalDocument.type_legal_document.name"
								@click="sendToRegulatoryEntityLocal()" class="text-primary pointer ms-2">
								<i class="far fa-paper-plane"></i>
							</span>
							<span v-else @click="toPrintLegalDocumentLocal(objectLegalDocument)"
								:class="(getSuccessFullConnectionFacturacionCLPrint) ? 'text-primary pointer' : 'not-allowed'"
								title="Imprimir Documento" class="ms-2">
								<i class="fas fa-print"></i>
							</span>
						</div>
					</div>
				</div>
				<hr>
			</div>
			<div class="row">
				<div class="col-md-5">
					<div class="row ">
						<span class="col-md-4 col-form-span col-form-span-sm fw-bold"
							for="typeLegalDocumentIdSelect">Tipo Documento</span>
						<div class="col-md-8 mb-2">
							<select class="form-select form-select-sm" id="typeLegalDocumentIdSelect"
								ref="typeLegalDocumentIdSelect" v-model="objectLegalDocument.type_legal_document_id"
								:disabled="disabledTypeLegalDocumentIdSelect" @change="changeTypeLegalDocumentIdSelect">
								<option v-for="(td, i) in listTypeLegalDocumentsFilter" :key="i" :value="td.id">{{
			td.name
		}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="row">
						<span class="col-md-3 col-form-span col-form-span-sm fw-bold"
							for="dateLegalDocumentInput">Fecha</span>
						<div class="col-md-8 mb-2">
							<input class="form-control form-control-sm" id="dateLegalDocumentInput" type="date"
								v-model="objectLegalDocument.date_legal_document"
								:disabled="objectLegalDocument.id > 0 || searchingLegalDocuments" />
						</div>
					</div>
				</div>
				<div class="col-md-3 text-end">
					<span class="pointer text-primary mb-2" @click="showIssuing = !showIssuing">
						<span v-if="!showIssuing">Mostrar Emisor</span>
						<span v-else>Ocultar Emisor</span>
					</span>
				</div>
			</div>
			<div v-if="showIssuing" class="border border-muted pt-2 ps-2 pe-2 mb-2">
				<div class="row">
					<div class="col-md-1">
						<span class="col-md-4 col-form-span col-form-span-sm fw-bold"
							for="issuingEntityNameInput">Emisor</span>
					</div>
					<div class="col-md-5 mb-2">
						<input class="form-control form-control-sm" id="issuingEntityNameInput" type="text"
							placeholder="Razon Social Emisor" :disabled="disabledIssuing"
							v-model="objectLegalDocument.issuing_entity_name" />
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="issuingEntityDocumentNumberInput" type="text"
							placeholder="RUT Emisor" :disabled="disabledIssuing"
							v-model="objectLegalDocument.issuing_entity_document_number" />
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="issuingEntityEconomicActivityInput" type="text"
							placeholder="Giro Emisor" :disabled="disabledIssuing"
							v-model="objectLegalDocument.issuing_entity_economic_activity" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-1">
						<span class="col-md-4 fst-normal fw-bold" for="issuingEntityAddressStreetInput">Dirección</span>
					</div>
					<div class="col-md-4 mb-2">
						<input class="form-control form-control-sm" id="issuingEntityAddressStreetInput" type="text"
							placeholder="Direccion Emisor" :disabled="disabledIssuing"
							v-model="objectLegalDocument.issuing_entity_address_street" />
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="issuingEntityAddressComunaInput" type="text"
							placeholder="Comuna Emisor" :disabled="disabledIssuing"
							v-model="objectLegalDocument.issuing_entity_address_comuna" />
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="issuingEntityAddressCityInput" type="text"
							placeholder="Ciudad Emisor" :disabled="disabledIssuing"
							v-model="objectLegalDocument.issuing_entity_address_city" />
					</div>
				</div>
			</div>
			<div class="border border-muted pt-2 ps-2 pe-2 mb-2">
				<div class="row">
					<div class="col-md-1">
						<span class="col-md-4 col-form-span col-form-span-sm fw-bold"
							for="receivingEntityNameInput">Receptor</span>
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="receivingEntityDocumentNumberInput"
							ref="receivingEntityDocumentNumberInput" type="text" placeholder="RUT Receptor"
							:disabled="disabledReceiving" v-model="objectLegalDocument.receiving_entity_document_number"
							@focus="$event.target.select()" @blur="analizeReceivingEntityDocumentNumber"
							@keydown="keydownReceivingEntityDocumentNumber($event)"
							@keypress.enter="analizeReceivingEntityDocumentNumber" />
					</div>
					<div class="col-md-5 mb-2">
						<input class="form-control form-control-sm" id="receivingEntityNameInput"
							ref="receivingEntityNameInput" type="text" placeholder="Nombre Receptor"
							:disabled="disabledReceiving || noReceivingEntityDocumentNumber || existReceivingEntityDocumentNumber"
							v-model="objectLegalDocument.receiving_entity_name" />
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="receivingEntityEconomicActivityInput"
							ref="receivingEntityEconomicActivityInput" placeholder="Giro Receptor" type="text"
							:disabled="disabledReceiving"
							v-model="objectLegalDocument.receiving_entity_economic_activity" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-1">
						<span class="col-md-4 fst-normal fw-bold"
							for="receivingEntityAddressStreetInput">Dirección</span>
					</div>
					<div class="col-md-4 mb-2">
						<input class="form-control form-control-sm" id="receivingEntityAddressStreetInput"
							ref="receivingEntityAddressStreetInput" type="text" placeholder="Dirección Receptor"
							:disabled="disabledReceiving || noReceivingEntityDocumentNumber"
							v-model="objectLegalDocument.receiving_entity_address_street" />
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="receivingEntityAddressComunaInput"
							ref="receivingEntityAddressComunaInput" type="text" placeholder="Comuna Receptor"
							:disabled="disabledReceiving || noReceivingEntityDocumentNumber"
							v-model="objectLegalDocument.receiving_entity_address_comuna" />
					</div>
					<div class="col-md-3 mb-2">
						<input class="form-control form-control-sm" id="receivingEntityAddressCityInput"
							ref="receivingEntityAddressCityInput" type="text" placeholder="Ciudad Receptor"
							:disabled="disabledReceiving || noReceivingEntityDocumentNumber"
							v-model="objectLegalDocument.receiving_entity_address_city" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-1">
						<span class="col-md-4 fst-normal fw-bold"
							for="receivingEntityAddressStreetInput">Contacto</span>
					</div>
					<div class="col-md-4 mb-2">
						<input class="form-control form-control-sm" id="contactInput" type="text"
							placeholder="Nombre de contacto"
							:disabled="disabledReceiving || noReceivingEntityDocumentNumber"
							v-model="objectLegalDocument.contact" />
					</div>
					<div class="col-md-4 mb-2">
						<input class="form-control form-control-sm" id="phonrInput" type="text"
							placeholder="Telefono contacto"
							:disabled="disabledReceiving || noReceivingEntityDocumentNumber"
							v-model="objectLegalDocument.phone" />
					</div>
				</div>
			</div>
			<div class="border border-muted pt-2 ps-2 pe-2 mb-2">
				<div v-show="showAddItem">
					<div class="row">
						<div class="col-md-1">
							<span class="col-md-4 fst-normal fw-bold" for="newItemInput">Nuevo Item</span>
						</div>
						<div class="col-md-9 mb-2">
							<input class="form-control form-control-sm" id="newItemInput" type="text" placeholder=""
								ref="newItemInput" :disabled="disabledNewItemInput" v-model="newItemInput"
								@keypress.enter="analizeNewItemInput" />
						</div>
						<div class="col-md-2 mb-2 text-end">
							<a class="btn btn-link" @click="clearDetailsLocal"
								v-show="objectLegalDocument.details.length > 0"><small>Borrar Detalle</small></a>
						</div>
					</div>
					<hr>
				</div>
				<div class="table-responsive">
					<table class="table table-sm" v-show="objectLegalDocument.details.length > 0 || inputCode0">
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">Código</th>
								<th>Artículo</th>
								<th class="text-center">Cantidad</th>
								<th class="text-end">Prec. Uni.</th>
								<th class="text-end">Total Item</th>
								<td class="text-end"></td>
							</tr>
						</thead>
						<tbody>
							<tr v-show="inputCode0">
								<td></td>
								<td class="text-center">{{ objectLegalDocumentDetail.code }}</td>
								<td>
									<input type="text" ref="newNameInput" style="border:none; width: 100%;"
										@focus="$event.target.select()" v-model="objectLegalDocumentDetail.name"
										@blur="keypressEnterNewItemInput" @keypress.enter="keypressEnterNewItemInput" />
								</td>
								<td class="text-center">
									<input type="text" ref="newQuantityInput"
										style="border:none; width: 100px; text-align: center;"
										@focus="$event.target.select()" v-model="objectLegalDocumentDetail.quantity"
										@blur="keypressEnterNewItemInput" @keypress.enter="keypressEnterNewItemInput" />
								</td>
								<td class="text-end">
									<input type="text" ref="newPriceInput"
										style="border:none; width: 100px; text-align: right;"
										@focus="$event.target.select()" v-model="objectLegalDocumentDetail.price"
										@blur="keypressEnterNewItemInput" @keypress.enter="keypressEnterNewItemInput" />
								</td>
								<td class="text-end">
									$ {{ methodFormatNumber(objectLegalDocumentDetail.price *
			objectLegalDocumentDetail.quantity) }}
								</td>
								<td class="text-end">
									<span @click="inputCode0 = false">
										<i class="fas fa-minus-circle text-danger pointer"></i>
									</span>
								</td>
							</tr>
							<tr v-for="(d, i) in objectLegalDocument.details" :key="i">
								<td class="text-center">{{ i + 1 }}</td>
								<td class="text-center">{{ d.code }}</td>
								<td>{{ d.name }}</td>
								<td class="text-center">{{ methodFormatNumber(d.quantity) }}</td>
								<td class="text-end">$ {{ methodFormatNumber(d.price) }}</td>
								<td class="text-end">$ {{ methodFormatNumber(d.total) }}</td>
								<td class="text-end">
									<span @click="deleteItemobjectLegalDocumentDetailLocal(i)">
										<i class="fas fa-minus-circle text-danger pointer"></i>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div v-show="objectLegalDocument.details.length == 0 && !inputCode0" class="p-5 text-center">
						<div v-show="objectLegalDocument.type_legal_document_id != 0">
							<div class="dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message"
								@click="clickBtn('btnLegalDocumentDetailFile')" @dragover.prevent @drop.prevent>
								<input type="file" name="file" class="invisible" ref="btnLegalDocumentDetailFile"
									@change="onChangeInputFileUploadLocal" id="btnLegalDocumentDetailFile"
									v-show="false" />
								<div class="text-center" @drop="onDropHandlerLocal">
									<img class="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />Suba
									un
									archivo Excel con el detalle para el documento legal
									<p class="mb-0 fs--1 text-400">Suba un archivo (xls, xlsx, cvs)<br />con un tamaño
										máximo de 400 KB</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pt-2 ps-2 pe-2">
				<div class="row">
					<div class="col-md-6">
						<span class="col-md-4 col-form-span col-form-span-sm fw-bold"
							for="observationTextarea">Observación</span>
						<textarea class="form-control form-control-sm" id="observationTextarea" rows="3"
							v-model="this.objectLegalDocument.observation"></textarea>
					</div>
					<div class="col-md-6 mb-2 mt-2">
						<div class="border border-muted mb-2">
							<div class="row">
								<div class="col-md-6">
									<div class="row mb-1">
										<div class="col-6 text-end">Exento: <span class="fw-bold">$</span></div>
										<div class="col-6 text-end"><span class="fw-bold me-2">{{
			methodFormatNumber(objectLegalDocument.exempt) }}</span></div>
									</div>
									<div class="row mb-1">
										<div class="col-6 text-end">Descuento: <span class="fw-bold">$</span></div>
										<div class="col-6 text-end"><span class="fw-bold me-2">{{
			methodFormatNumber(objectLegalDocument.discount) }}</span></div>
									</div>
									<div class="row mb-1">
										<div class="col-6 text-end">Recargo: <span class="fw-bold">$</span></div>
										<div class="col-6 text-end"><span class="fw-bold me-2">{{
			methodFormatNumber(objectLegalDocument.surcharge) }}</span></div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="row mb-1">
										<div class="col-6 text-end">Neto: <span class="fw-bold">$</span></div>
										<div class="col-6 text-end"><span class="fw-bold me-2">{{
			methodFormatNumber(objectLegalDocument.net) }}</span></div>
									</div>
									<div class="row mb-1">
										<div class="col-6 text-end">IVA: <span class="fw-bold">$</span></div>
										<div class="col-6 text-end"><span class="fw-bold me-2">{{
			methodFormatNumber(objectLegalDocument.tax) }}</span></div>
									</div>
									<div class="row mb-1">
										<div class="col-6 text-end">TOTAL: <span class="fw-bold">$</span></div>
										<div class="col-6 text-end"><span class="fw-bold me-2">{{
			methodFormatNumber(totalLegalDocument)
		}}</span></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pt-2 ps-2 pe-2" v-show="needReference == 1">
				<hr>
				<span class="col-md-4 col-form-span col-form-span-sm fw-bold"
					for="observationTextarea">Referencia</span>
				<div class="row">
					<div class="col-md-4">
						<select class="form-select form-select-sm mb-2">
							<option v-for="(rtld, i) in listReferenceTypeLegalDocuments" :key="i" :value="rtld.id">{{
			rtld.name }}</option>
						</select>
					</div>
					<div class="col-md-2">
						<input class="form-control form-control-sm mb-2" type="text" placeholder="Folio" />
					</div>
					<div class="col-md-2">
						<input class="form-control form-control-sm mb-2" type="date" />
					</div>
					<div class="col-md-2">
						<select class="form-select form-select-sm mb-2">
							<option>Anula Documento</option>
							<option>Corrige Texto</option>
							<option>Corrige Monto</option>
						</select>
					</div>
					<div class="col-md-2">
						<input class="form-control form-control-sm" type="text" placeholder="Motivo del documento" />
					</div>
				</div>
			</div>
			<div>
				<hr>
				<div class="text-end">
					<button class="btn btn-sm me-2" v-show="textBtnCancel != undefined" @click="cancelLocal">{{
						textBtnCancel
						}}</button>
					<button v-show="showBtnSaveLegalDocument" class="btn btn-primary btn-sm" ref="btnSaveLegalDocument"
						@click="saveLegalDocumentLocal">Guardar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import legalDocument from '@/mixins/legalDocuments'
import typeLegalDocument from '@/mixins/legalDocuments/typeLegalDocument.js'
import client from '@/mixins/clients'
import analizeSTR from '@/mixins/generals/analizeSTR.js'
import product from '@/mixins/products'

export default {
	name: 'LegalDocumentComponent',
	mixins: [mixin, axiosAction, errors, legalDocument, typeLegalDocument, client, analizeSTR, product],
	props: ['typeLegalDocumentId', 'showAddItemLegalDocument', 'btnSaveLegalDocument', 'id', 'textBtnCancel', 'disabledTypeLegalDocument', 'data'],
	data() {
		return {
			showIssuing: false,
			noReceivingEntityDocumentNumber: true,
			existReceivingEntityDocumentNumber: false,
			needReference: 0,
			newItemInput: '',
			uploadFile: false,
			inputCode0: false,
			fromShow: 'internal'
		}
	},
	components: {
	},
	computed: {
		disabledTypeLegalDocumentIdSelect() {
			return this.objectLegalDocument.id > 0 || this.searchingLegalDocuments || this.disabledTypeLegalDocument
		},
		disabledIssuing() {
			return true
		},
		disabledReceiving() {
			if (this.objectLegalDocument.type_legal_document_id == 0 || this.objectLegalDocument.id > 0)
				return true
			return false
		},
		disabledNewItemInput() {
			//return false
			return this.noReceivingEntityDocumentNumber || this.uploadFile || this.inputCode0 || this.objectLegalDocument.id > 0
		},
		listTypeLegalDocumentsFilter() {
			return this.listTypeLegalDocuments.filter(tld => { return (tld.weGenerate == 1) })
		},
		listReferenceTypeLegalDocuments() {
			let i = this.referenceTypeLegalDocument.map(rtld => parseInt(rtld.legalDocumentID)).indexOf(parseInt(this.objectLegalDocument.type_legal_document_id))
			let r = []
			if (i > -1) {
				let ind = -1
				r = this.listTypeLegalDocuments.filter(tld => {
					ind = this.referenceTypeLegalDocument[i].references.map(re => parseInt(re)).indexOf(parseInt(tld.id))
					return (ind > -1)
				})
			}
			return r
		},
		totalLegalDocument() {
			if (this.objectLegalDocument.legal_document_number == 0) {
				this.objectLegalDocument.net = 0
				this.objectLegalDocument.total = 0
				let percentageTax
				this.objectLegalDocument.details.reduce((a, b) => {
					let c = parseFloat(b.quantity) * parseFloat(b.price)
					if (this.objectLegalDocument.type_legal_document_id == 39) {
						this.objectLegalDocument.total = this.objectLegalDocument.total + c
						this.objectLegalDocument.net = this.objectLegalDocument.total / (1 + this.percentageTax)
					} else {
						percentageTax = (this.isFloat(b.percentage_tax)) ? b.percentage_tax : this.percentageTax
						this.objectLegalDocument.net = this.objectLegalDocument.net + c
						this.objectLegalDocument.total = this.objectLegalDocument.total + (c * (1 + percentageTax))
					}
					return a + c
				}, 0);
				this.objectLegalDocument.net = this.objectLegalDocument.net.toFixed(0)
				this.objectLegalDocument.total = this.objectLegalDocument.total.toFixed(0)
				this.objectLegalDocument.tax = this.objectLegalDocument.total - this.objectLegalDocument.net
			}
			return this.objectLegalDocument.total
		},
		showBtnSaveLegalDocument() {
			if (this.objectLegalDocument.id > 0)
				return false
			return (this.btnSaveLegalDocument != undefined) ? this.btnSaveLegalDocument : true
		},
		showAddItem() {
			if (this.objectLegalDocument.id > 0) {
				return false
			}
			let r = (this.showAddItemLegalDocument != undefined) ? this.showAddItemLegalDocument : true
			return r
		}
	},
	methods: {
		analizeFileLocal() {
			let ra = this.analizeFile(this.fileUpload)
			if (!ra.result) {
				console.log(ra.msg)
			} else {
				this.getFileUploadContentExcel(this.fileUpload)
			}
		},
		analizeReceivingEntityDocumentNumber() {
			if (this.checkDigitoRUT(this.objectLegalDocument.receiving_entity_document_number)) {
				this.objectLegalDocument.receiving_entity_document_number = this.formatRUT(this.objectLegalDocument.receiving_entity_document_number, '')
				this.getByReceivingEntityDocumentNumber(this.objectLegalDocument.receiving_entity_document_number)
			} else {
				if (this.objectLegalDocument.receiving_entity_document_number != '') {
					this.showMsgErrorHandling('Error de Dato', 'El RUT no es valido', 'warn', 5000, 'top right')
					this.$refs.receivingEntityDocumentNumberInput.focus()
				}
			}
		},
		analizeNewItemInput() {
			if (this.newItemInput == 0 && !this.inputCode0) {
				this.newItemInput = ''
				this.objectLegalDocumentDetail.code = 0
				this.objectLegalDocumentDetail.name = ''
				this.objectLegalDocumentDetail.price = ''
				this.objectLegalDocumentDetail.quantity = ''
				this.inputCode0 = true
				setTimeout(() => { this.$refs.newNameInput.focus() }, 50)
			} else {
				if (this.newItemInput.trim().length > 2) {
					this.newItemInput = this.newItemInput.trim()
					let r = this.analizeSTR(this.newItemInput)
					if (r.result == 'PRODUCT' || r.result == 'ISBN') {
						this.showProductLocal(r.data)
					}
				} else {
					this.showMsgErrorHandling('Dato insuficiente', 'Tiene que ingresar minimo de 3 caracteres para hacer una busqueda', 'warn', 5000, 'top right')
				}
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getByReceivingEntityDocumentNumberResult': this.getByReceivingEntityDocumentNumberResult(response); break;
				case 'getAllTypeLegalDocumentsResult': this.getAllTypeLegalDocumentsResult(response); break;
				case 'saveLegalDocumentResult': this.saveLegalDocumentResult(response); break;
				case 'sendToRegulatoryEntityResult': this.sendToRegulatoryEntityResult(response); break;
				case 'showClientResult': this.showClientResult(response); break;
				case 'showLocalDocumentResult': this.showLocalDocumentResult(response); break;
				case 'showProductResult': this.showProductResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		cancelLocal() {
			this.$emit('cancel')
		},
		clearDetailsLocal() {
			this.objectLegalDocument.details = []
			this.uploadFile = false
		},
		clearLegalDocumentComponentLocal() {
			this.setLegalDocumentComponentInit()
		},
		changeTypeLegalDocumentIdSelect() {
			this.objectLegalDocument.details = []
			setTimeout(() => { this.$refs.receivingEntityDocumentNumberInput.focus() }, 50)
			let i = this.listTypeLegalDocuments.map(tld => parseInt(tld.id)).indexOf(parseInt(this.objectLegalDocument.type_legal_document_id))
			if (i > -1) {
				this.needReference = this.listTypeLegalDocuments[i].needReference
			}
		},
		deleteItemobjectLegalDocumentDetailLocal(index) {
			let d = this.objectLegalDocument.details.splice(index, 1)
			if (this.objectLegalDocument.details.length == 0) {
				this.uploadFile = false
			}
			setTimeout(() => { this.$refs.newItemInput.focus() }, 50)
			return d
		},
		initModuleLocal() {
			this.filesUploadAccept = [
				{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: 'xlsx' }
			]
			this.fileUploadHeaderExcelJSON = [
				{ field: 'CODE', type: 'integer', index: -1, null: true },
				{ field: 'NAME', type: 'string', index: -1, null: true },
				{ field: 'PRICE', type: 'float|2', index: -1, null: false },
				{ field: 'QUANTITY', type: 'integer', index: -1, null: false },
				{ field: 'DISCOUNT_RATE', type: 'float|2', index: -1, null: true },
				{ field: 'DISCOUNT_AMOUNT', type: 'float|2', index: -1, null: true },
				{ field: 'SURCHARGE_RATE', type: 'float|2', index: -1, null: true },
				{ field: 'SURCHARGE_AMOUNT', type: 'float|2', index: -1, null: true },
				{ field: 'IS_NET', type: 'boleean', index: -1, null: true },
			]
			this.setLegalDocumentComponentInit()
			this.getAllTypeLegalDocuments()
		},
		getAllTypeLegalDocumentsResultLocal(response) {
			this.changeTypeLegalDocumentIdSelect()
			this.$refs.receivingEntityDocumentNumberInput.focus()
		},
		getByReceivingEntityDocumentNumberResultLocal(response) {
			this.existReceivingEntityDocumentNumber = false
			if (this.fromShow == 'internal') {
				if (response.data.success == true) {
					this.objectLegalDocument.receiving_entity_name = response.data.data.receiving_entity_name
					this.objectLegalDocument.receiving_entity_economic_activity = response.data.data.receiving_entity_economic_activity
					this.objectLegalDocument.receiving_entity_address_street = response.data.data.receiving_entity_address_street
					this.objectLegalDocument.receiving_entity_address_comuna = response.data.data.receiving_entity_address_comuna
					this.objectLegalDocument.receiving_entity_address_city = response.data.data.receiving_entity_address_city
					this.objectLegalDocument.contact = response.data.data.contact
					this.objectLegalDocument.phone = response.data.data.phone
					this.existReceivingEntityDocumentNumber = true
					this.setFocusObjectLegalDocument()
				} else {
					this.showClient(this.objectLegalDocument.receiving_entity_document_number)
					console.log('No existe un documento Electronico con este RUT')
				}
				this.noReceivingEntityDocumentNumber = false
			} else if (this.fromShow == 'external') {
				if (response.data.success == true) {
					this.objectLegalDocument.receiving_entity_name = response.data.data.receiving_entity_name
					this.objectLegalDocument.receiving_entity_economic_activity = (this.objectLegalDocument.receiving_entity_economic_activity == '') ? response.data.data.receiving_entity_economic_activity : this.objectLegalDocument.receiving_entity_economic_activity
					this.existReceivingEntityDocumentNumber = true
					this.setFocusObjectLegalDocument()
					this.noReceivingEntityDocumentNumber = false
				}
			}
		},
		getFileUploadContentExcelResultLocal() {
			let isValid = this.isFileValidJSON(this.fileUploadContentExcelJSON, this.fileUploadHeaderExcelJSON)
			if (isValid.result) {
				let rows = this.getJSONfromFileUpload(this.fileUploadContentExcelJSON, this.fileUploadHeaderExcelJSON)
				let percentageTax = 0.19
				rows.forEach(r => {
					if ((r.is_net == 0 || r.is_net == false || r.is_net == 'false' || r.is_net == null || r.is_net == undefined) && this.objectLegalDocument.type_legal_document_id != 39) {
						r.price_original = r.price
						percentageTax = (this.isFloat(r.percentage_tax)) ? r.percentage_tax : this.percentageTax
						r.price = parseFloat(r.price) / (1 + parseFloat(percentageTax))
						r.price = r.price.toFixed(this.fixed)
					}
					r.total = parseFloat(r.price) * parseFloat(r.quantity)
					r.total = r.total.toFixed(this.fixed)
					this.objectLegalDocument.details.push({
						code: r.code,
						name: r.name,
						price: r.price,
						price_original: r.price_original,
						quantity: r.quantity,
						total: r.total
					})
				})
				this.uploadFile = true
			} else {
				let msg = ''
				isValid.msg.forEach(m => {
					msg += '<li>' + m + '</li>'
				})
				this.showMsgErrorHandling('Sin Cargar', 'El archivo no se puedo cargar lo siguiente <ul>' + msg + '</ul>', 'warn', 5000, 'top right')
				this.uploadFile = false
			}
		},
		keydownReceivingEntityDocumentNumber(e) {
			if (e.keyCode != 13 && e.keyCode != 9 && e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40) {
				this.noReceivingEntityDocumentNumber = true
				this.objectLegalDocument.receiving_entity_name = ''
				this.objectLegalDocument.receiving_entity_economic_activity = ''
				this.objectLegalDocument.receiving_entity_address_street = ''
				this.objectLegalDocument.receiving_entity_address_comuna = ''
				this.objectLegalDocument.receiving_entity_address_city = ''
				this.objectLegalDocument.contact = ''
				this.objectLegalDocument.phone = ''
			} else {
			}
		},
		keypressEnterNewItemInput() {
			if (this.objectLegalDocumentDetail.name != '' &&
				this.objectLegalDocumentDetail.quantity != '' &&
				this.objectLegalDocumentDetail.price != '') {
				if (this.objectLegalDocumentDetail.name.trim().length < 4) {
					this.showMsgErrorHandling('Error de Dato', 'La descripción debe ser superior a 3 caracteres', 'warn', 5000, 'top right')
					this.$refs.newNameInput.focus()
				} else if (isNaN(this.objectLegalDocumentDetail.quantity)) {
					this.showMsgErrorHandling('Error de Dato', 'La cantidad debe ser numerica', 'warn', 5000, 'top right')
					this.$refs.newQuantityInput.focus()
				} else if (isNaN(this.objectLegalDocumentDetail.price)) {
					this.showMsgErrorHandling('Error de Dato', 'El precio debe ser numerico', 'warn', 5000, 'top right')
					this.$refs.newPriceInput.focus()
				} else {
					if (this.objectLegalDocumentDetail.quantity <= 0) {
						this.showMsgErrorHandling('Error de Dato', 'La cantidad debe ser mayor a 0', 'warn', 5000, 'top right')
						this.$refs.newQuantityInput.focus()
					} else if (this.objectLegalDocumentDetail.price <= 0) {
						this.showMsgErrorHandling('Error de Dato', 'El precio debe ser mayor a 0', 'warn', 5000, 'top right')
						this.$refs.newPriceInput.focus()
					} else {
						this.objectLegalDocument.details.push({
							id: this.objectLegalDocumentDetail.id,
							legal_document_id: this.objectLegalDocumentDetail.legal_document_id,
							type_code: this.objectLegalDocumentDetail.type_code,
							code: this.objectLegalDocumentDetail.code,
							name: this.objectLegalDocumentDetail.name,
							price: this.objectLegalDocumentDetail.price,
							quantity: this.objectLegalDocumentDetail.quantity,
							total: this.objectLegalDocumentDetail.price * this.objectLegalDocumentDetail.quantity,
							discount_rate: this.objectLegalDocumentDetail.discount_rate,
							discount_amount: this.objectLegalDocumentDetail.discount_amount,
							surcharge_rate: this.objectLegalDocumentDetail.surcharge_rate,
							surcharge_amount: this.objectLegalDocumentDetail.surcharge_amount,
							order_: this.objectLegalDocumentDetail.order_,
							created_at: this.objectLegalDocumentDetail.created_at,
							updated_at: this.objectLegalDocumentDetail.updated_at,
						})
						this.inputCode0 = false
						this.objectLegalDocumentDetail.code = 0
						this.objectLegalDocumentDetail.name = ''
						this.objectLegalDocumentDetail.price = ''
						this.objectLegalDocumentDetail.quantity = ''
						setTimeout(() => { this.$refs.newItemInput.focus() }, 50)
					}
				}
			} else {
				if (this.objectLegalDocumentDetail.name == '') {
					console.log('Falta Descripción')
					this.$refs.newNameInput.focus()
				} else if (this.objectLegalDocumentDetail.quantity == '') {
					console.log('Falta Cantidad')
					this.$refs.newQuantityInput.focus()
				} else if (this.objectLegalDocumentDetail.price == '') {
					console.log('Falta precio')
					this.$refs.newPriceInput.focus()
				}
			}
		},
		onChangeInputFileUploadLocal() {
			this.onChangeInputFileUpload('btnLegalDocumentDetailFile')
		},
		onChangeInputFileUploadResultLocal() {
			this.analizeFileLocal()
		},
		onDropHandlerLocal(e) {
			this.onDropHandler(e)
		},
		onDropHandlerResultLocal() {
			this.analizeFileLocal()
		},
		saveLegalDocumentLocal() {
			this.saveLegalDocument(this.objectLegalDocument)
		},
		saveLegalDocumentResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('saveLegalDocumentResult', response.data.data)
				this.sendToRegulatoryEntityLocal()
			}
		},
		sendToRegulatoryEntityLocal() {
			this.sendToRegulatoryEntity(this.objectLegalDocument.id)
		},
		sendToRegulatoryEntityResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('sendToRegulatoryEntityResult', response)
			} else {
				this.showMsgErrorHandling('ERROR', response.data.message, 'warn', 5000, 'top right')
			}
		},
		setFocusObjectLegalDocument() {
			if (this.objectLegalDocument.receiving_entity_economic_activity == '') {
				this.$refs.receivingEntityEconomicActivityInput.focus()
			} else if (this.objectLegalDocument.receiving_entity_address_street == '') {
				this.$refs.receivingEntityAddressStreetInput.focus()
			} else if (this.objectLegalDocument.receiving_entity_address_comuna == '') {
				this.$refs.receivingEntityAddressComunaInput.focus()
			} else if (this.objectLegalDocument.receiving_entity_address_city == '') {
				this.$refs.receivingEntityAddressCityInput.focus()
			}
		},
		setLegalDocumentComponentInit() {
			this.clearObjectLegalDocument()
			if (this.id != undefined && this.id != '' && this.id > 0) {
				this.showLocalDocumentLocal(this.id)
			} else {
				this.objectLegalDocument.type_legal_document_id = (this.typeLegalDocumentId != undefined && this.typeLegalDocumentId != '') ? this.typeLegalDocumentId : this.objectLegalDocument.type_legal_document_id
				if (this.objectLegalDocument.type_legal_document_id > 0) {
					this.$refs.receivingEntityDocumentNumberInput.focus()
				} else {
					this.$refs.typeLegalDocumentIdSelect.focus()
				}
			}
		},
		showClientResultLocal(response) {
			if (response.data.success == true) {
				this.objectLegalDocument.receiving_entity_name = response.data.data.text
				this.objectLegalDocument.receiving_entity_economic_activity = ''
				this.objectLegalDocument.receiving_entity_address_street = ''
				this.objectLegalDocument.receiving_entity_address_comuna = ''
				this.objectLegalDocument.receiving_entity_address_city = ''
				if (response.data.data.address.length > 0) {
					let address = response.data.data.address[response.data.data.address.length - 1]
					this.objectLegalDocument.receiving_entity_address_street = address.street
					this.objectLegalDocument.receiving_entity_address_comuna = address.comuna.name
					this.objectLegalDocument.receiving_entity_address_city = address.comuna.name
				}
				this.objectLegalDocument.contact = response.data.data.text
				this.objectLegalDocument.phone = response.data.data.phone
				if (this.objectLegalDocument.receiving_entity_economic_activity == '') {
					this.$refs.receivingEntityEconomicActivityInput.focus()
				} else if (this.objectLegalDocument.receiving_entity_address_street == '') {
					this.$refs.receivingEntityAddressStreetInput.focus()
				} else if (this.objectLegalDocument.receiving_entity_address_comuna == '') {
					this.$refs.receivingEntityAddressComunaInput.focus()
				} else if (this.objectLegalDocument.receiving_entity_address_city == '') {
					this.$refs.receivingEntityAddressCityInput.focus()
				}
			} else {
				console.log('No existe un cliente con este RUT')
			}
		},
		showLocalDocumentLocal(id) {
			this.showLocalDocument(id)
		},
		showLocalDocumentResultLocal(response) {
		},
		showProductLocal(id) {
			this.showProduct(id)
		},
		showProductResultLocal(response) {
			if (response.data.success == true) {
				this.newItemInput = ''
				this.inputCode0 = true
				this.objectLegalDocumentDetail.code = response.data.data.id
				this.objectLegalDocumentDetail.name = response.data.data.name
				setTimeout(() => { this.$refs.newQuantityInput.focus() }, 50)
			} else {
				this.showMsgErrorHandling('Articulo no Encontrado', 'No se pudo encontrar el articulo', 'warn', 5000, 'top right')
			}
		},
		toCompleteLegalDocumentLocal() {
			this.fromShow = 'external'
			this.clearLegalDocumentComponentLocal()
			this.setObjectLegalDocument(this.data)
			this.objectLegalDocument.type_legal_document_id = this.typeLegalDocumentId
			this.analizeReceivingEntityDocumentNumber()
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>