<template>
	<div id="app">
		<router-view/>
		<notifications :position="getNotifyPosition"/>
		<vue-confirm-dialog></vue-confirm-dialog>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		computed: {
			...mapGetters(['getNotifyPosition'])
		},
	}	
</script>