<template>
	<div id="logout">
		<div class="row flex-center min-vh-100 py-6 text-center">
			<div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5"><a class="d-flex flex-center mb-4" href="/index.html"><img class="me-2" src="/assets/img/icons/spot-illustrations/falcon.png" alt="" width="58" /><span class="font-sans-serif fw-bolder fs-5 d-inline-block">{{getNameApp}}</span></a>
				<div class="card">
				<div class="card-body p-4 p-sm-5">
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Se está cerrardo su sesión . . .</p>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from './mixins'
export default {
	name: 'Logout',
	mixins: [mixin],
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
	},
	mounted () {
		console.log('Cerrando sesión 	. . .')
		localStorage.removeItem("token")
		setTimeout(()=>{
			console.clear()
			this.$router.push('/login')
		}, 3000)
	},
}	
</script>

<style lang="scss">
</style>
