<template>
	<div id="users">
		<user-list-component></user-list-component>
	</div>
</template>

<script>
import UserListComponent from '@/components/UserListComponent.vue'

export default {
	name: 'Users',
	components: {
		'user-list-component': UserListComponent,
	},
	methods: {
		initModuleLocal() {
		},
	},
	mounted () {
		this.initModuleLocal()
	},
}	
</script>

<style lang="scss">
</style>
