<template>
	<div id="assignFreightOrderComponent">
		<div class="p-4">
			<div v-show="visibleInputFile">
				<div>
					<table class="table table-sm fs--1">
						<thead>
							<tr>
								<th>Enviar a</th>
								<th>Dirección</th>
								<th>Peso</th>
								<th>Bultos</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr class="border-start-2 border-end-1 hover-actions-trigger"
								v-for="(s, i) in listShippingsFilter" :key="i">
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<a class="text-primary pointer">
										<strong>#{{ s.id }}</strong>
									</a>
									enviar a
									<strong>{{ s.sendto }}</strong>
									<br />
									<small class="text-muted">
										<i class="far fa-envelope text-secondary"></i>
										<a href="mailto:ricky@example.com" class="text-muted ms-1">{{ s.email }}</a>
										|<i class="far fa-address-card ms-1 text-secondary"></i>
										<span class="ms-1">{{ s.document_number }}</span>
										|<i class="fas fa-phone-alt ms-1 text-secondary"></i>
										<span class="ms-1">{{ s.phone }}</span>
									</small>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<div>
										{{ s.address }}, {{ s.comuna }}
										<div v-if="s.transport != null">
											<span :class="'text-' + s.transport.color">
												<i class="fas fa-truck-moving"></i> {{ s.transport.name }}
												<span v-show="s.paid_shipping == 0"> -
													<span class="badge bg-dark dark__bg-dark">Por Pagar</span>
												</span>
											</span>
										</div>
										<div v-else>
											{{ s.transport }}
										</div>
									</div>
								</td>
								<td class="align-middle fw-medium border-bottom border-muted text-center">
									{{ s.packages }}
								</td>
								<td class="align-middle fw-medium border-bottom border-muted text-center">
									{{ s.weight }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div v-show="visibleInputFile">
				<div class="dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message"
					@click="clickBtn('btnLegalDocumentDetailFile')" @dragover.prevent @drop.prevent>
					<input type="file" name="file" class="invisible" ref="btnLegalDocumentDetailFile"
						@change="onChangeInputFileUploadLocal" id="btnLegalDocumentDetailFile" v-show="false" />
					<div class="text-center" @drop="onDropHandlerLocal">
						<img class="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />Suba
						un
						archivo Excel con las ordenes de fletes
						<p class="mb-0 fs--1 text-400">Suba un archivo (xls, xlsx, cvs)<br />con un tamaño
							máximo de 400 KB</p>
					</div>
				</div>
			</div>
			<div v-show="listAssignFreightOrderForFile.length > 0">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item">
						<a class="nav-link active" id="suggestions-tab" data-bs-toggle="tab" href="#tab-suggestions"
							role="tab" aria-controls="tab-suggestions" aria-selected="true">
							Sugerencias ({{ listAssignFreightOrderForFileSuggestionsFilter.length }})
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" id="assigned-tab" data-bs-toggle="tab" href="#tab-assigned" role="tab"
							aria-controls="tab-assigned" aria-selected="false">
							Asignadas ({{ listAssignFreightOrderForFileAssignedFilter.length }})
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" id="exists-tab" data-bs-toggle="tab" href="#tab-exists" role="tab"
							aria-controls="tab-exists" aria-selected="false">
							Existentes ({{ listAssignFreightOrderForFileExistsFilter.length }})
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" id="not-found-tab" data-bs-toggle="tab" href="#tab-not-found" role="tab"
							aria-controls="tab-not-found" aria-selected="false">
							Sin Resolver ({{ listAssignFreightOrderForFileNotFoundFilter.length }})
						</a>
					</li>
				</ul>
				<div class="tab-content border border-top-0 p-3" id="myTabContent">
					<div class="tab-pane fade show active" id="tab-suggestions" role="tabpanel"
						aria-labelledby="suggestions-tab">
						<table class="table table-sm fs--1 table-striped table-hover">
							<thead>
								<tr>
									<th class="text-center">OF</th>
									<th>Destinatario</th>
									<th class="text-center">Datos</th>
									<th class="text-center">Sugerencias</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(a, i) in listAssignFreightOrderForFileSuggestionsFilter" :key="i">
									<td class="align-middle white-space-nowrap text-center">
										{{ a.orden }}
										<br>
										<small>{{ a.emision }}</small>
									</td>
									<td class="align-middle white-space-nowrap">
										{{ a.destinatario }}
										<br>
										<small>{{ a.destino }}</small>
									</td>
									<td class="align-middle white-space-nowrap">
										<div>
											<small>Bultos {{ a.bultos }} - Peso {{ a.peso_kg }}</small>
										</div>
									</td>
									<td class="align-middle white-space-nowrap">
										<table width="100%">
											<tr v-for="(s, x) in a.suggestions" :key="x" class="border border-success">
												<td class="align-middle white-space-nowrap">
													<a class="text-primary pointer"
														:title="'Vincular con el EN Nº' + s.id">
														<strong>#{{ s.id }}</strong>
													</a>
													enviar a
													<strong>{{ s.sendto }}</strong>
													<br>
													<small class="text-muted">
														{{ s.address }}, {{ s.comuna }}
													</small>
												</td>
												<td class="text-end">
													<small>
														<i class="fas fa-boxes text-info"></i> Bultos: {{ s.packages }}
													</small>
													<br>
													<small>
														<i class="fas fa-weight-hanging text-warning"></i> Peso: {{
				s.weight }}
													</small>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tab-pane fade" id="tab-assigned" role="tabpanel" aria-labelledby="assigned-tab">
						<table class="table table-sm fs--1">
							<thead>
								<tr>
									<th class="text-center">OF</th>
									<th class="text-center">Fecha</th>
									<th>Destinatario</th>
									<th>Destino</th>
									<th class="text-center">Nº Envío</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(a, i) in listAssignFreightOrderForFileAssignedFilter" :key="i">
									<td class="text-center">{{ a.orden }}</td>
									<td class="text-center">{{ a.emision }}</td>
									<td>{{ a.destinatario }}</td>
									<td>{{ a.destino }}</td>
									<td class="text-center">{{ a.shipping_id }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tab-pane fade" id="tab-exists" role="tabpanel" aria-labelledby="exists-tab">
						<table class="table table-sm fs--1">
							<thead>
								<tr>
									<th class="text-center">OF</th>
									<th class="text-center">Fecha</th>
									<th>Destinatario</th>
									<th>Destino</th>
									<th class="text-center">Nº Envío</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(a, i) in listAssignFreightOrderForFileExistsFilter" :key="i">
									<td class="text-center">{{ a.orden }}</td>
									<td class="text-center">{{ a.emision }}</td>
									<td>{{ a.destinatario }}</td>
									<td>{{ a.destino }}</td>
									<td class="text-center">{{ a.shipping_id }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tab-pane fade" id="tab-not-found" role="tabpanel" aria-labelledby="not-found-tab">
						<table class="table table-sm fs--1">
							<thead>
								<tr>
									<th class="text-center">OF</th>
									<th class="text-center">Fecha</th>
									<th>Documento</th>
									<th>Destino</th>
									<th class="text-center">Nº Envío</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(a, i) in listAssignFreightOrderForFileNotFoundFilter" :key="i">
									<td class="text-center">{{ a.orden }}</td>
									<td class="text-center">{{ a.emision }}</td>
									<td>{{ a.numero_documento }}</td>
									<td>{{ a.destino }}</td>
									<td class="text-center">{{ a.destinatario }}</td>
									<td class="text-center">{{ a.bultos }}</td>
									<td class="text-center">{{ a.peso_kg }}</td>
									<td class="text-center">{{ a.tipo_pago }}</td>
									<td class="text-center">{{ a.direccion }}</td>
									<td class="text-center">{{ a.estado }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

export default {
	name: 'AssignFreightOrderComponent',
	mixins: [mixin, axiosAction, errors],
	props: [],
	data() {
		return {
			uploadFile: false,
			listAssignFreightOrderForFile: []
		}
	},
	components: {
	},
	computed: {
		listAssignFreightOrderForFileNotFoundFilter() {
			return this.listAssignFreightOrderForFile.filter(a => {
				return a.updated == false && a.exists == false && a.suggestions.length == 0
			})
		},
		listAssignFreightOrderForFileAssignedFilter() {
			return this.listAssignFreightOrderForFile.filter(a => {
				return a.updated == true
			})
		},
		listAssignFreightOrderForFileExistsFilter() {
			return this.listAssignFreightOrderForFile.filter(a => {
				return a.exists == true
			})
		},
		listAssignFreightOrderForFileSuggestionsFilter() {
			return this.listAssignFreightOrderForFile.filter(a => {
				return a.suggestions.length > 0 && a.updated == false && a.exists == false
			})
		},
		listShippingsFilter() {
			return []
		},
		visibleInputFile() {
			if (this.listAssignFreightOrderForFile.length > 0) {
				return false
			}
			return true
		}
	},
	methods: {
		analizeFileLocal() {
			let ra = this.analizeFile(this.fileUpload)
			if (!ra.result) {
				console.log(ra.msg)
			} else {
				this.getFileUploadContentExcel(this.fileUpload)
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getFileUploadContentExcelResultLocal() {
			let isValid = this.isFileValidJSON(this.fileUploadContentExcelJSON, this.fileUploadHeaderExcelJSON)
			if (isValid.result) {
				let rows = this.getJSONfromFileUpload(this.fileUploadContentExcelJSON, this.fileUploadHeaderExcelJSON)
				let param = []
				rows.forEach((r, i) => {
					param.push(r)
				})
				console.log(param)
				this.uploadFile = true
			} else {
				let msg = ''
				isValid.msg.forEach(m => {
					msg += '<li>' + m + '</li>'
				})
				this.showMsgErrorHandling('Sin Cargar', 'El archivo no se puedo cargar lo siguiente <ul>' + msg + '</ul>', 'warn', 5000, 'top right')
				this.uploadFile = false
			}
		},
		onChangeInputFileUploadLocal() {
			this.onChangeInputFileUpload('btnLegalDocumentDetailFile')
		},
		onChangeInputFileUploadResultLocal() {
			this.analizeFileLocal()
		},
		onDropHandlerLocal(e) {
			this.onDropHandler(e)
		},
		onDropHandlerResultLocal() {
			this.analizeFileLocal()
		},
		initModuleLocal() {
			this.filesUploadAccept = [
				{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: 'xlsx' }
			]
			this.fileUploadHeaderExcelJSON = [
				{ field: 'orden', type: 'integer', index: -1, null: true },
				{ field: 'emisión', type: 'string', index: -1, null: true },
				{ field: 'numero_documento', type: 'integer', index: -1, null: true },
				{ field: 'destino', type: 'string', index: -1, null: true },
				{ field: 'destinatario', type: 'string', index: -1, null: true },
				{ field: 'bultos', type: 'integer', index: -1, null: true },
				{ field: 'peso_kg', type: 'string', index: -1, null: true },
				{ field: 'tipo_pago', type: 'string', index: -1, null: true },
				{ field: 'dirección', type: 'string', index: -1, null: true },
				{ field: 'estado', type: 'string', index: -1, null: true },
				{ field: 'recibido', type: 'string', index: -1, null: true },
				{ field: 'rut_recibido', type: 'string', index: -1, null: true },
			]
			this.setAssignFreightOrderComponentInit()
		},
		setAssignFreightOrderComponentInit() {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>