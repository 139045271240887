<template>
	<div id="team">
		<div v-if="!loadingTeams">
			<div v-if="objectTeam.status_id == 43" class="alert alert-info" role="alert">
				Esta campaña está cerrada, por lo que solo es de consulta
			</div>
			<div class="row g-3 mb-3">
				<div class="col-xl-5 col-lg-12">
					<div class="row g-3 mb-3">
						<div class="col-12">
							<div class="card bg-transparent-50 overflow-hidden">
								<div class="card-header position-relative">
									<div class="position-relative z-index-2">
										<div>
											<span class="float-end text-primary pointer"
												@click="path('/team/' + objectTeam.id + '/admin')"
												title="Administración">
												<li class="far fa-folder-open pointer"></li> Admin
											</span>
											<h3 class="text-primary mb-1">{{ objectTeam.name }}</h3>
											<p><b>{{ objectTeam.entity.synthetic }}</b> - {{ objectTeam.client.name }}
												{{ objectTeam.client.surname }}</p>
										</div>
									</div>
									<div v-if="objectTeam.show_remuneration == 1">
										<div>
											Comisión(14%): <b>{{ methodFormatNumber(seeSummaryData('total_commission'))
												}}</b>
											- Viatico(2.5%): <b>{{ methodFormatNumber(seeSummaryData('total_viatic'))
												}}</b>
										</div>
									</div>
								</div>
								<div class="card-body p-0">
									<ul class="mb-0 list-unstyled">
										<li
											class="alert mb-0 rounded-0 py-3 px-card alert-info border-x-0 border-top-0">
											<div class="row flex-between-center">
												<div class="col">
													<div class="d-flex">
														<div class="fas fa-circle mt-1 fs--2"></div>
														<p class="fs--1 ps-2 mb-0"><strong>{{ qOrders }} pedidos</strong>
															cargado a esta campaña</p>
													</div>
												</div>
												<div class="col-auto d-flex align-items-center">
													<a class="alert-link fs--1 fw-medium" href="#orders1"
														:class="(qOrders == 0) ? 'text-500' : ''"
														@click="setTypeOrderSelected(2)">
														Ver pedidos
														<i class="fas fa-chevron-right ms-1 fs--2"></i>
													</a>
												</div>
											</div>
										</li>
										<li
											class="alert mb-0 rounded-0 py-3 px-card greetings-item border-top  border-0">
											<div class="row flex-between-center">
												<div class="col">
													<div class="d-flex">
														<div class="fas fa-circle mt-1 fs--2 text-primary"></div>
														<p class="fs--1 ps-2 mb-0"><strong>{{ qRecept }}
																recepciones</strong> aceptadas</p>
													</div>
												</div>
												<div class="col-auto d-flex align-items-center">
													<a class="alert-link fs--1 fw-medium" href="#orders2"
														:class="(qRecept == 0) ? 'text-500' : ''"
														@click="setTypeOrderSelected(5)">
														Ver recepciones
														<i class="fas fa-chevron-right ms-1 fs--2"></i>
													</a>
												</div>
											</div>
										</li>
										<li
											class="alert mb-0 rounded-0 py-3 px-card greetings-item border-top border-x-0 border-top-0">
											<div class="row flex-between-center">
												<div class="col">
													<div class="d-flex">
														<div class="fas fa-circle mt-1 fs--2 text-primary"></div>
														<p class="fs--1 ps-2 mb-0"><strong>{{ qTransfer }}
																transferencias</strong> realizadas a otra campaña</p>
													</div>
												</div>
												<div class="col-auto d-flex align-items-center">
													<a class="alert-link fs--1 fw-medium" href="#orders3"
														:class="(qTransfer == 0) ? 'text-500' : ''"
														@click="setTypeOrderSelected(4)">
														Ver transferencias
														<i class="fas fa-chevron-right ms-1 fs--2"></i>
													</a>
												</div>
											</div>
										</li>
										<li
											class="alert mb-0 rounded-0 py-3 px-card greetings-item border-top border-x-0 border-top-0">
											<div class="row flex-between-center">
												<div class="col">
													<div class="d-flex">
														<div class="fas fa-circle mt-1 fs--2 text-primary"></div>
														<p class="fs--1 ps-2 mb-0"><strong>{{ qReturn }}
																devoluciones</strong> realizadas</p>
													</div>
												</div>
												<div class="col-auto d-flex align-items-center">
													<a class="alert-link fs--1 fw-medium" href="#orders4"
														:class="(qReturn == 0) ? 'text-500' : ''"
														@click="setTypeOrderSelected(6)">
														Ver devoluciones
														<i class="fas fa-chevron-right ms-1 fs--2"></i>
													</a>
												</div>
											</div>
										</li>
										<li
											class="alert mb-0 rounded-0 py-3 px-card greetings-item border-top border-x-0 border-top-0">
											<div class="row flex-between-center">
												<div class="col">
													<div class="d-flex">
														<div class="fas fa-circle mt-1 fs--2 text-primary"></div>
														<p class="fs--1 ps-2 mb-0"><strong>{{ qInvoicesThird }} Facturas a
																Terceros</strong> realizadas</p>
													</div>
												</div>
												<div class="col-auto d-flex align-items-center">
													<a class="alert-link fs--1 fw-medium" href="#orders7"
														:class="(qInvoicesThird == 0) ? 'text-500' : ''"
														@click="setTypeOrderSelected(7)">
														Ver facturas
														<i class="fas fa-chevron-right ms-1 fs--2"></i>
													</a>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="card mb-3">
						<div class="card-header">
							<div class="d-flex align-items-center">
								<h5 class="fs-0 fw-normal text-800 mb-0">Gastos/Cargos de Campaña</h5>
							</div>
						</div>
						<div class="card-body p-0">
							<div class="scrollbar pt-0 px-card ask-analytics">
								<table class="table table-dashboard mb-0 table-borderless fs--1 border-200">
									<tbody>
										<tr class="border-bottom border-200"
											v-for="(item, index) in objectTeam.expenses" :key="index"
											:class="(item.amount < 0) ? 'text-danger' : ''">
											<td>
												<div class="d-flex align-items-center position-relative">
													<div class="flex-1 ms-3">
														<h6 class="mb-1 fw-semi-bold text-nowrap">
															<a class="text-900 stretched-link"><span
																	:class="(item.amount < 0) ? 'text-danger' : ''">{{ item.description }}</span></a>
														</h6>
														<p class="fw-semi-bold mb-0 text-500"><span
																:class="(item.amount < 0) ? 'text-danger' : ''">{{ item.type_expense.name }}</span>
														</p>
													</div>
												</div>
											</td>
											<td class="align-middle text-center fw-semi-bold">{{ item.status.name }}</td>
											<td class="align-middle text-end fw-semi-bold">
												${{ methodFormatNumber(item.amount) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="card-footer bg-light py-2" v-if="objectTeam.status_id == 42 && actionSecurity(5)">
							<div class="row flex-between-center">
								<div class="col-auto">
								</div>
								<div class="col-auto">
									<a class="btn btn-sm btn-falcon-default" @click="showExpensesTransferLocal()">Nuevo
										Gasto</a>
								</div>
							</div>
						</div>
					</div>
					<div class="card" id="orders7" :class="(typeOrderSelected == 7) ? 'border border-info' : ''"
						v-show="false">
						<div class="card-header">
							<div class="d-flex align-items-center">
								<h5 class="fs-0 fw-normal text-800 mb-0">Facturas a Terceros</h5>
							</div>
						</div>
						<div class="card-body p-0">
							<div class="scrollbar pt-0 px-card ask-analytics">
								<table class="table table-dashboard mb-0 table-borderless fs--1 border-200">
									<tbody>
										<tr class="btn-reveal-trigger" v-for="(item, index) in objectTeam.orders"
											:key="index"
											v-if="item.type_order_id == 7 && item.order_observations.length > 0">
											<div class="d-flex align-items-center position-relative">
												<div class="flex-1 ms-3">
													<h6 class="mb-1 fw-semi-bold text-nowrap">
														<span class="text-900 stretched-link">Facturar a
															{{ _split(_split(item.order_observations[0].observation,
			':')[1], '|')[0] }}</span>
													</h6>
													<p class="fw-semi-bold mb-0 text-500">
														<router-link :to="'/orderTeam/' + item.id" class="text-primary">
															<strong>#{{ item.id }}</strong>
														</router-link>
													</p>
												</div>
											</div>

											<td class="align-middle text-end fw-semi-bold">
												${{ methodFormatNumber(item.total) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="card-footer bg-light py-2">
							<div class="row flex-between-center">
								<div class="col-auto">
								</div>
								<div class="col-auto">
									<a class="btn btn-sm btn-falcon-default" @click="showOrderTransferLocal(7)">Nueva
										Factura a tercero</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-7 col-lg-12">
					<div class="row g-3 mb-3">
						<div class="col-md-6">
							<div class="card h-md-100 ecommerce-card-min-width">
								<div class="bg-holder bg-card"
									style="background-image:url(/assets/img/icons/spot-illustrations/corner-3.png);">
								</div>
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2 d-flex align-items-center">
										Campaña vs Remesas
									</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row align-items-end h-100 mb-n1">
										<div class="col-6 pe-md-0 pe-lg-3">
											<div class="row g-0">
												<div class="col-5">
													<h6 class="text-600">Campaña:</h6>
												</div>
												<div class="col-7">
													<h6 class="text-800 text-end">${{ methodFormatNumber(this.debt) }}
													</h6>
												</div>
											</div>
											<div class="row g-0">
												<div class="col-5">
													<h6 class="mb-0 text-600">Remesas:</h6>
												</div>
												<div class="col-7 text-end">
													<h6 class="mb-0 text-800">${{
			methodFormatNumber(seeSummaryData('total_payments')) }}</h6>
												</div>
											</div>
										</div>
										<div class="col-6">
											<div class="lms-half-doughnut mt-n6 ms-auto">
												<doughnut-chart :options="chartOptions"
													:chartData="chartDataTeamVSTotalPaymentsFilter">
												</doughnut-chart>
												<p class="mb-0 mt-n7 text-center fs-2 fw-medium">{{
			percentageTeamVSTotalPayments }}%</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card h-md-100 ecommerce-card-min-width">
								<div class="bg-holder bg-card"
									style="background-image:url(/assets/img/icons/spot-illustrations/corner-2.png);">
								</div>
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2 d-flex align-items-center">
										Libros Asignados vs Remesas
									</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row align-items-end h-100 mb-n1">
										<div class="col-6 pe-md-0 pe-lg-3">
											<div class="row g-0">
												<div class="col-5">
													<h6 class="text-600">Asignado:</h6>
												</div>
												<div class="col-7">
													<h6 class="text-800 text-end">${{
			methodFormatNumber(totalProductsAssigned) }}</h6>
												</div>
											</div>
											<div class="row g-0">
												<div class="col-5">
													<h6 class="mb-0 text-600">Remesas:</h6>
												</div>
												<div class="col-7 text-end">
													<h6 class="mb-0 text-800">${{
			methodFormatNumber(seeSummaryData('total_payments')) }}</h6>
												</div>
											</div>
										</div>
										<div class="col-6">
											<div class="lms-half-doughnut mt-n6 ms-auto">
												<doughnut-chart id="my-chart-id" :options="chartOptions"
													:chartData="chartDataProductsAssignedVSTotalPayments">
												</doughnut-chart>
												<p class="mb-0 mt-n7 text-center fs-2 fw-medium">{{
			percentageProductAssignedVSTotalPayments }}%</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card py-3 mb-3">
						<div class="card-body py-3">
							<div class="row g-0">
								<div class="col-6 col-md-4 border-200 border-bottom border-end pb-4">
									<h6 class="pb-1 text-700">Total Pedidos </h6>
									<p class="font-sans-serif lh-1 mb-1 fs-2">${{
			methodFormatNumber(seeSummaryData('total_orders')) }}</p>
									<div class="text-end" title="Bonificable" v-if="sumaListProductsPriceListBonus > 0">
										<i class="me-1">
											Bonificable:
											<small class="text-success"
												v-if="(!searchingProductPriceListNotBonus && !searchingProductPriceListNotFreight && !searchingProductPriceListNotTithe)">{{
			methodFormatNumber(seeSummaryData('bonusable')) }}</small>
											<small v-else>calculando...</small>
										</i>
									</div>
								</div>
								<div
									class="col-6 col-md-4 border-200 border-md-200 border-bottom border-md-end pb-4 ps-3">
									<h6 class="pb-1 text-700">Diezmos</h6>
									<p class="font-sans-serif lh-1 mb-1 fs-2">${{
			methodFormatNumber(seeSummaryData('tithe')) }}</p>
								</div>
								<div
									class="col-6 col-md-4 border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3">
									<h6 class="pb-1 text-700">Abonos</h6>
									<div>
										<p class="font-sans-serif lh-1 mb-1 fs-2">${{
			methodFormatNumber(seeSummaryData('total_payments')) }}</p>
										<div class="text-end text-danger" title="Sin Confirmar"
											v-if="sumConsignmentsTotalNotConfirm > 0"><i>*
												(<small>{{ methodFormatNumber(sumConsignmentsTotalNotConfirm) }}</small>)</i>
										</div>
									</div>
								</div>
								<div
									class="col-6 col-md-4 border-200 border-md-200 border-bottom border-md-bottom-0 border-md-end pt-4 pb-4 ps-3 ps-md-0">
									<h6 class="pb-1 text-700">Gastos </h6>
									<p class="font-sans-serif lh-1 mb-1 fs-2">${{
			methodFormatNumber(seeSummaryData('total_expenses')) }}</p>
								</div>
								<div
									class="col-6 col-md-4 border-200 border-md-bottom-0 border-end pt-4 pb-md-0 ps-md-3">
									<h6 class="pb-1 text-700">Cobro comisión por medio de pagos</h6>
									<p class="font-sans-serif lh-1 mb-1 fs-2">${{
			methodFormatNumber(seeSummaryData('charge_payments')) }}</p>
								</div>
								<div class="col-6 col-md-4 pb-0 pt-4 ps-3">
									<h6 class="pb-1 text-700">
										Multa
										<small class="text-danger" v-if="penaltyTotal > 0">{{
			methodFormatNumber(seeSummaryData('penalty_devolution')) }}</small>
										<div class="float-end form-check form-switch" title="No considerar"
											v-if="penaltyTotal > 0 && objectTeam.status_id == 42">
											<input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox"
												v-model="penaltyConsider" />
										</div>
									</h6>
									<p class="font-sans-serif lh-1 mb-1 fs-2">
										<span v-if="penaltyConsider">{{
			methodFormatNumber(seeSummaryData('penalty_devolution')) }}</span>
										<del v-else>{{ methodFormatNumber(seeSummaryData('penalty_devolution')) }}</del>
									</p>
								</div>
								<div class="col-6 col-md-4 border-200 border-top border-end pt-4">
									<h6 class="pb-1 text-700">Factura a Terceros </h6>
									<p class="font-sans-serif lh-1 mb-1 fs-2">${{
			methodFormatNumber(seeSummaryData('tax_diference_invoce_third')) }}</p>
								</div>
								<div class="col-6 col-md-8 pb-0 pt-4 ps-3 text-center border-200 border-top">
									<h6 class="pb-1 text-700">Saldo Campaña</h6>
									<p class="font-sans-serif lh-1 mb-1 fs-2"
										:class="(seeSummaryData('balance') > 0) ? 'text-danger' : 'text-success'">
										${{ (seeSummaryData('balance') < 0) ?
			methodFormatNumber(seeSummaryData('balance') * -1) :
			methodFormatNumber(seeSummaryData('balance')) }}</p>
								</div>
							</div>
						</div>
						<div class="text-end px-3" v-if="objectTeam.status_id == 42 && actionSecurity(3)">
							<hr>
							<button class="btn btn-falcon-primary btn-sm" type="button" @click="closeTeamLocal">
								<span class="d-none d-sm-inline-block ms-1">Cerrar Campaña</span>
							</button>
						</div>
					</div>
					<div class="card overflow-hidden">
						<div class="card-body p-0">
							<div class="table-responsive scrollbar">
								<table class="table table-dashboard mb-0 table-borderless fs--1 border-200">
									<thead class="bg-light">
										<tr class="text-900">
											<th>Articulo</th>
											<th class="text-center">Precio</th>
											<th class="text-end">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr class="border-bottom border-200" v-for="(item, index) in listProductsFilter"
											:key="index">
											<td>
												<div class="d-flex align-items-center position-relative">
													<img class="rounded-1 border border-200"
														src="/assets/img/ecommerce/1.jpg" width="60" alt=""
														v-show="false" />
													<div class="flex-1 ms-3">
														<h6 class="mb-1 fw-semi-bold text-nowrap">
															<a class="text-900 stretched-link"
																:title="item.product_id">{{ item.product }}</a>
														</h6>
														<p class="fw-semi-bold mb-0 text-500">Codigo {{ item.product_id
															}} | Cantidad {{ methodFormatNumber(item.quantity) }}</p>
													</div>
												</div>
											</td>
											<td class="align-middle text-center fw-semi-bold">
												${{ methodFormatNumber(item.price) }}</td>
											<td class="align-middle text-end fw-semi-bold">
												${{ methodFormatNumber(parseFloat(item.quantity) *
			parseFloat(item.price)) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="row g-0 justify-content-end">
								<div class="col-auto">
									<table class="table table-sm table-borderless fs--1 text-end">
										<tr class="border-top">
											<th class="text-900">Total:</th>
											<td class="fw-semi-bold">${{ methodFormatNumber(sumListProductsFilter) }}
											</td>
											<td></td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div class="card-footer bg-light py-2" v-if="objectTeam.status_id == 42 && actionSecurity(4)">
							<div class="row flex-between-center">
								<div class="col-auto">
									<a class="btn btn-sm btn-falcon-default" @click="showOrderTransferLocal(6)"
										:disabled="listProductsFilter.length == 0">Devolucion de Material</a>
								</div>
								<div class="col-auto">
									<a class="btn btn-sm btn-falcon-default" @click="showOrderTransferLocal(4)"
										:disabled="listProductsFilter.length == 0">Transferir Material</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>




			<div class="card mb-3" id="orders1" :class="(typeOrderSelected == 2) ? 'border border-info' : ''">
				<div class="card-header d-flex flex-between-center">
					<h6 class="mb-0">Pedidos de Colportaje</h6>
				</div>
				<div class="card-body pt-0">
					<div class="col">
						<div class="card h-lg-100 overflow-hidden">
							<div class="card-body p-0">
								<div class="table-responsive scrollbar">
									<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
										<thead class="bg-200 text-900">
											<tr>
												<th class="sort pe-1 align-middle white-space-nowrap">Pedido</th>
												<th class="sort pe-1 align-middle white-space-nowrap pe-7">Cargado a
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap"
													style="min-width: 12.5rem;">Enviado a</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Estado
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Monto
												</th>
											</tr>
										</thead>
										<tbody class="list" id="table-orders-body">
											<tr class="btn-reveal-trigger" v-for="(item, index) in objectTeam.orders"
												:key="index" v-if="item.type_order_id == 2">
												<td class="order py-2 align-middle white-space-nowrap">
													<router-link :to="'/orderTeam/' + item.id" class="text-primary">
														<strong>#{{ item.id }}</strong>
													</router-link>
													por <strong>{{ item.order_tracking_order[0].user }}</strong>
												</td>
												<td class="date py-2 align-middle">{{ item.client }}</td>
												<td class="address py-2 align-middle white-space-nowrap">
													{{ item.sendto[0].street }} -
													{{ item.sendto[0].comuna }}
													<p class="mb-0 text-500">{{ item.sendto[0].sendto }}
														{{ item.sendto[0].phone }}</p>
												</td>
												<td
													class="status py-2 align-middle text-center fs-0 white-space-nowrap">
													<span class="badge badge rounded-pill d-block"
														:class="'badge-soft-' + item.color">{{ item.status }}</span>
												</td>
												<td class="amount py-2 align-middle text-end fs-0 fw-medium">${{
			methodFormatNumber(item.total) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>






			<div class="card mb-3" id="orders2" :class="(typeOrderSelected == 5) ? 'border border-info' : ''">
				<div class="card-header d-flex flex-between-center">
					<h6 class="mb-0">Recepciones</h6>
				</div>
				<div class="card-body pt-0">
					<div class="col">
						<div class="card h-lg-100 overflow-hidden">
							<div class="card-body p-0">
								<div class="table-responsive scrollbar">
									<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
										<thead class="bg-200 text-900">
											<tr>
												<th class="sort pe-1 align-middle white-space-nowrap">Pedido</th>
												<th class="sort pe-1 align-middle white-space-nowrap pe-7">Fecha</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Estado
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Monto
												</th>
											</tr>
										</thead>
										<tbody class="list" id="table-orders-body">
											<tr class="btn-reveal-trigger" v-for="(item, index) in objectTeam.orders"
												:key="index" v-if="item.type_order_id == 5">
												<td class="order py-2 align-middle white-space-nowrap">
													<router-link :to="'/orderTeam/' + item.id" class="text-primary">
														<strong>#{{ item.id }}</strong>
													</router-link>
													Transferencia realizada por
													<strong>{{ item.order_tracking_order[0].user }}</strong>
												</td>
												<td class="date py-2 align-middle">{{ item.created_at | formatDateMin }}
												</td>
												<td
													class="status py-2 align-middle text-center fs-0 white-space-nowrap">
													<span class="badge badge rounded-pill d-block"
														:class="'badge-soft-' + item.color">{{ item.status }}</span>
												</td>
												<td class="amount py-2 align-middle text-end fs-0 fw-medium">${{
			methodFormatNumber(item.total) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="card mb-3" id="orders3" :class="(typeOrderSelected == 4) ? 'border border-info' : ''">
				<div class="card-header d-flex flex-between-center">
					<h6 class="mb-0">Transferencias</h6>
				</div>
				<div class="card-body pt-0">
					<div class="col">
						<div class="card h-lg-100 overflow-hidden">
							<div class="card-body p-0">
								<div class="table-responsive scrollbar">
									<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
										<thead class="bg-200 text-900">
											<tr>
												<th class="sort pe-1 align-middle white-space-nowrap">Pedido</th>
												<th class="sort pe-1 align-middle white-space-nowrap pe-7">Fecha</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Estado
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Monto
												</th>
											</tr>
										</thead>
										<tbody class="list" id="table-orders-body">
											<tr class="btn-reveal-trigger" v-for="(item, index) in objectTeam.orders"
												:key="index" v-if="item.type_order_id == 4">
												<td class="order py-2 align-middle white-space-nowrap">
													<router-link :to="'/orderTeam/' + item.id" class="text-primary">
														<strong>#{{ item.id }}</strong>
													</router-link>
													por <strong>{{ item.order_tracking_order[0].user }}</strong>
												</td>
												<td class="date py-2 align-middle">{{ item.created_at | formatDateMin }}
												</td>
												<td
													class="status py-2 align-middle text-center fs-0 white-space-nowrap">
													<span class="badge badge rounded-pill d-block"
														:class="'badge-soft-' + item.color">{{ item.status }}</span>
												</td>
												<td class="amount py-2 align-middle text-end fs-0 fw-medium">${{
			methodFormatNumber(item.total) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card mb-3" id="orders4" :class="(typeOrderSelected == 6) ? 'border border-info' : ''">
				<div class="card-header d-flex flex-between-center">
					<h6 class="mb-0">Devoluciones </h6>
				</div>
				<div class="card-body pt-0">
					<div class="col">
						<div class="card h-lg-100 overflow-hidden">
							<div class="card-body p-0">
								<div class="table-responsive scrollbar">
									<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
										<thead class="bg-200 text-900">
											<tr>
												<th class="sort pe-1 align-middle white-space-nowrap">Pedido</th>
												<th class="sort pe-1 align-middle white-space-nowrap pe-7">Fecha</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Estado
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Monto
												</th>
											</tr>
										</thead>
										<tbody class="list" id="table-orders-body">
											<tr class="btn-reveal-trigger" v-for="(item, index) in objectTeam.orders"
												:key="index" v-if="item.type_order_id == 6">
												<td class="order py-2 align-middle white-space-nowrap">
													<router-link :to="'/orderTeam/' + item.id" class="text-primary">
														<strong>#{{ item.id }}</strong>
													</router-link>
													por <strong>{{ item.order_tracking_order[0].user }}</strong>
												</td>
												<td class="date py-2 align-middle">{{ item.created_at | formatDateMin }}
												</td>
												<td
													class="status py-2 align-middle text-center fs-0 white-space-nowrap">
													<span class="badge badge rounded-pill d-block"
														:class="'badge-soft-' + item.color">{{ item.status }}</span>
												</td>
												<td class="amount py-2 align-middle text-end fs-0 fw-medium">${{
			methodFormatNumber(item.total) }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card mb-3" id="orders4" :class="(typeOrderSelected == 6) ? 'border border-info' : ''">
				<div class="card-header d-flex flex-between-center">
					<h6 class="mb-0">Pagos </h6>
				</div>
				<div class="card-body pt-0">
					<div class="col">
						<div class="card h-lg-100 overflow-hidden">
							<div class="card-body p-0">
								<div class="table-responsive scrollbar">
									<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
										<thead class="bg-200 text-900">
											<tr>
												<th class="sort pe-1 align-middle white-space-nowrap">ID</th>
												<th class="sort pe-1 align-middle white-space-nowrap">Forma de Pago</th>
												<th class="sort pe-1 align-middle white-space-nowrap pe-7">Monto</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Estado
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Remesa
												</th>
											</tr>
										</thead>
										<tbody class="list" id="table-orders-body">
											<tr class="btn-reveal-trigger" v-for="(item, index) in listPayments"
												:key="index">
												<td class="text">{{ item.id }}</td>
												<td>{{ item.typePayment }}</td>
												<td class="text-end">{{ methodFormatNumber(item.amount) }}</td>
												<td class="text-center"><span class="badge badge rounded-pill d-block"
														:class="'badge-soft-' + item.color">{{item.status}}</span></td>
												<td class="text-center">{{item.consignment_id}}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
		<div v-else class="text-center">
			<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando
				información de la campaña...</p>
		</div>
		<order-colportaje-transfer-modal-component ref="orderColportajeTransferComponent" :team="objectTeam.name"
			:teamId="objectTeam.id" :client="objectTeam.client" :listProducts="listProducts" :typeOrderId="typeOrderId"
			@saveOrderComponent="saveOrderLocal">
		</order-colportaje-transfer-modal-component>

		<expense-modal-component ref="expenseModalComponent" controller="team" :id="objectTeam.id"
			@saveExpenseComponent="saveExpenseLocal">
		</expense-modal-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import teams from '@/mixins/teams'
import expenses from '@/mixins/expenses'
import orders from '@/mixins/orders'
import productPriceList from '@/mixins/products/productPriceList.js'

import ExpenseModalComponent from '@/components/ExpenseModalComponent.vue'
import OrderColportajeTransferModalComponent from '@/components/OrderColportajeTransferModalComponent.vue'

import { DoughnutChart } from 'vue-chart-3';

export default {
	name: 'Team',
	mixins: [mixin, axiosAction, errors, teams, expenses, orders, productPriceList],
	props: ['id'],
	data() {
		return {
			listInvoiceToThird: [
			],
			listProducts: [],
			listProductsTeams: [],
			typeOrderId: 0,
			typeOrderSelected: 0,
			penaltyTotalLocal: 0,
			penaltyConsider: true,
			ordersWithoutPenalty: [],
			typeOrderIdNotConsiderate: [7, 14, 15],
			chartOptions: {
				responsive: true,
				aspectRadio: 1.8,
				plugins: {
					legend: {
						display: false
					}
				}
			},
			percentageTeamVSTotalPayments: 0,
			percentageProductAssignedVSTotalPayments: 0

		}
	},
	components: {
		'order-colportaje-transfer-modal-component': OrderColportajeTransferModalComponent,
		'expense-modal-component': ExpenseModalComponent,
		'doughnut-chart': DoughnutChart,
	},
	computed: {
		debt() {
			let total = parseInt(this.seeSummaryData('total_orders'))
			total += parseInt(this.seeSummaryData('tithe'))
			total += parseInt(this.seeSummaryData('charge_payments'))
			total += parseInt(this.seeSummaryData('penalty_devolution'))
			total += parseInt(this.seeSummaryData('tax_diference_invoce_third'))
			return total
		},
		chartDataProductsAssignedVSTotalPayments() {
			this.percentageProductAssignedVSTotalPayments = (this.seeSummaryData('total_payments') * 100) / this.totalProductsAssigned
			this.percentageProductAssignedVSTotalPayments = this.percentageProductAssignedVSTotalPayments.toFixed(0)
			let chartData = {
				datasets: [{
					data: [this.percentageProductAssignedVSTotalPayments, 100 - this.percentageProductAssignedVSTotalPayments],
					circumference: 180,
					rotation: 270,
					cutout: '75%',
					backgroundColor: ['#27bcfd', '#d8e2ef']
				}],
			}
			return chartData
		},
		chartDataTeamVSTotalPaymentsFilter() {
			this.percentageTeamVSTotalPayments = (this.seeSummaryData('total_payments') * 100) / this.debt
			this.percentageTeamVSTotalPayments = this.percentageTeamVSTotalPayments.toFixed(0)
			let chartData = {
				datasets: [{
					data: [this.percentageTeamVSTotalPayments, 100 - this.percentageTeamVSTotalPayments],
					circumference: 180,
					rotation: 270,
					cutout: '75%',
					backgroundColor: ['#2c7be5', '#d8e2ef']
				}],
			}
			return chartData
		},
		listProductsFilter() {
			let list = []
			let index = -1
			this.objectTeam.orders.forEach(order => {
				index = this.typeOrderIdNotConsiderate.map(el => parseInt(el)).indexOf(parseInt(order.type_order_id))
				if (index == -1) {
					order.order_products.forEach(product => {
						product.factor = (order.type_order_id == 4 || order.type_order_id == 6) ? -1 : (order.type_order_id == 7) ? 0 : 1
						list.push(product)
					});
				}
			});

			list = list.sort(function (a, b) { return (a.product > b.product) ? 1 : (a.product < b.product) ? -1 : 0 });
			let _list = []
			let repeated = false
			for (let p in list) {
				repeated = false
				for (let pr in _list) {
					if (list[p].product_id == _list[pr].product_id && list[p].price == _list[pr].price) {
						repeated = true
						_list[pr].quantity = parseFloat(_list[pr].quantity) + (parseFloat(list[p].quantity) * parseFloat(list[p].factor))
					}
				}
				if (!repeated)
					_list.push(list[p])
			}
			_list = _list.filter(p => { return (p.quantity > 0) })
			this.listProducts = _list
			if (_list.length > 0) {
				this.priceListsIdSelect = _list[0].price_list_id
			}
			_list = _list.sort(function (a, b) { return (a.quantity < b.quantity) ? 1 : (a.quantity > b.quantity) ? -1 : 0 });

			return _list
		},
		totalProductsAssigned() {
			let total = 0
			let index = -1
			this.objectTeam.orders.forEach(order => {
				index = this.typeOrderIdNotConsiderate.map(el => parseInt(el)).indexOf(parseInt(order.type_order_id))
				if (index > -1) {
					if (order.type_order_id != 7) {
						order.order_products.forEach(product => {
							product.factor = (order.type_order_id == 14) ? 1 : -1
							total += (parseInt(product.price) * parseInt(product.quantity)) * product.factor
						});
					}
				}
			});
			return total
		},
		qOrders() {
			return this.objectTeam.orders.reduce(function (a, b) {
				let c = (b.type_order_id == 2) ? 1 : 0
				return parseFloat(a) + parseFloat(c)
			}, 0);
		},
		qTransfer() {
			return this.objectTeam.orders.reduce(function (a, b) {
				let c = (b.type_order_id == 4) ? 1 : 0
				return parseFloat(a) + parseFloat(c)
			}, 0);
		},
		qRecept() {
			return this.objectTeam.orders.reduce(function (a, b) {
				let c = (b.type_order_id == 5) ? 1 : 0
				return parseFloat(a) + parseFloat(c)
			}, 0);
		},
		qReturn() {
			return this.objectTeam.orders.reduce(function (a, b) {
				let c = (b.type_order_id == 6) ? 1 : 0
				return parseFloat(a) + parseFloat(c)
			}, 0);
		},
		sumTeamExpensesTotal() {
			let total = 0
			total = this.objectTeam.expenses.reduce(function (a, b) {
				return parseFloat(a) + parseFloat(b.amount)
			}, 0)
			return total.toFixed(0)
		},
		qInvoicesThird() {
			let total = 0
			total = this.objectTeam.orders.reduce(function (a, b) {
				let c = (b.type_order_id == 7) ? 1 : 0
				return parseFloat(a) + parseFloat(c)
			}, 0);
			return total.toFixed(0)
		},
		listPayments() {
			let list = []
			this.objectTeam.consignments.forEach(consignment => {
				consignment.payments.forEach(payment => {
					payment.consignment_id = consignment.id
					list.push(payment)
				})
			})
			return list
		},
		penaltyTotal() {
			let devolution = this.sumaListProductsPriceListWithoutFreightDevolution / (this.tax + 1)
			let max = (this.sumaListProductsPriceListWithoutFreight / (this.tax + 1)) * this.devolution
			let result = (devolution - max) * this.penalty
			this.penaltyTotalLocal = (result > 0) ? result : 0
			return result
		},
		sumOrdersTithe() {
			let total = 0
			total = this.objectTeam.orders.reduce((a, b) => {
				let c = 0
				let index = this.typeOrderIdNotConsiderate.map(el => parseInt(el)).indexOf(parseInt(b.type_order_id))
				if (index == -1) {
					c = (b.type_order_id == 4 || b.type_order_id == 6) ? -1 * parseFloat(b.tithe) : parseFloat(b.tithe)
				}
				return parseFloat(a) + parseFloat(c)
			}, 0);
			return total.toFixed(0)
		},
		sumOrdersTotal() {
			let total = 0
			total = this.objectTeam.orders.reduce((a, b) => {
				let c = 0
				let index = this.typeOrderIdNotConsiderate.map(el => parseInt(el)).indexOf(parseInt(b.type_order_id))
				if (index == -1) {
					c = (b.type_order_id == 4 || b.type_order_id == 6) ? -1 * parseFloat(b.total) : parseFloat(b.total)
				}
				return parseFloat(a) + parseFloat(c)
			}, 0);
			return total.toFixed(0)
		},
		sumaListProductsPriceListWithoutFreightDevolution() {
			let total = 0
			total = this.objectTeam.orders.reduce((a, o) => {
				let b = 0
				if (o.type_order_id == 6 && o.paid == 0) {
					b = o.order_products.reduce((c, op) => {
						let d = 0
						if (op.bonusable == true) {
							d = (parseInt(op.price) - parseInt(op.with_freight)) * op.quantity
						}
						return parseFloat(c) + parseFloat(d)
					}, 0)
				}
				return parseFloat(a) + parseFloat(b)
			}, 0)
			return total.toFixed(0)
		},
		sumOrdersTotalWithoutFreight() {
			let total = 0
			total = this.objectTeam.orders.reduce(function (a, b) {
				let c = 0
				if (b.type_order_id != 7)
					c = (b.type_order_id == 4 || b.type_order_id == 6) ? -1 * parseFloat(b.total) : parseFloat(b.total)
				return parseFloat(a) + parseFloat(c)
			}, 0);
			return total.toFixed(0)
		},
		sumConsignmentsTotal() {
			let total = 0
			total = this.objectTeam.consignments.reduce(function (a, b) {
				let z = b.payments.reduce(function (c, d) {
					let f = d.amount
					return parseFloat(c) + parseFloat(f)
				}, 0)
				return parseFloat(a) + parseFloat(z)
			}, 0)
			return parseFloat(total.toFixed(0))
		},
		sumTaxPaymentTotal() {
			let total = 0
			total = this.objectTeam.consignments.reduce(function (a, b) {
				let z = b.payments.reduce(function (c, d) {
					let f = 0
					if (d.pivot.percentage != null) {
						f = (d.amount * (d.pivot.percentage / 100))
					}
					return parseFloat(c) + parseFloat(f)
				}, 0)
				return parseFloat(a) + parseFloat(z)
			}, 0)
			return parseFloat(total.toFixed(0))
		},
		sumConsignmentsTotalNotConfirm() {
			let total = 0
			total = this.objectTeam.consignments.reduce(function (a, b) {
				let z = b.payments.reduce(function (c, d) {
					let f = (d.status_id != 39) ? d.amount : 0
					return parseFloat(c) + parseFloat(f)
				}, 0)
				return parseFloat(a) + parseFloat(z)
			}, 0)
			return parseFloat(total.toFixed(0))
		},
		sumListProductsFilter() {
			let total = 0
			total = this.listProductsFilter.reduce(function (a, b) {
				let c = parseFloat(b.quantity) * parseFloat(b.price)
				return a + c
			}, 0);
			return total.toFixed(0)
		},
		sumaListProductsPriceListBonus() {
			let total = this.listProductsFilter.reduce((a, op) => {
				let b = 0
				if (op.bonusable == true) {
					b = parseFloat(op.quantity) * (op.price - op.with_freight)
				}
				return a + b
			}, 0)
			return total.toFixed(0)
		},
		sumaListProductsPriceListWithoutFreight() {
			let total = 0
			total = this.listProductsFilter.reduce((a, p) => {
				let b = 0
				if (p.bonusable == true) {
					b = parseFloat(p.quantity) * (parseInt(p.price) - parseInt(p.with_freight))
				}
				return a + b
			}, 0);
			return total.toFixed(0)
		},
		sumaDiferenceInvoiceToThird() {
			let sum = 0
			let cal = 0
			if (this.objectTeam.team_invoice_third != null) {
				this.objectTeam.team_invoice_third.forEach(el => {
					cal = (parseFloat(el.total) - (parseFloat(el.total) / (this.tax + 1))) - (parseFloat(el.cost) - (parseFloat(el.cost) / (this.tax + 1)))
					sum += cal
				})
			}
			return parseInt(sum)
		},
		totalFull() {
			let result = 0
			result += parseFloat(this.sumOrdersTotal)
			result += parseFloat(this.sumOrdersTithe)
			result += parseFloat(this.sumConsignmentsTotal * -1)
			result += parseFloat(this.sumTeamExpensesTotal * -1)
			result += (!this.penaltyConsider) ? 0 : parseFloat(this.penaltyTotalLocal)
			result += parseFloat(this.sumaDiferenceInvoiceToThird)
			result += parseFloat(this.sumTaxPaymentTotal)
			return result
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'closeTeamResult': this.closeTeamResult(response); break;
				case 'showTeamResult': this.showTeamResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		closeTeamLocal() {
			if (confirm('Al cerrar la campaña no se podran ingresar mas datos.\n¿Desea continuar?')) {
				this.setobjectTeamSummary()
				this.closeTeam()
			}
		},
		closeTeamResultLocal(response) { },
		initModuleLocal() {
			this.setTeamModule()
		},
		saveExpenseLocal(response) {
			if (response.data.success == true) {
				this.objectTeam.expenses.push(response.data.data)
				this.$refs.expenseModalComponent.$refs.btnCloseExpenseModalComponent.click()
			}
		},
		saveOrderLocal(response) {
			if (response.data.success == true) {
				this.objectTeam.orders.push(response.data.data)
			}
		},
		setTeamModule() {
			this.showTeamFull(this.id)
		},
		setTypeOrderSelected(id) {
			this.typeOrderSelected = id
			setTimeout(() => {
				this.typeOrderSelected = 0
			}, 5000)
		},
		showTeamResultLocal(response) { },
		showExpensesTransferLocal() {
			setTimeout(() => {
				this.$refs.expenseModalComponent.$refs.btnShowExpenseModalComponent.click()
			}, 100)
		},
		showOrderTransferLocal(typeOrderId) {
			this.typeOrderId = typeOrderId
			setTimeout(() => {
				this.$refs.orderColportajeTransferComponent.$refs.btnShowOrderColportajeTransferModalComponent.click()
			}, 100)
		},
		seeSummaryData(data) {
			if (this.objectTeam.status_id == 43)
				return this.objectTeam[data]
			let value = 0
			switch (data) {
				case 'total_orders':
					value = this.sumOrdersTotal
					break;
				case 'bonusable':
					value = this.sumaListProductsPriceListBonus
					break;
				case 'tithe':
					value = this.sumOrdersTithe
					break;
				case 'total_payments':
					value = this.sumConsignmentsTotal
					break;
				case 'total_expenses':
					value = this.sumTeamExpensesTotal
					break;
				case 'charge_payments':
					value = this.sumTaxPaymentTotal
					break;
				case 'penalty_devolution':
					value = this.penaltyTotalLocal
					break;
				case 'tax_diference_invoce_third':
					value = this.sumaDiferenceInvoiceToThird
					break;
				case 'balance':
					value = this.totalFull
					break;
				case 'total_viatic':
					value = (this.sumaListProductsPriceListBonus / 1.19) * (this.objectTeam.viatic / 100)
					break;
				case 'total_commission':
					value = (this.sumaListProductsPriceListBonus / 1.19) * (this.objectTeam.commission / 100)
					break;
				default:
					break;
			}
			return value
		},
		setobjectTeamSummary() {
			this.objectTeam.total_orders = this.sumOrdersTotal
			this.objectTeam.bonusable = this.sumaListProductsPriceListBonus
			this.objectTeam.tithe = this.sumOrdersTithe
			this.objectTeam.total_payments = this.sumConsignmentsTotal
			this.objectTeam.total_expenses = this.sumTeamExpensesTotal
			this.objectTeam.charge_payments = this.sumTaxPaymentTotal
			this.objectTeam.penalty_devolution = this.penaltyTotalLocal
			this.objectTeam.tax_diference_invoce_third = this.sumaDiferenceInvoiceToThird
			this.objectTeam.balance = this.totalFull
			this.objectTeam.total_viatic = (this.sumaListProductsPriceListBonus / 1.19) * (this.objectTeam.viatic / 100)
			this.objectTeam.total_commission = (this.sumaListProductsPriceListBonus / 1.19) * (this.objectTeam.commission / 100)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
