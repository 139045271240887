var correosChile = {
	data() {
		return {
			normalizandoCorreosChile: false,
			generandoEtiquetaCorresChile: false,
			listaCorreosChile: [],
			listaCorreosChileManifiesto: [],
			paginationCorreosChileManifiesto: [],
			remitentes: [
				{
					nombre: 'ACES Chile',
					rut: '82745300-5',
					direccion: [
						{
							codPais: '056',
							comuna: 'Santiago',
							calle: 'Santa Elena',
							numero: '1038',
							codPostal: '8330752'
						}
					]
				}
			],
			loadingCorreosChile: false,
			loadingCorreosChileManifiesto: false,
			saveCorreosChileManifiesto: false,
			generandoCorreosChileManifiesto: false
		}
	},
	methods: {
		agregarListaCorreosChileManifiesto(nuevoManifiesto) {
			this.listaCorreosChileManifiesto.unshift(nuevoManifiesto)
		},
		etiquetaCorreosChileZPL(
			nroDTE,
			dataCodAdmision,
			dataCodServicio,
			dataRemitenteNombre,
			dataRemitenteRut,
			dataRemitenteCodPais,
			dataRemitenteComuna,
			dataRemitenteDireccion,
			dataRemitenteCodPostal,
			dataRemitenteContacto,
			dataRemitenteTelefono,
			dataRemitenteEmail,
			dataDestinatarioNombre,
			dataDestinatarioCodPais,
			dataDestinatarioComuna,
			dataDestinatarioDireccion,
			dataDestinatarioCodPostal,
			dataDestinatarioRut,
			dataDestinatarioContacto,
			dataDestinatarioTelefono,
			dataDestinatarioEmail,
			bultos,
			kilos,
			codReferencia,
			tipoPortes,
			valorDeclarado
		) {
			let url = this.getApiBackendNew + 'correosChile/etiquetas'
			let param = {
				nroDTE: nroDTE,
				posicionInicial: 0,
				formatoEtiqueta: 'ZPL',
				modo: 'json',
				data: [{
					codAdmision: dataCodAdmision,
					codCentro: '',
					codServicio: dataCodServicio,
					remitente: {
						nombre: dataRemitenteNombre,
						codPais: dataRemitenteCodPais,
						comuna: dataRemitenteComuna,
						direccion: dataRemitenteDireccion,
						codPostal: dataRemitenteCodPostal,
						rut: dataRemitenteRut,
						contacto: dataRemitenteContacto,
						telefono: dataRemitenteTelefono,
						email: dataRemitenteEmail,
					},
					destinatario: {
						nombre: dataDestinatarioNombre,
						codPais: dataDestinatarioCodPais,
						comuna: dataDestinatarioComuna,
						direccion: dataDestinatarioDireccion,
						codPostal: dataDestinatarioCodPostal,
						rut: dataDestinatarioRut,
						contacto: dataDestinatarioContacto,
						telefono: dataDestinatarioTelefono,
						email: dataDestinatarioEmail,
					},
					bultos: bultos,
					kilos: kilos,
					codReferencia: codReferencia,
					tipoPortes: tipoPortes,
					valorDeclarado: valorDeclarado
				}],
			}
			this.generandoEtiquetaCorresChile = true
			this.axiosActionIS(url, 'POST', 'etiquetaCorreosChileZPLResult', param)
		},
		etiquetaCorreosChileZPLResult(response) {
			this.generandoEtiquetaCorresChile = false
			this.etiquetaCorreosChileZPLResultLocal(response)
		},
		etiquetaCorreosChileZPLResultLocal(response) {
			console.log('etiquetaCorreosChileZPLResultLocal no desarrollado', response)
		},
		generarCorreosChileManifiesto(correosChile) {
			this.generandoCorreosChileManifiesto = true
			let data = {
				data: correosChile
			}
			let url = this.getApiBackendNew + 'correosChile/manifiestos'
			this.axiosActionIS(url, 'POST', 'generarCorreosChileManifiestoResult', data)
		},
		generarCorreosChileManifiestoResult(response) {
			this.generandoCorreosChileManifiesto = false
			if (response.data.success == true) {
				this.agregarListaCorreosChileManifiesto(response.data.data)
			}
			this.generarCorreosChileManifiestoResultLocal(response)
		},
		generarCorreosChileManifiestoResultLocal(response) {
			console.log('generarCorreosChileManifiestoResultLocal no desarrollado', response)
		},
		obtenerEtiquetasParaManifestoCorreoChile() {
			this.loadingCorreosChile = true
			this.listaCorreosChile = []
			let url = this.getApiBackendNew + 'correosChile/forManifesto'
			this.axiosActionIS(url, 'GET', 'obtenerEtiquetasParaManifestoCorreoChileResult')
		},
		obtenerEtiquetasParaManifestoCorreoChileResult(response) {
			this.loadingCorreosChile = false
			if (response.data.success == true) {
				this.listaCorreosChile = response.data.data
			}
			this.obtenerEtiquetasParaManifestoCorreoChileResultLocal()
		},
		obtenerEtiquetasParaManifestoCorreoChileResultLocal(response) {
			console.log('generarManifiestoCorreoChileResultLocal no desarrollado', response)
		},
		obtenerCorreosChileManifiestos(page = 1) {
			this.loadingCorreosChileManifiesto = true
			this.listaCorreosChileManifiesto = []
			this.paginationCorreosChileManifiesto = []
			let param = '?page=' + page + '&qRow=10'
			let url = this.getApiBackendNew + 'correosChile/getManifiesto' + param
			this.axiosActionIS(url, 'GET', 'obtenerCorreosChileManifiestosResult')
		},
		obtenerCorreosChileManifiestosResult(response) {
			this.loadingCorreosChileManifiesto = false
			if (response.data.success == true) {
				this.listaCorreosChileManifiesto = response.data.data
				this.paginationCorreosChileManifiesto = response.data.paginate
			}
			this.obtenerCorreosChileManifiestosResultLocal(response)
		},
		obtenerCorreosChileManifiestosResultLocal(response) {
			console.log('obtenerCorreosChileManifiestosResultLocal no desarrollado', response)
		},
		normalizacionCorreosChile(direccion, comuna) {
			this.normalizandoCorreosChile = true
			let url = this.getApiBackendNew + 'correosChile/normalizacion'
			this.axiosActionIS(url, 'POST', 'normalizacionCorreosChileResult', { direccion: direccion, comuna: comuna })
		},
		normalizacionCorreosChileResult(response) {
			this.normalizandoCorreosChile = false
			this.normalizacionCorreosChileResultLocal(response)
		},
		normalizacionCorreosChileResultLocal(response) {
			console.log('normalizacionCorreosChileResultLocal no desarrollado', response)
		},
		trazabilidadCorreosChile(numeroEnvio) {
			let url = this.getApiBackendNew + 'correosChile/trazabilidad/' + numeroEnvio
			this.axiosActionIS(url, 'GET', 'trazabilidadCorreosChileResult')
		},
		trazabilidadCorreosChileResult(response) {
			this.trazabilidadCorreosChileResultLocal(response)
		},
		trazabilidadCorreosChileResultLocal(response) {
			console.log('trazabilidadCorreosChileResultLocal no desarrollado', response)
		}
	},
};

export default correosChile;