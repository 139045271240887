var shippings = {
    data() {
        return {
            dateFromShippings: '2019-01-01',
            dateToShippings: this.currentDate() + ' 23:59:59',
            searchShippings: '',
            loadingShippings: false,
            listShippings: [],
            paginationListShippings: [],
            objectShipping: {
                exists: 0,
                id: 0,
                document_number: null,
                sendto: '',
                address: '',
                comuna: '',
                phone: null,
                paid_shipping: 0,
                packages: '',
                weight: '',
                transport_id: null,
                transport: null,
                freight_order: '',
                total_price: 0,
                status_id: null,
                status: null,
                office: 0,
                created_at: '',
                updated_at: '',
                manifesto_id: 0,
                manifesto: null,
                manifestos: null,
                shipping_packages: [],
                shipping_request: [],
                trackings_shipping: [],
                legal_documents: [],
                genesys_cabmov: [],
                barcode: ''
            },
            searchingShipping: false,
            qRowsShipping: 25,
            savingShipping: false,
            statusIdsShippingRequestAllowed: [13],
            statusIdsShippingErasable: [19, 20],
        }
    },
    methods: {
        addListShippings(newShipping) {
            this.listShippings.unshift(newShipping)
            if (this.listShippings.length > this.qRowsShipping) {
                this.listShippings.splice(this.qRowsShipping, 1)
            }
        },
        clearObjectShipping() {
            this.objectShipping.exists = 0
            this.objectShipping.id = 0
            this.objectShipping.document_number = null
            this.objectShipping.sendto = ''
            this.objectShipping.address = ''
            this.objectShipping.comuna = ''
            this.objectShipping.phone = null
            this.objectShipping.paid_shipping = 0
            this.objectShipping.packages = ''
            this.objectShipping.weight = ''
            this.objectShipping.transport_id = null
            this.objectShipping.transport = null
            this.objectShipping.freight_order = ''
            this.objectShipping.total_price = 0
            this.objectShipping.status_id = null
            this.objectShipping.status = null
            this.objectShipping.office = 0
            this.objectShipping.created_at = ''
            this.objectShipping.updated_at = ''
            this.objectShipping.manifesto_id = 0
            this.objectShipping.manifesto = null
            this.objectShipping.manifestos = null
            this.objectShipping.shipping_packages = []
            this.objectShipping.shipping_request = []
            this.objectShipping.trackings_shipping = []
            this.objectShipping.legal_documents = []
            this.objectShipping.genesys_cabmov = []
            this.objectShipping.barcode = ''
        },
        deleteListShippings(id) {
            this.clearObjectShipping()
            let i = this.listShippings.map((el) => parseInt(el.id)).indexOf(parseInt(id))
            let shippingDeleted = this.listShippings.splice(i, 1)
        },
        deleteShipping(id) {
            this.savingShipping = true
            let url = this.getApiBackendNew + 'shipping/' + id
            this.axiosActionIS(url, 'DELETE', 'deleteShippingResult')
        },
        deleteShippingResult(response) {
            this.savingShipping = false
            if (response.data.success) {
                this.deleteListShippings(response.data.data)
            }
            this.deleteShippingResultLocal(response)
        },
        deleteShippingResultLocal(response) {
            console.log('deleteShippingResultLocal no desarrollado', response)
        },
        getAllShippings(page = 1, resource = 'default') {
            this.loadingShippings = true
            let param = '?search=' + this.searchShippings + '&page=' + page + '&fromDate=' + this.dateFromShippings + '&toDate=' + this.dateToShippings + '&qRow=' + this.qRowsShipping + '&resource=' + resource

            let url = this.getApiBackendNew + 'shippings' + param
            this.axiosActionIS(url, 'GET', 'getAllShippingsResult')
        },
        getAllShippingsResult(response) {
            this.loadingShippings = false
            if (response.data.success == true) {
                this.listShippings = response.data.data
                this.paginationListShippings = response.data.paginate
            }
            this.getAllShippingsResultLocal(response)
        },
        getAllShippingsResultLocal(response) {
            console.log('getAllShippingsResultLocal no desarrollado', response)
        },
        saveShipping(s) {
            if (!this.savingShipping) {
                this.savingShipping = true
                let url = this.getApiBackendNew + 'shipping/register'
                this.axiosActionIS(url, 'POST', 'saveShippingResult', s)
            }
        },
        saveShippingResult(response) {
            this.savingShipping = false
            if (response.data.success == true) {
                this.setObjectShipping(response.data.data)
            }
            this.saveShippingResultLocal(response)
        },
        saveShippingResultLocal(response) {
            console.log('saveShippingResultLocal no desarrollado', response)
        },
        saveShippingTracking(id, trackingShippingId, observation) {
            if (!this.savingShipping) {
                let data = {
                    observation: observation,
                    trackingShippingId: trackingShippingId
                }
                let url = this.getApiBackendNew + 'shipping/' + id + '/trackingShipping/register'
                this.axiosActionIS(url, 'POST', 'saveShippingTrackingResult', data)
            }
        },
        saveShippingTrackingResult(response) {
            if (response.data.success == true) {
                if (parseInt(this.objectShipping.id) == parseInt(response.data.data.shipping_id)) {
                    console.log('object', this.objectShipping, response.data.data)
                    this.objectShipping.trackings_shipping.push(response.data.data)
                    if (response.data.data.trackings_shipping.status_id != null && response.data.data.trackings_shipping.status_id != '') {
                        this.objectShipping.status_id = response.data.data.trackings_shipping.status_id
                        this.objectShipping.status = response.data.data.trackings_shipping.status
                    }
                }
                let index = this.listShippings.map(s => parseInt(s.id)).indexOf(parseInt(response.data.data.shipping_id))
                if (index > -1) {
                    if (this.listShippings[index].trackings_shipping != undefined) {
                        this.listShippings[index].trackings_shipping.push(response.data.data)
                    }
                    if (response.data.data.trackings_shipping.status_id != null && response.data.data.trackings_shipping.status_id != '') {
                        this.listShippings[index].status_id = response.data.data.trackings_shipping.status_id
                        this.listShippings[index].status = response.data.data.trackings_shipping.status
                    }
                }
            }
            this.saveShippingTrackingResultLocal(response)
        },
        saveShippingTrackingResultLocal(response) {
            console.log('saveShippingTrackingResultLocal no desarrollado', response)
        },
        setObjectShipping(s) {
            this.clearObjectShipping()
            let newS = Object.create(s)
            for (const property in newS) {
                this.objectShipping[property] = newS[property]
            }
        },
        showShipping(id, resource = 'default') {
            this.searchingShipping = true
            let url = this.getApiBackendNew + 'shipping/' + id + '?resource=' + resource
            this.axiosActionIS(url, 'GET', 'showShippingResult')
        },
        showShippingResult(response) {
            this.searchingShipping = false
            if (response.data.success == true) {
                this.setObjectShipping(response.data.data)
            }
            this.showShippingResultLocal(response)
        },
        showShippingResultLocal(response) {
            console.log('showShippingResultLocal no desarrollado', response)
        },
        showShippingByShippingRequest(shippingResquestId) {
            this.searchingShipping = true
            let url = this.getApiBackendNew + 'shipping/shippingRequest/' + shippingResquestId
            this.axiosActionIS(url, 'GET', 'showShippingByShippingRequestResult')
        },
        showShippingByShippingRequestResult(response) {
            this.searchingShipping = false
            if (response.data.success == true) {
                this.setObjectShipping(response.data.data)
            }
            this.showShippingByShippingRequestResultLocal(response)
        },
        showShippingByShippingRequestResultLocal(response) {
            console.log('showShippingByShippingRequestResultLocal no desarrollado', response)
        },
        showMsgErrorHandlingLocal(error) {
            this.$emit('errorAction', error)
        },
        statusIdsShippingErasableOK(s) {
            if (s.status == null) {
                return false
            }
            let i = this.statusIdsShippingErasable.map(st => parseInt(st)).indexOf(parseInt(s.status.id))
            return (i > -1)
        },
        toPrintLabelShippingAll(shipping_packages, firstItem = true) {
            shipping_packages.forEach((object, index) => {
                if ((firstItem && index == 0) || (index > 0))
                    this.toPrintLabelShippingOne(object)
            })
        },
        toPrintLabelShippingOne(sp) {
            let item = {
                'sendto': this.objectShipping.sendto,
                'phone': this.objectShipping.phone,
                'document_number': (this.objectShipping.document_number == null) ? '' : this.objectShipping.document_number,
                'email': (this.objectShipping.email == null) ? '' : this.objectShipping.email,
                'address': this.objectShipping.address,
                'comuna': this.objectShipping.comuna,
                'transport_id': this.objectShipping.transport_id,
                'transport': this.objectShipping.transport,
                'paid_shipping_desc': (this.objectShipping.paid_shipping == 1) ? 'CTACTE' : 'POR PAGAR',
                'clicod': 'EV' + this.objectShipping.id,
                'cliente': '0',
                'campomisdesc': '0',
                'barcode': sp.barcode
            }
            this.toPrintShipping(item)
        },
        toPrintShipping(item) {
            let arrayZPL = []
            arrayZPL.push({ 'name': 'sendto', 'value': this.cleanedStringZPL(item.sendto).toUpperCase() })
            arrayZPL.push({ 'name': 'phone', 'value': this.cleanedStringZPL(item.phone).toUpperCase() })
            arrayZPL.push({ 'name': 'number_document', 'value': this.cleanedStringZPL(item.document_number).toUpperCase() })
            arrayZPL.push({ 'name': 'email', 'value': this.cleanedStringZPL(item.email).toUpperCase() })
            arrayZPL.push({ 'name': 'address', 'value': this.cleanedStringZPL(item.address).toUpperCase() })
            arrayZPL.push({ 'name': 'comuna', 'value': this.cleanedStringZPL(item.comuna).toUpperCase() })
            arrayZPL.push({ 'name': 'img_zpl', 'value': (item.transport.img_zpl == null) ? item.transport.name : item.transport.img_zpl })
            arrayZPL.push({ 'name': 'paid_shipping_desc', 'value': item.paid_shipping_desc })
            arrayZPL.push({ 'name': 'clicod', 'value': item.clicod })
            arrayZPL.push({ 'name': 'cliente', 'value': item.cliente })
            arrayZPL.push({ 'name': 'codbareti2', 'value': item.barcode })
            arrayZPL.push({ 'name': 'campomisdesc', 'value': item.campomisdesc })
            arrayZPL.push({ 'name': 'total_price', 'value': item.campomisdesc })
            this.replaceZPLVariableFromArray(arrayZPL)
            this.socketWebSocketServerPrinter(this.ZPLEnd(), localStorage.printerWebSocketServer)
        },
        updateShippingByField(id, field, value) {
            this.savingShipping = true
            let s = { field: field, value: value }
            let url = this.getApiBackendNew + 'shipping/' + id + '/updateField'
            this.axiosActionIS(url, 'PUT', 'updateShippingByFieldResult', s)
        },
        updateShippingByFieldResult(response) {
            this.savingShipping = false
            if (response.data.success == true) {
                this.setObjectShipping(response.data.data)
            }
            this.updateShippingByFieldResultLocal(response)
        },
        updateShippingByFieldResultLocal(response) {
            console.log('updateShippingByFieldResultLocal no desarrollado', response)
        }
    },
};

export default shippings;