<template>
	<div id="userEditComponent">
		<button ref="btnClearObjectUser" v-show="false" @click="clearObjectUserLocal">Limpiar objectUser</button>
		<button ref="btnShowUser" v-show="false" @click="showUserLocal">Buscar User {{ id }}</button>
		<button ref="btnSetObjectUser" v-show="false" @click="setObjectUserLocal">Buscar User {{ id }}</button>
		<button ref="btnSaveUser" v-show="false" @click="saveUserLocal" :disabled="btnSaveUserDisabled">Guardar
			Usuario</button>
		<div class="">
			<div class="row" data-dropzone="data-dropzone" v-show="true">
				<div class="fallback">
					<input type="file" name="file" class="invisible" ref="btnUserFile" @change="onFileChangeLocal"
						:readonly="disabledFormUser" />
				</div>
				<div class="col-md-auto">
					<div class="dz-preview dz-preview-single" for="userFile">
						<div class="dz-preview-cover d-flex align-items-center justify-content-center mb-3 mb-md-0">
							<div class="avatar avatar-4xl">
								<img class="rounded-circle"
									:src="(filesUpload.length == 0) ? '/assets/img/team/avatar.png' : filesUpload[0].url"
									alt="..." data-dz-thumbnail="data-dz-thumbnail" />
							</div>
							<div class="dz-progress">
								<span class="dz-upload" data-dz-uploadprogress=""></span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md">
					<div class="dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message"
						@click="clickBtn('btnUserFile')" @dragover.prevent @drop.prevent>
						<div class="text-center" @drop="dragFileChangeLocal">
							<img class="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />Suba una foto
							para este nuevo Usuario
							<p class="mb-0 fs--1 text-400">Suba una imagen (jpg, png) de 300x300<br />con un tamaño
								máximo de 400 KB</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-8">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-wizard-name-user">Nombre</label>
					<input class="form-control" type="text" placeholder="Juan Perez"
						id="form-wizard-progress-wizard-name" v-model="objectUser.name" :readonly="disabledFormUser" />
				</div>
			</div>
			<div class="col-4">
				<div class="mb-3">
					<div class="mb-3">
						<label class="form-label" for="form-wizard-progress-wizard-nick-user">Nick</label>
						<input class="form-control" type="text" placeholder="jperez"
							id="form-wizard-progress-wizard-nick" v-model="objectUser.nick"
							:readonly="disabledFormUser" />
					</div>
				</div>
			</div>
		</div>
		<div class="mb-3">
			<label class="form-label" for="form-wizard-progress-wizard-email-user">Correo Electronico</label>
			<input class="form-control" type="email" placeholder="correo@electronico.com" required="required"
				id="form-wizard-progress-wizard-email-user" data-wizard-validate-email="true" v-model="objectUser.email"
				:readonly="disabledFormUser || user.email != ''" />
			<div class="invalid-feedback"><small>Debes agregar un correo electronico</small></div>
		</div>
		<div class="row g-2">
			<div class="col-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-wizard-password">Contraseña</label>
					<input class="form-control" type="password" name="password" placeholder="Contraseña"
						data-wizard-validate-password="true" v-model="objectUser.password"
						:readonly="disabledFormUser" />
					<div class="invalid-feedback">Ingrese una contraseña</div>
				</div>
			</div>
			<div class="col-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-wizard-confirm-password">Confirmar
						contraseña</label>
					<input class="form-control" type="password" name="confirmPassword"
						placeholder="Confirme la contraseña" id="form-wizard-progress-wizard-confirm-password"
						data-wizard-validate-confirm-password="true" v-model="objectUser.password_repeat"
						:readonly="disabledFormUser" />
					<div class="invalid-feedback">Confirmar contraseña</div>
				</div>
			</div>
		</div>
		<div class="row g-2">
			<div class="col-6">
				<div class="mb-3">
					<label class="form-label" for="form-wizard-progress-wizard-password">Rol</label>
					<select class="form-select" id="form-wizard-progress-typeUser" v-model="objectUser.role_id"
						:disabled="disabledFormUser">
						<option value="0" disabled>Seleccione un rol...</option>
						<option v-for="(item, index) in listRolesFilter" :key="index" :value="item.id">{{ item.name }}
						</option>
					</select>
					<div class="invalid-feedback">Seleccione un rol para este nuevo usuario</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import users from '@/mixins/users'
import role from '@/mixins/generals/role.js'

export default {
	name: 'UserEditComponent',
	mixins: [mixin, axiosAction, errors, users, role],
	props: ['id', 'edit', 'user'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		btnSaveUserDisabled() {
			let disabled = false
			if (!disabled && this.objectUser.name.length < 3) {
				disabled = true
			}
			if (!disabled && this.objectUser.nick.length < 3) {
				disabled = true
			}
			if (!disabled && !this.validateEmail(this.objectUser.email)) {
				disabled = true
			}
			if (!disabled && this.objectUser.password != this.objectUser.password_repeat) {
				disabled = true
			}
			if (!disabled && this.objectUser.password == '' && (this.objectUser.password != this.objectUser.password_repeat)) {
				disabled = true
			}
			if (!disabled && this.objectUser.role_id == 0)
				disabled = true
			if (!disabled && this.savingUser) {
				disabled = true
			}
			this.$emit('saveDisabled', disabled)
			return disabled
		},
		disabledFormUser() {
			if (this.edit == undefined)
				return false
			return !this.edit
		},
		listRolesFilter() {
			return this.listRoles
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getRolesResult': this.getRolesResult(response); break;
				case 'saveUserResult': this.saveUserResult(response); break;
				case 'showUserResult': this.showUserResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectUserLocal() {
			this.clearObjectUser()
		},
		getRolesLocal(page = 1) {
			this.getRoles(page, 'getCurrentUser')
		},
		getRolesResultLocal(response) { },
		initModuleLocal() {
			this.setUserEditComponentInit()
		},
		onFileChangeLocal(e) {
			this.filesUpload = []
			this.onFileChange(e)
		},
		dragFileChangeLocal(e) {
			this.filesUpload = []
			this.dragFileChange(e)
		},
		saveUserLocal() {
			this.saveUser(this.objectUser)
		},
		saveUserResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('saveUserResult', this.objectUser)
			}
		},
		setObjectUserLocal() {
			this.setObjectUser(this.user)
		},
		setUserEditComponentInit() {
			this.getRolesLocal()
		},
		showUserLocal() {
			this.clearObjectUserLocal()
			if (this.id > 0) {
				this.showUser(this.id)
			}
		},
		showUserResultLocal(response) {
			this.$emit('showUserResult', this.objectUser)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>