<template>
	<div id="correosChileTrazabilidadComponent">
		<button ref="btnTrazabilidadCorreosChile" @click="trazabilidadCorreosChileLocal" v-show="false">Trazabilidad {{
			numeroEnvio
		}}</button>
		<div v-if="correosChileTrazabilidad != null">
			<div class="row g-3 timeline pb-x1" :class="stateIcons(ic.id, i)"
				v-for="(ic, i) in correosChileTrazabilidad.iconos" :key="i">
				<div class="col-auto ps-4 ms-2">
					<div class="ps-2">
						<div class="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
							<span class="fas fa-envelope" :class="setIcono(ic.id, i)"></span>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="row gx-0 border-bottom pb-x1 mb-1">
						<div class="col">
							<h6 class="text-800 mb-1">{{ ic.titulo }}</h6>
							<p class="fs--1 text-600 mb-0">{{ ic.descripcion }}</p>
						</div>
						<div class="col-auto">
							<p class="fs--2 text-500 mb-0 text-end">{{ getHistoryIcons(ic.id, 'fechaString') }}</p>
							<div class="fs--2 mt-1">
								<strong>{{ getHistoryIcons(ic.id, 'estado') }}</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="correosChileTrazabilidad.nombreEntrega != ''" class=" pt-4">
				<div class="alert alert-success border-0 d-flex align-items-center" role="alert">
					<div class="bg-success me-3 icon-item">
						<span class="fas fa-check-circle text-white fs-3"></span>
					</div>
					<p class="mb-0 flex-1">
						El envío ha sido entregado a
						<strong>{{ correosChileTrazabilidad.nombreEntrega }}</strong>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import correosChile from '@/mixins/generals/correosChile.js'

export default {
	name: 'CorreosChileTrazabilidadComponent',
	mixins: [mixin, axiosAction, errors, correosChile],
	props: ['numeroEnvio'],
	data() {
		return {
			correosChileTrazabilidad: null,
			iconos: [
				{ id: '011', class: 'fas fa-home' },
				{ id: '010', class: 'fas fa-truck-loading' },
				{ id: '004', class: 'fas fa-truck-moving' },
				{ id: '005', class: 'fas fa-map-marked-alt' },
				{ id: '006', class: 'fas fa-hands-helping' },
				{ id: '008', class: 'far fa-building' },
			]
		}
	},
	components: {
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'trazabilidadCorreosChileResult': this.trazabilidadCorreosChileResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setCorreosChileTrazabilidadComponentInit()
		},
		getHistoryIcons(id, type = 'oficina') {
			let index = this.correosChileTrazabilidad.historial.map(h => h.icono).indexOf(id)
			return (index > -1) ? this.correosChileTrazabilidad.historial[index][type] : null
		},
		stateIcons(id) {
			let index = this.correosChileTrazabilidad.historial.map(h => h.icono).indexOf(id)
			if (index > -1) {
				if (this.correosChileTrazabilidad.historial[0].icono == id) {
					return 'timeline-primary timeline-current'
				} else {
					return 'timeline-primary timeline-past'
				}
			}
			return ''
		},
		setCorreosChileTrazabilidadComponentInit() {
		},
		setIcono(id) {
			let class_ = 'fas fa-question'
			let index = this.iconos.map(i => i.id).indexOf(id)
			if (index > -1) {
				class_ = this.iconos[index].class
			}
			class_ += (this.stateIcons(id) != '') ? ' text-primary' : ''
			return class_
		},
		trazabilidadCorreosChileLocal() {
			this.correosChileTrazabilidad = null
			if (this.numeroEnvio != undefined && this.numeroEnvio != null && this.numeroEnvio > 0) {
				this.trazabilidadCorreosChile(this.numeroEnvio)
			} else {
				alert('yaaa')
			}
		},
		trazabilidadCorreosChileResultLocal(response) {
			if (response.data.success == true) {
				this.correosChileTrazabilidad = response.data.data.data
			}
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>