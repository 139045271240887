var consignment = {
	data() {
		return {
			dateFromConsignments: '2020-07-15',
			dateToConsignments: '2020-07-18',
			listConsignments: [],
			loadingConsignments: false,
			objectConsignment: {
				exists: 0,
				id: 0,
				description: '',
				total: 0,
				team_id: 0,
				team: {},
				status_id: 0,
				status: {},
				multiple_payment: 1,
				type_payment_id: null,
				consignment_trackings_consignment: [],
				created_at: '',
				updated_at: ''
			},
			paginationConsignments: [],
			qRowsConsignment: 25,
			searchConsignments: '',
			urlsConsignment: {
				create: 'consignment/register',
				delete: '/delete',
				get: 'consignments',
				show: 'consignment/'
			},
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		addListConsignments(newConsignment) {
			this.listConsignments.unshift(newConsignment)
			if (this.listConsignments.length > this.qRowsConsignment) {
				this.listConsignments.splice(this.qRowsConsignment, 1)
			}
		},
		addListConsignmentsPayment(id, data) {
			let index = this.listConsignments.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			if (index > -1) {
				this.listConsignments[index].payments.push(data.payment)
			}
		},
		addObjectConsignmentPayment(id, data) {
			if (this.objectConsignment.id == id) {
				this.objectConsignment.payments.push(data.payment)
			}
		},
		deleteItemListConsignment(id) {
			let index = this.listConsignments.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let consignmentDeleted = this.listConsignments.splice(index, 1)
		},
		deleteItemListConsignmentPayment(id, paymentId) {
			let index = this.listConsignments.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			if (index > -1) {
				let ind = this.listConsignments[index].payments.map((el) => parseInt(el.id)).indexOf(parseInt(paymentId))
				let consignmentPaymentDeleted = this.listConsignments[index].payments.splice(ind, 1)
			}
		},
		deleteObjectConsignmentPayment(id, paymentId) {
			if (this.objectConsignment.id == id) {
				let index = this.objectConsignment.payments.map((el) => parseInt(el.id)).indexOf(parseInt(paymentId))
				let paymentDeleted = this.objectConsignment.payments.splice(index, 1)
			}
		},
		deleteConsignment(id) {
			this.$emit('showLockScreen', 'Borrando Remesa . . .')
			this.axiosActionIS(this.getApiBackend + this.urlsConsignment.show + id + this.urlsConsignment.delete, 'DELETE', 'deleteConsignmentResult')
		},
		deleteConsignmentResult(response) {
			if (response.data.success === true) {
				this.deleteItemListConsignment(response.data.data)
			}
			this.deleteConsignmentResultLocal(response)
		},
		deleteConsignmentResultLocal(response) {
			console.log('deleteConsignmentResultLocal no desarrollado', response)
		},
		changeQRowsConsignment() {
			localStorage.setItem('qRowsConsignment', this.qRowsConsignment)
			this.changeQRowsConsignmentLocal()
		},
		changeQRowsConsignmentLocal() {
			console.log('changeQRowsConsignmentLocal no desarrollado')
		},
		clearObjectConsignment() {
			this.objectConsignment.exists = 0
			this.objectConsignment.id = 0
			this.objectConsignment.description = ''
			this.objectConsignment.total = 0
			this.objectConsignment.team_id = 0
			this.objectConsignment.team = {}
			this.objectConsignment.status_id = 0
			this.objectConsignment.status = {}
			this.objectConsignment.multiple_payment = 1
			this.objectConsignment.type_payment_id = null
			this.objectConsignment.consignment_trackings_consignment = []
			this.objectConsignment.created_at = null
			this.objectConsignment.updated_at = null
		},
		getAllConsignments(page = 1) {
			this.loadingConsignments = true
			let parameters = '?search=' + this.searchConsignments + '&page=' + page + '&qRow=' + this.qRowsConsignment + '&fromDate=' + this.dateFromConsignments + '&toDate=' + this.dateToConsignments
			this.$emit('showLockScreen', 'Obteniendo lista de Remesas . . .')
			let url = this.getApiBackend + this.urlsConsignment.get + parameters
			this.axiosActionIS(url, 'GET', 'getAllConsignmentsResult')
		},
		getAllConsignmentsByUserClient(page = 1) {
			this.loadingConsignments = true
			let parameters = '?search=' + this.searchConsignments + '&page=' + page + '&qRow=' + this.qRowsConsignment + '&fromDate=' + this.dateFromConsignments + '&toDate=' + this.dateToConsignments
			this.$emit('showLockScreen', 'Obteniendo lista de Remesas . . .')
			let url = this.getApiBackend + this.urlsConsignment.get + '/byUserClient' + parameters
			this.axiosActionIS(url, 'GET', 'getAllConsignmentsResult')
		},
		getAllConsignmentsByTeam(page, teamId) {
			this.loadingConsignments = true
			this.listConsignments = []
			let url = this.getApiBackend + 'team/' + teamId + '/consignments?' + 'page=' + page
			this.axiosActionIS(url, 'GET', 'getAllConsignmentsResult')
		},
		getAllConsignmentsResult(response) {
			this.loadingConsignments = false
			if (response.data.success === true) {
				this.listConsignments = response.data.data
				this.paginationConsignments = response.data.paginate
			}
			this.getAllConsignmentsResultLocal(response)
		},
		getAllConsignmentsResultLocal(response) {
			console.log('getAllConsignmentsResultLocal no desrrollado', response)
		},
		saveConsignment(item) {
			let method = (item.exists == 0) ? 'POST' : 'PUT'
			console.log(this.getApiBackend + this.urlsConsignment.create, item)
			if (method == 'POST') {
				this.axiosActionIS(this.getApiBackend + this.urlsConsignment.create, method, 'saveConsignmentResult', item)
			} else {
				this.axiosActionIS(this.getApiBackend + this.urlsConsignment.show + this.objectConsignment.id, method, 'saveConsignmentResult', item)
			}
		},
		saveConsignmentResult(response) {
			if (response.data.success === true) {
				if (response.data.message == 'NEW') {
					this.setObjectConsignment(response.data.data)
				} else if (response.data.message == 'UPDATED') {
					this.updateListConsignment(response.data.data)
				}
				this.setObjectConsignment(response.data.data)
			}
			this.saveConsignmentResultLocal(response)
		},
		saveConsignmentResultLocal(response) {
			console.log('saveConsignmentResultLocal no desarrollado', response)
		},
		setListeningConsignment() {
			this.sockets.subscribe('DELETE_CONSIGNMENT', (data) => { this.deleteItemListConsignment(data.body) })
			this.sockets.subscribe('NEW_CONSIGNMENT', (data) => { this.addListConsignments(data.body) })
			this.sockets.subscribe('UPDATE_CONSIGNMENT', (data) => { this.updateListConsignment(data.body) })
		},
		setObjectConsignment(item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectConsignment[property] = newItem[property]
			}
		},
		setConsignmentInit() {
			this.qRowsConsignment = (localStorage.getItem('qRowsConsignment') === null) ? this.qRowsConsignment : localStorage.getItem('qRowsConsignment')
			this.dateFromConsignments = this.currentDate(-180)
			this.dateToConsignments = this.currentDate()
			this.setListeningConsignment()
			this.setConsignmentInitLocal()
		},
		showConsignment(id, action = 'view') {
			this.loadingConsignments = true
			let param = '?action=' + action
			this.axiosActionIS(this.getApiBackend + this.urlsConsignment.show + id + param, 'GET', 'showConsignmentResult', null)
		},
		showConsignmentResult(response) {
			this.loadingConsignments = false
			if (response.data.success === true) {
				this.setObjectConsignment(response.data.data)
			}
			this.showConsignmentResultLocal(response)
		},
		showConsignmentResultLocal(response) {
			console.log('showConsignmentResultLocal no desarrollado', response)
		},
		sumConsignmentPayment(payments) {
			let total = payments.reduce(function (a, b) {
				let c = parseInt(b.amount)
				return a + parseInt(c)
			}, 0);
			return total.toFixed(0)
		},
		updateListConsignment(item) {
			let index = this.listConsignments.map((el) => parseInt(el.id)).indexOf(parseInt(item.id))
			if (index > -1) {
				let newItem = Object.create(item)
				for (const property in newItem) {
					this.listConsignments[index][property] = newItem[property]
				}
			}
		}
	},
};

export default consignment;