/* Requiere de genesys.js */

var account = {
    data() {
        return {
            loadingAccounts: false,
            loadingFunds: false,
            loadingFunctions: false,
            loadingSubAccounts: false,
            listAccounts: [],
            listFunds: [],
            listFunctions: [],
            listSubAccounts: []
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getAccountByCode(account_Code) {
            let i = this.listAccounts.map(a => parseInt(a.Code)).indexOf(parseInt(account_Code))
            if (i > -1) {
                return this.listAccounts[i]
            }
            return { Code: 0, Name: 'Sin Definir' }
        },
        getAccountByAccountCodeAndSubAccountCode(account_Code, sub_account_Code) {
            if (account_Code > 0) {
                let a = this.getAccountByCode(account_Code)
                let l = this.listSubAccounts.filter(sa => {
                    return (parseInt(sa.Code) == parseInt(sub_account_Code) && (sa.type_sub_account_Code == a.type_sub_account_Code))
                })
                if (l.length == 1) {
                    return l[0]
                }
            }
            return { Code: 0, Name: 'Sub Cuenta Sin Definir' }
        },
        getFundByCode(fund_code) {
            let i = this.listFunds.map(f => parseInt(f.Code)).indexOf(parseInt(fund_code))
            if (i > -1) {
                return this.listFunds[i]
            }
            return { Code: 0, Name: 'Sin Definir' }
        },
        getFunctionByCode(function_code) {
            let i = this.listFunctions.map(a => parseInt(a.Code)).indexOf(parseInt(function_code))
            if (i > -1) {
                return this.listFunctions[i]
            }
            return { Code: 0, Name: 'Sin Definir' }
        },
        getAccounts() {
            this.loadingAccounts = true
            this.listAccounts = []
            let url = this.getApiBackendNew + 'accounts'
            this.axiosActionIS(url, 'GET', 'getAccountsResult')
        },
        getAccountsResult(response) {
            this.loadingAccounts = false
            if (response.data.success == true) {
                this.listAccounts = response.data.data
            }
            this.getAccountsResultLocal(response)
        },
        getAccountsResultLocal(response) {
            console.log('getAccountsResultLocal no desarrollado', response)
        },
        getFunds() {
            this.loadingFunds = true
            this.listFunds = []
            let url = this.getApiBackendNew + 'funds'
            this.axiosActionIS(url, 'GET', 'getFundsResult')
        },
        getFundsResult(response) {
            this.loadingFunds = false
            if (response.data.success == true) {
                this.listFunds = response.data.data
            }
            this.getFundsResultLocal(response)
        },
        getFundsResultLocal(response) {
            console.log('getFundsResultLocal no desarrollado', response)
        },
        getFunctions() {
            this.loadingFunctions = true
            this.listFunctions = []
            let url = this.getApiBackendNew + 'functions'
            this.axiosActionIS(url, 'GET', 'getFunctionsResult')
        },
        getFunctionsResult(response) {
            this.loadingFunctions = false
            if (response.data.success == true) {
                this.listFunctions = response.data.data
            }
            this.getFunctionsResultLocal(response)
        },
        getFunctionsResultLocal(response) {
            console.log('getFunctionsLocal no desarrollado', response)
        },
        getSubAccounts() {
            this.loadingSubAccounts = true
            this.listSubAccounts = []
            let url = this.getApiBackendNew + 'subAccounts'
            this.axiosActionIS(url, 'GET', 'getSubAccountsResult')
        },
        getSubAccountsResult(response) {
            this.loadingSubAccounts = false
            if (response.data.success == true) {
                this.listSubAccounts = response.data.data
            }
            this.getSubAccountsResultLocal(response)
        },
        getSubAccountsResultLocal(response) {
            console.log('getSubAccountsResultLocal no desarrollado', response)
        },
    },
};

export default account;