<template>
	<div id="manifestoComponent">
		<button @click="showManifestoLocal" ref="btnShowManifesto" v-show="false">Show Manifesto</button>
		<div v-if="objectManifesto.id > 0">
			<div class="card" id="runningProjectTable">
				<div class="px-2 py-1 rounded-1 position-absolute top-0 end-0 pointer" data-bs-theme="light"
					v-show="visibleBtnCloseComponent == true" @click="closeComponentLocal">
					<i class="fas fa-times text-muted"></i>
				</div>
				<div class="card-header border-buttom border-muted">
					<h6 class="mb-0">
						<a @dblclick="showManifestoLocal" class="pointer">{{ objectManifesto.barcode }}</a>
						-
						<span :class="'text-' + objectManifesto.transport.color">
							{{ objectManifesto.transport.name.toUpperCase() }}
						</span>
						<span class="float-end text-secondary me-2">| Actualización: 19/07/2024 08:46:57</span>
						<span class="float-end text-secondary me-1">Creación 19/07/2024 08:46:57</span>
					</h6>
				</div>
				<div class="card-body p-0">
					<div class="scrollbar">
						<table
							class="table mb-0 table-borderless fs--1 border-200 overflow-hidden table-running-project">
							<thead class="bg-body-tertiary">
								<tr class="border-buttom border-muted">
									<th class="text-800">Enviar a</th>
									<th class="text-800" data-sort="time">Dirección</th>
									<th class="text-800 text-center" data-sort="worked">Bultos</th>
									<th class="text-800 text-center" data-sort="date">Peso</th>
									<th class="text-800 text-center">Cta Cte</th>
									<th class="text-800 text-center">OF</th>
								</tr>
							</thead>
							<tbody class="list">
								<tr v-for="(s, i) in listShippingsFilter" :key="i">
									<td class="align-middle white-space-nowrap border-bottom border-muted">
										<a class="text-primary pointer">
											<strong>#{{ s.id }}</strong>
										</a>
										enviar a
										<strong>{{ s.sendto }}</strong>
										<br />
										<small class="text-muted">
											<i class="far fa-envelope text-secondary"></i>
											<a href="mailto:ricky@example.com" class="text-muted ms-1">{{ s.email }}</a>
											|<i class="far fa-address-card ms-1 text-secondary"></i>
											<span class="ms-1">{{ s.document_number }}</span>
											|<i class="fas fa-phone-alt ms-1 text-secondary"></i>
											<span class="ms-1">{{ s.phone }}</span>
										</small>
									</td>
									<td class="align-middle white-space-nowrap border-bottom border-muted">
										<div>
											{{ s.address }}, {{ s.comuna }}
										</div>
									</td>
									<td class="align-middle fw-medium border-bottom border-muted text-center">
										{{ s.packages }}
									</td>
									<td class="align-middle fw-medium border-bottom border-muted text-center">
										{{ s.weight }}
									</td>
									<td class="align-middle fw-medium border-bottom border-muted text-center">
										<span v-show="s.paid_shipping == 1">
											<i class="fas fa-check text-success"></i>
										</span>
										<span v-show="s.paid_shipping == 0">
											<i class="fas fa-times text-danger"></i>
										</span>
									</td>
									<td class="align-middle fw-medium border-bottom border-muted text-center">
										{{ s.freight_order }}
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2"></td>
									<td class="text-center">
										<strong>{{ totalPackages }}</strong>
									</td>
									<td class="text-center">
										<strong>{{ totalWeight }}</strong>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
				<div class="card-footer bg-body-tertiary py-0 text-end">
					<button class="btn btn-sm btn-success mb-3" v-if="objectManifesto.status_id == 27"
						@click="trackingManifestoRegisterLocal(objectManifesto.id, 6)">
						<i class="fas fa-truck-loading"></i> Despachar
					</button>
					<button class="btn btn-sm btn-danger mb-3 ms-2">
						<i class="far fa-file-pdf"></i> pdf
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import manifesto from '@/mixins/manifestos'

export default {
	name: 'ManifestoComponent',
	mixins: [mixin, axiosAction, errors, manifesto],
	props: ['id', 'visibleBtnCloseComponent'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		listShippingsFilter() {
			return this.objectManifesto.shippings
		},
		totalPackages() {
			let total = this.listShippingsFilter.reduce((a, b) => {
				return a + parseInt(b.packages)
			}, 0);
			return total.toFixed(0)
		},
		totalWeight() {
			let total = this.listShippingsFilter.reduce((a, b) => {
				return a + parseInt(b.weight)
			}, 0);
			return total.toFixed(2)
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'showManifestoResult': this.showManifestoResult(response); break;
				case 'trackingManifestoRegisterResult': this.trackingManifestoRegisterResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		closeComponentLocal() {
			this.$emit('closeComponent')
		},
		initModuleLocal() {
			this.setManifestoComponentInit()
		},
		setManifestoComponentInit() {
		},
		showManifestoLocal() {
			if (this.id != undefined && this.id != null && this.id > 0) {
				this.showManifesto(this.id)
			}
		},
		showManifestoResultLocal(response) {
			console.log(response)
		},
		trackingManifestoRegisterLocal(id, trackingManifestoId) {
			this.trackingManifestoRegister(id, trackingManifestoId)
		},
		trackingManifestoRegisterResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.data.trackings_manifesto.status_id != null) {
					this.objectManifesto.status_id = response.data.data.trackings_manifesto.status_id
					this.objectManifesto.status = response.data.data.trackings_manifesto.status
				}
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>