<template>
	<div id="paymentsModule">
		<payment-list-component ref="paymentListComponent"></payment-list-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import PaymentListComponent from '@/components/PaymentListComponent.vue'

export default {
	name: 'PaymentsModule',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
		}
	},
	components: {
		'payment-list-component': PaymentListComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			setTimeout(() => { this.$refs.paymentListComponent.$refs.btnGetPayment.click() }, 50)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
