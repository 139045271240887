<template>
	<div id="actionsModule">
		<div class="card mb-3 p-3">
			<action-list-component view="table"></action-list-component>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

import ActionListComponent from '@/components/ActionListComponent.vue'

export default {
	name: 'ActionsModule',
	mixins: [mixin],
	components: {
		'action-list-component': ActionListComponent
	},
	methods: {
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
