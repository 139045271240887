<template>
	<div id="clientModule">
		<client-list-component></client-list-component>
	</div>
</template>

<script>
	import mixin from '@/mixins'
	import axiosAction from '@/mixins/axiosAction.js'
	import errors from '@/mixins/errors.js'

	import ClientListComponent from '@/components/ClientListComponent.vue'

	export default {
		name: 'ClientModule',
		mixins: [mixin, axiosAction, errors],
		data() {
			return {
			}
		},
		components: {
			'client-list-component' : ClientListComponent
		},
		computed: {
		},
		methods: {
			axiosResultLocal(responseLocal, response) {
				switch(responseLocal) {
					case 'defaultFunction': this.defaultFunction(response); break;
					default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
				}
			},
			initModuleLocal(name) {
			}
		},
		mounted () {
			this.initModule(this.$options.name)
		},
	}	
</script>

<style lang="scss">
</style>
