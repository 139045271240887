<template>
	<div id="login">
		<main class="main" id="top">
			<div class="container-fluid">
				<div class="row min-vh-100 bg-100">
					<div class="col-6 d-none d-lg-block position-relative">
						<div class="bg-holder" style="background-image:url(/assets/img/generic/14.jpg);background-position: 50% 20%;">
						</div>
					</div>
					<div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
						<div class="row justify-content-center g-0">
							<div class="col-lg-9 col-xl-8 col-xxl-6">
								<div class="card">
									<div class="card-header bg-circle-shape bg-shape text-center p-2">
										<a class="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light">{{getNameApp}}</a>
									</div>
									<div class="card-body p-4">
										<div class="row flex-between-center">
											<div class="col-auto">
												<h3>Control de Acceso</h3>
											</div>
										</div>
										<div>
											<div class="mb-3">
												<label class="form-label" for="split-login-email">Correo Electronico</label>
												<input class="form-control" id="split-login-email" type="email" v-model="credentials.email" />
											</div>
											<div class="mb-3">
												<div class="d-flex justify-content-between">
													<label class="form-label" for="split-login-password">Contraseña</label>
												</div>
												<input class="form-control" id="split-login-password" type="password" v-model="credentials.password"/>
											</div>
											<div class="row flex-between-center" v-show="false">
												<div class="col-auto">
													<div class="form-check mb-0">
													<input class="form-check-input" type="checkbox" id="split-checkbox" />
													<label class="form-check-label mb-0" for="split-checkbox">Recordar</label>
													</div>
												</div>
												<div class="col-auto">
													<router-link to="/forgotpassword" class="fs--1">
														¿Has olvidado tu contraseña?
													</router-link>
												</div>
											</div>
											<div class="mb-3">
												<button class="btn btn-primary d-block w-100 mt-3" name="submit" :disabled="disabledBtnLogin" @click="login">{{ msgBtnLogin }}</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>

	import ObjectInspector from 'vue-object-inspector';
	import mixin from './mixins'
	export default {
		name: 'Login',
		mixins: [mixin],
		data() {
			return {
				checkingLogin: false,
				credentials: {
					email: '',
					password: ''
				},
				resultLogin: {
					success: false,
					msgLoginMsg: '',
					error: null
				},
				msgBtnLogin: 'Ingresar',
			}
		},
		components: {
			'object-inspector': ObjectInspector
		},
		computed: {
			disabledBtnLogin() {
				if(!this.validateEmail(this.credentials.email))
					return true
				if(this.credentials.password.trim().length < 6)
					return true
				if(this.checkingLogin)
					return true
				return false
			}
		},
		methods: {
			login() {
				if(!this.disabledBtnLogin) {
					this.checkingLogin = true
					this.msgLoginMsg = ''
					this.msgBtnLogin = 'Conectando...'
					this.axios
						.post(this.getApiBackend + 'login', this.credentials)
						.then(response => {
							if(response.data.success === true) {
								this.loginSuccess(response.data.data)
							} else {
								this.loginError(response.data.message)
							}
						})
						.catch((response) => {
							this.checkingLogin = false
							this.msgBtnLogin = 'Intentar nuevamente'
							this.resultLogin.error = response.config
							if(!response.config.validateStatus()) {
								this.$notify({text: 'No conexión con el servidor de autentificación', type: 'error'})
							} else {
								this.$notify({text: 'Ha ocurrido un error inesperado', type: 'error'})
							}
						})
				}
			},
			loginError(msg) {
				this.resultLogin.success = false
				this.resultLogin.msgLoginMsg = msg
				this.checkingLogin = false
				this.msgBtnLogin = 'Ingresar'
				this.$notify({text: msg, type: 'warn'})
				console.error(msg)
			},
			loginSuccess(data) {
				this.resultLogin.success = true
				this.resultLogin.msgLoginMsg = 'Acceso confirmado'
				this.msgBtnLogin = 'Accediendo...'
				localStorage.setItem("token", data.token)
				this.$notify({text: 'Acceso confirmado', type: 'success'})
				setTimeout(()=>{ this.$router.push('/loading') }, 1000)
			},
		},
		mounted () {
			localStorage.removeItem("token")
		},
	}	
</script>

<style lang="scss">
</style>
