<template>
	<div id="attend" class="attend">
		<div class="d-flex mb-2">
			<span class="fa-stack me-2 ms-n1">
				<i class="fas fa-circle fa-stack-2x text-300"></i>
				<i class="fa-inverse fa-stack-1x text-primary fas fa-cart-plus" data-fa-transform="shrink-4"></i>
			</span>
            <div class="col">
				<h5 class="mb-0 text-primary position-relative">
					<span class="bg-200 dark__bg-1100 pe-3">Atención de Pedidos</span>
					<span class="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
				</h5>
				<p class="mb-0">Aquí se atiende todo lo que mueve Stock<span class="float-end">algo mas</span></p>
            </div>
        </div>
		<div class="card mb-3">
			<div class="card-body">
				<div class="row justify-content-between align-items-center">
					<div class="col-md">
						<input class="form-control form-control-sm" id="exampleFormControlInput1" type="email" :placeholder="placeholderInputSearch"/>
					</div>
					<div class="col-auto" v-if="false">
						<button class="btn btn-falcon-primary btn-sm">Buscar pedidos</button>
					</div>
				</div>
			</div>
		</div>

		<div class="row g-3 mb-3">
            <div class="col-lg-7">
              	<div class="card h-100">
                	<div class="card-header">
                  		<h5 class="mb-0">Detalle de articulos</h5>
                	</div>
                	<div class="card-body bg-light scrollbar-overlay" style="max-height: 75vh">
						<div class="table-responsive fs--1">
			                <table class="table table-striped border-bottom">
								<thead class="bg-200 text-900">
									<tr>
										<th class="border-0">Articulo</th>
										<th class="border-0 text-center">Cantidad</th>
										<th class="border-0 text-end">Atendido</th>
									</tr>
								</thead>
								<tbody>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42431076/resize/560/632?1700587470" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Solo un poco Mas</h6>
													</a>
													<p class="mb-0">SKU: 13156 - ISBN: 9789877989205</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
									<tr class="border-200">
										<td class="align-middle">
											<div class="d-flex align-items-center">
												<a href="">
													<img class="img-fluid rounded-1 me-3 d-none d-md-block" src="https://cdnx.jumpseller.com/editorial-aces-chile/image/42429647/resize/560/632?1700587096" alt="" width="40" />
												</a>
												<div class="flex-1">
													<a class="fs-0 text-900" href="">
														<h6 class="mb-0 text-nowrap pointer">Cristo: Diccionario de la celestial academia de la lengua</h6>
													</a>
													<p class="mb-0">SKU: 13154 - ISBN: 9789877989144</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">6</td>
										<td class="align-middle text-end">0</td>
									</tr>
								</tbody>
                			</table>
              			</div>
					</div>
              	</div>
            </div>
            <div class="col-lg-5">
            	<div class="card mb-2">
					<div class="card h-100">
                    	<div class="bg-holder d-none d-xl-block bg-card" style="background-image:url(assets/img/icons/spot-illustrations/corner-4.png);opacity: 0.7;"></div>
						<!--/.bg-holder-->
						<div class="card-body">
							<h5>Orden: #2737</h5>
							<p class="fs--1">April 21, 2019, 5:33 PM</p>
							<div>
								<strong class="me-2">Estado: </strong>
								<div class="badge rounded-pill badge-soft-success fs--2">
									Completed
									<span class="fas fa-check ms-1" data-fa-transform="shrink-2"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
            	<div class="card mb-3">
					<div class="card h-100">
                    	<div class="bg-holder d-none d-xl-block bg-card" style="background-image:url(assets/img/icons/spot-illustrations/corner-1.png);opacity: 0.7;"></div>
						<!--/.bg-holder-->
						<div class="card-body">
							<h5>Detalle de Envío</h5>
							<div>
								<table class="table table-borderless fs--1 fw-medium mb-0">
                        			<tbody>
										<tr>
											<td class="p-1" style="width: 35%;">Cliente:</td>
											<td class="p-1 text-600">Cliente WEB</td>
										</tr>
										<tr>
											<td class="p-1" style="width: 35%;">Dirección:</td>
											<td class="p-1 text-600">SANTA ELENA 1038</td>
										</tr>
										<tr>
											<td class="p-1" style="width: 35%;">Comuna:</td>
											<td class="p-1 text-600">Santiago</td>
										</tr>
										<tr>
											<td class="p-1" style="width: 35%;">Telefono:</td>
											<td class="p-1"><a class="text-600 text-decoration-none" href="tel:+56224418266">24418266</a></td>
										</tr>
									</tbody>
                      </table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from '@/mixins'
	import axiosAction from '@/mixins/axiosAction.js'
	import errors from '@/mixins/errors.js'

	export default {
		name: 'Attend',
		mixins: [mixin, axiosAction, errors],
		data() {
			return {
			}
		},
		components: {
		},
		computed: {
			placeholderInputSearch() {
				return 'Lea a etiqueta aquí'
			}
		},
		methods: {
			axiosResultLocal(responseLocal, response) {
				switch(responseLocal) {
					case 'defaultFunction': this.defaultFunction(response); break;
					default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
				}
			},
			initModuleLocal() {
			}
		},
		mounted () {
			this.initModule(this.$options.name)
		},
	}	
</script>

<style lang="scss">
</style>
