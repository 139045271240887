<template>
	<div id="shippingsRequest">
		<div class="card mb-1" id="shippingsRequestCard" v-if="!loadingModal">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Solicitudes de Envio </h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="Buscar solicitudes" aria-label="search"
										ref="searchShippingsRequest" :disabled="loadingShippingsRequest"
										v-model="searchShippingsRequest" @focus="$event.target.select()"
										@keyup.enter="getAllShippingsRequestLocal(1)" />
									<div class="input-group-text bg-transparent pointer"
										@click="getAllShippingsRequestLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div class="d-none" id="orders-bulk-actions">
							<div class="d-flex">
								<select class="form-select form-select-sm" aria-label="Bulk actions">
									<option selected="">Bulk actions</option>
									<option value="Refund">Refund</option>
									<option value="Delete">Delete</option>
									<option value="Archive">Archive</option>
								</select>
								<button class="btn btn-falcon-default btn-sm ms-2" type="button">Apply</button>
							</div>
						</div>
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm" type="button" data-bs-toggle="offcanvas"
								data-bs-target="#shippingsRequestOffCanvasRight" aria-controls="offcanvasRight"
								@click="showShippingRequestComponent" ref="btnShowShippingRequestComponent">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nueva</span>
							</button>
							<button class="btn btn-falcon-default btn-sm mx-2" type="button" data-bs-toggle="offcanvas"
								data-bs-target="#filtersShippingsRequestOffCanvasTop"
								aria-controls="filtersShippingsRequestOffCanvasTop">
								<span class="fas fa-filter" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Filtros</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<div class="text-center encima text-white bg-1000 fs-2" v-if="loadingShippingsRequest">
						<div style="z-index: 2;">
							<small class="spinner-border spinner-border-sm" role="status">
								<span class="visually-hidden">Loading...</span>
							</small>
							Buscando Solicitudes de envío ...
						</div>
					</div>
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 sort align-middle">Enviar a</th>
								<th class="text-900 sort align-middle white-space-nowrap" style="min-width: 12.5rem;">
									Dirección</th>
								<th class="text-900 sort align-middle white-space-nowrap text-center">Estado</th>
								<th class="text-900 sort align-middle white-space-nowrap">Comentario</th>
								<th class="text-900 sort align-middle white-space-nowrap text-end">Ingresado por</th>
								<th class="text-900 sort align-middle white-space-nowrap text-end">Acciones</th>
							</tr>
						</thead>
						<tbody class="">
							<tr class="btn-reveal-trigger border-start-2 border-end-1"
								v-for="(sr, index) in listShippingsRequestFilter" :key="index" :class="sr.classGeneral">
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<a class="text-primary pointer" @click="showShippingRequestLocal(sr.id)">
										<strong>#{{ sr.id }}</strong>
									</a>
									enviar a
									<strong>{{ sr.sendto }}</strong>
									<br />
									<small class="text-muted">
										<i class="far fa-envelope text-secondary"></i>
										<a href="mailto:ricky@example.com" class="text-muted ms-1">{{ sr.email }}</a>
										|<i class="far fa-address-card ms-1 text-secondary"></i>
										<span class="ms-1">{{ sr.number_document }}</span>
										|<i class="fas fa-phone-alt ms-1 text-secondary"></i>
										<span class="ms-1">{{ sr.phone }}</span>
									</small>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<div>
										{{ sr.address }}, {{ sr.comuna.name }}
										<span v-if="sr.comuna_id == null">
											<i class="fas fa-exclamation-triangle text-warning"
												title="La comuna no está seleccionada de una lista"></i>
										</span>
										<div>
											<span :class="'text-' + getColorTransport(sr.transport_id)">
												<i class="fas fa-truck-moving"></i> {{ sr.transport.name }}
												<span v-show="sr.paid_shipping == 0"> -
													<span class="badge bg-dark dark__bg-dark">Por Pagar</span>
												</span>
											</span>
											<span v-if="sr.freight_order != null && sr.freight_order != ''"
												@click="trackingTransportLocal(sr)" :title="'OF ' + sr.freight_order">
												<i class="ms-1 fas fa-info-circle text-info pointer"
													v-if="sr.freight_order != null && sr.freight_order != ''"
													:title="'OF ' + sr.freight_order"></i>
											</span>
										</div>
									</div>
								</td>
								<td class="align-middle text-center fs-0 white-space-nowrap border-bottom border-muted">
									<span class="badge badge rounded-pill d-block"
										:class="'badge-soft-' + sr.status.color">{{ sr.status.name }}</span>
								</td>
								<td class="align-middle fw-medium border-bottom border-muted">{{ sr.commentary }}</td>
								<td class="date align-middle text-end border-bottom border-muted">
									<span>{{ sr.user.name }}</span>
									<br />
									<small>{{ sr.created_at }}</small>
								</td>
								<td class="align-middle text-end border-bottom border-muted">
									<span @click="toPrintLabelLocal(sr)"
										:class="(getSuccessFullConnectionWebSocketServer) ? 'text-info pointer' : 'not-allowed'">
										<i class="fas fa-barcode ms-2"></i>
									</span>
									<span @click="toPrintLegalDocumentLocal(sr)"
										:class="(getSuccessFullConnectionFacturacionCLPrint) ? 'text-primary pointer' : 'not-allowed'">
										<i class="fas fa-print ms-2"></i>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="text-center" v-if="loadingShippingsRequest">
					Buscando Solicitudes de envío ...
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListShippingsRequest" @getAllList="getAllShippingsRequestLocal">
					</pagination>
				</div>
			</div>
		</div>
		<div v-else>
			Cargando Información minima del modulo ...
		</div>

		<div class="offcanvas offcanvas-end " id="shippingsRequestOffCanvasRight" tabindex="-1"
			aria-labelledby="shippingsRequestOffCanvasRightLabel" style="width: 600px" data-bs-keyboard="false">
			<div class="border border-primary"></div>
			<div class="offcanvas-header">
				<h5 id="shippingsRequestOffCanvasRightLabel">Solicitud de Envío -
					<span v-if="shippingRequestSelected == 0">Nueva</span>
					<span v-else @dblclick="showShippingRequestConsole">{{ shippingRequestSelected }}</span>
				</h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					v-show="false" ref="btnCloseShippingsRequestOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<div class="h-100">
					<shipping-request-component ref="shippingRequestComponent" :withLinkGenesys="true"
						:id="shippingRequestSelected"
						@deleteShippingRequestResultComponent="deleteShippingRequestResultLocal"
						@saveShippingRequestResultComponent="saveShippingRequestResultLocal"
						@shippingRequestFoundResult="shippingRequestFoundResultLocal"
						@updateStatusShippingRequestResultComponent="updateStatusShippingRequestResultLocal">
					</shipping-request-component>
				</div>
			</div>
			<div class="offcanvas-footer">
				<div class="container pb-1">
					<button class="btn btn-danger btn-sm" :disabled="disabledBtnDeleteShippingRequest"
						v-show="showBtnDeleteShippingRequest" @click="deleteShippingRequestLocal">Eliminar Soclicitud de
						Envío</button>
					<button class="btn btn-primary btn-sm float-end" @click="saveShippingRequestLocal">Guardar</button>
				</div>
			</div>
		</div>

		<div class="offcanvas offcanvas-top" id="filtersShippingsRequestOffCanvasTop" tabindex="-1"
			aria-labelledby="filtersShippingsRequestOffCanvasTopLabel" style="height: 230px">
			<div class="container">
				<div class="offcanvas-header border-bottom border-muted p-1 mb-1">
					<h5 id="filtersShippingsRequestOffCanvasTopLabel"><span class="fas fa-filter"
							data-fa-transform="shrink-3 down-2"></span> Filtros</h5>
					<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
						ref="btnCloseShippingsRequestOffCanvasTop"></button>
				</div>
				<div class="offcanvas-body pt-1 fs--1">
					<div class="row">
						<div class="col-sm-4 border-end border-muted">
							<h6>Estados</h6>
							<div class="row">
								<div v-for="(s, i) in listStatusFilter" :key="i" class="col-sm-6">
									<div class="form-check mb-0">
										<input class="form-check-input" :id="'flexCheck' + s.id" type="checkbox"
											value="" v-model="s.checked" @change="clickCheckboxStatus(0)" />
										<label class="form-check-label mb-0" :for="'flexCheck' + s.id">{{ s.name
											}}</label>
									</div>
								</div>
								<div v-if="listStatusFilter.length > 0" class="col-sm-6">
									<div class="form-check mb-0">
										<input class="form-check-input" id="'flexCheck0'" type="checkbox" value=""
											v-model="selectedAllStatus" @change="clickCheckboxStatus('-1')" />
										<label class="form-check-label mb-0" for="'flexCheck0'">Todo</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-4 border-end border-muted">
							<h6>Rango de Fecha</h6>
							<div class="mb-3 row">
								<label class="col-sm-2 col-form-label" for="staticEmail">Desde</label>
								<div class="col-sm-10">
									<input v-model="dateFromShippingsRequest" class="form-control form-control-sm"
										id="inputPassword" type="date" />
								</div>
								<label class="col-sm-2 col-form-label" for="inputPassword">Hasta</label>
								<div class="col-sm-10">
									<input v-model="dateToShippingsRequest" class="form-control form-control-sm"
										id="inputPassword" type="date" />
								</div>
							</div>
							<div class="row">
								<div class="col-sm-7"></div>
								<div class="col-sm-5 text-end">
									<button class="btn btn-primary btn-sm"
										@click="getAllShippingsRequestLocal(1)">Buscar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal de Trazabilidad Correos de Chile -->
		<button class="btn btn-primary" type="button" data-bs-toggle="modal"
			data-bs-target="#correosChileTrazabilidadComponentModal" ref="btnShowcorreosChileTrazabilidadComponentModal"
			v-show="false">Launch static backdrop modal</button>
		<div class="modal fade" id="correosChileTrazabilidadComponentModal" data-bs-keyboard="false"
			data-bs-backdrop="static" tabindex="-1" aria-labelledby="correosChileTrazabilidadComponentModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-lg mt-6" role="document">
				<div class="modal-content border-0">
					<div class="modal-header border-danger">
						<div class="rounded-top-3 bg-body-tertiary py-1 ps-2 pe-6">
							<h4 class="" id="correosChileTrazabilidadComponentModalLabel">
								Trazabilidad Correos Chile - OF {{ numeroEnvio }}
							</h4>
						</div>
						<div class="position-absolute top-0 end-0 mt-3 me-3 z-1">
							<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
								data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>
					<div class="modal-body py-4">
						<correos-chile-trazabilidad-component ref="correosChileTrazabilidadComponent"
							:numeroEnvio="numeroEnvio"></correos-chile-trazabilidad-component>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import shippingsRequest from '@/mixins/shippingsRequest'
import transport from '@/mixins/generals/transport.js'
import facturacionCL from '@/mixins/generals/facturacionCL.js'
import label from "@/mixins/labels/"
import analizeSTR from "@/mixins/generals/analizeSTR.js"
import status from '@/mixins/status'

import Pagination from '@/components/Pagination.vue'
import ShippingRequestComponent from '@/components/ShippingRequestComponent.vue'
import CorreosChileTrazabilidadComponent from '@/components/CorreosChileTrazabilidadComponent.vue'

export default {
	name: 'ShippingsRequest',
	mixins: [mixin, axiosAction, errors, shippingsRequest, transport, facturacionCL, label, analizeSTR, status],
	data() {
		return {
			classStatusGeneral: [
				{ class: 'border-dark', status_ids: [9, 10] },
				{ class: 'border-primary', status_ids: [11, 12, 13, 14, 67] },
				{ class: 'border-success', status_ids: [16, 17] },
				{ class: 'border-warning', status_ids: [9, 67] },
				{ class: 'border-danger', status_ids: [18] },
			],
			loadingModal: false,
			shippingRequestFoundErasable: false,
			objectShippingRequestSelected: null,
			numeroEnvio: 0
		}
	},
	components: {
		'shipping-request-component': ShippingRequestComponent,
		'pagination': Pagination,
		'correos-chile-trazabilidad-component': CorreosChileTrazabilidadComponent
	},
	computed: {
		disabledBtnDeleteShippingRequest() {
			let r = true
			if (this.objectShippingRequestSelected != null) {
				let i = this.listStatusErasable.map(s => parseInt(s)).indexOf(parseInt(this.objectShippingRequestSelected.status_id))
				r = (i == -1)
			}
			return r
		},
		listShippingsRequestFilter() {
			this.listShippingsRequest.forEach(sr => {
				if (sr.recipient_data != null) {
					sr.sendto = sr.recipient_data.sendto
					sr.address = sr.recipient_data.address
					sr.comuna = sr.recipient_data.comuna
					sr.comuna_id = sr.recipient_data.comuna_id
					sr.phone = sr.recipient_data.phone
					sr.number_document = sr.recipient_data.number_document
					sr.email = sr.recipient_data.email
					sr.paid_shipping = sr.recipient_data.paid_shipping
					sr.transport_id = sr.recipient_data.transport_id
					sr.transport = sr.recipient_data.transport
				} else {
					sr.sendto = ''
					sr.address = ''
					sr.comuna_id = 0
					sr.comuna = { name: '' }
					sr.phone = ''
					sr.number_document = ''
					sr.email = ''
					sr.paid_shipping = 0
					sr.transport_id = 0
					sr.transport = { name: '' }
				}
				if (sr.shipping.length == 1) {
					sr.freight_order = sr.shipping[0].freight_order
				}
				sr.total_price = parseInt(sr.total_price)
				sr.classGeneral = this.getClassStatusGeneral(sr.status_id)
			});
			return this.listShippingsRequest
		},
		listStatusFilter() {
			let statusIds = (localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != null && localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != undefined && localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != 'null' && localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != 'undefined') ? JSON.parse(localStorage.getItem(this.nameLocalStorageShippingRequestStatus)) : []

			this.listStatus.forEach(s => {
				s.checked = (statusIds.map(i => parseInt(i)).indexOf(parseInt(s.id)) > -1)
			})
			this.selectedAllStatus = (this.listStatus.length == statusIds.length)
			return this.listStatus
		},
		listTipocompFilter() {
			return [
				{ tipocompcod: 20, tipocompdesc: 'Orden de Pedido', sintetico: 'OP', view: true },
				{ tipocompcod: 2, tipocompdesc: 'Nota de Crédito', sintetico: 'NC', view: true },
				{ tipocompcod: 120, tipocompdesc: 'Reposición', sintetico: 'R', view: true },
				{ tipocompcod: 40, tipocompdesc: 'Envio de Suscripción', sintetico: 'ES', view: true },
				{ tipocompcod: 1, tipocompdesc: 'Boleta/Factura', sintetico: 'B/F', view: false }
			]
		},
		showBtnDeleteShippingRequest() {
			return (this.shippingRequestFoundErasable ||
				this.actionSecurity(15)) &&
				this.objectShippingRequestSelected != null
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllStatusResult': this.getAllStatusResult(response); break;
				case 'getAllShippingsRequestResult': this.getAllShippingsRequestResult(response); break;
				case 'getAllTransportsResult': this.getAllTransportsResult(response); break;
				case 'showLabelResult': this.showLabelResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clickCheckboxStatus(option = '0') {
			let ids = '['
			let c = 0
			this.listStatusFilter.forEach(s => {
				if (option == '-1') {
					s.checked = this.selectedAllStatus
				}
				if (s.checked == true) {
					c++
					ids += (ids != '[') ? ',' + s.id : s.id
				}
			})
			ids += ']'
			console.log(option, c, this.listStatusFilter.length)
			if (option == 0) {
				this.selectedAllStatus = (c == this.listStatusFilter.length)
			}
			localStorage.setItem(this.nameLocalStorageShippingRequestStatus, ids)
		},
		deleteShippingRequestLocal() {
			this.$refs.shippingRequestComponent.$refs.btnDeleteShippingRequest.click()
		},
		deleteShippingRequestResultLocal(response) {
			if (response.data.success == true && response.data.data > 0) {
				this.deleteItemListShippingsRequest(response.data.data)
				this.objectShippingRequestSelected = null
				this.$refs.btnCloseShippingsRequestOffCanvasRight.click()
			}
		},
		initModuleLocal() {
			this.dateFromShippingsRequest = this.currentDate(-3)
			this.dateToShippingsRequest = this.currentDate()
			this.loadingModal = true
			this.getAllTransportsLocal()
		},
		getAllShippingsRequestLocal(page = 1) {
			this.searchShippingsRequest = (this.analizeSTR(this.searchShippingsRequest).result == 'SHIPPINGREQUEST') ? this.analizeSTR(this.searchShippingsRequest).data : this.searchShippingsRequest
			let statusIds = (localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != null && localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != undefined && localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != 'null' && localStorage.getItem(this.nameLocalStorageShippingRequestStatus) != 'undefined') ? JSON.parse(localStorage.getItem(this.nameLocalStorageShippingRequestStatus)) : []
			this.$refs.btnCloseShippingsRequestOffCanvasTop.click()
			this.getAllShippingsRequest(page, statusIds)
		},
		getAllShippingsRequestResultLocal(response) {
			this.loadingModal = false
			setTimeout(() => { this.$refs.searchShippingsRequest.focus() }, 50)
		},
		getAllStatusResultLocal(response) {
			this.getAllShippingsRequestLocal()

		},
		getAllTransportsLocal() {
			this.getAllTransports()
		},
		getAllTransportsResultLocal(response) {
			this.showLabelLocal()
		},
		getClassStatusGeneral(statusId) {
			let css = ''
			let index = -1
			this.classStatusGeneral.forEach(csg => {
				index = csg.status_ids.map(id => parseInt(id)).indexOf(parseInt(statusId))
				if (index > -1) {
					css = csg.class
				}
			})
			return css
		},
		getCodbaretiGenesys(shippingRequestGenesysCabmov) {
			let codbareti = ''
			if (shippingRequestGenesysCabmov.length > 0)
				codbareti = shippingRequestGenesysCabmov[0].codbareti
			return codbareti

		},
		getLegalDocumentNumberRel(item, show = 'ALL', typeLegalDocumentIdMain = 52) {
			let legalDocuments = []
			item.legal_documents.forEach(e => {
				legalDocuments.push({ type_legal_document_id: e.type_legal_document_id, legal_document_number: e.legal_document_number })
			})
			item.genesys_cabmov.forEach(e => {
				if (parseInt(e.tipocompcod) == 1 || parseInt(e.tipocompcod) == 2 || parseInt(e.tipocompcod) == 33 || parseInt(e.tipocompcod) == 39 || parseInt(e.tipocompcod) == 52) {
					legalDocuments.push({ type_legal_document_id: e.tipocompcod, legal_document_number: e.nrocomp })
				}
			})
			let result = false
			if (legalDocuments.length > 0) {
				if (show == 'ALL') {
					result = ''
					legalDocuments.forEach(e => {
						result += e.legal_document_number + ' (' + e.type_legal_document_id + ')\n'
					})
				} else if (show == 'MAIN') {
					if (legalDocuments.length == 1) {
						result = legalDocuments[0].legal_document_number
					} else {
						let index = legalDocuments.map(e => parseInt(e.type_legal_document_id)).indexOf(typeLegalDocumentIdMain)
						result = (index > -1) ? legalDocuments[index].legal_document_number : legalDocuments[0].legal_document_number
					}
				}
			}
			return result;
		},
		getNrocompGenesys(shippingRequestGenesysCabmov, type) {
			let nrocomp = 'S/N'
			let con = 0
			Object.values(shippingRequestGenesysCabmov).forEach(val => {
				let index = this.listTipocompFilter.map((el) => parseInt(el.tipocompcod)).indexOf(parseInt(val.tipocompcod))
				if (index > -1 && type == 'INT' && this.listTipocompFilter[index].view) {
					con++
					nrocomp = (nrocomp == 'S/N') ? this.listTipocompFilter[index].sintetico + val.nrocomp : nrocomp
				} else if (index > -1 && type == 'LEG' && !this.listTipocompFilter[index].view) {
					nrocomp = val.nrocomp
				}
			})
			if (type == 'INT' && nrocomp != 'S/N' && con > 1) {
				nrocomp = (con == 2) ? nrocomp + "+" : nrocomp + "+" + (con - 1)
			}
			return nrocomp
		},
		saveShippingRequestLocal() {
			this.$refs.shippingRequestComponent.$refs.btnSaveShippingRequest.click()
		},
		saveShippingRequestResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.message[0].message == 'UPDATE') {
					this.updateItemListShippingsRequest(response.data.data)
				} else if (response.data.message[0].message == 'INSERT') {
					this.addListShippingsRequest(response.data.data)
				}
				this.objectShippingRequestSelected = null
				this.$refs.btnCloseShippingsRequestOffCanvasRight.click()
			}
		},
		shippingRequestFoundResultLocal(sr) {
			this.objectShippingRequestSelected = sr
			if (sr.user_id == this.getUser.id) {
				this.shippingRequestFoundErasable = true
			}
		},
		showLabelLocal() {
			this.showLabel(1)
		},
		showLabelResultLocal() {
			this.getAllStatus()
		},
		showShippingRequestConsole() {
			this.$refs.shippingRequestComponent.$refs.btnShowShippingRequestConsole.click()
		},
		showShippingRequestComponent() {
			this.objectShippingRequestSelected = null
			this.shippingRequestFoundErasable = false
			this.shippingRequestSelected = 0
			this.$refs.shippingRequestComponent.$refs.btnClearShippingRequest.click()
		},
		showShippingRequestLocal(id) {
			this.objectShippingRequestSelected = null
			this.shippingRequestFoundErasable = false
			this.$refs.btnShowShippingRequestComponent.click()
			setTimeout(() => {
				this.shippingRequestSelected = id
				setTimeout(() => { this.$refs.shippingRequestComponent.$refs.btnShowShippingRequest.click() }, 100)
			}, 50)
		},
		trackingTransportLocal(s) {
			if (parseInt(s.transport_id) == 3) {
				this.numeroEnvio = s.freight_order
				setTimeout(() => {
					this.$refs.btnShowcorreosChileTrazabilidadComponentModal.click()
					this.$refs.correosChileTrazabilidadComponent.$refs.btnTrazabilidadCorreosChile.click()
				}, 50)

			}
		},
		toPrintLegalDocumentLocal(item) {
			item.genesys_cabmov.forEach(e => {
				if (parseInt(e.tipocompcod) == 1 || parseInt(e.tipocompcod) == 33 || parseInt(e.tipocompcod) == 39 || parseInt(e.tipocompcod) == 52 || parseInt(e.tipocompcod) == 56 || parseInt(e.tipocompcod) == 61) {
					e.tipocompcod = (parseInt(e.tipocompcod) == 1) ? 39 : (parseInt(e.tipocompcod) == 2) ? 61 : parseInt(e.tipocompcod)
					this.toPrintFacturacionCL(e.tipocompcod, e.nrocomp)
				}
			})
			item.legal_documents.forEach(e => {
				console.log('Imprimir', e.type_legal_document_id, e.legal_document_number)
				this.toPrintFacturacionCL(e.type_legal_document_id, e.legal_document_number)
			})
		},
		updateStatusShippingRequestResultLocal(srtsr) {
			this.updateStatusListShippingsRequest(srtsr)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss">
.scrollbar {
	position: relative;
	z-index: 0;
}

.encima {
	position: absolute;
	z-index: 1;
	opacity: 0.5;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
</style>
