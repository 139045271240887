<template>
	<div id="unauthorized">
		<div class="row flex-center min-vh-100 py-6 text-center">
			<div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5"><a class="d-flex flex-center mb-4" href="/index.html"><img class="me-2" src="/assets/img/icons/spot-illustrations/falcon.png" alt="" width="58" /><span class="font-sans-serif fw-bolder fs-5 d-inline-block">ACES Chile</span></a>
				<div class="card">
				<div class="card-body p-4 p-sm-5">
					<div class="fw-black lh-1 text-300 fs-error">401</div>
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">No tiene permitido el ingreso sin autentificarse</p>
					<hr />
					<p>Asegurese de tener los permisos necesarios para acceder al Sistema.</p><p> Si crees que esto es un error <a href="mailto:colportajechile@editorialaces.com">contactenos</a>.</p><a class="btn btn-primary btn-sm mt-3" href="../../index.html"><span class="fas fa-home me-2"></span>Ir al Control de Acceso</a>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Unauthorized',
		data() {
			return {
			}
		},
		computed: {
		},
		methods: {
		},
		mounted () {
			localStorage.removeItem("token")
			console.clear()
			setTimeout(()=>{ this.$router.push('/login') }, 5000)
		},
	}	
</script>

<style lang="scss">
</style>
