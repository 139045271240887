<template>
	<div id="journalsModule">
		<div v-show="journalIdSelected == -1">
			<juournal-list-component @showJournal="showJournalLocal"></juournal-list-component>
		</div>
		<div v-show="journalIdSelected > -1">
			<juournal-component ref="juournalComponent" :id="journalIdSelected"
				@listJournal="listJournalLocal"></juournal-component>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import JuournalListComponent from '@/components/JuournalListComponent.vue'
import JuournalComponent from '@/components/JuournalComponent.vue'

export default {
	name: 'JuournalListComponent',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
			journalIdSelected: -1
		}
	},
	components: {
		'juournal-list-component': JuournalListComponent,
		'juournal-component': JuournalComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		},
		listJournalLocal() {
			this.journalIdSelected = -1
		},
		showJournalLocal(id) {
			this.journalIdSelected = id
			setTimeout(() => { this.$refs.juournalComponent.$refs.btnShowJournal.click() }, 50)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
