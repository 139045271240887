/* Rquiere de mixin.js */

var errors = {
	methods: {
		errorHttpHandling(error) {
			if (error.response !== undefined) {
				let msg = (error.response.data !== undefined) ? error.response.data.message : error.response.statusText

				switch (error.response.status) {
					case 401:
						this.error401()
						break
					case 403:
						this.error403()
						break
					case 404:
						this.error404(error.response)
						break
					case 500:
						this.error500(error.response)
						break
					default:
						this.showMsgErrorHandling('Error Http!!! - (' + error.response.status + ') ' + msg)
						break
				}
			} else {
				this.showMsgErrorHandling('Front-end', error, 'error')
			}
		},
		error401() {
			this.showMsgErrorHandling('Error', 'No autentificado. Se cerrará la aplicación', 'error', 5000, 'top right')
			setTimeout(() => this.path('/logout'), 3000)
		},
		error403() {
			this.showMsgErrorHandling('No autorizado', '<b>No esta autorizado para esta accion.</b><br><i>Comuniquese con el Administrador</i>', 'warn', 5000, 'top right')
		},
		error404(response) {
			this.showMsgErrorHandling('NO ENCONTRADO', '<b>Error 400</b> No podemos encontrar la URL en el Servidor', 'warn', 5000, 'top right')
			if (this.actionSecurity(0)) {
				console.log(response)
			}
		},
		error500(response) {
			this.showMsgErrorHandling('Error en el Servidor', 'El servidor ha fallado, revise la consola para mas información', 'error', 5000, 'top right')
			if (this.actionSecurity(0)) {
				response.data.message = response.data.message.replaceAll('\:', '\:\n')
				console.error('Error en la linea: ' + response.data.line + '\n> Archivo: ' + response.data.file + '\n> Mensaje: ' + response.data.message)
			}
		},
		showMsgErrorHandling(title = '', text, type = 'info', duration = 10000, position = 'bottom, right') {
			this.setNotifyPosition(position)
			this.$notify({ title: '<b>' + title.toUpperCase() + '</b>', text: text, type: type, duration: duration })
			this.showMsgErrorHandlingLocal(text)
		},
		showMsgErrorHandlingLocal(text) {
			console.log("showMsgErrorHandlingLocal no desarrollado \n> Mensaje: ", text)
		}
	},
};

export default errors;