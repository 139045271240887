<template>
	<div id="rolesModule">
		<div class="card mb-3 p-3">
			<role-list-component></role-list-component>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

import RoleListComponent from '@/components/RoleListComponent.vue'

export default {
	name: 'RolesModule',
	mixins: [mixin],
	components: {
		'role-list-component': RoleListComponent
	},
	methods: {
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>