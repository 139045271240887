<template>
	<div id="menusModule">
		<div class="card mb-3 p-3">
			<menu-list-component :openAll="false" :selectable="false" :selectAll="false" :editable="true"
				:insertable="true" :viewDescription="true">
			</menu-list-component>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

import MenuListComponent from '@/components/MenuListComponent.vue'

export default {
	name: 'MenusMenusModule',
	mixins: [mixin],
	components: {
		'menu-list-component': MenuListComponent
	},
	methods: {
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
