<template>
	<div id="orders">
		<order-team-list-component ref="orderTeamListComponent" @showNewOrder="showNewOrderModalLocal"
			:newOrder="newOrder"></order-team-list-component>
		<new-order-modal ref="newOrderModal" @saveOrder="saveOrderLocal"></new-order-modal>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import NewOrderModal from '@/components/OrderColportajeModalComponent.vue'
import OrderTeamListComponent from '@/components/OrderTeamListComponent.vue'


export default {
	name: 'Orders',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
			newOrder: null
		}
	},
	components: {
		'new-order-modal': NewOrderModal,
		'order-team-list-component': OrderTeamListComponent
	},
	computed: {
	},
	methods: {
		initModuleLocal() {
		},
		saveOrderLocal(data) {
			this.newOrder = data
			setTimeout(() => { this.$refs.orderTeamListComponent.$refs.btnAddListOrders.click() }, 50)
		},
		showNewOrderModalLocal() {
			this.newOrder = null
			this.$refs.newOrderModal.$refs.btnShowOrderColportajeModalComponent.click()
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
