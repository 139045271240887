<template>
	<div id="orderByTeamComponent">
		<button ref="btnShowOrder" @click="showOrderLocal" :disabled="searchingOrder" v-show="false">Show Order</button>
		<div v-show="!searchingOrder" v-if="objectOrder.id > 0">
			<div class="card mb-3">
				<div class="bg-holder d-none d-lg-block bg-card"
					style="background-image:url(/assets/img/icons/spot-illustrations/corner-4.png);opacity: 0.7;">
				</div>
				<!--/.bg-holder-->

				<div class="card-body position-relative">
					<h5>{{ objectOrder.typeOrder.name }}: #{{ objectOrder.id }}</h5>
					<p class="fs--1">{{ objectOrder.created_at | formatDateFull }}</p>
					<div><strong class="me-2 fs-1">Status: </strong>
						<span class="badge badge rounded-pill fs-1" :class="'badge-soft-' + objectOrder.status.name">{{
			objectOrder.status.name }}</span>
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
							<h5 class="mb-2 fs-0">Cargado por</h5>
							<h6 class="mb-1">{{ objectOrder.user.name }}</h6>
						</div>
						<div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
							<h5 class="mb-2 fs-0">Cargado a</h5>
							<h6 class="mb-1">{{ objectOrder.client.name }} {{ objectOrder.client.surname }}</h6>
							<p class="mb-0 fs--1">{{ objectOrder.team.name }}</p>
						</div>
						<div class="col-md-6 col-lg-4" v-if="objectOrder.client_address_client_sendto != null">
							<h5 class="mb-2 fs-0">Enviar a</h5>
							<h6 class="mb-1">{{ objectOrder.client_address_client_sendto.client_sendto.sendto }}</h6>
							<p class="mb-0 fs--1">
								<strong>
									<i class="far fa-address-card"></i>
								</strong>
								<span class="ms-1">
									{{ objectOrder.client_address_client_sendto.client_sendto.number_document }}
								</span>
							</p>
							<p class="mb-0 fs--1">
								<strong>
									<i class="fas fa-envelope"></i>
								</strong>
								<a class="ms-1"
									:href="'mailto:' + objectOrder.client_address_client_sendto.client_sendto.email">
									{{ objectOrder.client_address_client_sendto.client_sendto.email }}
								</a>
							</p>
							<p class="mb-0 fs--1">
								<strong>
									<i class="fas fa-phone-alt"></i>
								</strong>
								<a class="ms-1"
									:href="'tel:' + objectOrder.client_address_client_sendto.client_sendto.phone">
									{{ objectOrder.client_address_client_sendto.client_sendto.phone }}
								</a>
							</p>
							<p class="mb-0 fs--1">
								<strong>
									<i class="fas fa-house-user"></i>
								</strong>
								<span class="ms-1">
									{{ objectOrder.client_address_client_sendto.address.street }},
									{{ objectOrder.client_address_client_sendto.address.comuna.name }}
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="row g-3">
				<div class="col-md-8">
					<div class="card h-auto mh-100 mb-3">
						<div class="card-body">
							<div class="table-responsive fs--1">
								<table class="table table-striped border-bottom">
									<thead class="bg-200">
										<tr>
											<th class="text-900 border-0">Articulo</th>
											<th class="text-900 border-0 text-center">Cantidad</th>
											<th class="text-900 border-0 text-end">Precio</th>
											<th class="text-900 border-0 text-end">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(op, i) in objectOrder.products" :key="i" class="border-200">
											<td class="align-middle">
												<h6 class="mb-0 text-nowrap">{{ op.product.name }}</h6>
												<p class="mb-0">Codigo {{ op.product.id }}</p>
											</td>
											<td class="align-middle text-center">{{ methodFormatNumber(op.quantity) }}
											</td>
											<td class="align-middle text-end">${{ methodFormatNumber(op.price) }}</td>
											<td class="align-middle text-end">${{ methodFormatNumber(op.price *
			op.quantity) }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="row g-0 justify-content-end">
								<div class="col-auto">
									<table class="table table-sm table-borderless fs--1 text-end">
										<tr>
											<th class="text-900">Subtotal:</th>
											<td class="fw-semi-bold">${{ methodFormatNumber(objectOrder.total) }}</td>
										</tr>
										<tr>
											<th class="text-900">Diezmo:</th>
											<td class="fw-semi-bold">${{ methodFormatNumber(objectOrder.tithe) }}</td>
										</tr>
										<tr class="border-top">
											<th class="text-900">Total:</th>
											<td class="fw-semi-bold">${{ methodFormatNumber(parseInt(objectOrder.total)
			+
			parseInt(objectOrder.tithe)) }}</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer bg-light pt-0">
						<div class="border-bottom border-200 fs--1 py-3">
							<a class="text-700" href="#!">{{ objectOrder.observations.length }} Comentarios</a>
						</div>
						<div class="d-flex align-items-center border-top border-200 pt-3">
							<div class="avatar avatar-xl">
								<img class="rounded-circle" :src="getUrlImagen + 'perfil/' + getUser.img" alt="" />
							</div>
							<input class="form-control rounded-pill ms-2 fs--1" type="text"
								placeholder="Escriba su comentario" v-model="observation"
								@keyup.enter="saveOrderObservationLocal(objectOrder)" />
						</div>
						<div class="d-flex mt-3" v-for="(item, index) in objectOrder.observations" :key="index">
							<div class="avatar avatar-xl">
								<img class="rounded-circle" :src="getUrlImagen + 'perfil/' + item.user.img" alt="" />
							</div>
							<div class="flex-1 ms-2 fs--1">
								<p class="mb-1 bg-200 rounded-3 p-2"><a class="fw-semi-bold">{{ item.user.name }}</a> {{
			item.observation }}</p>
								<div class="px-2">
									{{ item.created_at | formatDateMin }}
									<span class="text-danger pointer" v-if="item.user_id == getUser.id"
										@click="deleteOrderObservationLocal(item)">Borrar</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4" v-show="objectOrder.type_order_id == 2">
					<div class="card mb-3">
						<div class="card-header">
							<h6 class="mb-0">Seguimiento</h6>
						</div>
						<div class="card-body scrollbar recent-activity-body-height ps-2">
							<div v-for="(s, i) in statusOrderFunction()" :key="i"
								class="row g-3 timeline timeline-primary pb-x1 mb-2"
								:class="(s.tracking != null) ? (statusOrderCurrent == s.status_id) ? 'timeline-current' : 'timeline-past' : ''">
								<div class="col-auto ps-4 ms-2">
									<div class="ps-2">
										<div class="icon-item icon-item-sm rounded-circle bg-200 shadow-none"
											:class="(s.tracking != null) ? 'text-primary' : ''">
											<span :class="s.icon"></span>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="row gx-0 border-bottom pb-x1">
										<div class="col">
											<h6 class="text-800 mb-1">{{ s.name }}</h6>
											<i v-if="s.tracking == null" class="fs--1 text-600 mb-2">
												Esperando información ...
											</i>
											<p v-else class="fs--1 text-600 mb-2">
												realizado por {{ s.tracking.user.name }}
											</p>
										</div>
										<div class="col-auto">
											<p v-if="s.tracking == null" class="fs--2 text-500 mb-0">??</p>
											<p v-else class="fs--2 text-500 mb-0">{{ s.tracking.created_at |
			formatDateMin }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card mb-3" v-show="objectOrder.legal_document.length > 0">
						<div class="card-header">
							<h6 class="mb-0">Documentos Legales</h6>
						</div>
						<div class="card-body ps-1">
							<table class="table table-sm fs--1">
								<tbody>
									<tr v-for="(ld, i) in objectOrder.legal_document" :key="i">
										<td>
											{{ ld.type_legal_document.name }}
										</td>
										<td class="text-end">
											<a class="text-primary" :href="ld.url"
												:download="ld.type_legal_document.syntetic + ld.legal_document_number"
												v-show="ld.url != '' && ld.legal_document_number > 0"
												title="Descargar Documento">
												{{ ld.legal_document_number }}
											</a>
											<span v-show="ld.legal_document_number == 0">
												{{ ld.legal_document_number }}
											</span>
										</td>
										<td class="text-end">
											{{ methodFormatNumber(ld.total) }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div v-show="showBtnConfirm" class="text-end">
						<button class="btn btn-sm btn-primary" @click="confirmOrderLocal"
							:disabled="confirmingOrder">Confirmar</button>
					</div>
				</div>
			</div>
			<div class="text-end" v-show="showBtnDeleteOrder">
				<button class="btn btn-sm btn-danger" @click="deleteOrderLocal">Eliminar Pedido</button>
			</div>
		</div>
		<div v-show="searchingOrder">
			Buscando Orden Nº {{ id }}
		</div>

		<!-- Modal Legal Document Component -->
		<button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#legalDocumentModal"
			ref="btnShowLegalDocumentModal" v-show="false">Show Legal Document</button>
		<div class="modal fade" id="legalDocumentModal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
			aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl mt-6" role="document">
				<div class="modal-content border-0">
					<div class="position-absolute top-0 end-0 mt-3 me-3 z-1">
					</div>
					<div class="modal-body p-0">
						<div class="rounded-top-3 bg-body-tertiary py-3 ps-4 pe-6">
							<h4 class="mb-1" id="staticBackdropLabel">Guia de despacho</h4>
						</div>
						<div class="p-4">
							<legal-document-component ref="legalDocumentComponent" typeLegalDocumentId="52"
								@cancel="cancelLegaDocumentLocal"
								@saveLegalDocumentResult="saveLegalDocumentResultLocal"
								@sendToRegulatoryEntityResult="sendToRegulatoryEntityResultLocal" textBtnCancel="Cerrar"
								:disabledTypeLegalDocument="false" :showAddItemLegalDocument="false"
								:data="objectLegalDocument"></legal-document-component>
						</div>
					</div>
					<div class="modal-footer" v-show="false">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
							ref="btnCloseLegalDocumentModal">Close</button>
					</div>
				</div>
			</div>
		</div>

		<!-- offcanvas Shipping Request Component -->
		<button class="btn btn-falcon-default btn-sm" type="button" data-bs-toggle="offcanvas"
			data-bs-target="#shippingsRequestOffCanvasRight" aria-controls="offcanvasRight"
			@click="showShippingRequestComponent" ref="btnShowShippingRequestComponent" v-show="false">New Shipping
			Request
		</button>
		<div class="offcanvas offcanvas-end " id="shippingsRequestOffCanvasRight" ref="shippingsRequestOffCanvasRight"
			tabindex="-1" aria-labelledby="shippingsRequestOffCanvasRightLabel" style="width: 600px"
			data-bs-keyboard="false">
			<div class="border border-primary"></div>
			<div class="offcanvas-header">
				<h5 id="shippingsRequestOffCanvasRightLabel">Solicitud de Envío -
					<span v-if="shippingRequestSelected == 0">Nueva</span>
					<span v-else @dblclick="showShippingRequestConsole">{{ shippingRequestSelected }}</span>
				</h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					v-show="false" ref="btnCloseShippingsRequestOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<div class="h-100">
					<shipping-request-component ref="shippingRequestComponent" :withLinkGenesys="true"
						:id="shippingRequestSelected"
						@saveShippingRequestResultComponent="saveShippingRequestResultLocal"
						@savedShippingRequest="savedShippingRequestLocal" :data="objectShippingRequest">
					</shipping-request-component>
				</div>
			</div>
			<div class="offcanvas-footer">
				<div class="container pb-1">
					<button class="btn btn-primary btn-sm float-end" @click="saveShippingRequestLocal"
						:disabled="disabledBtnSaveShippingRequest">Guardar</button>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import order from '@/mixins/orders'
import legalDocument from '@/mixins/legalDocuments'

import LegalDocumentComponent from '@/components/LegalDocumentComponent.vue'
import ShippingRequestComponent from '@/components/ShippingRequestComponent.vue'

export default {
	name: 'OrderByTeamComponent',
	mixins: [mixin, axiosAction, errors, order, legalDocument],
	props: ['id'],
	data() {
		return {
			statusOrder: [
				{ status_id: 30, name: 'Creado', synthetic: 'CREATED', 'type_status_id': 6, color: 'light', show: true, icon: 'far fa-list-alt', tracking: null, erasable: true },
				{ status_id: 31, name: 'Confirmado', synthetic: 'CONFIRMED', 'type_status_id': 6, color: 'dark', show: true, icon: 'fas fa-check', tracking: null, erasable: false },
				{ status_id: 33, name: 'Atendido', synthetic: 'ATTENDED', 'type_status_id': 6, color: 'info', show: true, icon: 'fas fa-clipboard-check', tracking: null, erasable: false },
				{ status_id: 34, name: 'Empacado', synthetic: 'PACKED', 'type_status_id': 6, color: 'secondary', show: true, icon: 'fas fa-box', tracking: null, erasable: false },
				{ status_id: 35, name: 'Despachado', synthetic: 'DISPATCHED', 'type_status_id': 6, color: 'primary', show: true, icon: 'fas fa-paper-plane', tracking: null, erasable: false },
				{ status_id: 36, name: 'Entregado', synthetic: 'DELIVERED', 'type_status_id': 6, color: 'success', show: true, icon: 'fas fa-handshake', tracking: null, erasable: false },
			],
			statusOrderCurrent: null,
			objectLegalDocument: {
				receiving_entity_document_number: '',
				receiving_entity_name: '',
				receiving_entity_economic_activity: '',
				receiving_entity_address_street: '',
				receiving_entity_address_comuna: '',
				receiving_entity_address_city: '',
				details: []
			},
			objectShippingRequest: {
				commentary: '',
				total_price: 0,
				recipient_data: {
					id: 0,
					shipping_request_id: 0,
					sendto: '',
					address: '',
					comuna_id: 0,
					comuna: null,
					phone: '',
					number_document: '',
					email: '',
					paid_shipping: 1,
					transport_id: 0,
					transport: null
				},
			},
			shippingRequestSelected: 0,
			confirmingOrder: false,
			disabledBtnSaveShippingRequest: false
		}
	},
	components: {
		'legal-document-component': LegalDocumentComponent,
		'shipping-request-component': ShippingRequestComponent,
	},
	computed: {
		confirmedOrder() {
			let i = this.statusOrder.map(so => parseInt(so.status_id)).indexOf(31)
			let r = false
			if (i > -1) {
				r = (this.statusOrder[i].tracking != null)
			}
			return r
		},
		showBtnConfirm() {
			return !this.confirmedOrder && this.actionSecurity(18)
		},
		showBtnDeleteOrder() {
			if (this.objectOrder.type_order_id != 2) {
				return false
			}
			let r = false

			if (this.objectOrder.trackings_order.length > 0) {
				console.log(parseInt(this.objectOrder.trackings_order[0].user_id), this.getUser.id)
				if (parseInt(this.objectOrder.trackings_order[0].user_id) != parseInt(this.getUser.id)) {
					let i = this.statusOrder.map(so => parseInt(so.status_id)).indexOf(parseInt(this.objectOrder.status_id))
					if (i > -1) {
						r = this.statusOrder[i].erasable
					}
				}
			}
			console.log(r)

			r = (!r) ? this.actionSecurity(19) : r

			return r
		},
		trackingsOrder() {
			return this.objectOrder.trackings_order
		},
	},
	methods: {
		addOrderTrackingsOrderResultLocal(response) {
			if (response.data.success == true) {
				this.$refs.btnCloseShippingsRequestOffCanvasRight.click()
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'addOrderTrackingsOrderResult': this.addOrderTrackingsOrderResult(response); break;
				case 'deleteOrderResult': this.deleteOrderResult(response); break;
				case 'deleteOrderObservationResult': this.deleteOrderObservationResult(response); break;
				case 'getProductPriceListFromOrderResult': this.getProductPriceListFromOrderResult(response); break;
				case 'saveOrderLegalDocumentResult': this.saveOrderLegalDocumentResult(response); break;
				case 'saveOrderObservationResult': this.saveOrderObservationResult(response); break;
				case 'saveOrderShippingRequestResult': this.saveOrderShippingRequestResult(response); break;
				case 'sendToRegulatoryEntityResult': this.sendToRegulatoryEntityResult(response); break;
				case 'showOrderResult': this.showOrderResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		cancelLegaDocumentLocal() {
			this.confirmingOrder = false
			this.$refs.btnCloseLegalDocumentModal.click()
		},
		confirmOrderLocal() {
			this.confirmingOrder = true
			if (this.objectOrder.legal_document.length == 0) {
				this.getProductPriceListFromOrderLocal()
			} else {
				let i = this.objectOrder.legal_document.map(ld => parseInt(ld.type_legal_document_id)).indexOf(52)
				if (i > -1) {
					if (this.objectOrder.legal_document[i].legal_document_number > 0) {
						this.showShippingRequestLocal()
					} else {
						this.sendToRegulatoryEntityLocal(this.objectOrder.legal_document[i].id)
					}
				}

			}
		},
		deleteOrderLocal() {
			if (this.showBtnDeleteOrder) {
				this.$confirm(
					{
						message: 'Está seguro que quiere eliminar la Orden Nº ' + this.objectOrder.id,
						button: {
							no: 'No, de ninguna manera',
							yes: 'Si, dale'
						},
						callback: confirm => {
							if (confirm) {
								this.deleteOrder(this.objectOrder.id)
							}
						}
					}
				)
			}
		},
		deleteOrderResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.data == this.objectOrder.id) {
					this.showMsgErrorHandling('Eliminado', 'Se ha eliminado con existo la Orden Nº ' + response.data.data, 'success', 5000, 'top right')
					this.path('/teamOrders')
				}
			} else {
				this.showMsgErrorHandling('NO Eliminado', response.data.message, 'warn', 5000, 'top right')
			}

		},
		getProductPriceListFromOrderLocal() {
			this.getProductPriceListFromOrder(this.objectOrder.id, 3)
		},
		getProductPriceListFromOrderResultLocal(response) {
			if (response.data.success == true) {
				let i = -1
				let price = 0
				this.objectLegalDocument.receiving_entity_document_number = this.objectOrder.client_address_client_sendto.client_sendto.number_document
				this.objectLegalDocument.receiving_entity_name = this.objectOrder.client_address_client_sendto.client_sendto.sendto
				this.objectLegalDocument.receiving_entity_economic_activity = ''
				this.objectLegalDocument.receiving_entity_address_street = this.objectOrder.client_address_client_sendto.address.street
				this.objectLegalDocument.receiving_entity_address_comuna = this.objectOrder.client_address_client_sendto.address.comuna.name
				this.objectLegalDocument.receiving_entity_address_city = this.objectOrder.client_address_client_sendto.address.comuna.name
				this.objectLegalDocument.contact = this.objectOrder.client_address_client_sendto.client_sendto.sendto
				this.objectLegalDocument.phone = this.objectOrder.client_address_client_sendto.client_sendto.phone
				this.objectLegalDocument.details = []
				this.objectOrder.products.forEach(p => {
					price = p.price
					i = response.data.data.map(op => parseInt(op.id)).indexOf(parseInt(p.id))
					if (i > -1) {
						price = response.data.data[i].price
					}
					this.objectLegalDocument.details.push({
						id: 0,
						legal_document_id: 0,
						type_code: 'INT1',
						code: p.product_id,
						name: p.product.name,
						price: (price / 1.19).toFixed(2),
						quantity: p.quantity,
						total: (price / 1.19).toFixed(2) * p.quantity,
					})
				})
				setTimeout(() => { this.$refs.legalDocumentComponent.$refs.btnToCompleteData.click() }, 50)
				this.$refs.btnShowLegalDocumentModal.click()
			}
		},
		showShippingRequestLocal() {
			this.confirmingOrder = true
			this.$refs.btnShowShippingRequestComponent.click()
		},
		deleteOrderObservationLocal(item) {
			this.deleteOrderObservation(item.order_id, item.id)
		},
		deleteOrderObservationResultLocal(response) { },
		initModuleLocal() {
			this.$refs.shippingsRequestOffCanvasRight.addEventListener('hidden.bs.offcanvas', () => {
				this.confirmingOrder = false
			})
			this.typeOrderIdsShow = [2, 4, 5, 6, 14, 15]
			this.setOrderByTeamComponentInit()
		},
		savedShippingRequestLocal(val) {
			this.disabledBtnSaveShippingRequest = val
		},
		saveLegalDocumentResultLocal(ld) {
			this.saveOrderLegalDocument(this.objectOrder.id, ld.id)
		},
		saveOrderLegalDocumentResultLocal(response) {
			if (response.data.success == true) {
				this.objectOrder.legal_document.push(response.data.data)
			}
			this.confirmingOrder = false
		},
		saveOrderObservationLocal(item) {
			if (this.observation.length > 2) {
				this.saveOrderObservation(item.id)
			}
		},
		saveOrderObservationResultLocal(response) { },
		saveShippingRequestLocal() {
			this.$refs.shippingRequestComponent.$refs.btnSaveShippingRequest.click()
		},
		saveShippingRequestResultLocal(response) {
			this.confirmingOrder = false
			if (response.data.success == true) {
				this.saveOrderShippingRequest(this.objectOrder.id, response.data.data.id)
			}
		},
		saveOrderShippingRequestResultLocal(response) {
			this.addOrderTrackingsOrder(this.objectOrder.id, 2, 'Documento Electronico')
		},
		sendToRegulatoryEntityLocal(legalDocumentId) {
			this.sendToRegulatoryEntity(legalDocumentId)
		},
		sendToRegulatoryEntityResultLocal(response) {
			if (response.data.success == true) {
				let i = this.objectOrder.legal_document.map(ld => parseInt(ld.id)).indexOf(parseInt(response.data.data.id))
				if (i > -1) {
					this.objectOrder.legal_document[i].legal_document_number = response.data.data.legal_document_number
					this.objectOrder.legal_document[i].url = response.data.data.url
					this.objectOrder.legal_document[i].url_assignable = response.data.data.url_assignable
				}
				this.$refs.btnCloseLegalDocumentModal.click()
				this.showShippingRequestLocal()
			} else {
				this.showMsgErrorHandling('ERROR', response.data.message, 'warn', 5000, 'top right')
			}
		},
		setOrderByTeamComponentInit() {
			if (this.id > 0) {
				this.showOrderLocal()
			}
		},
		showOrderLocal() {
			if (this.id != undefined && this.id != null && this.id > 0) {
				this.showOrder(this.id, 'orderByTeam')
			}
		},
		showOrderResultLocal(response) {
			console.log(response.data, this.objectOrder)
			/*if (response.data.success == true) {
				if (this.objectOrder.id == 0) {
					this.showMsgErrorHandling('SIN ACCESO', 'No se puede mostrar la Orden Nº ' + response.data.data.id, 'warn', 5000, 'top right')
					this.path('/teamOrders')
				}
			} else {
				this.showMsgErrorHandling('NO Encontrado', response.data.data, 'warn', 5000, 'top right')
				this.path('/teamOrders')
			}*/
		},
		showShippingRequestComponent() {
			this.shippingRequestSelected = 0
			let i = this.objectOrder.legal_document.map(ld => parseInt(ld.type_legal_document_id)).indexOf(52)
			console.log(this.objectOrder.total)
			this.objectShippingRequest.total_price = parseInt(this.objectOrder.total).toFixed(0)
			if (i > -1) {
				this.objectShippingRequest.commentary = 'G' + this.objectOrder.legal_document[i].legal_document_number
				this.objectShippingRequest.total_price = parseInt(this.objectOrder.legal_document[i].total).toFixed(0)
			}

			this.objectShippingRequest.recipient_data.sendto = this.objectOrder.client_address_client_sendto.client_sendto.sendto
			this.objectShippingRequest.recipient_data.address = this.objectOrder.client_address_client_sendto.address.street
			this.objectShippingRequest.recipient_data.comuna_id = this.objectOrder.client_address_client_sendto.address.comuna.id
			this.objectShippingRequest.recipient_data.comuna = this.objectOrder.client_address_client_sendto.address.comuna
			this.objectShippingRequest.recipient_data.phone = this.objectOrder.client_address_client_sendto.client_sendto.phone
			this.objectShippingRequest.recipient_data.number_document = this.objectOrder.client_address_client_sendto.client_sendto.number_document
			this.objectShippingRequest.recipient_data.email = this.objectOrder.client_address_client_sendto.client_sendto.email
			setTimeout(() => { this.$refs.shippingRequestComponent.$refs.btnToCompleteData.click() }, 1000)
		},
		statusOrderFunction() {
			this.statusOrder.forEach(so => {
				this.trackingsOrder.forEach(oto => {
					if (so.status_id == oto.tracking_order.status_id) {
						so.tracking = oto
						this.statusOrderCurrent = so.status_id
					}
				})
			})
			return this.statusOrder
		},

	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>