/* Rquiere de mixin.js */

var transport = {
    data() {
        return {
            listTransports: [],
            methods: {
                transportGet: 'transports',
            },
            paginationTransports: [],
            searchTransports: '',
            loadingTransport: false,
            strTransport: [
                { result: 'Starken', strs: ['TUR BUS', 'TURBUS'] },
                { result: 'Correos de Chile', strs: ['CORREOS DE CHILE', 'CORREO DE CHILE', 'CORREOSDECHILE', 'CORREODCHILE'] },
                { result: 'Retira en ACES', strs: ['RETIRA DE ACES', 'RETIRA EN ACES', 'ACES', 'Santa Elena 1038'] },
            ]
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getAllTransports(page = 1) {
            this.listTransports = []
            this.loadingTransport = true
            let parameters = '?search=' + this.searchTransports + '&page=' + page
            let url = this.getApiBackend + this.methods.transportGet + parameters
            this.axiosActionIS(url, 'GET', 'getAllTransportsResult')
        },
        getColorTransport(id) {
            let index = this.listTransports.map((el) => parseInt(el.id)).indexOf(parseInt(id))
            return (index > -1) ? this.listTransports[index].color : ''
        },
        getNameTransport(id) {
            let index = this.listTransports.map((el) => parseInt(el.id)).indexOf(parseInt(id))
            return (index > -1) ? this.listTransports[index].name : ''
        },
        getAllTransportsResult(response) {
            this.loadingTransport = false
            if (response.data.success == true) {
                this.listTransports = response.data.data
                this.paginationTransports = response.data.paginate
            }
            this.getAllTransportsResultLocal(response)
        },
        getAllTransportsResultLocal(response) {
            console.log('getAllTransportsResultLocal no desarrollado', response)
        },
        searchIdTransportsByStr(str) {
            let index = this.listTransports.map((el) => el.name.trim().toUpperCase()).indexOf(str.trim().toUpperCase())
            if (index == -1)
                return index
            return this.listTransports[index].id
        },
        selectTransporByStr(str) {
            let transport = { id: 0, name: '' }
            this.strTransport.forEach(s => {
                s.strs.forEach(st => {
                    if (st.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
                        str = s.result
                    }
                })
            })
            this.listTransports.forEach(t => {
                if (t.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
                    transport = t
                }
            });
            return transport
        }
    },
};

export default transport;