<template>
	<div id="generalConfigurationComponent">
		<button ref="btnSetGeneralConfiguration" @click="setGeneralConfigurationInit" v-show="false"></button>
		<div class="rounded-top-3 bg-body-tertiary py-3 ps-4 pe-6">
			<h4 class="mb-1" id="generalConfigModalLabel">Configuraciones generales</h4>
		</div>
		<div class="p-4">
			<div class="d-flex">
				<span class="fa-stack ms-n1 me-3">
					<i class="fas fa-circle fa-stack-2x text-200"></i>
					<i class="fa-inverse fa-stack-1x text-primary fas fa-print" data-fa-transform="shrink-2"></i>
				</span>
				<div class="flex-1">
					<h5 class="mb-2 fs-0">Impresoras</h5>
						<div class="mb-3 row">
							<label class="col-sm-2 col-form-label">Boletas</label>
							<div class="col-sm-10">
								<select class="form-select form-select-sm" aria-label=".form-select-sm example"
									v-model="indexSelectedPrinterFacturacionCLPrint"
									@change="changeSelectedPrinterFacturacionCLPrint(i)"
									v-if="getSuccessFullConnectionFacturacionCLPrint">
									<option :value="i" v-for="(ib, i) in getListPrinterFacturacionCLPrint" :key="i" @click="changeSelectedPrinterFacturacionCLPrint(i)">{{ ib }}</option>
								</select>
								<div class="d-grid gap-2" v-else>
									<button class="btn btn-sm btn-primary" type="button" @click="connectFacturacionCLPrint()">Conectar con WebSocket facturacionCL</button>
								</div>
							</div>
							<label class="col-sm-2 col-form-label">Etiquetas</label>
							<div class="col-sm-10">
								<select class="form-select form-select-sm" aria-label=".form-select-sm example"
									@change="changeSelectedWebSocketServer()"
									v-model="indexSelectedPrinterWebSocketServer"
									v-if="getSuccessFullConnectionWebSocketServer">
									<option :value="i" v-for="(ie, i) in getListPrinterWebSocketServer" :key="i">{{ ie }}</option>
								</select>
								<div class="d-grid gap-2" v-else>
									<button class="btn btn-sm btn-primary" type="button" @click="connectWebSocketServer()">Conectar con WebSocket Etiquetas</button>
								</div>
							</div>
						</div>
					<hr class="my-4" />
				</div>
			</div>
			<div class="d-flex" v-show="false">
				<span class="fa-stack ms-n1 me-3">
					<i class="fas fa-circle fa-stack-2x text-200"></i>
					<i class="fa-inverse fa-stack-1x text-primary fas fa-align-left" data-fa-transform="shrink-2"></i>
				</span>
				<div class="flex-1">
					<h5 class="mb-2 fs-0">Description</h5>
					<p class="text-break fs--1">The illustration must match to the contrast of the theme. The illustraion must described the concept of the design. To know more about the theme visit the page. </p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

export default {
	name: 'GeneralConfigurationComponent',
	mixins: [mixin, axiosAction, errors],
	props: [],
	data () {
		return {
			indexSelectedPrinterFacturacionCLPrint: -1,
			indexSelectedPrinterWebSocketServer: -1
		}
	},
	components: {
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.connectFacturacionCLPrint()
			this.connectWebSocketServer()
			this.setGeneralConfigurationInit()
		},
        changeSelectedWebSocketServer() {
            localStorage.printerWebSocketServer = this.getListPrinterWebSocketServer[this.indexSelectedPrinterWebSocketServer]
        },
        changeSelectedPrinterFacturacionCLPrint() {
            localStorage.printerFacturacionCL = this.getListPrinterFacturacionCLPrint[this.indexSelectedPrinterFacturacionCLPrint]
        },
		setGeneralConfigurationInit() {
			this.setPrinterWebSocketServer()
			this.setPrinterFacturacionCLPrint()
		},
		setPrinterWebSocketServer() {
			let index = this.getListPrinterWebSocketServer.map(i => i).indexOf(localStorage.printerWebSocketServer)
			if(index > -1) {
				this.indexSelectedPrinterWebSocketServer = index
			}
		},
		setPrinterFacturacionCLPrint() {
			let index = this.getListPrinterFacturacionCLPrint.map(i => i).indexOf(localStorage.printerFacturacionCL)
			if(index > -1) {
				this.indexSelectedPrinterFacturacionCLPrint = index
			}
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>