/* Requiere de genesys.js */

var domicilio = {
    data() {
        return {
            searchingDomicilio: false,
            movimiento: [],
            listDomicilioCliente: []
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getDomicilioWithCliente(domicli) {
            this.searchingDomicilio = true
            let url = this.getApiBackend + 'genesys/get'

            let param = { 'get': 'domicilio/' + '/getWithCliente/' + domicli }

            this.axiosActionIS(url, 'POST', 'getDomicilioWithClienteResult', param)
        },
        getDomicilioWithClienteResult(response) {
            this.searchingDomicilio = false
            this.getDomicilioWithClienteResultLocal(response)
        },
        getDomicilioWithClienteResultLocal(response) {
            console.log('getDomicilioWithClienteResultLocal no desarrollado', response)
        },
        getDomicilioIdCliente(clicod) {
            this.listDomicilioCliente = []
            let url = 'domicilio/' + '/getIdCliente?clicod=' + clicod
            this.$emit('showLockScreen', 'Buscando Domicilio del cliente ' + clicod)
            this.axios.get(url)
                .then(response => { this.getDomicilioIdClienteResult(response); this.$emit('hideLockScreen') })
                .catch(error => { console.log(error); this.$emit('hideLockScreen') })
        },
        getDomicilioIdClienteResult(response) {
            this.listDomicilioCliente = response.data.data
            this.getDomicilioIdClienteResultLocal(response)
        },
        getDomicilioIdClienteResultLocal(response) {
            console.log(response)
        },
        setDomitel(domicod, domitel) {
            let url = 'domicilio/' + '/setDomitel'
            let data = {
                domicod: domicod,
                domitel: domitel
            }
            this.axios.post(url, data)
                .then(response => { this.setDomitelResult(response); this.$emit('hideLockScreen') })
                .catch(error => { console.log(error); this.$emit('hideLockScreen') })

        },
        setDomitelResult(response) {
            this.$snotify.success('Telefono cambiado!!!', 'OK')
            this.setDomitelResultLocal(response)
        },
        setDomitelResultLocal(response) {
            console.log('setDomitelResultLocal No desarrollado ', response)
        }
    },
};

export default domicilio;