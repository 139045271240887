<template>
	<div id="chartOfAccountListComponent">
		<div class="card mb-1" id="legalDocumentsListCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
									<i class="fas fa-list-ol"></i>
									Plan de Cuentas
								</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										@keyup.enter="getChartOfAccountsLocal(1)" @focus="$event.target.select()" />
									<div class="input-group-text bg-transparent pointer"
										@click="getChartOfAccountsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm" type="button">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<table class="table table-sm table-bordered fs--1 mb-0 overflow-hidden table-hover">
					<thead class="bg-aasinet text-white">
						<tr>
							<th class="text-center">Código</th>
							<th class="text-center">Nombre</th>
							<th class="text-center">Tipo Sub-Cuenta</th>
						</tr>
					</thead>
					<thead>
						<tr>
							<td>1000000</td>
							<td>ACTIVO</td>
							<td></td>
						</tr>
						<tr>
							<td>1100000</td>
							<td>ACTIVO CORRIENTE</td>
							<td></td>
						</tr>
						<tr>
							<td>1110000</td>
							<td>EFECTIVO Y EQUIVALENTES DE EFECTIVO</td>
							<td></td>
						</tr>
					</thead>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

export default {
	name: 'ChartOfAccountListComponent',
	mixins: [mixin, axiosAction, errors],
	props: [],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getChartOfAccountsLocal(page = 1) {

		},
		initModuleLocal() {
			this.setListChartOfAccountsComponentInit()
		},
		setListChartOfAccountsComponentInit() {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>

<style lang="scss"></style>