<template>
	<div id="teamPermanentStatisticsComponent">
		<button ref="btnGetDataPermanents" @click="getDataPermanentLocal" v-show="false">Launch static backdrop
			modal</button>
		<div class="row mb-3">
			<div class="col">
				<div class="card bg-100 shadow-none border">
					<div class="row gx-0 flex-between-center">
						<div class="col-sm-auto d-flex align-items-center"><img class="ms-n2"
								src="/assets/img/illustrations/crm-bar-chart.png" alt="" width="90" />
							<div>
								<h6 class="text-primary fs--1 mb-0">Estadisticas de </h6>
								<h4 class="text-primary fw-bold mb-0">Colportores <span
										class="text-info fw-medium">Permanentes</span></h4>
							</div>
							<img class="ms-n4 d-md-none d-lg-block" src="/assets/img/illustrations/crm-line-chart.png"
								alt="" width="150" />
						</div>
						<div class="col-md-auto p-3">
							<form class="row align-items-center g-3">
								<div class="col-auto">
									<h6 class="text-700 mb-0">Periodo: </h6>
								</div>
								<div class="col-md-auto position-relative">
									<select class="form-select form-select-sm ps-4" @change="changeMonthAnalyze"
										v-model="monthAnalyze">
										<option v-for="(m, i) in monthsAnalyze" :key="i" :value="m.value">{{ m.text }}
										</option>
									</select>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row g-3 mb-3">
			<div class="col-lg-6">
				<div class="card overflow-hidden mb-3" style="min-width: 12rem">
					<div class="bg-holder bg-card"
						style="background-image:url(/assets/img/icons/spot-illustrations/corner-3.png);"></div>
					<!--/.bg-holder-->

					<div class="card-body position-relative">
						<h6>Total Compras</h6>
						<div class="display-4 fs-4 mb-2 fw-normal font-sans-serif"
							data-countup='{"endValue":43594,"prefix":"$"}'>$ {{ methodFormatNumber(totalBonusable) }}
						</div>
					</div>
				</div>

				<div class="card mb-3">
					<div class="card-body px-xxl-0 pt-4">
						<div class="row g-0">
							<div class="col-xxl-4 col-md-6 px-3 text-center border-end pb-3 p-xxl-0 ps-md-0">
								<div class="icon-circle icon-circle-primary"><span
										class="fs-2 fas fa-book-open text-primary"></span></div>
								<h4 class="mb-1 font-sans-serif"><span class="text-700 mx-2"
										data-countup='{"endValue":"4968"}'>{{ totalQuantityBooks[0].total }}</span><span
										class="fw-normal text-600">{{ totalQuantityBooks[0].name }}</span></h4>
							</div>
							<div
								class="col-xxl-4 col-md-6 px-3 text-center border-end pb-3 pt-4 p-xxl-0 pb-md-0 ps-md-0">
								<div class="icon-circle icon-circle-success"><span
										class="fs-2 fas fa-book text-success"></span></div>
								<h4 class="mb-1 font-sans-serif"><span class="text-700 mx-2"
										data-countup='{"endValue":"3712"}'>{{ totalQuantityBooks[1].total }}</span><span
										class="fw-normal text-600">{{ totalQuantityBooks[1].name }}</span></h4>
							</div>
							<div class="col-xxl-4 col-md-6 px-3 text-center pt-4 p-xxl-0 pb-0 pe-md-0">
								<div class="icon-circle icon-circle-warning"><span
										class="fs-2 far fa-newspaper text-warning"></span></div>
								<h4 class="mb-1 font-sans-serif"><span class="text-700 mx-2"
										data-countup='{"endValue":"1054"}'>{{ totalQuantityBooks[2].total }}</span><span
										class="fw-normal text-600">{{ totalQuantityBooks[2].name }}</span></h4>
							</div>
						</div>
					</div>
				</div>


				<div class="card mb-3">
					<div class="card-header">
						<div class="row justify-content-between">
							<div class="col-md-auto">
								<h5 class="mb-3 mb-md-0">Lista de Productos</h5>
							</div>
						</div>
					</div>
					<div class="card-body p-0">
						<div class="row gx-x1 mx-0 bg-200 text-900 fs--1 fw-semi-bold">
							<div class="col-9 col-md-8 py-2 px-x1">Producto</div>
							<div class="col-3 col-md-4 px-x1">
								<div class="row">
									<div class="col-md-8 py-2 d-none d-md-block text-center">Cantidad</div>
									<div class="col-12 col-md-4 text-end py-2">Precio</div>
								</div>
							</div>
						</div>
						<div class="row gx-x1 mx-0 align-items-center border-bottom border-200"
							v-for="(p, i) in listProductsFilter(-1)" :key="i">
							<div class="col-8 py-3 px-x1">
								<div class="d-flex align-items-center">
									<img class="img-fluid rounded-1 me-3 d-none d-md-block" :src="p.img" alt=""
										width="60" />
									<div class="flex-1">
										<h5 class="fs-0"><span class="text-900" href="#">{{ p.name }}</span></h5>
									</div>
								</div>
							</div>
							<div class="col-4 py-3 px-x1">
								<div class="row align-items-center">
									<div
										class="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
										<div>
											{{ p.quantity }}
										</div>
									</div>
									<div class="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">${{
			methodFormatNumber(p.price) }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="card mb-3">
					<div class="card-body p-0">
						<div class="scrollbar">
							<table
								class="table table-dashboard mb-0 table-borderless fs--1 border-200 overflow-hidden rounded-3 table-member-info">
								<thead class="bg-body-tertiary">
									<tr>
										<th class="text-900">Colportor info</th>
										<th class="text-900 text-center">Campo</th>
										<th class="text-900 text-center">Total Periodo</th>
										<th class="text-900 text-end">Promedio Compra</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(c, i) in listColportoresFilter" :key="i"
										class="border-bottom border-200">
										<td>
											<div class="d-flex align-items-center position-relative">
												<div class="avatar avatar-2xl">
													<div class="avatar avatar-xl me-2" v-if="c.img == null">
														<div class="avatar-name rounded-circle"
															:style="'background-color: #' + c.entity.color_hex">
															<span>{{ capitalizarPrimeraLetra(c.name.substring(0, 1))
																}}{{ capitalizarPrimeraLetra(c.surname.substring(0, 1))
																}}</span>
														</div>
													</div>
													<img class="rounded-circle" src="assets/img/team/7.jpg" alt=""
														v-else />
												</div>
												<div class="flex-1 ms-3">
													<h6 class="mb-0 fw-semi-bold"><span
															class="pointer stretched-link text-900"
															@click="showModalStatisticsColportor(c)">{{ c.name }} {{
			c.surname }}</span></h6>
													<p class="text-500 fs--2 mb-0">{{ c.type_colportor.name }}</p>
												</div>
											</div>
										</td>
										<td class="align-middle text-center">{{ c.entity.synthetic }}</td>
										<td class="align-middle text-end">{{ methodFormatNumber(c.totalOrders) }}</td>
										<td class="align-middle text-end">{{ methodFormatNumber(c.totalOrders /
			indexBetween) }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="card echart-session-by-browser-card">
					<div class="card-header d-flex flex-between-center bg-body-tertiary py-2">
						<h6 class="mb-0">Categorias</h6>
					</div>
					<div class="card-body d-flex flex-column justify-content-between py-0">
						<div class="my-auto py-5 py-md-0">

							<doughnut-charts id="my-chart-id" :options="chartOptionsDoughnut"
								:chartData="chartDataDoughnut">
							</doughnut-charts>

						</div>
						<div class="border-top">
							<table class="table table-sm mb-0">
								<tbody>
									<tr v-for="(c, i) in listCategories" :key="i">
										<td class="py-3">
											<div class="d-flex align-items-center">
												<h6 class="text-600 mb-0 ms-2">{{ c.name }}</h6>
											</div>
										</td>
										<td class="py-3">
											<div class="d-flex align-items-center">
												<span class="fas fa-circle fs--2 me-2"
													:style="'color: #' + c.color_hex"></span>
												<h6 class="fw-normal text-700 mb-0">{{ c.members }}</h6>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>



			</div>
		</div>
		<div>



		</div>



		<button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#scrollinglongcontent"
			ref="bntShowModalStatisticsColportorLocal" v-show="false">Launch Scrolling long content modal</button>
		<div class="modal fade" id="scrollinglongcontent" data-keyboard="false" tabindex="-1"
			aria-labelledby="scrollinglongcontentLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content" v-if="colportorSelected != null">
					<div class="card font-sans-serif">
						<div class="card-body d-flex gap-3 flex-column flex-sm-row align-items-center">
							<img class="rounded-3" src="/assets/img/team/default.png" alt="" width="112" />
							<table class="table table-borderless fs--1 fw-medium mb-0">
								<tbody>
									<tr>
										<td class="p-1" style="width: 35%;">Nombre:</td>
										<td class="p-1 text-600">{{ colportorSelected.name }} {{
			colportorSelected.surname }}</td>
									</tr>
									<tr>
										<td class="p-1" style="width: 35%;">Categoria:</td>
										<td class="p-1 text-600">{{ colportorSelected.type_colportor.name }}</td>
									</tr>
									<tr>
										<td class="p-1" style="width: 35%;">Correo:</td>
										<td class="p-1"><a class="text-600 text-decoration-none"
												href="mailto:goodguy@nicemail.com">{{ colportorSelected.email }}
											</a><small class="badge rounded badge-subtle-success false">Verified</small>
										</td>
									</tr>
									<tr>
										<td class="p-1" style="width: 35%;">Telefono:</td>
										<td class="p-1"><a class="text-600 text-decoration-none"
												href="tel:+01234567890 ">{{ colportorSelected.phone }} </a><small
												class="badge rounded badge-subtle-primary false">2-step
												verification</small>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="row mt-2">
						<div class="col-lg-6">
							<div class="card overflow-hidden mb-3" style="min-width: 12rem">
								<div class="bg-holder bg-card"
									style="background-image:url(/assets/img/icons/spot-illustrations/corner-3.png);">
								</div>
								<!--/.bg-holder-->

								<div class="card-body position-relative">
									<h6>Total Compras<span
											class="badge badge-subtle-success rounded-pill ms-2">9.54%</span></h6>
									<div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-center">$ {{
			methodFormatNumber(colportorSelected.totalOrders) }}</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="card overflow-hidden mb-3" style="min-width: 12rem">
								<div class="bg-holder bg-card"
									style="background-image:url(/assets/img/icons/spot-illustrations/corner-2.png);">
								</div>
								<!--/.bg-holder-->

								<div class="card-body position-relative">
									<h6>Promedio<span class="badge badge-subtle-success rounded-pill ms-2">9.54%</span>
									</h6>
									<div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-center">$ {{
			methodFormatNumber(colportorSelected.totalOrders / indexBetween) }}</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div class="row gx-x1 mx-0 align-items-center border-bottom border-200"
							v-for="(p, i) in listProductsFilter(clientIdSelected)" :key="i">
							<div class="col-8 py-3 px-x1">
								<div class="d-flex align-items-center">
									<img class="img-fluid rounded-1 me-3 d-none d-md-block" :src="p.img" alt=""
										width="40" />
									<div class="flex-1">
										<h5 class="fs-0"><span class="text-900" href="#">{{ p.name }}</span></h5>
									</div>
								</div>
							</div>
							<div class="col-4 py-3 px-x1">
								<div class="row align-items-center">
									<div
										class="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
										<div>
											{{ p.quantity }}
										</div>
									</div>
									<div class="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">${{
			methodFormatNumber(p.price) }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import statisticsTeams from '@/mixins/teams/statisticsTeams.js'

import { BarChart } from 'vue-chart-3';
import { DoughnutChart } from 'vue-chart-3';

import ObjectInspector from "vue-object-inspector";

export default {
	name: 'TeamPermanentStatisticsComponent',
	mixins: [mixin, axiosAction, errors, statisticsTeams],
	props: ['typeTeamIds', 'entityId'],
	data() {
		return {
			productNotStadistic: [5000287, 5002792],
			monthAnalyze: 1,
			monthsAnalyze: [
				{ value: 1, text: 'Mes Pasado', between: 1 },
				{ value: 2, text: '2 Meses', between: 2 },
				{ value: 3, text: '3 Meses', between: 3 },
				{ value: 4, text: '4 Meses', between: 4 },
				{ value: 6, text: '6 Meses', between: 4 },
				{ value: 12, text: '12 Meses', between: 8 },
			],
			chartOptionsDoughnut: {
				responsive: true,
				plugins: {
					legend: {
						display: false
					}
				}
			},
			clientIdSelected: -2,
			colportorSelected: null,
		}
	},
	components: {
		'object-inspector': ObjectInspector,
		'bar-charts': BarChart,
		'doughnut-charts': DoughnutChart
	},
	computed: {
		chartDataDoughnut() {
			let b = []
			let d = []
			let l = []
			this.listCategories.forEach(e => {
				d.push(e.members)
				l.push(e.name)
				b.push('#' + e.color_hex)
			})
			let data = {
				labels: l,
				datasets: [{
					data: d,
					backgroundColor: b,
				}]
			}
			return data
		},
		getDateFromFilter() {
			let date = this.currentDate().split('-')
			let month = parseInt(date['1']) - this.monthAnalyze
			this.listMonth = []
			if (month < 1) {
				month += 12
				date[0] -= 1
				if (date[0] < 2024) {
					date[0] = 2024
					month = 1
				}
			}
			date[1] = (month < 10) ? '0' + month : month
			date[2] = '01'
			return date[0] + '-' + date[1] + '-' + date[2]
		},
		indexBetween() {
			return this.monthsAnalyze[this.monthsAnalyze.map(e => e.value).indexOf(this.monthAnalyze)].between
		},
		listColportoresFilter() {
			let list = []
			let index = 0
			let ind = 0
			if (this.objectResult != null) {
				this.objectResult.teams.forEach(c => {
					index = list.map(l => parseInt(l.id)).indexOf(parseInt(c.client_id))
					if (index == -1) {
						let col = {
							id: c.client_id,
							name: c.client_name,
							surname: c.client_surname,
							img: c.client_img,
							email: c.client_email,
							phone: c.client_phone,
							entity_id: c.entity_id,
							entity: {
								id: c.entity_id,
								name: c.entity_name,
								synthetic: c.entity_synthetic,
								color_hex: c.entity_color_hex
							},
							type_colportor_id: c.type_colportor_id,
							type_colportor: {
								id: c.type_colportor_id,
								name: c.type_colportor_name,
								percentage_min: c.type_colportor_percentage_min,
								color_hex: c.type_colportor_color_hex
							},
							orders: [],
							totalOrders: 0
						}
						this.objectResult.orders.forEach(o => {
							if (parseInt(o.client_o_id) == parseInt(col.id)) {
								o.products = []
								this.objectResult.products.forEach(p => {
									if (p.order_id == o.order_id) {
										if (parseInt(p.product_with_freight) > 0) {
											p.product_price -= parseInt(p.product_with_freight)
										}
										ind = this.productNotStadistic.map(e => parseInt(e)).indexOf(parseInt(p.product_id))
										if (ind == -1) {
											p.product_price = parseFloat(p.product_price)
											col.totalOrders += parseFloat(p.product_price) * parseFloat(p.product_quantity)
										}
									}
								})
								col.orders.push(o)
							}
						})
						list.push(col)
					}
				});
			}
			return list
		},
		listMonthFilter() {
			return this.rangeMonths(this.getDateFromFilter, this.filters.toDate)
		},
		totalBonusable() {
			this.countQuantity0 = 0
			let total = this.listColportoresFilter.reduce((a, b) => {
				return a + b.totalOrders
			}, 0);
			return total.toFixed(0)
		},
		listCategories() {
			let list = []
			let ind = 0
			this.listColportoresFilter.forEach(c => {
				ind = list.map(e => parseInt(e.id)).indexOf(parseInt(c.type_colportor_id))
				if (ind == -1) {
					c.type_colportor.members = 1
					list.push(c.type_colportor)
				} else {
					list[ind].members++
				}
			})
			return list
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getStatisticsTeamByFiltersResult': this.getStatisticsTeamByFiltersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setNombreInit()
		},
		changeMonthAnalyze() {
			this.filters.fromDate = this.getDateFromFilter
			this.getDataPermanentLocal()
		},
		getDataPermanentLocal() {
			this.filters.typeTeamIds = this.typeTeamIds
			this.filters.entityIds = this.entityId
			this.getStatisticsTeamByFilters('getDataByFilter')
		},
		getStatisticsTeamByFiltersResultLocal(response) {
			console.log(response.data)
		},
		listProductsFilter(clientId = -1) {
			let list = []
			let i = -1
			let ind = -1
			if (this.objectResult != null) {
				this.objectResult.orders.forEach(o => {
					if (parseInt(o.client_o_id) == parseInt(clientId) || clientId == -1) {
						this.objectResult.products.forEach(p => {
							ind = this.productNotStadistic.map(e => parseInt(e)).indexOf(parseInt(p.product_id))
							if (ind == -1) {
								if (parseInt(p.order_id) == parseInt(o.order_id)) {
									i = list.map(l => parseInt(l.id)).indexOf(parseInt(p.product_id))
									if (i == -1) {
										let pr = {
											id: p.product_id,
											name: p.product_name,
											synthetic: p.product_synthetic,
											img: p.product_img,
											quantity: parseInt(p.product_quantity),
											price: parseInt(p.product_price)
										}
										list.push(pr)
									} else {
										list[i].quantity += parseInt(p.product_quantity)
									}
								}
							}
						})
					}
				})

			}
			return list
		},
		setNombreInit() {
			let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
			this.filters.fromDate = this.getDateFromFilter
			let d = new Date()
			let i = (d.getMonth() == 0) ? 12 : d.getMonth() - 1
			this.monthsAnalyze[0].text = months[i]
			d = d.getFullYear() + '-' + (d.getMonth() + 1) + '-01'
			this.filters.toDate = d
		},
		showModalStatisticsColportor(client) {
			this.clientIdSelected = client.id
			this.colportorSelected = client
			this.$refs.bntShowModalStatisticsColportorLocal.click()
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>