var action = {
	data() {
		return {
			listActions: [],
			loadingActions: false,
			objectAction: {
				exists: 0,
				id: 0,
				name: '',
				description: '',
				controller_path: '',
				created_at: null,
				updated_at: null
			},
			paginationActions: [],
			savingAction: false,
			searchAction: '',
			qRowAction: 25
		}
	},
	methods: {
		clearObjectAction() {
			this.objectAction.exists = 0
			this.objectAction.id = 0
			this.objectAction.name = ''
			this.objectAction.description = ''
			this.objectAction.controller_path = ''
			this.objectAction.created_at = null
			this.objectAction.updated_at = null
		},
		getActions(page) {
			this.listActions = []
			this.paginationActions = []
			this.loadingActions = true
			let param = '?search=' + this.searchAction + '&page=' + page + '&qRow=' + this.qRowAction
			let url = this.getApiBackendNew + 'actions' + param
			this.axiosActionIS(url, 'GET', 'getActionsResult')
		},
		getActionsResult(response) {
			this.loadingActions = false
			if (response.data.success == true) {
				this.listActions = response.data.data
				this.paginationActions = response.data.page
			}
			this.getActionsResultLocal(response)
		},
		getActionsResultLocal(response) {
			console.log('getActionsResultLocal no desarrollado', response)
		},
		saveAction(a) {
			this.savingAction = true
			let method = (a.id == 0) ? 'POST' : 'PUT'
			let url = (a.id == 0) ? 'action/register' : 'action/' + a.id + '/update'
			url = this.getApiBackendNew + url
			console.log(url, a)
			this.axiosActionIS(url, method, 'saveActionResult', a)
		},
		saveActionResult(response) {
			this.savingAction = false
			if (response.data.success == true) {
				this.setObjectAction(response.data.data)
				if (response.data.message == 'UPDATED') {
					this.setItemListActions(response.data.data)
				}
			}
			this.saveActionResultLocal(response)
		},
		saveActionResultLocal(response) {
			console.log('saveActionResultLocal no desarrollado', response)
		},
		setItemListActions(item) {
			let index = this.listActions.map(a => parseInt(a.id)).indexOf(parseInt(item.id))
			if (index > -1) {
				let newItem = Object.create(item)
				for (const property in newItem) {
					this.listActions[index][property] = newItem[property]
				}
			}
		},
		setObjectAction(item) {
			this.clearObjectAction()
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectAction[property] = newItem[property]
			}
		}
	},
};

export default action;