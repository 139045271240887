/* Rquiere de mixin.js */

var typeClient = {
	data() {
		return {
			listTypesClient: [],
			methodsTypeClient: {
				get: 'typesClient',
				show: 'typeClient/',
			},
			paginationTypesClient: [],
			searchTypesClient: '',
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		getAllTypesClient(page = 1) {
			let parameters = '?search=' + this.searchTypesClient + '&page=' + page
			this.axiosActionIS(this.getApiBackend + this.methodsTypeClient.get + parameters, 'GET', 'getAllTypesClientResult')
		},
		getAllTypesClientResult(response) {
			if (response.data.success) {
				this.listTypesClient = response.data.data
				this.paginationTypesClient = response.data.paginate
			}
			this.getAllTypesClientResultLocal(response)
		},
		getAllTypesClientResultLocal(response) {
			console.log('getAllTypesClientResultLocal no desarrollado', response)
		},
		getTypeClient(id) {
			this.axiosActionIS(this.getApiBackend + this.methodsTypeClient.show + id, 'GET', 'getTypeClientResult')
		},
		getTypeClientResult(response) {
			this.getTypeClientResultLocal(response)
		},
		getTypeClientResultLocal(response) {
			console.log('getTypeClientResultLocal no está desarrollado', response)
		},
	},
};

export default typeClient;