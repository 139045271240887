import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import readyXlsFile from 'read-excel-file'

var mixin = {
    data() {
        return {
            test: true,
            defaultRows: 25,
            filesUpload: [],
            fileUpload: null,
            filesUploadAccept: [],
            fileUploadContentExcelJSON: [],
            fileUploadHeaderExcelJSON: [],
            loadingFileUploadContentExcel: false,
            needGenesys: true,
            messageError: [],
        }
    },
    computed: {
        ...mapGetters([
            'getApiBackend',
            'getApiBackendNew',
            'getConnected',
            'getEnvironment',
            'getEnvironmentCurrent',
            'getHeaders',
            'getHistory',
            'getHistoryFull',
            'getIndexSelectedPrinterFacturacionCLPrint',
            'getNotifyPosition',
            'getAction',
            'getMenu',
            'getNameApp',
            'getUrlResource',
            'getToken',
            'getUrl',
            'getUser',
            'getUrlImagen',
            'getListPrinterFacturacionCLPrint',
            'getSuccessFullConnectionFacturacionCLPrint',
            'getListPrinterWebSocketServer',
            'getSuccessFullConnectionWebSocketServer',
            'getVersion'
        ])
    },
    filters: {
        formatDateFull(dateTime) {
            if (dateTime == '')
                return dateTime
            let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
            let dates = dateTime.split(' ')
            let date = dates[0].split('/')
            let time = dates[1].split(':')
            let hour = (parseInt(time[0]) > 12) ? parseInt(time[0]) - 12 : parseInt(time[0])
            let ap = (parseInt(time[0]) > 12) ? 'PM' : 'AM'
            return months[parseInt(date[1]) - 1] + ' ' + parseInt(date[0]) + ', ' + date[2] + ', ' + hour + ':' + time[1] + ' ' + ap
        },
        formatDateMin(dateTime) {
            if (dateTime == '' || dateTime == '?' || dateTime == undefined)
                return '?'
            let monthsSmall = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
            let dates = dateTime.split(' ')
            let date = dates[0].split('/')
            date = (date.length == 1) ? dates[0].split('-') : date
            let strTime = ''
            if (dates.length == 2) {
                let time = dates[1].split(':')
                strTime = ', ' + time[0] + ':' + time[1] + ' '
            }
            return monthsSmall[parseInt(date[1]) - 1] + ' ' + parseInt(date[0]) + ', ' + date[2] + strTime
        },
        formatDateOnly(dateTime) {
            if (dateTime == '' || dateTime == '?' || dateTime == undefined)
                return '?'
            let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
            let dates = dateTime.split(' ')
            let date = dates[0].split('/')
            date = (date.length == 1) ? dates[0].split('-') : date
            return date[2] + ' de ' + months[parseInt(date[1]) - 1] + ' del ' + parseInt(date[0])
        },
        formatMonthYearOnly(dateTime) {
            if (dateTime == '' || dateTime == '?' || dateTime == undefined)
                return '?'
            let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
            let dates = dateTime.split(' ')
            let date = dates[0].split('/')
            date = (date.length == 1) ? dates[0].split('-') : date
            return months[parseInt(date[1]) - 1] + ' del ' + parseInt(date[0])

        }


    },
    methods: {
        ...mapActions([
            'connectFacturacionCLPrint',
            'connectWebSocketServer',
            'socketFacturacionCLPrinter',
            'socketWebSocketServerPrinter'
        ]),
        ...mapMutations([
            'setHeadersAuthorization',
            'setInitialized',
            'setMenu',
            'setAction',
            'setHistoryFull',
            'setToken',
            'setUser',
            'setNotifyPosition'
        ]),
        actionSecurity(actionId) {
            return (this.getAction.map(el => parseInt(el.id)).indexOf(actionId) > -1)
        },
        analizeFile(f) {
            let r = {
                result: true,
                msg: ''
            }
            if (f != null) {
                let extension = f.name.split('.')
                if (extension.length < 1) {
                    r.result = false
                    r.msg = 'No se acepta este tipo de archivo'
                } else {
                    let i = this.filesUploadAccept.map(tf => tf.extension).indexOf(extension[extension.length - 1])
                    if (i == -1) {
                        r.result = false
                        r.msg = 'No se acepta este tipo de archivo "' + extension[extension.length - 1] + '"'
                    }
                }
            } else {
                r.result = false
                r.msg = 'No se detectó ningún archivo'
            }
            return r
        },
        axiosActionIS(url, method, responseLocal, data = []) {
            this.axiosAction(url, method, this.getHeaders, responseLocal, data)
        },
        back(position) {
            this.$router.go(position)
        },
        capitalizarPrimeraLetra(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        checkDigitoRUT(stringRUT) {
            if (stringRUT === null) {
                return false
            }
            stringRUT = stringRUT.replaceAll('.', '')
            let soloRUT = stringRUT.split('-')
            if (soloRUT.length != 2)
                return false //El Formato del rut es invalido
            let digito = this.searchDigitoRUT(soloRUT[0])
            return (String(digito).toUpperCase() == String(soloRUT[1]).toUpperCase())
        },
        cleanedStringZPL(cadena) {
            var specialChars = "º°";

            // Los eliminamos todos
            for (var i = 0; i < specialChars.length; i++) {
                cadena = cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
            }
            cadena = cadena.replace(/á/gi, "a");
            cadena = cadena.replace(/é/gi, "e");
            cadena = cadena.replace(/í/gi, "i");
            cadena = cadena.replace(/ó/gi, "o");
            cadena = cadena.replace(/ú/gi, "u");
            cadena = cadena.replace(/ñ/gi, "n");
            return cadena;
        },
        console(str) {
            console.log(str)
        },
        copyClipboard(str) {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        currentDate(days = 0) {
            let today = new Date();

            today.setDate(today.getDate() + days);
            let yyyy = today.getFullYear();

            let current = yyyy + '-' + this.currentMonth(today) + '-' + this.currentDay(today);
            return current;
        },
        currentDay(today = new Date()) {
            let dd = today.getDate();
            return (dd < 10) ? '0' + dd : dd;
        },
        currentMonth(today = new Date()) {
            let mm = today.getMonth() + 1;
            return (mm < 10) ? '0' + mm : mm;
        },
        defaultFunction(response) {
            console.log('Default Function, configure el que corresponde', response)
        },
        formatRUT(stringRUT, point = '.') {
            if (stringRUT == null) {
                return 'NOT VALID'
            }
            stringRUT = stringRUT.replaceAll('.', '')
            stringRUT = stringRUT.replaceAll('-', '')
            if (!this.checkDigitoRUT(stringRUT)) {
                stringRUT = stringRUT.substring(0, stringRUT.length - 1) + '-' + stringRUT.substring(stringRUT.length - 1, stringRUT.length)
                if (!this.checkDigitoRUT(stringRUT)) {
                    stringRUT = 'NOT VALID'
                }
            }
            if (stringRUT != 'NOT VALID') {
                let stringRUTArray = stringRUT.split('-')
                let resto = stringRUTArray[0].length % 3
                let threesomes = (resto == 0) ? Math.trunc(stringRUTArray[0].length / 3) - 1 : Math.trunc(stringRUTArray[0].length / 3)
                for (let i = 0; i < threesomes; i++) {
                    stringRUTArray[0] = stringRUTArray[0].substring(0, stringRUTArray[0].length - (3 * (i + 1)) - i) + point + stringRUTArray[0].substring(stringRUTArray[0].length, stringRUTArray[0].length - (3 * (i + 1)) - i)
                }
                stringRUT = stringRUTArray[0] + '-' + stringRUTArray[1]
            }
            return stringRUT
        },
        getFileUploadContentExcel(f) {
            this.fileUploadContentExcelJSON = []
            this.loadingFileUploadContentExcel = true
            readyXlsFile(f).then((rows) => {
                this.fileUploadContentExcelJSON = rows
                this.getFileUploadContentExcelResult()
            })
        },
        getFileUploadContentExcelResult() {
            this.loadingFileUploadContentExcel = false
            this.getFileUploadContentExcelResultLocal()
        },
        getFileUploadContentExcelResultLocal() {
            console.log('getFileUploadContentExcelResultLocal no desarrollado')
        },
        isFloat(n) {
            return !isNaN(parseFloat(n))
        },
        isInt(n) {
            return Number(n) == n && n % 1 === 0;
        },
        isFileValidJSON(fileJSON, header) {
            let r = {
                result: false,
                msg: []
            }
            let con = 0
            let i = -1
            if (fileJSON.length > 1) {
                header.forEach(h => {
                    i = fileJSON[0].map(c => c).indexOf(h.field)
                    if (i > -1) {
                        h.index = i
                        con++
                    } else {
                        r.msg.push('Not Found ' + h.field)
                    }
                })
            } else {
                r.msg = ['No analizado']
            }
            if (con == header.length) {
                r.result = true
                r.msg = ['Analisis Exitoso']
            }
            return r
        },
        getJSONfromFileUpload(file, field) {
            let json = []
            file.forEach((r, i) => {
                if (i > 0) {
                    let row = {}
                    field.forEach(f => {
                        if (f.index > -1) {
                            Object.defineProperty(row, this.cleanedStringZPL(f.field).toLowerCase(), { value: r[f.index], writable: true, enumerable: true })
                        }
                    })
                    json.push(row)
                }
            })
            return json
        },
        methodFormatNumber(amount, decimals = 0, signo = '.') {

            amount += ''; // por si pasan un numero en vez de un string
            amount = parseFloat(amount.replace(/[^0-9\.\-]/g, '')); // elimino cualquier cosa que no sea numero o punto

            decimals = decimals || 0; // por si la variable no fue fue pasada

            // si no es un numero o es igual a cero retorno el mismo cero
            if (isNaN(amount) || amount === 0)
                return parseFloat(0).toFixed(decimals);

            // si es mayor o menor que cero retorno el valor formateado como numero
            amount = '' + amount.toFixed(decimals);

            var amount_parts = amount.split('.'),
                regexp = /(\d+)(\d{3})/;

            while (regexp.test(amount_parts[0]))
                amount_parts[0] = amount_parts[0].replace(regexp, '$1' + signo + '$2');

            return amount_parts.join('.');
        },
        initModule(name = 'undefined') {
            console.log('Cargando datos del modulo ' + this.$options.name)
            this.initModuleLocal()
        },
        keyDownFalse(e) {
            e.preventDefault()
            return
        },
        listMessageError() {
            let list = ''
            this.messageError.forEach(m => {
                list = (list == '') ? '<br/><ul>' : list
                list += '<li>' + m + '</li>'
            })
            list = (list != '') ? list + '</ul>' : ''
            return list
        },
        onFileChange(e) {
            const file = e.target.files[0]
            this.filesUpload.push({ data: e, url: URL.createObjectURL(file) })
        },
        onChangeInputFileUpload(ref) {
            this.fileUpload = null
            let input = document.getElementById(ref)
            if (input.files.length > 0) {
                this.fileUpload = input.files[0]
            }
            this.onChangeInputFileUploadResultLocal()
        },
        onDropHandler(e) {
            this.fileUpload = null
            this.fileUpload = e.dataTransfer.files[0]
            this.onDropHandlerResultLocal()
        },
        dragFileChange(e) {
            const file = e.dataTransfer.files[0]
            this.filesUpload.push({ data: e, url: URL.createObjectURL(file) })
        },
        path(to = '/') {
            this.$router.push(to)
        },
        saveHistoryFull(message) {

        },
        clickBtn(ref) {
            this.$refs[ref].click()
        },
        searchDigitoRUT(stringRUT) {
            if (stringRUT === null)
                return false
            let soloRUT = stringRUT.split("-");
            stringRUT = soloRUT[0];
            stringRUT = stringRUT.split('.').join('');
            stringRUT = stringRUT.split(',').join('');
            let cont = 7;
            let suma = 0;
            if (isNaN(stringRUT))
                return -1; //El Rut contiene valores alfanumericos
            for (let i = stringRUT.length - 1; i > -1; i--) {
                cont = (cont == 7) ? 2 : cont + 1;
                suma += stringRUT[i] * cont;
            }
            return (11 - (suma % 11) == 10) ? 'K' : ((11 - (suma % 11) == 11) ? '0' : (11 - (suma % 11)));
        },
        validateEmail(mail) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                return true
            }
            return false
        },
        _split(string, val) {
            return string.split(val)
        },
        isNumber(value) {
            return typeof value === 'number';
        },
        rangeMonths(yearMonthFrom, yearMonthTo) {
            let yearMonthFromArray = yearMonthFrom.split('-')
            let yearMonthToArray = yearMonthTo.split('-')
            let list = []
            let small = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

            return yearMonthFromArray
        },
        xmlToJson(xml) {
            let convert = require('xml-js');
            let jsonResult = convert.xml2json(xml, { compact: true, spaces: 4 })
            return JSON.parse(jsonResult)
        }
    }
};

export default mixin;