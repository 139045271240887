<template>
	<div id="consignments">
		<div class="card mb-3" id="ConsignmentsTable">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-3 col-sm-auto d-flex align-items-center pe-0">
						<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Remesas</h5>
					</div>
					<div class="col-3 col-sm-13">
						<div>
							<div>
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="Buscar remesas" aria-label="search" v-model="searchConsignments"
										@keyup.enter="getAllConsignmentsLocal(1)" />
									<div class="input-group-text bg-transparent pointer"
										@click="getAllConsignmentsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-auto p-3">
						<form class="row align-items-center g-3">
							<div class="col-auto">
								<h6 class="text-700 mb-0">Fechas: </h6>
							</div>
							<div class="col-md-auto position-relative">
								<input class="form-control form-control-sm datetimepicker ps-4" id="consignmentsDateRange"
									type="text"
									data-options="{&quot;mode&quot;:&quot;range&quot;,&quot;dateFormat&quot;:&quot;M d&quot;,&quot;disableMobile&quot;:true , &quot;defaultDate&quot;: [&quot;Sep 12&quot;, &quot;Sep 19&quot;] }" /><span
									class="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2">
								</span>
							</div>
						</form>
					</div>
					<div class="col-6 col-sm-auto ms-auto text-end ps-0">
						<div id="Consignments-actions">
							<button class="btn btn-falcon-default btn-sm" type="button"
								@click="showNewConsignmentModalLocal(0)">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
							<button class="btn btn-falcon-default btn-sm mx-2 d-none" type="button">
								<span class="fas fa-filter" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Filter</span>
							</button>
							<button class="btn btn-falcon-default btn-sm d-none" type="button">
								<span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Export</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar" v-if="!loadingConsignments">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
						<thead class="bg-200 text-900">
							<tr>
								<th class="sort pe-1 align-middle white-space-nowrap pe-7">Remesa</th>
								<th class="sort pe-1 align-middle white-space-nowrap">Campaña</th>
								<th class="sort pe-1 align-middle white-space-nowrap text-center pe-7">Fecha</th>
								<th class="sort pe-1 align-middle white-space-nowrap text-center">Total</th>
								<th class="sort pe-1 align-middle white-space-nowrap text-center">Estado</th>
								<th class="pe-2"></th>
							</tr>
						</thead>
						<tbody class="list" id="table-consignments-body">
							<tr class="btn-reveal-trigger" v-for="(item, index) in listConsignments" :key="index">
								<td class="consignment py-2 align-middle white-space-nowrap">
									<a class="text-primary pointer" @click="showNewConsignmentModalLocal(item.id)">
										<strong>#{{ item.id }}</strong>
									</a>
									<strong> {{ item.description }}</strong>
								</td>
								<td class="name py-2 align-middle white-space-nowrap">
									{{ item.team.name }}
									<p class="mb-0 text-500">{{ item.team.client.name }} {{ item.team.client.surname }}
										{{ item.team.client.phone }}</p>
								</td>
								<td class="date py-2 align-middle">{{ item.created_at | formatDateMin }}</td>
								<td class="amount py-2 align-middle text-end fs-0 fw-medium">${{
									methodFormatNumber(sumConsignmentPayment(item.payments)) }}</td>
								<td class="status py-2 align-middle text-center fs-0 white-space-nowrap">
									<span v-if="item.status === null">-</span>
									<span v-else class="badge badge rounded-pill d-block"
										:class="'badge-soft-' + item.status.color">{{ item.status.name }}</span>
								</td>
								<td class="py-2 align-middle white-space-nowrap text-end">
									<div class="dropdown font-sans-serif position-static">
										<button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
											type="button" id="order-dropdown-0" data-bs-toggle="dropdown"
											data-boundary="viewport" aria-haspopup="true" aria-expanded="false"><span
												class="fas fa-ellipsis-h fs--1"></span></button>
										<div class="dropdown-menu dropdown-menu-end border py-0"
											aria-labelledby="order-dropdown-0">
											<div class="bg-white py-2">
												<span class="dropdown-item pointer"
													@click="showNewConsignmentModalLocal(item.id)">Ver</span>
												<div class="dropdown-divider"
													v-show="(item.payments.length == 0) && actionSecurity(1)"></div>
												<a class="dropdown-item text-danger pointer"
													v-show="(item.payments.length == 0) && actionSecurity(1)"
													@click="deleteConsignmentLocal(item.id)">Eliminar</a>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else class="text-center">
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando las
						remesas...</p>
				</div>
			</div>
			<div class="card-footer">
				<div class="row flex-between-center">
					<div class="col-auto">
						<select class="form-select form-select-sm" v-model="qRowsConsignment"
							@change="changeQRowsConsignment">
							<option value="10">10 Resultados</option>
							<option value="25">25 Resultados</option>
							<option value="50">50 Resultados</option>
							<option value="100">100 Resultados</option>
							<option value="200">200 Resultados</option>
							<option value="1000">1000 Resultados</option>
						</select>
					</div>
					<pagination :pagination="paginationConsignments" @getAllList="getAllConsignmentsLocal"></pagination>
					<div class="col-auto"><a class="fs--1 font-sans-serif" href="#!" v-show="false">Ayuda</a></div>
				</div>
			</div>
		</div>
		<new-consignment-modal ref="newConsignmentModal" :consignmentId="consignmentId"
			@saveConsignmentComponent="saveConsignmentLocal" @savePaymentParent="savePaymentLocal"
			@deletePaymentParent="deletePaymentLocal" @showUploadDocumentParent="showUploadDocumentLocal">
		</new-consignment-modal>
		<upload-document-modal-component ref="uploadDocumentModalComponent" :message="messageUploadDocumentModalComponent"
			:id="idUploadDocumentModalComponent" :controller="controllerUploadDocumentModalComponent"
			configUploadDocumentId="2" @closeUploadDocumentModalComponentParent="closeUploadDocumentModalComponentLocal">
		</upload-document-modal-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import consignments from '@/mixins/consignments'

import Pagination from '@/components/Pagination.vue'
import NewConsignmentModal from '@/components/ConsignmentModalComponent.vue'
import UploadDocumentModalComponent from '@/components/UploadDocumentModalComponent.vue'

import flatpickr from 'flatpickr'

export default {
	name: 'Consignments',
	mixins: [mixin, axiosAction, errors, consignments],
	data() {
		return {
			consignmentId: 0,
			controllerUploadDocumentModalComponent: '',
			idUploadDocumentModalComponent: '',
			messageUploadDocumentModalComponent: '',

		}
	},
	components: {
		'pagination': Pagination,
		'new-consignment-modal': NewConsignmentModal,
		'upload-document-modal-component': UploadDocumentModalComponent,
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteConsignmentResult': this.deleteConsignmentResult(response); break;
				case 'getAllConsignmentsResult': this.getAllConsignmentsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setConsignmentInitLocal()
		},
		changeQRowsConsignmentLocal() {
			this.getAllConsignmentsLocal(1)
		},
		closeUploadDocumentModalComponentLocal() {
			this.showNewConsignmentModalLocal(this.consignmentId)
		},
		deleteConsignmentLocal(id) {
			if (confirm('¿Está seguro de eliminar la remesa?')) {
				this.deleteConsignment(id)
			}
		},
		deleteConsignmentResultLocal(response) {
			if (response.data.success == true) {
				this.$notify({ title: 'Resultado', text: 'Remesa Borrada con Exito.', type: 'success' })
			}
		},
		deletePaymentLocal(id, paymentId) {
			this.deleteItemListConsignmentPayment(id, paymentId)
		},
		getAllConsignmentsLocal(page) {
			this.getAllConsignmentsByUserClient(page)
		},
		getAllConsignmentsResultLocal(response) {
		},
		saveConsignmentLocal(response) {
			if (response.data.success == true) {
				if (response.data.message == 'UPDATED') {
					this.updateListConsignment(response.data.data)
				} else if (response.data.message == 'NEW') {
					this.addListConsignments(response.data.data)
				}
			}
		},
		savePaymentLocal(id, data) {
			this.addListConsignmentsPayment(id, data)
		},
		setConsignmentInitLocal() {
			flatpickr(
				"#consignmentsDateRange", {
				mode: "range",
				dateFormat: "Y-m-d",
				defaultDate: [this.dateFromConsignments, this.dateToConsignments],
				onClose: (selectedDates, dateStr, instance) => {
					dateStr = dateStr.split('to')
					if (dateStr.length == 2) {
						this.dateFromConsignments = dateStr[0].trim()
						this.dateToConsignments = dateStr[1].trim()
						this.getAllConsignmentsLocal()
					}
				},
			})
		},
		showNewConsignmentModalLocal(id) {
			this.consignmentId = id
			setTimeout(() => {
				this.$refs.newConsignmentModal.$refs.btnShowConsignmentModalComponent.click()
			}, 100)
		},
		showUploadDocumentLocal(controller, id, message) {
			this.idUploadDocumentModalComponent = id
			this.controllerUploadDocumentModalComponent = controller
			this.messageUploadDocumentModalComponent = message
			setTimeout(() => {
				this.$refs.uploadDocumentModalComponent.$refs.btnShowUploadDocumentModalComponent.click()
			}, 100);

		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
