<template>
	<div id="expenseModalComponent">
        <button class="btn btn-primary d-none" type="button" data-bs-toggle="modal" data-bs-target=".expenseModalComponent" ref="btnShowExpenseModalComponent" @click="btnShowExpenseModalComponent">Launch static backdrop modal</button>
        <div class="modal fade expenseModalComponent" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" aria-labelledby="expenseModalComponentLabel" aria-hidden="true">
                <div class="modal-dialog mt-6 modal-md" role="document">
                    <div class="modal-content bconsignment-0">
                        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" @click="btnCloseExpenseModalComponent" ref="btnCloseExpenseModalComponent"></button>
                        </div>
                        <div class="modal-body p-0">
                            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                                <h4 class="mb-1" id="expenseModalComponentLabel">Ingresar un Nuevo Gasto</h4>
                                <p class="fs--2 mb-0">Subtitulo Model <a class="link-600 fw-semi-bold" href="#!">Model</a></p>
                            </div>
							<div class="p-4">
								<div>
									<div class="mb-3">
										<label class="form-label" for="basic-form-gender">Tipo de Gasto</label>
										<select class="form-select" id="basic-form-gender" aria-label="Default select example" v-model="objectExpense.type_expense_id">
											<option value="0" selected="selected" disabled>Seleccion Tipo de gasto</option>
											<option v-for="(item, index) in listTypeExpenses" :key="index" :value="item.id">{{item.name}}</option>
										</select>
									</div>
									<div class="mb-3">
										<label class="form-label" for="basic-form-name">Descripción</label>
										<input class="form-control" id="basic-form-name" type="text" v-model="objectExpense.description"/>
									</div>
									<div class="mb-3">
										<label class="form-label" for="basic-form-amount">Monto</label>
										<input class="form-control" id="basic-form-amount" type="text" v-model="objectExpense.amount"/>
									</div>
									<div class="text-end">
										<button class="btn btn-primary" type="button" @click="saveExpenseLocal()" :disabled="disabledSaveExpense">Guardar</button>
									</div>
								</div>								
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import expenses from '@/mixins/expenses'
import typeExpense from '@/mixins/expenses/typeExpense.js'

export default {
	name: 'ExpenseModalComponent',
	mixins: [mixin, axiosAction, errors, expenses, typeExpense],
	props: ['controller', 'id'],
	data () {
		return {
		}
	},
	components: {
	},
	computed: {
		disabledSaveExpense() {
			if(this.objectExpense.type_expense_id == 0)
				return true
			if(this.objectExpense.description.trim().length < 3)
				return true
			if(!(this.objectExpense.amount > 0))
				return true
			return false
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'getAllTypeExpensesResult': this.getAllTypeExpensesResult(response); break;
				case 'saveExpenseResult': this.saveExpenseResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setExpenseInit()
		},
		btnShowExpenseModalComponent() {
			this.clearObjectTeam()
		},
		btnCloseExpenseModalComponent() {
			this.clearObjectTeam()
		},
		getAllTypeExpensesResultLocal(response) {
		},
		setExpenseInit() {
			this.getAllTypeExpenses()
		},
		saveExpenseLocal() {
			this.saveExpense(this.controller, this.id, this.objectExpense)
		},
		saveExpenseResultLocal(response) {
			if(response.data.success == true) {
				this.$emit('saveExpenseComponent', response)
			}
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>