<template>
	<div id="correosChileComponent">
		<div class="p-3">
			<ul class="nav nav-tabs" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#tab-home"
						ref="manifestoCorreosChileTab" role="tab" aria-controls="tab-home"
						aria-selected="true"
						@click="obtenerCorreosChileManifiestosLocal(1)">
						Manifiestos
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#tab-profile"
						role="tab" aria-controls="tab-profile" aria-selected="false"
						@click="obtenerEtiquetasParaManifestoCorreoChileLocal">
						+ Nuevo
					</a>
				</li>
			</ul>
			<div class="tab-content border border-top-0" id="myTabContent">
				<div class="tab-pane fade show active" id="tab-home" role="tabpanel"
					aria-labelledby="home-tab">
					<table class="table table-sm">
						<thead>
							<tr class="fs--2">
								<th class="text-center">ID</th>
								<th class="text-center">Archivo</th>
								<th class="text-center">Estado</th>
								<th class="text-center">Creado</th>
								<th class="text-center">Modificado</th>
								<th class="text-center"></th>
							</tr>
						</thead>
						<tbody>
							<tr class="fs--2" v-for="(ccm, i) in listaCorreosChileManifiestoFilter"
								:key="i">
								<td class="text-center">{{ ccm.id }}</td>
								<td>{{ ccm.name }}</td>
								<td class="text-center">{{ ccm.status.name }}</td>
								<td class="text-center">{{ ccm.created_at }}</td>
								<td class="text-center">{{ ccm.updated_at }}</td>
								<td class="text-end">
									<a :href="getUrlResource + '/isystem/documents/correoschile/' + ccm.name"
										target="_blank">
										<i class="fas fa-file-download"></i>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
					<pagination :pagination="paginationCorreosChileManifiesto" @getAllList="obtenerCorreosChileManifiestosLocal">
					</pagination>
				</div>
				<div class="tab-pane fade" id="tab-profile" role="tabpanel"
					aria-labelledby="profile-tab">
					<table class="table table-sm">
						<thead>
							<tr class="fs--2">
								<th class="white-space-nowrap">
									<input class="form-check-input" id="checkbox-bulk-purchases-select"
										type="checkbox" v-model="allSelectListaCorreosChile"
										@change="selectAllListaCorreosChile" />
								</th>
								<th class="text-center">PRODUCTO</th>
								<th class="text-center">DESTINATARIO</th>
								<th class="text-center">CUMUNA</th>
								<th class="text-center">REFERENCIA</th>
								<th class="text-center">SEGUIMIENTO</th>
								<th class="text-center">BULTOS</th>
								<th class="text-center">FECHA</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(cc, i) in listCorreosChileFilter" :key="i" class="fs--2">
								<td>
									<input class="form-check-input" id="checkbox-bulk-purchases-select"
										type="checkbox" v-model="cc.correos_chile_manifiesto_id" />
								</td>
								<td>{{ cc.nombreProducto }}</td>
								<td>{{ cc.nombreDestinatario }}</td>
								<td>{{ cc.comunaDestinatario }}</td>
								<td>{{ cc.codReferencia }}</td>
								<td class="text-center">{{ cc.numeroEnvio }}</td>
								<td class="text-end">{{ cc.bultos }}</td>
								<td class="text-center">{{ cc.created }}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr class="fs--1 fw-semi-bold">
								<td colspan="6" class="text-end">TOTAL BULTOS</td>
								<td class="text-end">{{ totalBultosCorreosChileFilter }}</td>
							</tr>
						</tfoot>
					</table>
					<div class="text-end">
						<button class="btn btn-sm btn-primary m-2 align-middle"
							:disabled="disabledBtnGenerarteCorreosChileManifesto"
							@click="generateCorreosChileManifestoLocal">
							<span v-if="!generandoCorreosChileManifiesto">
								Generar Manifiesto
							</span>
							<span v-else>
								<small class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></small>
								Generando Manifiesto . . .
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import correosChile from '@/mixins/generals/correosChile.js'

import Pagination from '@/components/Pagination.vue'

export default {
	name: 'CorreosChileComponent',
	mixins: [mixin, axiosAction, errors, correosChile],
	props: [],
	data () {
		return {
			allSelectListaCorreosChile: false
		}
	},
	components: {
		'pagination': Pagination,
	},
	computed: {
		disabledBtnGenerarteCorreosChileManifesto() {
			if (this.totalBultosCorreosChileFilter == 0) {
				return true
			}
			if(this.generandoCorreosChileManifiesto == true) {
				return true
			}
			return false
		},
		listCorreosChileFilter() {
			return this.listaCorreosChile
		},
		listaCorreosChileManifiestoFilter() {
			return this.listaCorreosChileManifiesto
		},
		totalBultosCorreosChileFilter() {
			let total = 0
			this.allSelectListaCorreosChile = true
			this.listaCorreosChile.forEach(cc => {
				if (cc.correos_chile_manifiesto_id == true) {
					total += parseInt(cc.bultos)
				} else {
					this.allSelectListaCorreosChile = false
				}
			})
			return total
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'obtenerEtiquetasParaManifestoCorreoChileResult': this.obtenerEtiquetasParaManifestoCorreoChileResult(response); break;
				case 'generarCorreosChileManifiestoResult': this.generarCorreosChileManifiestoResult(response); break;
				case 'obtenerCorreosChileManifiestosResult': this.obtenerCorreosChileManifiestosResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clickManifestoCorreosChileTab() {
			this.$refs.manifestoCorreosChileTab.click()
		},
		initModuleLocal() {
			this.setCorreosChileComponentInit()
		},
		generateCorreosChileManifestoLocal() {
			let data = []
			this.listCorreosChileFilter.forEach(cc => {
				if (cc.correos_chile_manifiesto_id == true) {
					data.push(cc)
				}
			})
			this.generarCorreosChileManifiesto(data)
		},
		generarCorreosChileManifiestoResultLocal(response) {
			if (response.data.success == true) {
				this.$refs.manifestoCorreosChileTab.click()
			} else {
				console.log(response)
			}
		},
		obtenerCorreosChileManifiestosLocal(page = 1) {
			this.obtenerCorreosChileManifiestos(page)
		},
		obtenerCorreosChileManifiestosResultLocal(response) {
		},
		obtenerEtiquetasParaManifestoCorreoChileLocal() {
			this.obtenerEtiquetasParaManifestoCorreoChile()
		},
		obtenerEtiquetasParaManifestoCorreoChileResultLocal(response) {
			this.listaCorreosChile.forEach(cc => {
				cc.numeroEnvio = new String(cc.numeroEnvio);
				if (cc.nombreProducto == 1) {
					cc.nombreProducto = 'PAQUETE EXPRESS DOMICILIO'
				} else {
					cc.nombreProducto = 'PAQUETE EXPRESS SUCURSAL'
				}
				if (cc.correos_chile_manifiesto_id == null) {
					cc.correos_chile_manifiesto_id = true
				} else {
					cc.correos_chile_manifiesto_id = false
				}
			});
		},
		selectAllListaCorreosChile() {
			this.listaCorreosChile.forEach(cc => {
				cc.correos_chile_manifiesto_id = this.allSelectListaCorreosChile
			})
		},
		setCorreosChileComponentInit() {
		},
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>