<template>
	<div id="orderColportaje">
		<order-by-team-component ref="orderByTeamComponent" :id="id"></order-by-team-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import OrderByTeamComponent from '@/components/OrderByTeamComponent.vue'

export default {
	name: 'OrderColportaje',
	mixins: [mixin, axiosAction, errors],
	props: ['id'],
	data() {
		return {
		}
	},
	components: {
		'order-by-team-component': OrderByTeamComponent
	},
	methods: {
		initModuleLocal() {
			this.showOrderLocal()
		},
		showOrderLocal() {
			setTimeout(() => { this.$refs.orderByTeamComponent.$refs.btnShowOrder.click() }, 50)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
