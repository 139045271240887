export default {
    state: {
        id: '',
        name: '',
        email: '',
        nick: '',
        img: '',
        menu: [],
        action: [],
    },
    mutations: {
        setUser(state, user) {
            state.id = user.id
            state.name = user.name
            state.email = user.email
            state.nick = user.nick
            state.img = user.img
        },
        setAction(state, action) { state.action = action },
        setMenu(state, menu) { state.menu = menu }
    },
    getters: {
        getId: state => { return state.id },
        getName: state => { return state.name },
        getEmail: state => { return state.email },
        getNick: state => { return state.nick },
        getImg: state => { return state.img },
        getAction: state => { return state.action },
        getMenu: state => { return state.menu },
        getUser: state => { return state }
    },
    actions: {},
    modules: {},
};