/* Rquiere de mixin.js */

var user = {
	data() {
		return {
			objectUser: {
				id: 0,
				name: '',
				email: '',
				nick: '',
				email_verified_at: '',
				removable: 1,
				change_password: 1,
				img: '',
				status_id: 1,
				status: null,
				remember_token: '',
				created_at: '',
				updated_at: '',
				roles: [],
				user_price_list: [],
				clients: [],
				roles: [],
				role_id: 0,
				password: '',
				password_repeat: '',
				client_id: null
			},
			listUsers: [],
			urlsUser: {
				changePassword: 'user/changePassword',
				resetPassword: 'user/resetPassword',
				create: 'user/register',
				delete: '/delete',
				userGet: 'users',
				update: 'user/update',
				show: 'user/',
				priceList: '/priceLists',
			},
			paginationUsers: [],
			loadingUsers: false,
			savingUser: false,
			searchingUser: false,
			searchUsers: '',
			qRowsUser: 10,
			loadingPriceListsUser: false
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		addListUsers(newUser) {
			this.listUsers.push(newUser)
		},
		addListUserPriceList(i) {
			this.objectUser.user_price_list.push(i)
		},
		changePassword(currentPassword, newPassword) {
			let data = {
				currentPassword: currentPassword.trim(),
				newPassword: newPassword.trim()
			}
			this.$emit('showLockScreen', 'Reseteando contraseña . . .')
			let url = this.getApiBackend + this.urlsUser.changePassword
			this.axiosActionIS(url, 'PUT', 'changePasswordResult', data)
		},
		changePasswordResult(response) {
			this.changePasswordResultLocal(response)
		},
		changePasswordResultLocal(response) {
			console.log('changePasswordResultLocal no desarrollado', response)
		},
		changeQRowsUser() {
			localStorage.setItem('qRowsUser', this.qRowsUser)
			this.changeQRowsUserLocal()
		},
		changeQRowsUserLocal() {
			console.log('changeQRowsUserLocal no desarrollado')
		},
		clearObjectUser() {
			this.objectUser.id = 0
			this.objectUser.name = ''
			this.objectUser.email = ''
			this.objectUser.nick = ''
			this.objectUser.email_verified_at = ''
			this.objectUser.removable = 1
			this.objectUser.change_password = 1
			this.objectUser.img = ''
			this.objectUser.status_id = 1
			this.objectUser.status = null
			this.objectUser.remember_token = ''
			this.objectUser.created_at = ''
			this.objectUser.updated_at = ''
			this.objectUser.roles = []
			this.objectUser.user_price_list = []
			this.objectUser.clients = []
			this.objectUser.roles = []
			this.objectUser.role_id = 0
			this.objectUser.password = ''
			this.objectUser.password_repeat = ''
			this.objectUser.client_id = null
		},
		deleteItemUserPriceList(id) {
			let index = this.objectUser.user_price_list.map((el) => parseInt(el.price_list_id)).indexOf(parseInt(id))
			if (index > -1)
				return this.objectUser.user_price_list.splice(index, 1)
			return false
		},
		deleteUserPriceList(id, priceListId) {
			let url = this.getApiBackend + this.urlsUser.show + id + '/priceList/' + priceListId + '/delete'
			this.axiosActionIS(url, 'DELETE', 'deleteUserPriceListResult')
		},
		deleteUserPriceListResult(response) {
			if (response.data.success == true) {
				this.deleteItemUserPriceList(response.data.data)
				this.$notify({ title: 'QUITADA!!!', text: 'Se ha quidatado la lista de precios', type: 'info' })
			}
			this.deleteUserPriceListResultLocal(response)
		},
		deleteUserPriceListResultLocal(response) {
			console.log('deleteUserPriceListResultLocal no desarrollado', response)
		},
		deleteUser(id) {
			let url = this.getApiBackend + this.urlsUser.show + id + this.urlsUser.delete
			this.axiosActionIS(url, 'DELETE', 'deleteUserResult')
		},
		deleteItemListUsers(id) {
			let index = this.listUsers.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let userDeleted = this.listUsers.splice(index, 1)
		},
		deleteUserResult(response) {
			if (response.data.success) {
				this.deleteItemListUsers(response.data.data)
				this.$notify({ title: 'USUARIO ELIMINADO', text: response.data.message, type: 'info' })
			} else {
				this.$notify({ title: response.data.data.toUpperCase(), text: response.data.message, type: 'warn' })
			}
			this.deleteUserResultLocal(response)
		},
		deleteUserResultLocal(response) {
			console.log('deleteUserResultLocal no desarrolado', response)
		},
		getAllUserPriceLists(id) {
			this.loadingPriceListsUser = true
			this.objectUser.user_price_list = []
			let url = this.getApiBackend + this.urlsUser.show + id + this.urlsUser.priceList
			this.axiosActionIS(url, 'GET', 'getAllUserPriceListsResult')
		},
		getAllUserPriceListsResult(response) {
			this.loadingPriceListsUser = false
			if (response.data.success == true) {
				this.objectUser.user_price_list = response.data.data
			}
			this.getAllUserPriceListsResultLocal(response)
		},
		getAllUserPriceListsResultLocal(response) {
			console.log('getAllUserPriceListsResultLocal no desarrollado', response)
		},
		getAllUsers(page = 1) {
			this.listUsers = []
			this.paginationUsers = []
			this.loadingUsers = true
			let parameters = '?search=' + this.searchUsers + '&page=' + page + '&qRow=' + this.qRowsUser
			let url = this.getApiBackend + this.urlsUser.userGet + parameters
			this.axiosActionIS(url, 'GET', 'getAllUsersResult')
		},
		getAllUsersResult(response) {
			this.loadingUsers = false
			if (response.data.success == true) {
				this.listUsers = response.data.data
				this.paginationUsers = response.data.paginate
			}
			this.getAllUsersResultLocal(response)
		},
		getAllUsersResultLocal(response) {
			console.log('getAllUsersResultLocal no desarrollado', response)
		},
		getUserByClientId(clientId) {
			this.clearObjectUser()
			this.listUsers = []
			this.searchingUser == true
			let url = this.getApiBackend + this.urlsUser.show + 'byClient/' + clientId
			this.axiosActionIS(url, 'GET', 'getUserByClientIdResult')
		},
		getUserByClientIdResult(response) {
			if (response.data.success == true) {
				if (response.data.message == 'FOUND') {
					this.setObjectUser(response.data.data[0])
				} else {
					this.listUsers = response.data.data
				}
			}
			this.getUserByClientIdResultLocal(response)
		},
		getUserByClientIdResultLocal(response) {
			console.log('getUserByClientIdResultLocal no desarrollado', response)
		},
		initialsUser(user) {
			let us = user.name.split(' ')
			if (us.length > 1) {
				return us[0].substring(0, 1) + us[1].substring(0, 1)
			}
			return us[0].substring(0, 2)
		},
		roleUserFirs(u) {
			if (u.roles.length > 0) {
				return u.roles[0].name
			}
			return 'Sin Asignar'
		},
		saveUser(u) {
			this.savingUser = true
			let method = (u.id == 0) ? 'POST' : 'PUT'
			let url = (method == 'POST') ? this.getApiBackend + this.urlsUser.create : this.getApiBackend + this.urlsUser.show + u.id + '/update'
			this.axiosActionIS(url, method, 'saveUserResult', u)
		},
		saveUserResult(response) {
			this.savingUser = false
			if (response.data.success) {
				this.setObjectUser(response.data.data)
				if (response.data.message == 'NEW') {
					this.$notify({ title: 'NUEVO USUARIO!!!', text: 'Se ha creado un nuevo usuario', type: 'success' })
					this.addListUsers(response.data.data)
				} else if (response.data.message == 'UPDATE') {
					this.$notify({ title: 'ACTUALIZADO!!!', text: 'Se ha actualizado el usuario', type: 'info' })
					this.updateItemListUsers(response.data.data)
				}
			} else {
				this.setErrorSaveUserLocal(response)
			}
			this.saveUserResultLocal(response)
		},
		saveUserResultLocal(response) {
			console.log('saveUserResultLocal no desarrollado', response)
		},
		saveUserPriceList(id, priceListId) {
			let url = this.getApiBackend + this.urlsUser.show + id + '/priceList/register'
			this.axiosActionIS(url, 'POST', 'saveUserPriceListResult', { priceListId: priceListId })
		},
		saveUserPriceListResult(response) {
			if (response.data.success == true) {
				this.addListUserPriceList(response.data.data)
				this.$notify({ title: 'AGREGADA', text: 'Lista de Precio agregada', type: 'info' })
			}
			this.saveUserPriceListResultLocal(response)
		},
		saveUserPriceListResultLocal(response) {
			console.log('saveUserPriceListResultLocal no desarrollado', response)
		},
		setUserInit() {
			this.qRowsUser = (localStorage.getItem('qRowsUser') === null) ? this.qRowsUser : localStorage.getItem('qRowsUser')
			this.setUserInitLocal()
		},
		setUserInitLocal() {
			console.log('setUserInitLocal no desarrollado')
		},
		resetPassword(id) {
			let authChangePassword = {
				id: id
			}
			this.$emit('showLockScreen', 'Reseteando contraseña . . .')
			this.axiosActionIS(this.getApiBackend + this.urlsUser.resetPassword, 'PUT', 'resetPasswordResult', authChangePassword)
		},
		resetPasswordResult(response) {
			this.resetPasswordResultLocal(response)
		},
		resetPasswordResultLocal(response) {
			console.log('resetPasswordResultLocal no definido', response)
		},
		setErrorSaveUserLocal(response) {
			this.$notify({ title: 'NO SE GUARDO!!!', text: response.data.message, type: 'warn' })
		},
		setObjectUser(item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectUser[property] = newItem[property]
			}

			if (newItem['roles'] != undefined && newItem['roles'].length > 0)
				this.objectUser.role_id = newItem['roles'][0]['id']
		},
		setUserOfListUser(index, item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.listUsers[index][property] = newItem[property]
			}
		},
		showUser(id) {
			this.searchingUser = true
			let url = this.getApiBackend + this.urlsUser.show + id
			this.axiosActionIS(url, 'GET', 'showUserResult')
		},
		showUserResult(response) {
			this.searchingUser = false
			if (response.data.success == true) {
				this.setObjectUser(response.data.data)
			}
			this.showUserResultLocal(response)
		},
		showUserResultLocal(response) {
			console.log('showUserResultLocal no desarrollado', response)
		},
		updateItemListUsers(data) {
			let index = this.listUsers.map((el) => el.id).indexOf(data.id)
			if (index > -1) {
				this.setUserOfListUser(index, data)
			}
		},
		updateUserClientId(id, clientId) {
			this.savingUser = true
			let data = { clientId: clientId }
			let url = this.getApiBackend + this.urlsUser.show + id + '/updateClient'
			this.axiosActionIS(url, 'PUT', 'updateUserFieldResult', data)
		},
		updateUserStatusId(id, statusId) {
			this.savingUser = true
			let data = {
				statusId: statusId
			}
			let url = this.getApiBackend + this.urlsUser.show + id + '/updateStatus'
			this.axiosActionIS(url, 'PUT', 'updateUserFieldResult', data)
		},
		updateUserFieldResult(response) {
			this.savingUser = false
			if (response.data.success == true) {
				this.$notify({ title: 'Actualizado!!!', text: response.data.message, type: 'success' })
				this.setObjectUser(response.data.data)
				this.updateItemListUsers(response.data.data)
			} else {
				this.$notify({ title: 'NO SE ACTUALIZO!!!', text: response.data.message, type: 'warn' })
			}
			this.updateUserFieldResultLocal(response)
		},
		updateUserFieldResultLocal(response) {
			console.log('updateUserFieldResultLocal no desarrollado', response)
		}
	},
};

export default user;