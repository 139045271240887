<template>
	<div id="universityCareerListComponent">
		<button ref="btnSetSelectionUniversityCareerListComponent" @click="setSelectionLocal" v-show="false">Set Selection</button>
		<table class="table table-sm table-striped table-hover fs--1 mb-0 overflow-hidden" v-if="type == 'table'">
			<thead class="bg-200 text-900">
				<tr>
					<th class="align-middle text-center">ID</th>
					<th class="align-middle text-">Carrera</th>
					<th class="align-middle text-center">Matricula</th>
					<th class="align-middle text-center">Hospedaje</th>
					<th class="align-middle text-center">Arancel</th>
					<th class="align-middle text-center">Beca Completa</th>
					<th class="align-middle text-center">Media Beca</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in listUniversityCareers" :key="index" class="pointer" @dblclick="dblclickLocal(item)">
					<td class="align-middle text-center">{{item.id}}</td>
					<td class="align-middle text-">{{item.name}}</td>
					<td class="align-middle text-center">$ {{methodFormatNumber(item.tuition)}}</td>
					<td class="align-middle text-center">$ {{methodFormatNumber(item.lodging)}}</td>
					<td class="align-middle text-center">$ {{methodFormatNumber(item.cost)}}</td>
					<td class="align-middle text-center">$ {{methodFormatNumber(item.scholarship)}}</td>
					<td class="align-middle text-center">$ {{methodFormatNumber(item.scholarship_media)}}</td>
				</tr>
			</tbody>
		</table>
		<select v-else v-model="selectedIdLocal" :class="_class" @change="changeLocal($event)">
			<option :value="0" disabled selected>Seleccione un opción de la lista</option>
			<option v-for="(item, index) in listUniversityCareers" :key="index" :value="item.id">{{item.name}}</option>
		</select>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import universityCareers from '@/mixins/generals/universityCareer.js'

export default {
	name: 'UniversityCareerListComponent',
	mixins: [mixin, axiosAction, errors, universityCareers],
	props: ['type', '_class', 'selectedId'],
	data () {
		return {
			selectedIdLocal: this.selectedId
		}
	},
	components: {
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'getAllUniversityCareersResult': this.getAllUniversityCareersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		dblclickLocal(item) {
			this.$emit('actionSelectionSingle', item)
		},
		changeLocal(e) {
			let index = this.listUniversityCareers.map(e => parseInt(e.id)).indexOf(parseInt(e.target.value))
			if(index > -1) {
				this.$emit('actionSelectionSingle', this.listUniversityCareers[index])
			} else {
				this.$emit('actionSelectionSingle', false)
			}
		},
		getAllUniversityCareersResultLocal(response) {},
		initModuleLocal() {
			this.setUniversityCareerTableComponentInit()
		},
		nombreModalComponent() {},
		nombreModalComponent() {},
		setUniversityCareerTableComponentInit() {
			this.getAllUniversityCareers()
		},
		setSelectionLocal() {
			this.selectedIdLocal = this.selectedId
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>