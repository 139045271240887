import Vue from 'vue'
import Vuex from 'vuex'
import User from './user.js'
import Config from './config.js'
import Socketio from './socketio.js'
import FacturacionCLPrint from './facturacionCLPrint.js'
import WebSocketServer from './webSocketServer.js'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		initialized: false,
		token: null,
		history: [],
		historyFull: [],
		notifyPosition: 'bottom right'
	},
	getters: {
		getInitialized: state => { return state.initialized },
		getToken: state => { return state.token },
		getHistory: state => { return state.history },
		getHistoryFull: state => { return state.historyFull },
		getNotifyPosition: state => { return state.notifyPosition }
	},
	mutations: {
		setInitialized(state, value) { state.initialized = value },
		setToken(state, token) { state.token = token },
		setHistory(state, router) { state.history.push(router) },
		setHistoryFull(state, message) { state.historyFull.unshift({ date: new Date(), message: message }) },
		setNotifyPosition(state, position) { state.notifyPosition = position },
	},
	actions: {
	},
	modules: {
		user: User,
		config: Config,
		socketio: Socketio,
		facturacionCLPrint: FacturacionCLPrint,
		webSocketServer: WebSocketServer
	}
})
