import Vue from 'vue'
import VueRouter from 'vue-router'

import App from '../App.vue'
import Login from '../Login.vue'
import Logout from '../Logout.vue'
import Unauthorized from '../Unauthorized.vue'
import Loading from '../Loading.vue'

import Dashboard from '../views/falcon/Dashboard.vue'
import Analytics from '../views/falcon/Analytics.vue'
import CRM from '../views/falcon/CRM.vue'
import Ecommerce from '../views/falcon/Ecommerce.vue'
import Management from '../views/falcon/Management.vue'
import Saas from '../views/falcon/Saas.vue'
import Profile from '../views/user/Profile.vue'

import Tests from '../modules/system/Tests.vue'

import User from '../modules/system/User.vue'
import Users from '../modules/system/Users.vue'

import Actions from '../modules/system/Actions.vue'
import Budgets from '../modules/product/Budgets.vue'
import Menus from '../modules/system/Menus.vue'
import Roles from '../modules/system/Roles.vue'
import Teams from '../modules/team/Teams.vue'
import Payments from '../modules/accounting/Payments.vue'
import Team from '../modules/team/Team.vue'
import TeamAdmin from '../modules/team/TeamAdmin.vue'
import TeamClient from '../modules/team/TeamClient.vue'
import Statistics from '../modules/team/Statistics.vue'
import OrdersColportaje from '../modules/team/Orders.vue'
import OrderTeam from '../modules/team/Order.vue'
import Consignments from '../modules/team/Consignments.vue'
import Manifestos from '../modules/logistic/Manifestos.vue'
import ShippingsRequest from '../modules/shippingsRequest/ShippingsRequest.vue'
import Shippings from '../modules/logistic/Shippings.vue'
import ClientHistory from '../modules/client/ClientHistory.vue'
import Clients from '../modules/client/Clients.vue'
import LegalDocuments from '../modules/accounting/LegalDocuments.vue'
import Aasinet from '../modules/accounting/Aasinet.vue'
import Journals from '../modules/accounting/Journals.vue'
import ChartOfAccounts from '../modules/accounting/ChartOfAccounts.vue'

import Attend from '../modules/logistic/Attend.vue'

Vue.use(VueRouter)

const routes = [
	{ path: '/login', name: 'Login', component: Login },
	{ path: '/logout', name: 'Logout', component: Logout },
	{ path: '/unauthorized', name: 'Unauthorized', component: Unauthorized },
	{ path: '/loading', name: 'Loading', component: Loading },
	{ path: '*', redirect: '/' },
	{
		path: '/',
		component: App,
		children: [
			{ path: '/', name: 'Dashboard', component: Teams },
			{ path: '/attend', name: 'Attend', component: Attend },
			{ path: '/actions', name: 'Actions', component: Actions },
			{ path: '/menus', name: 'Menus', component: Menus },
			{ path: '/roles', name: 'Roles', component: Roles },
			{ path: '/tests', name: 'Tests', component: Tests },
			{ path: '/users', name: 'Users', component: Users },
			{ path: '/user/:id', name: 'User', component: User, props: true },
			{ path: '/budgets', name: 'Budgets', component: Budgets },
			{ path: '/clients', name: 'Clients', component: Clients },
			{ path: '/client/:id/history', name: 'ClientHistory', component: ClientHistory, props: true },
			{ path: '/teams', name: 'Teams', component: Teams },
			{ path: '/team/statistics', name: 'Statistics', component: Statistics },
			{ path: '/team/:id', name: 'Team', component: Team, props: true },
			{ path: '/team/:id/admin', name: 'TeamAdmin', component: TeamAdmin, props: true },
			{ path: '/team/:teamId/client/:clientId', name: 'TeamClient', component: TeamClient, props: true },
			{ path: '/teamOrders', name: 'OrdersColportaje', component: OrdersColportaje },
			{ path: 'orderTeam/:id', name: 'OrderTeam', component: OrderTeam, props: true },
			{ path: '/consignments', name: 'Consignments', component: Consignments },
			{ path: '/analytics', name: 'Analytics', component: Analytics },
			{ path: '/crm', name: 'CRM', component: CRM },
			{ path: '/ecommerce', name: 'Ecommerce', component: Ecommerce },
			{ path: '/management', name: 'Management', component: Management },
			{ path: '/saas', name: 'Saas', component: Saas },
			{ path: '/profile', name: 'Profile', component: Profile },
			{ path: '/shippingsRequest', name: 'ShippingsRequest', component: ShippingsRequest },
			{ path: '/shippings', name: 'Shippings', component: Shippings },
			{ path: '/legalDocuments', name: 'LegalDocuments', component: LegalDocuments },
			{ path: '/manifestos', name: 'Manifestos', component: Manifestos },
			{ path: '/aasinet', name: 'Aasinet', component: Aasinet },
			{ path: '/journals', name: 'Journals', component: Journals },
			{ path: '/payments', name: 'Payments', component: Payments },
			{ path: '/chartOfAccounts', name: 'ChartOfAccounts', component: ChartOfAccounts },
			{
				path: '/about',
				name: 'About',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
			},
		]
	},
	/**/

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router