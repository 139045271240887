<template>
	<div id="clientListComponent">
		<div class="card mb-3" id="clientsTable">
			<div class="card-header">
                <div class="row flex-between-center">
                    <div class="col-3 col-sm-auto d-flex align-items-center pe-0">
                        <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Cliente</h5>
                    </div>
                    <div class="col-3 col-sm-13">
						<div>
							<div>
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search" placeholder="Buscar pedidos" aria-label="search"
										v-model="searchClients"
										@keyup.enter="getAllClientsLocal(1)"/>
									<div class="input-group-text bg-transparent pointer" @click="getAllClientsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
                    </div>
					<div class="col-6 col-sm-auto ms-auto text-end ps-0">
                        <div id="orders-actions">
                            <button class="btn btn-falcon-default btn-sm" type="button" @click="showClientEditComponentModal(0)">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
                            <button class="btn btn-falcon-default btn-sm mx-2 d-none" type="button">
								<span class="fas fa-filter" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Filter</span>
							</button>
                            <button class="btn btn-falcon-default btn-sm d-none" type="button">
								<span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Export</span>
							</button>
                        </div>
					</div>
				</div>
            </div>
            <div class="card-body p-0">
				<div class="table-responsive scrollbar" v-if="!loadingClients">
					<table class="table table-hover table-striped overflow-hidden table-sm fs--1 mb-0">
						<thead class="bg-200 text-900">
							<tr>
								<th scope="col">Nombre</th>
								<th scope="col">Email</th>
								<th scope="col">Telefono</th>
								<th scope="col">Tipo de Cliente</th>
								<th scope="col" class="text-end">Acción</th>
							</tr>
						</thead>
						<tbody>
							<tr class="align-middle" v-for="(item, index) in listClients" :key="index">
								<td class="text-nowrap">
									<div class="d-flex align-items-center">
										<div class="avatar avatar-xl">
											<img class="rounded-circle" :src="(item.img == null || item.img == '') ? '/assets/img/team/avatar.png' : item.img" alt="" />
										</div>
										<div class="ms-2">{{item.text}}</div>
									</div>
								</td>
								<td class="text-nowrap">{{item.email}}</td>
								<td class="text-nowrap">{{item.phone}}</td>
								<td class="text-nowrap text-center">{{item.type_client}}</td>
								<td class="text-end">
									<div class="dropdown font-sans-serif position-static">
										<button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false"><span class="fas fa-ellipsis-h fs--1"></span></button>
										<div class="dropdown-menu dropdown-menu-end border py-0">
											<div class="bg-white py-2">
												<span class="dropdown-item pointer" @click="showClientEditComponentModal(item.id)">Editar</span>
												<span class="dropdown-item pointer text-danger" @click="deleteClientLocal(item)">Borrar</span>
											</div>
										</div>
									</div>									
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else class="text-center">
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando en la base de datos...</p>
				</div>
			</div>
			<div class="card-footer">
				<div class="row flex-between-center">
                    <div class="col-auto">
						<select class="form-select form-select-sm" v-model="qRowsClient" @change="changeQRowsClient">
							<option value="10">10 Resultados</option>
							<option value="25">25 Resultados</option>
							<option value="50">50 Resultados</option>
							<option value="100">100 Resultados</option>
							<option value="200">200 Resultados</option>
							<option value="1000">1000 Resultados</option>
						</select>
					</div>
					<pagination :pagination="paginationClients" @getAllList="getAllClientsLocal"></pagination>
					<div class="col-auto"><a class="fs--1 font-sans-serif" href="#!" v-show="false">Ayuda</a></div>
                  </div>
			</div>
		</div>

		<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#clientWizarComponentModal" ref="btnShowClientWizardComponentModal" v-show="false">
			Abrir Client Edit Component
		</button>

		<!-- Modal -->
		<div class="modal fade" id="clientWizarComponentModal" tabindex="-1" aria-labelledby="clientWizarComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<client-wizard-component class="modal-content" typeClient="Cliente"
						ref="clientWizarComponent"
						:id="clientIdSelected"
						@saveClient="saveClientLocal">
					</client-wizard-component>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import clients from '@/mixins/clients'

import Pagination from '@/components/Pagination.vue'
import ClientWizardComponent from '@/components/ClientWizardComponent.vue'


export default {
	name: 'ClientListComponent',
	mixins: [mixin, axiosAction, errors, clients],
	props: [],
	data () {
		return {
			clientIdSelected: 0
		}
	},
	components: {
		'client-wizard-component': ClientWizardComponent,
		'pagination': Pagination,
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch(responseLocal) {
				case 'deleteClientResult': this.deleteClientResult(response); break;
				case 'getAllClientsResult': this.getAllClientsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		deleteClientLocal(c) {
			if(confirm('Esta por borrar a ' + c.name + ' ' + c.surname + '\n¿Desea continuar?' )) {
				this.deleteClient(c.id)
			}
		},
		deleteClientResultLocal(response) {},
		getAllClientsLocal(page = 1) {
			this.getAllClients(page)
		},
		getAllClientsResultLocal(response) {
		},
		initModuleLocal() {
			this.setNombreInit()
			this.getAllClientsLocal()
		},
		saveClientLocal(c, type = 'NEW') {
			if(type == 'NEW') {
				this.addListClients(c)
			} else if(type == 'UPDATE'){
				this.updateItemListClients(c)
			}
		},
		setNombreInit() {
		},
		showClientEditComponentModal(clientId = 0) {
			this.clientIdSelected = clientId
			setTimeout(() => { this.$refs.clientWizarComponent.$refs.btnShowClient.click() }, 10)
			this.$refs.btnShowClientWizardComponentModal.click()
		}
	},
	mounted () {
		this.initModule(this.$options.name)
	},
}
</script>