<template>
	<div id="Loading">
		<div class="row flex-center min-vh-100 py-6 text-center">
			<div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
				<a class="d-flex flex-center mb-4" href="/index.html">
					<img class="me-2" src="/assets/img/icons/spot-illustrations/falcon.png" alt="" width="58" />
					<span class="font-sans-serif fw-bolder fs-5 d-inline-block">{{ getNameApp }}</span>
				</a>
				<div class="card">
					<div class="card-body p-4 p-sm-5">
						<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos {{
							message }}</p>
						<div v-show="error.message != ''">
							<div class="alert alert-danger border-2 d-flex align-items-center" role="alert">
								<div class="bg-danger me-3 icon-item"><span
										class="fas fa-times-circle text-white fs-3"></span></div>
								<p class="mb-0 flex-1">{{ error.message }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
export default {
	name: 'Loading',
	mixins: [mixin, axiosAction],
	data() {
		return {
			message: '',
			error: {
				message: ''
			}
		}
	},
	computed: {
	},
	methods: {
		initLoading() {
			this.checkUser()
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'checkUserResult': this.checkUserResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')'); break;
			}
		},
		checkUser() {
			this.error.message = ''
			this.message = 'cargando su información de Perfil . . .'
			let url = this.getApiBackend + 'auth/checkconection'
			this.axiosActionIS(url, 'GET', 'checkUserResult')
		},
		checkUserResult(response) {
			if (response.data.success == true) {
				this.setInitialized(true)
				this.setUser(response.data.auth)
				this.setToken(localStorage.getItem('token'))
				console.log('Se recuperó con exito los datos del usuario!!!')
				this.getMenus()
			} else {
				console.clear()
				this.$router.push('/unauthorized')
			}
		},
		errorHttpHandling(response) {
			console.log(response)
			this.$router.push('/unauthorized')
		},
		getMenus() {
			this.error.message = ''
			this.message = 'cargando los Menus . . .'

			this.axios.get(this.getApiBackend + 'auth/getMenus', {
				headers: this.getHeaders
			})
				.then(response => {
					console.log('Se recuperó con exito sus menus!!!')
					this.setMenu(response.data)
					this.getActions()
				})
				.catch(error => {
					this.error.message = 'Ups!!!, Ha ocurrido un error al tratar de cargar los menus.'
				})
		},
		getActions() {
			this.error.message = ''
			this.message = 'cargando las acciones autorizadas . . .'
			let url = this.getApiBackend + 'auth/getActions'
			this.axios.get(url, {
				headers: this.getHeaders
			})
				.then(response => {
					console.log('Se recuperó con exito las acciones autorizadas!!!')
					this.setAction(response.data)
					this.redirect()
				})
				.catch(error => {
					this.error.message = 'Ups!!!, Ha ocurrido un error al tratar de cargar las acciones autorizadas.'
				})

		},
		redirect() {
			let redirect = (localStorage.getItem('redirect') != null) ? localStorage.getItem('redirect') : '/'
			localStorage.removeItem('redirect')
			this.$router.push(redirect)
		}
	},
	mounted() {
		this.initLoading()
	},
}	
</script>

<style lang="scss"></style>
