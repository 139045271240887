<template>
	<div id="uploadDocumentModalComponent">
        <button class="btn btn-primary d-none" type="button" data-bs-toggle="modal" data-bs-target=".uploadDocumentModalComponent" ref="btnShowUploadDocumentModalComponent" @click="btnShowUploadDocumentModalComponent">Launch static backdrop modal</button>
        <div class="modal fade uploadDocumentModalComponent" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" aria-labelledby="uploadDocumentModalComponentLabel" aria-hidden="true">
                <div class="modal-dialog mt-6 modal-lg" role="document">
                    <div class="modal-content bconsignment-0">
                        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" @click="btnCloseUploadDocumentModalComponent" ref="btnCloseUploadDocumentModalComponent"></button>
                        </div>
                        <div class="modal-body p-0">
                            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                                <h4 class="mb-1" id="uploadDocumentModalComponentLabel">Adjuntar Archivo</h4>
                                <p class="fs--2 mb-0" >{{message}}</p>
                            </div>
                            <div class="p-4">
                                <div class="row">
                                    <div class="col-lg-5">
                                    <h6 class="mt-5 mt-lg-0">
                                        Archivos 
                                        <small>
                                            <label for="file-upload" class="subir float-end pointer">
                                                <i class="fas fa-cloud-upload-alt"></i> Subir archivo
                                            </label>
                                        </small>
									    <input id="file-upload" @change='loadFile' type="file" style='display: none;' accept=".png, .jpg, .jpeg"/>
                                    </h6>
                                        <ul class="nav flex-lg-column fs--1">
                                            <li class="nav-item me-2 me-lg-0" v-for="(item, index) in listUploadDocuments" :key="index">
                                                <a class="nav-link nav-link-card-details" @click="indexSelect(index)">
                                                    <span class="fas fa-user me-2">
                                                    </span>
                                                    {{item.uploadDocument.name_original}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="d-flex">
                                            <span class="fa-stack ms-n1 me-3">
                                                <i class="fas fa-circle fa-stack-2x text-200"></i>
                                                <i class="fa-inverse fa-stack-1x text-primary fas fa-tag" data-fa-transform="shrink-2"></i>
                                            </span>
                                            <div class="flex-1">
                                                <a :href="src" class="float-end mb-1 mr-1" title="Abrir en otra pestaña" v-if="indexSelected > -1">
                                                    <i class="fas fa-external-link-alt"></i>
                                                </a>
                                                <h5 class="mb-2 fs-0">Visualización</h5>
                                                <hr class="my-4" />
                                                <div class="col-12 text-center" v-if="indexSelected > -1">
                                                    <img :src="src" style="max-width: 100%; max-height: 600px;"/>
                                                    <button class="btn p-0 float-end  mb-1 mr-1" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar" @click="deleteUploadDocumentLocal()">
								                        <span class="far fa-trash-alt text-danger pointer"></span>
							                        </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import uploadDocument from '@/mixins/generals/uploadDocument.js'

export default {
    name: 'UploadDocumentModalComponent',
    mixins: [mixin, axiosAction, errors, uploadDocument],
    props: ['message', 'id', 'controller', 'configUploadDocumentId'],
    data() {
        return {
            indexSelected: -1
        }
    },
    components: {
    },
    computed: {
        src() {
            let img = ''
            if(this.listUploadDocuments.length > 0 && this.indexSelected > -1) {
                img = this.img = this.listUploadDocuments[this.indexSelected].uploadDocument.public_path + this.listUploadDocuments[this.indexSelected].uploadDocument.name_save
            }
            return img
        }
    },
    methods: {
        axiosResultLocal(responseLocal, response) {
            switch(responseLocal) {
                case 'deleteUploadDocumentResult': this.deleteUploadDocumentResult(response); break;
                case 'getUploadDocumentByControllerResult': this.getUploadDocumentByControllerResult(response); break;
                case 'saveUploadDocumentResult': this.saveUploadDocumentResult(response); break;
                default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
            }
        },
        initModuleLocal() {
        },
        btnCloseUploadDocumentModalComponent() {
            this.$emit('closeUploadDocumentModalComponentParent')
        },
        btnShowUploadDocumentModalComponent() {
            this.indexSelected = -1
            this.getUploadDocumentByControllerLocal()
        },
        deleteUploadDocumentLocal() {
            let id = this.listUploadDocuments[this.indexSelected].id
			if(confirm('¿Está seguro de ELIMINAR el archivo "' + this.listUploadDocuments[this.indexSelected].uploadDocument.name_original + '"?')){
                console.log('Elimando Documento Adjunto Nº ' + id)
                this.deleteUploadDocument(this.controller, this.id,  id)
			}
        },
        deleteUploadDocumentResultLocal(response) {
            this.indexSelected = (this.listUploadDocuments.length > 0) ? 0 : -1
        },
        getUploadDocumentByControllerLocal() {
            this.indexSelected = -1
            this.getUploadDocumentByController(this.id, this.controller)
        },
        getUploadDocumentByControllerResultLocal(response) {
            if(this.listUploadDocuments.length > 0) {
                this.indexSelect(0)
            }
        },
        indexSelect(index) {
            this.indexSelected = index
        },
        loadFile(event) {
            let file = event.target.files[0]
            let formData = new FormData();
            formData.append('file', file);
            formData.append('configUploadDocumentId', this.configUploadDocumentId);

            this.saveUploadDocument(this.controller, this.id, formData)
        },
        saveUploadDocumentResultLocal(response) {
            let index = this.listUploadDocuments.map((el) => parseInt(el.id)).indexOf(parseInt(response.data.data.id))
            if(index > -1) {
                this.indexSelect(index)
            }
        }
    },
    mounted () {
        this.initModule(this.$options.name)
    },
}	
</script>

<style lang="scss">
</style>
