<template>
	<div id="chartOfAccounts">
		<chart-of-account-list-component></chart-of-account-list-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import ChartOfAccountListComponent from '@/components/ChartOfAccountListComponent.vue'

export default {
	name: 'ChartOfAccounts',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
		}
	},
	components: {
		'chart-of-account-list-component': ChartOfAccountListComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
