<template>
	<div id="ClientHistoryTeamComponent" class="client-history-component">
		<button ref="btnShowClient" v-show="(true && objectClient.id == 0) && 1 == 0" @click="showClientLocal">Buscar
			Cliente {{ clientId }}</button>
		<div v-if="(objectClient.id > 0)">
			<div class="row">
				<div class="col-md-3 p-3">
					<div class="border p-3 bg-white text-center">
						<img src="/img/perfil/default.png" style="width: 80%;" v-if="(objectClient.img == null)" />
						<img :src="objectClient.img" style="width: 80%;" v-else />
						<div class="mt-3">
							<h5 class="text-primary text-center">{{ objectClient.text }}</h5>
							<small>Carrera Asignada:
								<span v-if="actionSecurity(13)" class="pointer"
									@dblclick="dblclickChangeUniversityCareerLocal" data-bs-toggle="modal"
									data-bs-target=".universityCareerSelectionModal">{{
			objectTeamClient.universityCareer.name }}</span>
								<span v-else>{{ objectTeamClient.universityCareer.name }}</span>
							</small>
						</div>
						<div>
							<div class="d-flex align-items-center">
								<div class="progress rounded-3 bg-100" style="height: 5px; width:100%"
									:title="successCalculation() + '% del objetivo'">
									<div class="progress-bar rounded-pill" :class="'bg-' + successCalculation('color')"
										role="progressbar" :style="'width: ' + successCalculation() + '%'"
										:aria-valuenow="successCalculation()" aria-valuemin="0" aria-valuemax="100">
									</div>
								</div>
							</div>
						</div>
						<div class="mt-2">
							<router-link :to="'/team/' + objectTeamClient.team.id + '/admin'">{{
			objectTeamClient.team.name }}</router-link>
						</div>
					</div>
				</div>
				<div class="col-md-9 p-3">
					<div class="row g-3 mb-3">
						<div class="col-md-6 col-xxl-3">
							<div class="card h-md-100 ecommerce-card-min-width">
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2 d-flex align-items-center">
										Bonificable
										<span class="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top"
											title="Calculated according to last week's sales">
											<span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
										</span>
									</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row">
										<div class="col">
											<p class="font-sans-serif lh-1 mb-1 fs-4"
												v-if="(!searchingProductPriceListNotBonus && !searchingProductPriceListNotFreight && !searchingProductPriceListNotTithe)">
												$ {{ methodFormatNumber(sumaListProductsPriceListBonus) }}
											</p>
											<p class="font-sans-serif lh-1 mb-1 fs-4" v-else>
												<small>calculando...</small>
											</p>
											<span class="badge badge-soft-success rounded-pill fs--2"
												v-show="false">+3.5%</span>
										</div>
										<div class="col-auto ps-0" v-show="false">
											<div class="echart-bar-weekly-sales h-100"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-xxl-3">
							<div class="card h-md-100">
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2">Diezmos</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row justify-content-between">
										<div class="col-auto align-self-end">
											<div class="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">$
												{{ methodFormatNumber(sumTitheTotal) }}</div>
											<span class="badge rounded-pill fs--2 bg-200 text-primary" v-show="false">
												<span class="fas fa-caret-up me-1"></span>
												13.6%
											</span>
										</div>
										<div class="col-auto ps-0 mt-n4">
											<div class="echart-default-total-order"
												data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
												data-echart-responsive="true"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-xxl-3">
							<div class="card h-md-100">
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2">Total Asignado</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row justify-content-between">
										<div class="col-auto align-self-end">
											<div class="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1 text-end">$
												{{ methodFormatNumber(sumOrdersTotal) }}</div>
											<span class="badge rounded-pill fs--2 bg-200 text-primary" v-show="false">
												<span class="fas fa-caret-up me-1"></span>
												13.6%
											</span>
										</div>
										<div class="col-auto ps-0 mt-n4">
											<div class="echart-default-total-order"
												data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
												data-echart-responsive="true"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-xxl-3">
							<div class="card h-md-100">
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2">Gastos</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row justify-content-between">
										<div class="col-auto align-self-end">
											<div class="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1 text-end">$
												{{ methodFormatNumber(sumExpensesTotal) }}</div>
											<span class="badge rounded-pill fs--2 bg-200 text-primary" v-show="false">
												<span class="fas fa-caret-up me-1"></span>
												13.6%
											</span>
										</div>
										<div class="col-auto ps-0 mt-n4">
											<div class="echart-default-total-order"
												data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
												data-echart-responsive="true"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-xxl-3">
							<div class="card h-md-100">
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2">Abonos</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row justify-content-between">
										<div class="col-auto align-self-end">
											<div class="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1 text-end">$
												{{ methodFormatNumber(totalPayments) }}</div>
											<span class="badge rounded-pill fs--2 bg-200 text-primary" v-show="false">
												<span class="fas fa-caret-up me-1"></span>
												13.6%
											</span>
										</div>
										<div class="col-auto ps-0 mt-n4">
											<div class="echart-default-total-order"
												data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
												data-echart-responsive="true"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-xxl-3">
							<div class="card h-md-100">
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2">Impuestos por medio de pago</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row justify-content-between">
										<div class="col-auto align-self-end">
											<div class="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1 text-end">$
												{{ methodFormatNumber(totalTaxPayments) }}</div>
											<span class="badge rounded-pill fs--2 bg-200 text-primary" v-show="false">
												<span class="fas fa-caret-up me-1"></span>
												13.6%
											</span>
										</div>
										<div class="col-auto ps-0 mt-n4">
											<div class="echart-default-total-order"
												data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
												data-echart-responsive="true"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-xxl-3">
							<div class="card h-md-100 border"
								:class="(balanceTotal < 0) ? 'border-danger' : 'border-success'">
								<div class="card-header pb-0">
									<h6 class="mb-0 mt-2">Saldo</h6>
								</div>
								<div class="card-body d-flex flex-column justify-content-end">
									<div class="row justify-content-between">
										<div class="col-auto align-self-end">
											<div class="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1 text-end">$
												<span :class="(balanceTotal < 0) ? 'text-danger' : 'text-success'">{{
			methodFormatNumber(balanceTotal) }}</span>
											</div>
											<span class="badge rounded-pill fs--2 bg-200 text-primary" v-show="false">
												<span class="fas fa-caret-up me-1"></span>
												13.6%
											</span>
										</div>
										<div class="col-auto ps-0 mt-n4">
											<div class="echart-default-total-order"
												data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
												data-echart-responsive="true"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="card mb-3">
					<div class="card-header d-flex flex-between-center py-2">
						<h6 class="my-1">Stock de Libros</h6>
					</div>
					<div class="card-body px-0 py-0 pb-2">
						<div class="table-responsive scrollbar">
							<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
								<thead class="bg-200 text-900">
									<tr>
										<th class="sort pe-1 align-middle white-space-nowrap">Articulo</th>
										<th class="sort pe-1 align-middle white-space-nowrap text-center">Precio</th>
										<th class="sort pe-1 align-middle white-space-nowrap text-center">Cantidad</th>
										<th class="sort pe-1 align-middle white-space-nowrap text-center">Total</th>
									</tr>
								</thead>
								<tbody class="list" id="table-teams-body">
									<tr v-if="(listProductsFilter.length == 0)">
										<td colspan="4" class="text-center p-2">No tiene productos asignados</td>
									</tr>
									<tr v-for="(item, index) in listProductsFilter" :key="index"
										v-if="(item.quantity != 0)">
										<td class="align-middle" :title="('Codigo: ' + item.product_id)">{{ item.product
											}}</td>
										<td class="align-middle text-end">{{ methodFormatNumber(item.price) }}</td>
										<td class="align-middle text-center">{{ methodFormatNumber(item.quantity) }}
										</td>
										<td class="align-middle text-end">{{ methodFormatNumber(item.price *
			item.quantity) }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="card-foot py-2 text-end" v-show="objectTeamClient.team.status_id == 42">
						<button class="btn btn-primary btn-sm me-3" @click="showOrderTransferLocal(15)"
							v-if="listProductsFilter.length" :disabled="!actionSecurity(11)">Devolver</button>
					</div>
				</div>
				<div class="card mb-3" id="listOrders">
					<div class="card-header d-flex flex-between-center">
						<h6 class="mb-0">Pedidos de Colportaje</h6>
					</div>
					<div class="card-body pt-0">
						<div class="col">
							<div class="card h-lg-100 overflow-hidden">
								<div class="card-body p-0">
									<div class="table-responsive scrollbar">
										<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
											<thead class="bg-200 text-900">
												<tr>
													<th class="sort pe-1 align-middle white-space-nowrap">Pedido</th>
													<th class="sort pe-1 align-middle white-space-nowrap pe-7">Fecha
													</th>
													<th class="sort pe-1 align-middle white-space-nowrap text-center">
														Estado</th>
													<th class="sort pe-1 align-middle white-space-nowrap text-center">
														Monto</th>
													<th class="sort pe-1 align-middle white-space-nowrap text-center">
														Diezmo</th>
												</tr>
											</thead>
											<tbody class="list" id="table-orders-body">
												<tr class="btn-reveal-trigger" v-for="(item, index) in listOrders"
													:key="index">
													<td class="order py-2 align-middle white-space-nowrap">
														<router-link :to="'/orderTeam/' + item.id" class="text-primary"
															target="_blank">
															<strong>#{{ item.id }}</strong>
														</router-link>
														asignado por
														<strong>{{ item.order_tracking_order[0].user }}</strong>
													</td>
													<td class="date py-2 align-middle">{{ item.created_at |
			formatDateMin
														}}</td>
													<td
														class="status py-2 align-middle text-center fs-0 white-space-nowrap">
														<span
															class="badge badge rounded-pill d-block badge-soft-success"
															v-if="item.type_order_id == 14">Asignado</span>
														<span class="badge badge rounded-pill d-block badge-soft-danger"
															v-else>Devuelto</span>
													</td>
													<td class="amount py-2 align-middle text-end fs-0 fw-medium"
														:class="(item.type_order_id == 15) ? 'text-danger' : ''">${{
			methodFormatNumber(item.total) }}</td>
													<td class="amount py-2 align-middle text-end fs-0 fw-medium"
														:class="(item.type_order_id == 15) ? 'text-danger' : ''">${{
			methodFormatNumber(item.tithe) }}</td>
												</tr>
											</tbody>
										</table>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3" id="listPayments">
					<div class="card-header d-flex flex-between-center">
						<h6 class="mb-0" @dblclick="setPaymentTableComponentLocal()">Remesa de {{ objectClient.text }}
						</h6>
					</div>
					<div class="card-body pt-0">
						<div class="col">
							<div class="card h-lg-100 overflow-hidden">
								<div class="card-body p-0">
									<div class="table-responsive scrollbar">
										<payment-table-component ref="paymentTableComponent" :id="objectTeamClient.id"
											controller="teamClient"
											:disableBtnPlusPayment="objectTeamClient.team.status_id != 42 || !actionSecurity(10)"
											:attachImage="false" @savePaymentParent="savePaymentLocal"
											@deletePaymentParent="deletePaymentLocal"
											@showUploadDocumentParent="showUploadDocumentLocal"
											@totalPaymentsParent="totalPaymentsLocal">
										</payment-table-component>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3" id="listExpenses">
					<div class="card-header d-flex flex-between-center">
						<h6 class="mb-0">Gastos asignados a {{ objectClient.text }}</h6>
					</div>
					<div class="card-body pt-0">
						<div class="col">
							<div class="card h-lg-100 overflow-hidden">
								<div class="card-body p-0">
									<div class="table-responsive scrollbar">
										<table class="table table-sm fs--1 mb-0 overflow-hidden">
											<thead class="bg-200 text-900">
												<tr>
													<th class="align-middle">ID</th>
													<th class="align-middle">Concepto</th>
													<th class="align-middle">Descripción</th>
													<th class="align-middle">Monto</th>
													<th class="align-middle text-end">
														<div
															v-show="objectTeamClient.team.status_id == 42 && actionSecurity(12)">
															<button class="btn p-0" type="button"
																data-bs-toggle="tooltip" data-bs-placement="top"
																title="Guardar" @click="showExpensesTransferLocal">
																<span class="fas fa-plus text-info pointer"></span>
															</button>
														</div>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-if="loadingTeamClientExpenses">
													<td colspan="5" class="text-center">
														cargando los abonos de {{ objectClient.text }} ...
													</td>
												</tr>
												<tr v-for="(item, index) in listExpenses" :key="index" v-else>
													<td class="align-middle text-center">{{ item.id }}</td>
													<td class="align-middle text-">{{ item.type_expense.name }}</td>
													<td class="align-middle text-">{{ item.description }}</td>
													<td class="align-middle text-end">$
														{{ methodFormatNumber(item.amount) }}</td>
													<td class="align-middle text-end">
														<div
															v-show="objectTeamClient.team.status_id == 42 && actionSecurity(12)">
															<button class="btn p-0" type="button"
																data-bs-toggle="tooltip" data-bs-placement="top"
																title="Borrar" @click="deleteExpenseLocal(item.id)">
																<span
																	class="far fa-trash-alt pointer text-danger"></span>
															</button>
														</div>
													</td>
												</tr>
												<tr v-if="listExpenses == 0 && !loadingTeamClientExpenses">
													<td colspan="5" class="text-center">
														No hay abonos cargados.
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center">
			Buscando información del colportor...
		</div>

		<order-colportaje-transfer-modal-component ref="orderColportajeTransferComponent" :team="''" :teamId="teamId"
			:client="objectClient" :listProducts="listProductsFilter" :typeOrderId="typeOrderId"
			@saveOrderComponent="saveOrderLocal">
		</order-colportaje-transfer-modal-component>

		<expense-modal-component ref="expenseModalComponent" controller="teamClient" :id="objectTeamClient.id"
			@saveExpenseComponent="saveExpenseLocal">
		</expense-modal-component>

		<div class="modal fade universityCareerSelectionModal" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="universityCareerSelectionModalLabel" aria-hidden="true">
			<div class="modal-dialog mt-6 modal-md" role="document">
				<div class="modal-content bconsignment-0">
					<div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
						<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
							data-bs-dismiss="modal" aria-label="Close"
							ref="btnCloseUniversityCareerSelectionModalComponent"></button>
					</div>
					<div class="modal-body p-0">
						<div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
							<h4 class="mb-1" id="universityCareerSelectionModalLabel">Cambio de carrera</h4>
							<p class="fs--2 mb-0">Seleccione la carrera</p>
						</div>
						<div class="p-4">
							<university-career-list-component ref="universityCareerListComponent"
								:_class="'form-select form-select-sm'"
								:selectedId="objectTeamClient.university_career_id"
								@actionSelectionSingle="actionSelectionUniversityCareer">
							</university-career-list-component>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5" v-show="messagePayment != ''">
			<div class="p-3 text-white" :class="(true) ? 'bg-success' : 'bg-danger'" id="liveToast" role="alert"
				aria-live="assertive" aria-atomic="true" data-bs-autohide="true">
				{{ messagePayment }}
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import clients from '@/mixins/clients'
import orders from '@/mixins/orders'
import productPriceList from '@/mixins/products/productPriceList.js'
import teamClient from '@/mixins/teams/teamClient.js'
import expenses from '@/mixins/expenses'

import ExpenseModalComponent from '@/components/ExpenseModalComponent.vue'
import OrderColportajeTransferModalComponent from '@/components/OrderColportajeTransferModalComponent.vue'
import PaymentTableComponent from '@/components/PaymentTableComponent.vue'
import UniversityCareerListComponent from '@/components/UniversityCareerListComponent.vue'


export default {
	name: 'ClientHistoryTeamComponent',
	mixins: [mixin, axiosAction, errors, clients, orders, productPriceList, teamClient, expenses],
	props: ['clientId', 'showDetail', 'teamId'],
	data() {
		return {
			totalPayments: 0,
			totalTaxPayments: 0,
			typeOrderId: 15,
			messagePayment: ''
		}
	},
	components: {
		'expense-modal-component': ExpenseModalComponent,
		'payment-table-component': PaymentTableComponent,
		'order-colportaje-transfer-modal-component': OrderColportajeTransferModalComponent,
		'university-career-list-component': UniversityCareerListComponent,
	},
	computed: {
		balanceTotal() {
			let total = 0
			total = parseFloat(this.totalPayments) - (parseFloat(this.sumOrdersTotal) + parseFloat(this.sumTitheTotal) + parseFloat(this.sumExpensesTotal) + parseFloat(this.totalTaxPayments))
			return total
		},
		listProductsFilter() {
			let list = []
			let index = -1
			let factor = 1
			this.listOrders.forEach(o => {
				factor = (o.type_order_id == 14) ? 1 : -1
				o.order_products.forEach(p => {
					index = list.map(e => parseInt(e.product_id)).indexOf(parseInt(p.product_id))
					if (index == -1) {
						p.quantity = parseInt(p.quantity) * factor
						list.push({ price: p.price, price_list_id: p.price_list_id, product: p.product, product_id: p.product_id, quantity: p.quantity, synthetic: p.synthetic, tax: p.tax, with_freight: p.with_freight, bonusable: p.bonusable, tithable: p.tithable })
					} else {
						list[index].quantity = parseInt(list[index].quantity) + (parseInt(p.quantity) * factor)
					}
				})
			});
			list = list.filter((p => { return p.quantity > 0 }))
			this.priceListsIdSelect = (list.length > 0) ? list[0].price_list_id : 0
			return list
		},
		sumaListProductsPriceListBonus() {
			let total = 0
			let index = -1
			this.listProductsFilter.forEach(op => {
				if (op.bonusable == true) {
					total = parseInt(total) + ((parseInt(op.price) - parseInt(op.with_freight)) * parseInt(op.quantity))
				}
			})
			return total.toFixed(0)
		},
		sumOrdersTotal() {
			let total = 0
			total = this.listOrders.reduce(function (a, o) {
				let b = 0
				if (o.type_order_id != 7)
					b = (o.type_order_id == 15) ? -1 * parseFloat(o.total) : parseFloat(o.total)
				return parseFloat(a) + parseFloat(b)
			}, 0);
			return total.toFixed(0)
		},
		sumTitheTotal() {
			let total = 0
			total = this.listOrders.reduce(function (a, o) {
				let b = 0
				if (b.type_order_id != 7) {
					b = (o.type_order_id == 15) ? -1 * parseFloat(o.tithe) : parseFloat(o.tithe)
				}
				return parseFloat(a) + parseFloat(b)
			}, 0);
			return total.toFixed(0)
		},
	},
	methods: {
		actionSelectionUniversityCareer(item) {
			this.$refs.btnCloseUniversityCareerSelectionModalComponent.click()
			if (this.objectTeamClient.university_career_id != item.id) {
				item.university_career_id = item.id
				this.updateTeamClient(this.objectTeamClient.id, item)
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteExpenseResult': this.deleteExpenseResult(response); break;
				case 'getTeamClientExpensesResult': this.getTeamClientExpensesResult(response); break;
				case 'getProductPriceListNotBonusResult': this.getProductPriceListNotBonusResult(response); break;
				case 'getProductPriceListNotFreightResult': this.getProductPriceListNotFreightResult(response); break;
				case 'getProductPriceListNotTitheResult': this.getProductPriceListNotTitheResult(response); break;
				case 'getTeamClientOrdersResult': this.getTeamClientOrdersResult(response); break;
				case 'showClientResult': this.showClientResult(response); break;
				case 'showTeamClientResult': this.showTeamClientResult(response); break;
				case 'updateTeamClientResult': this.updateTeamClientResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectClientLocal() {
			this.clearObjectClient()
		},
		dblclickChangeUniversityCareerLocal() {

		},
		deleteExpenseLocal(id) {
			if (confirm('¿Está seguro de ELIMINAR el gasto asociado a ' + this.objectClient.text + '?')) {
				this.deleteExpense('teamClient', this.objectTeamClient.id, id)
			}
		},
		deleteExpenseResultLocal(response) { },
		getAllUniversityCareersResultLocal(response) { },
		getProductPriceListNotBonusResultLocal(response) { },
		getProductPriceListNotFreightResultLocal(response) { },
		getProductPriceListNotTitheResultLocal(response) { },
		getTeamClientExpensesResultLocal(response) { },
		getTeamClientOrders(teamId, clientId) {
			let url = this.getApiBackend + 'team/' + teamId + '/client/' + clientId + '/orders'
			this.axiosActionIS(url, 'GET', 'getTeamClientOrdersResult')
		},
		getTeamClientOrdersResult(response) {
			if (response.data.success == true) {
				this.listOrders = response.data.data

				if (this.listOrders.length > 0) {
					this.priceListsIdSelect = this.listOrders[0].order_products[0].price_list_id
					this.getProductPriceListNotBonus(this.priceListsIdSelect)
					this.getProductPriceListNotFreight(this.priceListsIdSelect)
					this.getProductPriceListNotTithe(this.priceListsIdSelect)
				}

			}
		},
		initModuleLocal() {
			this.setClientHistoryInit()
		},
		saveExpenseLocal(response) {
			if (response.data.success == true) {
				this.listExpenses.push(response.data.data)
			}
			this.$refs.expenseModalComponent.$refs.btnCloseExpenseModalComponent.click()
		},
		savePaymentLocal(response) {
			if (response.payment.source.length != 0) {
				if (response.payment.source.type == 'consignment') {
					this.messagePayment = 'Se asignó automaticamente a la remesa "' + response.payment.source.data[0].description + '" - #' + response.payment.source.data[0].id
					setTimeout(() => { this.messagePayment = '' }, 5000)
				}
			}
		},
		deletePaymentLocal(response) {
		},
		showUploadDocumentLocal(data) {
		},
		setClientHistoryInit() {
		},
		showClientLocal() {
			if (this.clientId > 0) {
				this.showClient(this.clientId)
			}
		},
		showClientResultLocal(response) {
			if (this.objectClient.id > 0) {
				this.showTeamClient(this.teamId, this.objectClient.id)
				if (this.showDetail == true) {
					this.getTeamClientOrders(this.teamId, this.objectClient.id)
				}
			}
		},
		showExpensesTransferLocal() {
			setTimeout(() => {
				this.$refs.expenseModalComponent.$refs.btnShowExpenseModalComponent.click()
			}, 100)
		},
		showOrderTransferLocal(typeOrderId) {
			this.typeOrderId = typeOrderId
			setTimeout(() => {
				this.$refs.orderColportajeTransferComponent.$refs.btnShowOrderColportajeTransferModalComponent.click()
			}, 100)
		},
		showTeamClientResultLocal(response) {
			this.getTeamClientExpenses(this.objectTeamClient.id)
			setTimeout(() => {
				this.setPaymentTableComponentLocal()
				this.$refs.universityCareerListComponent.$refs.btnSetSelectionUniversityCareerListComponent.click()
			}, 100)
		},
		setPaymentTableComponentLocal() {
			this.$refs.paymentTableComponent.$refs.btnSetPaymentTableComponent.click()
		},
		totalPaymentsLocal(total, totalTax) {
			this.totalPayments = total
			this.totalTaxPayments = totalTax
		},
		saveOrderLocal(response) {
			this.listOrders.push(response.data.data)
		},
		successCalculation(typeAnswer = '%') {
			if (typeAnswer == '%') {
				let por = (parseFloat(this.totalPayments) * 100) / parseFloat(this.objectTeamClient.scholarship)
				return por
			} else if (typeAnswer == 'color') {
				if (parseFloat(this.totalPayments) >= parseFloat(this.objectTeamClient.scholarship))
					return 'success'
				if (parseFloat(this.totalPayments) >= parseFloat(this.objectTeamClient.scholarship_media))
					return 'primary'
				return 'danger'
			}
			return 0;
		},
		updateTeamClientResultLocal(response) { }
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>