<template>
	<div id="roleListComponent">
		<div>
			<table class="table table-sm fs--1">
				<thead>
					<tr>
						<th class="text-center">ID</th>
						<th>Nombre</th>
						<th>Etiqueta</th>
						<th class="text-center">Creado</th>
						<th class="text-center">Actualizado</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(r, i) in listRolesFilter" :key="i">
						<td class="text-center">
							<a class="pointer text-800" @click="setObjectRoleLocal(r)">
								{{ r.id }}
							</a>
						</td>
						<td>{{ r.name }}</td>
						<td>{{ r.label }}</td>
						<td class="text-center">{{ r.created_at }}</td>
						<td class="text-center">{{ r.updated_at }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- Modal Role-->
		<button ref="btnShowRoleModal" data-bs-toggle="modal" data-bs-target="#roleModal" v-show="false">Show Role
			Modal</button>
		<div class="modal fade" id="roleModal" tabindex="-1" aria-labelledby="roleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title text-900" id="roleModalLabel">
							Role
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row g-2">
							<div class="col-6">
								<div class="mb-3">
									<label class="form-label">
										Nombre
									</label>
									<input class="form-control form-control-sm" type="text" placeholder="Nombre"
										v-model="objectRole.name" />
								</div>
							</div>
							<div class="col-6">
								<div class="mb-3">
									<label class="form-label">
										Etiqueta
									</label>
									<input class="form-control form-control-sm" type="text" placeholder="Etiqueta"
										v-model="objectRole.label" />
								</div>
							</div>
						</div>
						<div>
							<ul class="nav nav-tabs" id="myTab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="menus-tab" data-bs-toggle="tab" href="#tab-menus"
										ref="manifestoCorreosChileTab" role="tab" aria-controls="tab-menus"
										aria-selected="true">
										Menus
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" id="actions-tab" data-bs-toggle="tab" href="#tab-actions"
										role="tab" aria-controls="tab-actions" aria-selected="false">
										Acciones
									</a>
								</li>
							</ul>
							<div class="tab-content border border-top-0" id="myTabContent">
								<div class="tab-pane fade show active" id="tab-menus" role="tabpanel"
									aria-labelledby="menus-tab">

									<div class="overflow" style="max-height: 20rem">
										<menu-list-component ref="menuListComponent" :openAll="true" :selectable="true"
											:menusSelected="objectRole.menus"
											@changeMenuSelected="changeMenuSelectedLocal">
										</menu-list-component>
									</div>
									<div class=" modal-footer">
										<button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal"
											ref="btnCloseRoleModal">Cerrar</button>
										<button type="button" class="btn btn-sm btn-primary"
											:disabled="disabledBtnSaveRole" @click="saveRoleLocal">Guardar</button>
									</div>
								</div>
								<div class="tab-pane fade" id="tab-actions" role="tabpanel"
									aria-labelledby="actions-tab">
									<div class="overflow" style="max-height: 20rem">
										<action-list-component ref="actionListComponent" view="list"
											:actionsSelected="objectRole.actions" :selectable="true"
											@changeActionSelected="changeActionSelectedLocal"></action-list-component>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import role from '@/mixins/generals/role.js'

import ActionListComponent from '@/components/ActionListComponent.vue'
import MenuListComponent from '@/components/MenuListComponent.vue'

export default {
	name: 'RoleListComponent',
	mixins: [mixin, axiosAction, errors, role],
	props: [],
	data() {
		return {
		}
	},
	components: {
		'action-list-component': ActionListComponent,
		'menu-list-component': MenuListComponent
	},
	computed: {
		disabledBtnSaveRole() {
			if (this.savingRole == true)
				return true

			return false
		},
		listRolesFilter() {
			return this.listRoles
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteRoleMenuResult': this.deleteRoleMenuResult(response); break;
				case 'deleteRoleActionResult': this.deleteRoleActionResult(response); break;
				case 'getRolesResult': this.getRolesResult(response); break;
				case 'saveRoleResult': this.saveRoleResult(response); break;
				case 'saveRoleActionResult': this.saveRoleActionResult(response); break;
				case 'saveRoleMenuResult': this.saveRoleMenuResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		changeActionSelectedLocal(a) {
			if (a.selected == true) {
				this.saveRoleAction(this.objectRole.id, a.id)
			} else {
				this.deleteRoleAction(this.objectRole.id, a.id)
			}
		},
		changeMenuSelectedLocal(m) {
			if (m.selected == true) {
				this.saveRoleMenu(this.objectRole.id, m.id)
			} else {
				this.deleteRoleMenu(this.objectRole.id, m.id)
			}
		},
		deleteRoleActionResultLocal(response) { },
		deleteRoleMenuResultLocal(response) { },
		initModuleLocal() {
			this.setRoleListComponentInit()
		},
		getRolesLocal(page = 1) {
			this.getRoles(page)
		},
		getRolesResultLocal(response) { },
		saveRoleLocal() {
			this.saveRole(this.objectRole)
		},
		saveRoleResultLocal(response) { },
		saveRoleActionResultLocal(response) { },
		saveRoleMenuResultLocal(response) { },
		setRoleListComponentInit() {
			this.getRolesLocal()
		},
		setObjectRoleLocal(r) {
			this.savingRole = false
			this.setObjectRole(r)
			setTimeout(() => {
				this.$refs.menuListComponent.$refs.btnSetMenusSelectedFromRole.click()
				this.$refs.actionListComponent.$refs.btnSetActionsSelectedFromRole.click()
			}, 50)
			this.$refs.btnShowRoleModal.click()
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>