<template>
	<div id="legalDocumentsList">
		<button ref="btnAddLegalDocument" @click="addLegalDocumentLocal" v-show="false">Add Legal Document</button>
		<button ref="btnUpdateLegalDocument" v-show="false">Update Legal Document</button>
		<div class="card mb-1" id="legalDocumentsListCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Documentos Legales </h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										v-model="searchLegalDocuments" @keyup.enter="getAllLegalDocumentsLocal(1)"
										@focus="$event.target.select()" :disabled="loadingLegalDocuments" />
									<div class="input-group-text bg-transparent pointer"
										@click="getAllLegalDocumentsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm" type="button"
								ref="btnEditLegalDocumentComponent" @click="viewLegalDocumentComponentLocal(0)">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 sort align-middle white-space-nowrap text-center">
									Documento</th>
								<th class="text-900 sort align-middle white-space-nowrap text-center">Folio</th>
								<th class="text-900 sort align-middle white-space-nowrap text-center">Fecha</th>
								<th class="text-900 sort align-middle white-space-nowrap">Destinatario</th>
								<th class="text-900 sort align-middle white-space-nowrap">Dirección</th>
								<th class="text-900 sort align-middle white-space-nowrap text-end">Monto</th>
								<th class="text-900 sort align-middle white-space-nowrap text-end"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(ld, i) in listLegalDocumentsFilter" :key="i">
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<strong class="ms-1">{{ ld.type_legal_document.name }}</strong>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<a class="text-primary pointer" @click="viewLegalDocumentComponentLocal(ld.id)">
										<strong>#{{ ld.legal_document_number }}</strong>
									</a>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">{{
			ld.date_legal_document | formatDateOnly }}</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<strong>{{ ld.receiving_entity_name }}</strong>
									<br />
									<span class="text-muted">
										<i class="far fa-address-card text-secondary"></i>
										<span class="ms-1">{{ ld.receiving_entity_document_number }}</span>
									</span>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									{{ ld.receiving_entity_address_street }}
									<div>
										<span>
											{{ ld.receiving_entity_address_comuna }} -
											{{ ld.receiving_entity_address_city }}
										</span>
									</div>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted text-end">{{
			methodFormatNumber(ld.total) }}</td>
								<th class="align-middle white-space-nowrap border-bottom border-muted text-end">
									<span v-if="ld.legal_document_number == 0"
										:title="'Generar ' + ld.type_legal_document.name"
										@click="sendToRegulatoryEntityLocal(ld)">
										<i class="far fa-paper-plane text-primary pointer ms-2"></i>
									</span>
									<div v-else>
										<a class="text-danger" :href="ld.url"
											:download="ld.type_legal_document.syntetic + ld.legal_document_number"
											v-show="ld.url != ''">
											<i class="far fa-file-pdf ms-2"></i>
										</a>
										<a class="text-warnign" :href="ld.url_assignable"
											:download="ld.type_legal_document.syntetic + 'C' + ld.legal_document_number"
											v-show="ld.url_assignable != ''">
											<i class="far fa-file-pdf ms-2"></i>
										</a>
										<span @click="toPrintLegalDocumentLocal(ld)"
											:class="(getSuccessFullConnectionFacturacionCLPrint) ? 'text-primary pointer' : 'not-allowed'">
											<i class="fas fa-print ms-2"></i>
										</span>
									</div>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationLegalDocuments" @getAllList="getAllLegalDocumentsLocal">
					</pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import legalDocument from '@/mixins/legalDocuments'
import facturacionCL from '@/mixins/generals/facturacionCL.js'

import Pagination from '@/components/Pagination.vue'

export default {
	name: 'LegalDocumentsList',
	mixins: [mixin, axiosAction, errors, legalDocument, facturacionCL],
	props: ['newLegalDocument'],
	data() {
		return {
		}
	},
	components: {
		'pagination': Pagination
	},
	computed: {
		listLegalDocumentsFilter() {
			return this.listLegalDocuments
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllLegalDocumentsResult': this.getAllLegalDocumentsResult(response); break;
				case 'sendToRegulatoryEntityResult': this.sendToRegulatoryEntityResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		addLegalDocumentLocal() {
			this.addListLegalDocuments(this.newLegalDocument)
		},
		getAllLegalDocumentsLocal(page = 1) {
			this.getAllLegalDocuments(page)
		},
		getAllLegalDocumentsResultLocal(response) { },
		initModuleLocal() {
			this.setLegalDocumentsListInit()
		},
		sendToRegulatoryEntityLocal(ld) {
			this.sendToRegulatoryEntity(ld.id)
		},
		sendToRegulatoryEntityResultLocal(response) {
			if (response.data.success == true) {

			} else {
				this.showMsgErrorHandling('ERROR', response.data.message, 'warn', 5000, 'top right')
			}
		},
		setLegalDocumentsListInit() {
			this.getAllLegalDocumentsLocal()
		},
		toPrintLegalDocumentLocal(ld) {
			this.toPrintFacturacionCL(ld.type_legal_document_id, ld.legal_document_number)
		},
		viewLegalDocumentComponentLocal(id) {
			this.$emit('legalDocumentComponent', id)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>