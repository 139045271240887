<template>
	<div id="orderColportajeTransferComponent">
		<button class="btn btn-primary d-none" type="button" data-bs-toggle="modal"
			data-bs-target=".orderColportajeTransferComponent" ref="btnShowOrderColportajeTransferModalComponent"
			@click="btnShowOrderColportajeTransferModalComponent">Launch static backdrop modal</button>
		<div class="modal fade orderColportajeTransferComponent" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="orderColportajeTransferComponentLabel" aria-hidden="true">
			<div class="modal-dialog mt-6 modal-lg" role="document"
				:class="(typeOrderId == 7) ? 'modal-xl' : 'modal-lg'">
				<div class="modal-content bconsignment-0">
					<div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
						<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
							data-bs-dismiss="modal" aria-label="Close"
							@click="btnCloseOrderColportajeTransferModalComponent"
							ref="btnCloseOrderColportajeTransferModalComponent"></button>
					</div>
					<div class="modal-body p-0">
						<div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
							<div v-if="typeOrderId == 4">
								<h4 class="mb-1" id="orderColportajeTransferComponentLabel">Transferencia de Materiales
								</h4>
								<p class="fs--2 mb-0">Desde la campaña <a class="link-600 fw-semi-bold"
										href="#!">{{ team }}</a></p>
							</div>
							<div v-if="typeOrderId == 6">
								<h4 class="mb-1" id="orderColportajeTransferComponentLabel">Devolución de Materiales
								</h4>
								<p class="fs--2 mb-0">Se descontará de la campaña <a class="link-600 fw-semi-bold"
										href="#!">{{ team }}</a></p>
							</div>
							<div v-if="(typeOrderId == 14)">
								<h4 class="mb-1" id="orderColportajeTransferComponentLabel">Asignación de Materiales a
									colportor</h4>
								<p class="fs--2 mb-0">Se asignará al colportor <a class="link-600 fw-semi-bold"
										href="#!">{{ (client.text == undefined || client.text == null) ? client.name + '
										' + client.surname : client.text}}</a></p>
							</div>
							<div v-if="(typeOrderId == 15)">
								<h4 class="mb-1" id="orderColportajeTransferComponentLabel">Devolución de Materiales a
									la campaña</h4>
								<p class="fs--2 mb-0">El colportor {{ (client.text == undefined || client.text == null) ?
				client.name + ' ' + client.surname : client.text }} devuelve a la campaña <a
										class="link-600 fw-semi-bold" href="#!">{{ team }}</a></p>
							</div>
							<div v-if="typeOrderId == 7">
								<h4 class="mb-1" id="orderColportajeTransferComponentLabel" v-if="typeOrderId == 7">
									Facturas a Terceros</h4>
							</div>
						</div>
						<div class="p-4">
							<div class="flex-1" v-if="typeOrderId == 4">
								<h5 class="mb-2 fs-0">Datos de Envío</h5>
								<div class="row g-3">
									<div class="col-md-6">
										<label class="form-label" for="inputTeam">
											Campaña
											<small :title="'Código seleccionado: ' + teamIdLocal"
												class="text-success fas fa-check" v-if="(teamIdLocal > 0)"></small>
										</label>
										<select class="form-select form-select-sm" id="inputTeam" v-model="teamIdLocal"
											:disabled="loadingTeams">
											<option value="0" selected disabled>
												Seleccione la campaña...
											</option>
											<option v-for="(item, index) in listTeamsFilter" :key="index"
												:value="item.id" v-if="item.id != teamId">{{ item.name }}</option>
										</select>
									</div>
									<div class="col-md-6">
										<label class="form-label" for="inputComuna">Observación</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectOrder.order_observations[0].observation" />
									</div>
								</div>
								<hr class="my-2" />
							</div>
							<div class="flex-1" v-else-if="typeOrderId == 7">
								<h5 class="mb-2 fs-0">Datos de Facturación</h5>
								<div class="row g-3">
									<div class="col-md-6">
										<label class="form-label" for="inputComuna">Razon Social</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.receiving_entity_name" />
									</div>
									<div class="col-md-3">
										<label class="form-label" for="inputComuna">RUT</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.receiving_entity_document_number" />
									</div>
									<div class="col-md-3">
										<label class="form-label" for="inputComuna">Giro</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.receiving_entity_economic_activity" />
									</div>
								</div>
								<div class="row g-3">
									<div class="col-md-6">
										<label class="form-label" for="inputComuna">Dirección</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.receiving_entity_address_street" />
									</div>
									<div class="col-md-3">
										<label class="form-label" for="inputComuna">Comuna</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.receiving_entity_address_comuna" />
									</div>
									<div class="col-md-3">
										<label class="form-label" for="inputComuna">Ciudad</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.receiving_entity_address_city" />
									</div>
								</div>
								<div class="row g-3">
									<div class="col-md-6">
										<label class="form-label" for="inputComuna">Contacto</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.contact" />
									</div>
									<div class="col-md-3">
										<label class="form-label" for="inputComuna">Telefono</label>
										<input type="text" class="form-control form-control-sm"
											v-model="objectLegalDocument.phone" />
									</div>
								</div>
								<hr class="my-2" />
							</div>
							<div class="d-flex">
								<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
									<thead>
										<tr>
											<th scope="col" class="text-center">#</th>
											<th scope="col">Articulo</th>
											<th scope="col" class="text-center" style="width: 75">Cantidad</th>
											<th scope="col" class="text-center">Precio</th>
											<th scope="col" class="text-center">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in objectOrder.order_products" :key="index"
											:class="(item.quantity > 0) ? 'text-black' : 'text-500'"
											v-if="item.quantityCurrent > 0">
											<td class="text-center align-middle">{{ index + 1 }}</td>
											<td class="align-middle">
												{{ item.product }}
												<p class="mb-0">Código {{ item.product_id }} {{ item.quantityCurrent }}
												</p>
											</td>
											<td class="align-middle">
												<div class="input-group input-group-sm flex-nowrap"
													data-quantity="data-quantity">
													<button class="btn btn-sm btn-outline-secondary border-300 px-2"
														data-type="minus" :disabled="item.quantity < 1"
														@click="item.quantity--">-</button>
													<input
														class="form-control form-control-sm text-center px-2 input-spin-none"
														type="number" min="0" :max="item.quantityCurrent"
														aria-label="Amount (to the nearest dollar)" style="width: 30px"
														v-model="item.quantity" readonly />
													<button class="btn btn-sm btn-outline-secondary border-300 px-2"
														data-type="plus" @click="item.quantity++"
														:disabled="!(item.quantity < item.quantityCurrent)">+</button>
												</div>
											</td>
											<td class="text-end align-middle">
												<span v-if="typeOrderId != 7">{{ methodFormatNumber(item.price)
													}}</span>
												<input v-else
													class="form-control form-control-sm text-end px-2 input-spin-none"
													type="number" :min="item.price"
													aria-label="Amount (to the nearest dollar)" style="width: 70px"
													v-model="item.price" />
											</td>
											<td class="text-end align-middle">{{ methodFormatNumber(item.quantity *
				item.price) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="row g-0 justify-content-end">
								<div class="col-auto">
									<table class="table table-sm table-borderless fs--1 text-end">
										<tr>
											<th class="text-900">Subtotal:</th>
											<td class="fw-semi-bold" style="width: 100px">${{
				methodFormatNumber(totalOrderProducts) }}</td>
											<td></td>
										</tr>
										<tr>
											<th class="text-900">Diezmo:</th>
											<td class="fw-semi-bold">${{ methodFormatNumber(titheOrderProducts) }}</td>
											<td></td>
										</tr>
										<tr class="border-top">
											<th class="text-900">Total:</th>
											<td class="fw-semi-bold">${{ methodFormatNumber(parseInt(objectOrder.total)
				+ parseInt((objectOrder.tithe == null) ? 0 : objectOrder.tithe)) }}</td>
											<td></td>
										</tr>
									</table>
								</div>
							</div>
							<div class="pt-3 text-end">
								<div class="form-check form-check-inline" v-if="typeOrderId == 6">
									<input class="form-check-input" id="inlineCheckbox1" type="checkbox" value="option1"
										v-model="objectOrder.paid" />
									<label class="form-check-label" for="inlineCheckbox1">Devolución de material
										fallado</label>
								</div>
								<button class="btn btn-sm btn-primary" type="button" :disabled="disableButtonSaveOrder"
									@click="saveOrderComponentLocal">Guardar Pedido</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import teams from '@/mixins/teams'
import orders from '@/mixins/orders'
import productPriceList from '@/mixins/products/productPriceList.js'
import legalDocuments from '@/mixins/legalDocuments'

export default {
	name: 'OrderColportajeTransferModalComponent',
	mixins: [mixin, axiosAction, errors, teams, orders, productPriceList, legalDocuments],
	props: ['team', 'listProducts', 'client', 'teamId', 'typeOrderId'],
	data() {
		return {
			teamIdLocal: 0,
			priceListsIdSelect: 5
		}
	},
	components: {
	},
	computed: {
		disableButtonSaveOrder() {
			if (this.teamIdLocal < 1 && this.typeOrderId == 4)
				return true
			if (this.objectOrder.client_id < 1 && this.typeOrderId == 4)
				return true
			if (this.totalOrderProducts == 0)
				return true

			if (this.typeOrderId == 7 && this.objectLegalDocument.receiving_entity_name.trim().length < 6)
				return true
			if (this.typeOrderId == 7 && this.objectLegalDocument.receiving_entity_document_number.trim().length < 9)
				return true
			if (this.typeOrderId == 7 && this.objectLegalDocument.receiving_entity_economic_activity.trim().length < 6)
				return true
			if (this.typeOrderId == 7 && this.objectLegalDocument.receiving_entity_address_street.trim().length < 6)
				return true
			if (this.typeOrderId == 7 && this.objectLegalDocument.receiving_entity_address_comuna.trim().length < 3)
				return true
			if (this.typeOrderId == 7 && this.objectLegalDocument.receiving_entity_address_city.trim().length < 3)
				return true
			return false
		},
		listTeamsFilter() {
			let list = []

			list = this.listTeams.filter(t => {
				return t.status_id == 42
			})
			return list
		},
		totalOrderProducts() {
			let total = this.objectOrder.order_products.reduce(function (a, b) {
				let c = b.quantity * b.price
				return a + c
			}, 0);
			this.objectOrder.total = total
			return total.toFixed(0)
		},

	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getProductPriceListNotFreightResult': this.getProductPriceListNotFreightResult(response); break;
				case 'getProductPriceListNotTitheResult': this.getProductPriceListNotTitheResult(response); break;
				case 'getAllTeamsResult': this.getAllTeamsResult(response); break;
				case 'saveOrderResult': this.saveOrderResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setOrderColportajeTransferInit()
		},
		getAllTeamsLocal() {
			this.getAllTeamByUserClient()
		},
		getAllTeamsResultLocal(response) { },
		getProductPriceListNotFreightResultLocal(response) { },
		getProductPriceListNotTitheResultLocal(response) { },
		btnCloseOrderColportajeTransferModalComponent() { },
		btnShowOrderColportajeTransferModalComponent() {
			this.clearObjectOrder()
			this.clearObjectLegalDocument()
			this.getProductPriceListNotTithe(this.priceListsIdSelect)
			this.getProductPriceListNotFreight(this.priceListsIdSelect)
			this.objectOrder.client_id = this.client.id
			this.objectOrder.team_id = this.teamId
			for (let i = 0; i < this.listProducts.length; i++) {
				this.objectOrder.order_products.push({
					exists: 0,
					id: 0,
					order_id: 0,
					product_id: this.listProducts[i].product_id,
					product: this.listProducts[i].product,
					quantity: 0,
					quantityCurrent: this.listProducts[i].quantity,
					price: this.listProducts[i].price,
					original_price: this.listProducts[i].price,
					tax: this.listProducts[i].tax,
					price_list_id: this.listProducts[i].price_list_id,
					factor: this.listProducts[i].factor,
					synthetic: this.listProducts[i].synthetic,
					tithable: this.listProducts[i].tithable,
					bonusable: this.listProducts[i].bonusable,
					with_freight: this.listProducts[i].with_freight,
					created_at: '',
					updated_at: ''
				})
			}
		},
		saveOrderComponentLocal() {
			this.objectOrder.team_id_to = this.teamIdLocal
			this.objectOrder.type_order_id = this.typeOrderId

			if (this.typeOrderId == 7) {
				this.objectOrder.order_observations[0].observation = 'Facturar a:'
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.receiving_entity_name
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.receiving_entity_document_number
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.receiving_entity_economic_activity
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.receiving_entity_address_street
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.receiving_entity_address_comuna
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.receiving_entity_address_city
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.contact
				this.objectOrder.order_observations[0].observation += '|' + this.objectLegalDocument.phone
			}
			this.saveOrderTransfer(this.objectOrder)
		},
		saveOrderResultLocal(response) {
			this.$emit('saveOrderComponent', response)
			this.$refs.btnCloseOrderColportajeTransferModalComponent.click()
		},
		setOrderColportajeTransferInit() {
			this.getAllTeamsLocal()
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>