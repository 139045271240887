var statisticsTeams = {
    data() {
        return {
            filters: {
                entityIds: [],
                typeTeamIds: [],
                fromDate: '',
                toDate: '',
                statusIds: [42, 43]
            },
            objectResult: null,
            listTeamsFound: [],
            loadingTeamsStatistic: false,
            quantitiesBooks: [
                { name: 'Libros', total: 0, ids: [-1] },
                { name: 'Conflicto', total: 0, ids: [11886] },
                { name: 'Revistas', total: 0, ids: [286] },
            ]

        }
    },
    computed: {
        totalQuantityBooks() {
            let i = -1
            let e = false
            this.quantitiesBooks.forEach(q => {
                q.total = 0
            })
            this.listProductsFilter(-1).forEach(p => {
                i = this.productNotStadistic.map(e => parseInt(e)).indexOf(parseInt(p.id))
                if (i == -1) {
                    e = false
                    this.quantitiesBooks.forEach(q => {
                        q.ids.forEach(id => {
                            if (parseInt(id) == parseInt(p.id)) {
                                e = true
                                q.total += parseInt(p.quantity)
                            }
                        })
                    })
                    if (e == false) {
                        this.quantitiesBooks[0].total += parseInt(p.quantity)
                    }
                }
            })
            return this.quantitiesBooks
        }
    },
    filters: {
    },
    methods: {
        getStatisticsTeamByFilters(address) {
            this.loadingTeamsStatistic = true
            this.objectResult = null
            let url = this.getApiBackend + 'statistic/team/' + address
            console.log(url, this.filters)
            this.axiosActionIS(url, 'POST', 'getStatisticsTeamByFiltersResult', this.filters)
        },
        getStatisticsTeamByFiltersResult(response) {
            this.loadingTeamsStatistic = false
            if (response.data.success) {
                this.objectResult = response.data.data
            }
            this.getStatisticsTeamByFiltersResultLocal(response)
        },
        getStatisticsTeamByFiltersResultLocal(response) {
            console.log('getStatisticsTeamByFiltersResultLocal no desarrollado', response)
        },
    },
};

export default statisticsTeams;