/* Rquiere de errors.js */

var axiosAction = {
	methods: {
		axiosAction(url, method, headers, responseLocal, data = []) {
			switch (method) {
				case 'DELETE':
					this.axiosDelete(url, headers, responseLocal)
					break;
				case 'GET':
					this.axiosGet(url, headers, responseLocal)
					break;
				case 'POST':
					this.axiosPost(url, data, headers, responseLocal)
					break;
				case 'PUT':
					this.axiosPut(url, data, headers, responseLocal)
					break;
				default:
					console.warn('No está determinado el metodo de comunicación (DELETE, GET, POST, PUT)')
					break;
			}
		},
		axiosDelete(url, headers, responseLocal) {
			this.axios.delete(url, { headers: headers })
				.then(response => { this.axiosResultLocal(responseLocal, response) })
				.catch(error => { this.errorHttpHandling(error) })
		},
		axiosGet(url, headers, responseLocal) {
			this.axios.get(url, { headers: headers })
				.then(response => { this.axiosResultLocal(responseLocal, response) })
				.catch(error => { this.errorHttpHandling(error) })
		},
		axiosPost(url, data, headers, responseLocal) {
			this.axios.post(url, data, { headers: headers })
				.then(response => { this.axiosResultLocal(responseLocal, response) })
				.catch(error => { this.errorHttpHandling(error) })
		},
		axiosPut(url, data, headers, responseLocal) {
			this.axios.put(url, data, { headers: headers })
				.then(response => { this.axiosResultLocal(responseLocal, response) })
				.catch(error => { this.errorHttpHandling(error) })
		},
		axiosResultLocal(responseLocal, response) {
			console.groupCollapsed('Resultado de consulta axios, funcion por omision (axiosResultLocal)')
			console.log(responseLocal)
			console.log(response)
			console.groupEnd()
		}
	},
};

export default axiosAction;