/* Rquiere de mixin.js */

var status = {
	data() {
		return {
			listStatus: [],
			paginationStatus: [],
			searchStatus: '',
			typeStatusId: -1
		}
	},
	computed: {
	},
	filters: {
	},
	methods: {
		changeSelectStatusItem(item) {
			let statusSelected = (localStorage.statusSelected != undefined) ? JSON.parse(localStorage.statusSelected) : []
			let index = statusSelected.map((el) => parseInt(el)).indexOf(parseInt(item.id))
			if (item.selected && index == -1) {
				statusSelected.push(item.id)
			} else if (!item.selected && index > -1) {
				let deleteStatusSelected = statusSelected.splice(index, 1)
			}
			localStorage.setItem('statusSelected', JSON.stringify(statusSelected))
		},
		getAllStatus(page = 1) {
			let parameters = '?search=' + this.searchStatus + '&page=' + page + '&typeStatusId=' + this.typeStatusId
			let url = this.getApiBackendNew + 'status' + parameters
			this.axiosActionIS(url, 'GET', 'getAllStatusResult')
		},
		getAllStatusResult(response) {
			if (response.data.success == true) {
				this.listStatus = response.data.data
				this.paginationStatus = response.data.paginate
			}
			this.getAllStatusResultLocal(response)
		},
		getAllStatusResultLocal(response) {
			console.log('getAllStatusResultLocal no desarrollado', response)
		},
		getSelectedListStatus() {
			let ids = ''
			let found = true
			let statusSelected = (localStorage.statusSelected != undefined) ? JSON.parse(localStorage.statusSelected) : []
			Object.values(statusSelected).forEach(item => {
				let index = this.listStatus.map((el) => parseInt(el.id)).indexOf(parseInt(item))
				if (index > -1) {
					this.listStatus[index].selected = true
					found = false
				}
			})
			Object.values(this.listStatus).forEach(item => {
				item.selected = (item.selected == undefined) ? found : item.selected
				if (item.selected) {
					let index = statusSelected.map((el) => parseInt(el)).indexOf(parseInt(item.id))
					if (index == -1)
						statusSelected.push(item.id)
					ids += (ids != '') ? ',' + item.id : item.id
				}
			})
			localStorage.setItem('statusSelected', JSON.stringify(statusSelected))
			return ids
		},
		resultAxiosLocalStatus(responseLocal, response) {
			this.$emit('hideLockScreen')
			switch (responseLocal) {
				case 'getAllStatusResult': this.getAllStatusResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')'); break;
			}
		},
	},
};

export default status;